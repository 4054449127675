export default {

    state: {
        showaccount: false
    },

    getters: {
        SHOWACCOUNT: state => {
            return state.showaccount
        }
    },
    mutations: {
        SET_SHOWACCOUNT(state, payload) {
            state.showaccount = payload
        }
    },
    actions: {
        
    },
    modules: {
    }
}