<template>
  <q-dialog
    v-model="show"
    persistent
    transition-show="scale"
    transition-hide="scale"
  >
    <q-card class="mypdial text-white posttypedial">
      <q-card-section class="dialhead">
        <div class="row">
          <div class="col-6">
            <div class="text-h6">Вид почтового отправления</div>
          </div>
          <div class="col-6">
            <div class="text-h6 q-ml-sm">Дополнительные услуги</div>
          </div>
        </div>
      </q-card-section>
      <q-card-section>
        <div class="row">
          <div class="col-6">
            <div class="row">
              <div class="col q-mb-xs">
                <q-btn-toggle
                  v-model="selector"
                  toggle-color="accent"
                  unelevated
                  class="bg-ptdbg q-mb-sm"
                  no-caps
                  :options="[
                    { label: 'Письмо', value: 'pisma' },
                    { label: 'Бандероль', value: 'banderoli' },
                    { label: 'Посылка', value: 'posilki' }
                  ]"
                />
              </div>
            </div>
            <div class="row">
              <div class="col" v-show="selector == 'pisma'">
                <q-option-group
                  size="xs"
                  dark
                  v-model="postType"
                  :options="pisma"
                  color="accent"
                />
              </div>
              <div class="col" v-show="selector == 'banderoli'">
                <q-option-group
                  size="xs"
                  dark
                  v-model="postType"
                  :options="banderoli"
                  color="accent"
                />
              </div>
              <div class="col" v-show="selector == 'posilki'">
                <q-option-group
                  size="xs"
                  dark
                  v-model="postType"
                  :options="posilki"
                  color="accent"
                />
              </div>
            </div>
          </div>
          <div class="col-6">
            <div v-show="invoices.length > 0">
              <q-toggle
                v-for="(qitem, index) in invoices"
                :key="index"
                size="xs"
                color="accent"
                :label="qitem.label"
                v-model="postServices"
                :val="qitem.value"
                @input="
                  postServices =
                    postServices.length > 0
                      ? postServices.filter(item => {
                          if (qitem.value[0] !== 'i') {
                            return true;
                          } else {
                            if (item[0] == 'i') {
                              if (qitem.value == item) {
                                return true;
                              } else {
                                return false;
                              }
                            } else {
                              return true;
                            }
                          }
                        })
                      : postServices
                "
                v-bind:ref="'i' + index"
              />
              <q-input
                v-show="selector != 'posilki'"
                dense
                bg-color="white"
                color="accent"
                clearable
                label="Дополнительная отметка отправителя"
                filled
                class="q-mt-sm q-ml-sm"
                v-model="invoiceTip"
                :disable="getInvoiceTipFromRecep"
              />
              <q-toggle
                size="xs"
                color="accent"
                v-model="getInvoiceTipFromRecep"
                label="Использовать пометку получателя для отметки отправителя"
              />
            </div>
            <div
              v-show="
                postType !== null && postType[0] == 'p' && selector == 'posilki'
              "
            >
              <q-select
                color="black"
                class="q-mt-md"
                bg-color="white"
                popup-content-class="text-black"
                filled
                v-model="postPack"
                :options="pack"
                dense
                label="Выберите тип упаковки"
                emit-value
                map-options
              />
            </div>
          </div>
        </div>
      </q-card-section>

      <q-card-actions
        align="right"
        class="q-px-md q-pt-md q-pb-md text-white dialbtns"
      >
        <q-btn
          unelevated
          label="Ок"
          no-caps
          @click="done(true)"
          color="accent"
        />
        <q-btn
          unelevated
          label="Отмена"
          no-caps
          @click="done(false)"
          color="accent"
          class="q-ml-md"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import PostData from "src/service/classes/posttypes.js";
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    recepientsIds: {
      type: Array,
      default: null
    },
    openedRecep: {
      type: Object,
      default: null
    }
  },
  computed: {
    mass() {
      return this.$router.currentRoute.name == "mass";
    },
    pisma() {
      let pisma = [];
      for (let [key, value] of Object.entries(this.postData.types)) {
        if (key[0] == "e") {
          let nValue = Object.assign({}, value);
          nValue.value = key;
          pisma.push(nValue);
        }
      }
      return pisma;
    },
    banderoli() {
      let band = [];
      for (let [key, value] of Object.entries(this.postData.types)) {
        if (key[0] == "b") {
          let nValue = Object.assign({}, value);
          nValue.value = key;
          band.push(nValue);
        }
      }
      return band;
    },
    posilki() {
      let posil = [];
      for (let [key, value] of Object.entries(this.postData.types)) {
        if (key[0] == "p") {
          let nValue = Object.assign({}, value);
          nValue.value = key;
          posil.push(nValue);
        }
      }
      return posil;
    },
    pack() {
      let pack = [];
      for (let [key, value] of Object.entries(this.postData.boxTypes)) {
        let nValue = Object.assign({}, value);
        nValue.value = key;
        pack.push(nValue);
      }
      return pack;
    },
    invoices() {
      if (this.postType !== null) {
        let inv = [];

        for (let [key, value] of Object.entries(this.postData.services)) {
          if (this.postData.types[this.postType].services.includes(key)) {
            let nValue = Object.assign({}, value);
            nValue.value = key;
            inv.push(nValue);
          }
        }
        return inv;
      } else {
        return [];
      }
    }
  },
  data() {
    return {
      selector: "pisma",
      postData: new PostData(),
      postType: "e1",
      postServices: [],
      postPack: "k1",
      invoiceTip: "",
      getInvoiceTipFromRecep: false
    };
  },
  watch: {
    show(val) {
      if (val == true) {
        this.postType =
          this.openedRecep !== null
            ? this.openedRecep.posttype !== null
              ? this.openedRecep.posttype
              : "e2"
            : null;
        this.postServices =
          this.openedRecep !== null ? this.openedRecep.postservices : [];
        this.postPack =
          this.openedRecep !== null
            ? this.openedRecep.postpack !== null
              ? this.openedRecep.postpack
              : "k1"
            : null;
        this.invoiceTip =
          this.openedRecep !== null ? this.openedRecep.invoicetip : "";
      }
    },
    selector(val) {
      if (val == "posilki") {
        if (this.openedRecep.posttype === null) {
          this.postType = "p1";
          this.postPack = "k1";
        }
      } else if (val == "pisma") {
        if (this.openedRecep.posttype === null) {
          this.postType = "e2";
          this.postPack = "k1";
        }
      } else if (val == "banderoli") {
        if (this.openedRecep.posttype === null) {
          this.postType = "b2";
          this.postPack = "k1";
        }
      }
    }
  },
  methods: {
    generateSinglePostData() {
      this.$store.commit("SET_POST_DATA_TO_RECEP", {
        recep: this.openedRecep,
        newData: {
          posttype: this.postType,
          postservices:
            this.postType[0] == "p"
              ? this.postServices.filter(item => item[0] != "i")
              : this.postServices,
          postpack: this.postType[0] == "p" ? this.postPack : null,
          invoicetip: this.postType[0] == "p" ? "" : this.invoiceTip,
          getInvoiceTipFromRecep: this.getInvoiceTipFromRecep
        }
      });
    },
    async generateMassPostData(clear = false) {
      this.$store.dispatch("CHANGE_AND_REPLACE_RECEPIENTS_IN_LIST", {
        selected: this.recepientsIds,
        newData: {
          posttype: clear ? null : this.postType,
          postservices: clear ? [] : this.postServices,
          postpack: this.postPack,
          invoicetip: clear ? "" : this.invoiceTip,
          getInvoiceTipFromRecep: this.getInvoiceTipFromRecep
        },
        progressText: clear
          ? "Удаление сведений о почтовом отправлении"
          : "Заполнение сведений о почтовом отправлении"
      });
    },
    done(payload) {
      if (payload == true) {
        if (this.mass && this.openedRecep === null) {
          this.generateMassPostData();
        } else {
          this.generateSinglePostData();
          this.$store.commit("UPDATE_CANVAS");
        }
      }
      this.$emit("update:show", false);
      if (this.mass) {
        this.$emit("update:openedRecep", null);
      }
    },
    clearPostTypeMass() {
      this.generateMassPostData(true);
    }
  },
  mounted() {
    this.$eventBus.$on("clear-posttype-mass", this.clearPostTypeMass);
  },
  beforeDestroy() {
    this.$eventBus.$off("clear-posttype-mass");
  }
};
</script>

<style lang="sass">
.posttypedial
  width: 750px
  max-width: 750px!important
.dialhead, .dialbtns
  background: rgba(0,0,0,0.15)
.headerptd
  margin-left: 6px
  margin-bottom: 4px
  display: block
.bg-ptdbg
  background: #007173
</style>
