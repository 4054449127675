<template>
  <q-dialog
    v-model="show"
    persistent
    transition-show="scale"
    transition-hide="scale"
  >
    <q-card class="mypdial text-white invdial">
      <q-card-section class="dialhead">
        <div class="text-h6">Тип уведомления</div>
      </q-card-section>
      <q-card-section>
        <div class="row">
          <div class="col">
            <q-option-group
              v-model="invoiceType"
              :options="invoiceTypeOptions"
              color="accent"
              dark
              inline
            />
          </div>
        </div>
      </q-card-section>
      <q-card-section class="dialhead">
        <div class="text-h6">Вид и категория почтового отправления</div>
      </q-card-section>
      <q-card-section>
        <div class="row">
          <div class="col">
            <q-option-group
              v-model="postType"
              :options="postTypeOptions"
              color="accent"
              dark
            />
          </div>
          <div class="col">
            <q-option-group
              v-model="postCategory"
              :options="postCategoryOptions"
              color="accent"
              dark
            />
          </div>
        </div>
      </q-card-section>
      <q-card-section class="dialhead">
        <div class="text-h6">Дополнительная отмека отправителя</div>
      </q-card-section>
      <q-card-section>
        <div class="row">
          <div class="col">
            <q-input
              dense
              bg-color="white"
              color="accent"
              clearable
              filled
              class="q-my-xs"
              v-model="invoiceTip"
            />
          </div>
        </div>
      </q-card-section>

      <q-card-actions
        align="right"
        class="q-px-md q-pt-md q-pb-md text-white dialbtns"
      >
        <q-btn
          unelevated
          label="Ок"
          no-caps
          @click="done(true)"
          color="accent"
        />
        <q-btn
          unelevated
          label="Отмена"
          no-caps
          @click="done(false)"
          color="accent"
          class="q-ml-md"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>


<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    recepientsIds: {
      type: Array,
      default: null
    },
    openedRecep: {
      type: Object,
      default: null
    }
  },
  computed: {
    mass() {
      return this.$router.currentRoute.name == "mass";
    }
  },
  data() {
    return {
      invoiceTip: null,
      invoiceType: null,
      invoiceTypeOptions: [
        {
          label: "Простое",
          value: 0
        },
        {
          label: "Заказное",
          value: 1
        }
      ],
      postType: null,
      postTypeOptions: [
        {
          label: "Письма",
          value: 0
        },
        {
          label: "Бандероли",
          value: 1
        },
        {
          label: "Посылки",
          value: 2
        },
        {
          label: "Отправления 1-го класса",
          value: 3
        }
      ],
      postCategory: null,
      postCategoryOptions: [
        {
          label: "Заказного(ой)",
          value: 0
        },
        {
          label: "С объявленной ценностью",
          value: 1
        },
        {
          label: "Обыкновенной",
          value: 2
        }
      ]
    };
  },
  watch: {
    show(val) {
      if (val == true) {
        this.invoiceType =
          this.openedRecep !== null ? this.openedRecep.invoicetype : null;
        this.postType =
          this.openedRecep !== null ? this.openedRecep.posttype : null;
        this.postCategory =
          this.openedRecep !== null ? this.openedRecep.postcategory : null;
        this.invoiceTip =
          this.openedRecep !== null ? this.openedRecep.invoicetip : null;
      }
    }
  },

  methods: {
    generateSingleInvoice() {
      this.$store.commit("SET_INVOICE_TO_RECEP", {
        recep: this.openedRecep,
        invoicetype: this.invoiceType,
        posttype: this.postType,
        postcategory: this.postCategory,
        invoicetip: this.invoiceTip
      });
    },
    async generateMassInvoice(clear = false) {
      this.$store.dispatch("CHANGE_AND_REPLACE_RECEPIENTS_IN_LIST", {
        selected: this.recepientsIds,
        newData: {
          invoicetype: clear ? null : this.invoiceType,
          posttype: clear ? null : this.postType,
          postcategory: clear ? null : this.postCategory,
          invoicetip: this.invoiceTip
        },
        progressText: clear
          ? "Удаление сведений о почтовом отправлении"
          : "Заполнение сведений о почтовом отправлении"
      });
    },
    done(payload) {
      if (payload == true) {
        if (this.mass && this.openedRecep === null) {
          this.generateMassInvoice();
        } else {
          this.generateSingleInvoice();
          this.$store.commit("UPDATE_CANVAS");
          this.$emit("print-invoice");
        }
      }
      this.$emit("update:show", false);
      if (this.mass) {
        this.$emit("update:openedRecep", null);
      }
    },
    clearInvoice(payload) {
      if (payload.field == "invoice") {
        this.$store.commit("SET_INVOICE_TO_RECEP", {
          recep: payload.recep,
          invoicetype: null,
          posttype: payload.recep.posttype,
          postcategory: payload.recep.postcategory,
          invoicetip: null
        });
      } else if (payload.field == "post") {
        this.$store.commit("SET_INVOICE_TO_RECEP", {
          recep: payload.recep,
          invoicetype: payload.recep.invoicetype,
          posttype: null,
          postcategory: null,
          invoicetip: null
        });
      }
    },
    clearInvoiceMass() {
      this.generateMassInvoice(true);
    }
  },
  mounted() {
    this.$eventBus.$on("clear-invoice", this.clearInvoice);
    this.$eventBus.$on("clear-invoice-mass", this.clearInvoiceMass);
  },
  beforeDestroy() {
    this.$eventBus.$off("clear-invoice");
    this.$eventBus.$off("clear-invoice-mass");
  }
};
</script>

<style lang="sass">
.invdial
  width: 520px
.dialhead, .dialbtns
  background: rgba(0,0,0,0.15)
</style>