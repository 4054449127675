export default {
  mutations: {
    //metrica mutations
    ADDED_LOGO_FROM_FILE: () => {},
    ADDED_LOGO_FROM_SITE: () => {},
    SINGLEPRINT_ENVELOPE: () => {},
    SINGLEPRINT_INVOICE: () => {},
    SINGLEPRINT_INVENTORY: () => {},
    MASSPRINT_ENVELOPE: () => {},
    MASSPRINT_INVOICE: () => {},
    MASSPRINT_INVENTORY: () => {},
    MASSPRINT_REESTR: () => {},
    MASSPRINT_POSTCALC: () => {},
    RECOVERY_EMAIL_REQUEST: () => {},
    RECOVERY_EMAIL_SUCCESS: () => {},
    LICENCE_FINISHED: () => {},
    CONFIRM_EMAIL: () => {}
  }
};
