import { date } from "quasar";
import PostData from "src/service/classes/posttypes.js";
import { rubles } from "rubles";
export default class F103Html {
  constructor(sender, recepients, settings, f103Params) {
    this.pData = new PostData();
    this.fFamily = "Arial";
    this.fsHead = 8;
    this.fsTable = 6;
    this.fsTableHead = 7;
    this.fsTableRow = 7;
    this.fsCaption = 6;
    this.fsFooter = 6;
    this.fsFooterTitle = 8;
    this.fsFooterCaption = 4;
    this.hrHeader = 11;
    this.hrHeaderCaption = 7;
    this.hrEmpty = 2;
    this.hrTableHead = 9;
    this.hrTableRow = 9;
    this.hrTableTotalRow = 12;
    this.hrFooter = 9;
    this.hrFooterBold = 12;
    this.hrFooterCaption = 7;
    this.hrFooterCaptionBold = 10;

    ////
    this.bb = ` data-b-b-s="thin"  data-b-b-c="#000000"`;
    this.textHeader = ` data-f-name="${this.fFamily}" data-f-sz="${this.fsHead}" data-f-bold="true" data-a-v="middle"`;
    this.textCaption = ` data-f-name="${this.fFamily}" data-f-sz="${this.fsCaption}" data-a-h="center" `;
    this.emptyRow = ` data-height="${this.hrEmpty}"`;
    this.square = ` data-f-name="${this.fFamily}" data-f-sz="${this.fsHead}" data-b-a-s="thin"  data-b-a-c="#000000" data-f-bold="true" data-a-v="middle" data-a-h="center"`;
    this.tHead = `data-a-wrap="true" data-f-name="${this.fFamily}" data-f-sz="${this.fsTableHead}" data-a-h="center" data-a-v="middle"`;
    this.tRow = `data-a-wrap="true" data-f-name="${this.fFamily}" data-f-sz="${this.fsTableRow}" data-a-h="center" data-a-v="middle"`;
    this.tRowLeft = `data-a-wrap="true" data-f-name="${this.fFamily}" data-f-sz="${this.fsTableRow}" data-a-h="left" data-a-v="middle"`;
    this.tTotalRow = `data-f-bold="true" data-b-a-s="medium" data-b-a-c="#000000" data-a-wrap="true" data-f-name="${this.fFamily}" data-f-sz="${this.fsTableRow}" data-a-h="center" data-a-v="middle"`;
    this.blr = `data-b-l-s="thin" data-b-r-s="thin" data-b-l-c="#000000" data-b-r-c="#000000"`;
    this.bt = ` data-b-t-s="thin"  data-b-t-c="#000000"`;
    this.tRowFooter = `data-f-name="${this.fFamily}" data-f-sz="${this.fsFooter}" data-f-bold="true" data-a-v="middle"`;
    this.tRowFooterBold = `data-f-name="${this.fFamily}" data-f-sz="${this.fsFooterTitle}" data-f-bold="true" data-a-v="middle"`;
    this.tRowFooterCaption = `data-f-name="${this.fFamily}" data-f-sz="${this.fsFooterCaption}" data-a-v="middle" data-a-h="center"`;

    this.title = `<tr data-height="${this.hrHeader}">
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td colspan="5" data-f-name="${this.fFamily}" data-f-sz="${
      this.fsHead
    }" data-a-h="right" data-f-bold="true" data-a-v="middle">СПИСОК
            №
        </td>
        <td colspan="4" data-b-b-s="thin"  data-b-b-c="#000000" data-f-name="${
          this.fFamily
        }" data-f-sz="${
      this.fsHead
    }" data-a-v="middle" data-f-bold="true" data-a-h="center">${
      f103Params.listNumber
    }</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr  data-height="${this.hrHeader}">
        <td colspan="24"></td>
        <td colspan="15" ${this.textHeader} data-a-h="center">внутренних
            почтовых
            отправлений
        </td>
        <td colspan="2"></td>
        <td colspan="25"></td>
      </tr>
      <tr  data-height="${this.hrHeader}">
        <td colspan="28"></td>
        <td colspan="6" ${this.bb}  ${
      this.textHeader
    } data-a-h="center">${date.formatDate(
      f103Params.listDate,
      "DD.MM.YYYY"
    )}</td>
        <td colspan="32"></td>
      </tr>
      <tr  data-height="${this.hrHeaderCaption}" >
        <td colspan="28"></td>
        <td colspan="6" ${this.textCaption}>(дата)</td>
        <td colspan="32"></td>
      </tr>
      <tr ${this.emptyRow}>
        <td colspan="66" ></td>
      </tr>
      <tr  data-height="${this.hrHeader}">
        <td></td>
        <td colspan="9" ${this.textHeader} data-a-h="left">Вид
            РПО:
        </td>
        <td colspan="37" ${this.bb} ${
      this.textHeader
    } data-a-h="left">${this.pData.getMailTypeName(recepients[0].posttype)}</td>
        <td colspan="19"></td>
      </tr>
      <tr   data-height="${this.hrEmpty}">
        <td colspan="66"></td>
      </tr>
      <tr data-height="${this.hrHeader}" >
        <td></td>
        <td colspan="9" ${this.textHeader}  data-a-h="left">Способ
            оплаты:
        </td>
        <td colspan="10"  ${this.textHeader}  data-a-h="left">-основной
            услуги
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td ${this.square}>${
      f103Params.basicPaymentType == "nal" ? "x" : ""
    }</td>
        <td></td>
        <td colspan="7" ${this.textHeader}  data-a-h="left">
            </span>марки      или
        </td>
        <td></td>
        <td  ${this.square}>${
      f103Params.basicPaymentType == "beznal" ? "x" : ""
    }</td>
        <td></td>
        <td colspan="12" ${this.textHeader}  data-a-h="left">безналичный
            расчет
        </td>
        <td colspan="19"></td>
      </tr>
      <tr data-height="${this.hrEmpty}">
        <td colspan="66"></td>
      </tr>
      <tr data-height="${this.hrHeader}" >
        <td></td>
        <td colspan="9" ${this.textHeader}  data-a-h="left">
        </td>
        <td colspan="10"  ${
          this.textHeader
        }  data-a-h="left">-уведомлений о вручении
        </td>
        <td></td>
        <td></td>
        <td></td>
        <td ${this.square}>${
      f103Params.servicePaymentType == "nal" ? "x" : ""
    }</td>
        <td></td>
        <td colspan="7" ${this.textHeader}  data-a-h="left">
            </span>марки      или
        </td>
        <td></td>
        <td  ${this.square}>${
      f103Params.servicePaymentType == "beznal" ? "x" : ""
    }</td>
        <td></td>
        <td colspan="12" ${this.textHeader}  data-a-h="left">безналичный
            расчет
        </td>
        <td colspan="19"></td>
      </tr>
      <tr data-height="${this.hrEmpty}">
        <td colspan="66"></td>
      </tr>
      <tr data-height="${this.hrHeader}">
        <td></td>
        <td colspan="9" ${this.textHeader}  data-a-h="left">Способ
            пересылки:
        </td>
        <td colspan="37" ${this.textHeader}  data-a-h="left" ${
      this.bb
    }>Наземный</td>
        <td colspan="19"></td>
      </tr>
      <tr data-height="${this.hrEmpty}">
        <td colspan="66"></td>
      </tr>
      <tr data-height="${this.hrHeader}" >
        <td></td>
        <td colspan="9" ${this.textHeader}  data-a-h="left" >Отправитель:</td>
        <td colspan="37"  ${this.textHeader}  data-a-h="left"  ${
      this.bb
    } data-a-wrap="true">${sender.fullname}</td>
        <td></td>
        <td colspan="3" ${this.textHeader}  data-a-h="left">email:</td>
        <td colspan="15" ${this.textHeader}  data-a-h="left" ${this.bb}></td>
      </tr>
      <tr data-height="${this.hrEmpty}">
        <td colspan="66"></td>
      </tr>
      <tr data-height="${this.hrEmpty}">
        <td colspan="66"></td>
      </tr>
      <tr data-height="${this.hrEmpty}">
        <td colspan="66" data-b-t-s="thin" data-b-t-c="#000000"  data-b-r-s="thin" data-b-r-c="#000000"  data-b-l-s="thin" data-b-l-c="#000000"></td>
      </tr>
      <tr  data-height="${this.hrHeader}">
        <td data-b-l-s="thin" data-b-l-c="#000000"></td>
        <td colspan="36" ${
          this.textHeader
        }  data-a-h="left" >С услугой «SMS-уведомление» на номер мобильного телефона отправителя РПО
        </td>
        <td ${this.square}>${
      f103Params.senderPhone !== null && f103Params.senderPhone[0] !== undefined
        ? f103Params.senderPhone[0]
        : ""
    }</td>
        <td ${this.square}>${
      f103Params.senderPhone !== null && f103Params.senderPhone[1] !== undefined
        ? f103Params.senderPhone[1]
        : ""
    }</td>
        <td ${this.square}>${
      f103Params.senderPhone !== null && f103Params.senderPhone[2] !== undefined
        ? f103Params.senderPhone[2]
        : ""
    }</td>
        <td ${this.square}>${
      f103Params.senderPhone !== null && f103Params.senderPhone[3] !== undefined
        ? f103Params.senderPhone[3]
        : ""
    }</td>
        <td ${this.square}>${
      f103Params.senderPhone !== null && f103Params.senderPhone[4] !== undefined
        ? f103Params.senderPhone[4]
        : ""
    }</td>
        <td ${this.square}>${
      f103Params.senderPhone !== null && f103Params.senderPhone[5] !== undefined
        ? f103Params.senderPhone[5]
        : ""
    }</td>
        <td ${this.square}>${
      f103Params.senderPhone !== null && f103Params.senderPhone[6] !== undefined
        ? f103Params.senderPhone[6]
        : ""
    }</td>
        <td ${this.square}>${
      f103Params.senderPhone !== null && f103Params.senderPhone[7] !== undefined
        ? f103Params.senderPhone[7]
        : ""
    }</td>
        <td ${this.square}>${
      f103Params.senderPhone !== null && f103Params.senderPhone[8] !== undefined
        ? f103Params.senderPhone[8]
        : ""
    }</td>
        <td ${this.square}>${
      f103Params.senderPhone !== null && f103Params.senderPhone[9] !== undefined
        ? f103Params.senderPhone[9]
        : ""
    }</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td  data-b-r-s="thin" data-b-r-c="#000000"></td>
      </tr>
      <tr data-height="${this.hrEmpty}">
        <td colspan="66"  data-b-l-s="thin" data-b-l-c="#000000"  data-b-r-s="thin" data-b-r-c="#000000"></td>
      </tr>
      <tr data-height="${this.hrHeader}">
        <td  data-b-l-s="thin" data-b-l-c="#000000"></td>
        <td ${this.square}>${
      f103Params.smsDeliveryToRecep == true ? "x" : ""
    }</td>
        <td></td>
        <td colspan="17" ${
          this.textHeader
        }  data-a-h="left">«SMS-уведомление о вручении РПО»
        </td>
        <td ${this.square}>${
      f103Params.smsDeliveryToPost == true ? "x" : ""
    }</td>
        <td></td>
        <td colspan="24" ${
          this.textHeader
        }  data-a-h="left">«SMS-уведомление о поступлении РПО в адресное ОПС»
        </td>
        <td colspan="2"></td>
        <td ${this.square}>${f103Params.smsPacket == true ? "x" : ""}</td>
        <td></td>
        <td colspan="14" ${this.textHeader}  data-a-h="left">«SMS-пакет»</td>
        <td></td>
        <td  data-b-r-s="thin" data-b-r-c="#000000"></td>
      </tr>
      <tr data-height="${this.hrEmpty}" >
        <td colspan="66" data-b-b-s="thin" data-b-b-c="#000000" data-b-l-s="thin" data-b-l-c="#000000"  data-b-r-s="thin" data-b-r-c="#000000"></td>
      </tr>
      <tr data-height="${this.hrEmpty}">
        <td colspan="66" data-b-l-s="thin" data-b-l-c="#000000"  data-b-r-s="thin" data-b-r-c="#000000"></td>
      </tr>
      <tr  data-height="${this.hrHeader}">
        <td data-b-l-s="thin" data-b-l-c="#000000"></td>
        <td ${this.square}>${f103Params.courierTax == true ? "x" : ""}</td>
        <td></td>
        <td colspan="13"  ${this.textHeader}  data-a-h="left">«Курьерский сбор»
        </td>
        <td colspan="49"></td>
        <td data-b-r-s="thin" data-b-r-c="#000000"></td>
      </tr>
      <tr data-height="${this.hrEmpty}" >
        <td colspan="66" data-b-b-s="thin" data-b-b-c="#000000" data-b-l-s="thin" data-b-l-c="#000000"  data-b-r-s="thin" data-b-r-c="#000000"></td>
      </tr>
      <tr data-height="${this.hrEmpty}" >
        <td colspan="66"></td>
      </tr>
      <tr data-height="${this.hrHeader}">
        <td></td>
        <td colspan="16" ${
          this.textHeader
        }  data-a-h="left">Наименование и индекс места приема:
        </td>
        <td colspan="49" ${this.textHeader}  data-a-h="left" ${this.bb}>${
      settings.list.postID
    }</td>
      </tr>
      <tr data-height="${this.hrEmpty}" >
        <td colspan="66"></td>
      </tr>
      <tr data-height="${this.hrHeader}">
        <td></td>
        <td colspan="9" ${this.textHeader}  data-a-h="left" >Всего отправлений:
        </td>
        <td colspan="5" ${this.textHeader}  data-a-h="center" ${this.bb}>${
      recepients.length
    }</td>
        <td></td>
        <td colspan="6" ${this.textHeader}  data-a-h="left" >Всего листов:
        </td>
        <td colspan="6" ${this.textHeader}  data-a-h="center" ${this.bb}></td>
        <td></td>
        <td colspan="4" ${this.textHeader}  data-a-h="left" >Лист №:
        </td>
        <td colspan="6" ${this.textHeader}  data-a-h="center" ${this.bb} >1</td>
        <td colspan="27"></td>
      </tr>
      <tr data-height="${this.hrEmpty}" >
        <td colspan="66"></td>
      </tr>
      <tr data-height="${this.hrEmpty}" >
        <td colspan="66"></td>
      </tr>
`;

    this.tabHead = `
    <tr data-height="${this.hrTableHead}">
  <td ${this.tHead} ${this.blr} ${this.bb} ${this.bt} rowspan="10" >№ п/п</td>
  <td ${this.tHead} colspan="12" ${this.bt} ${this.blr} ${this.bb}>Адресат ФИО</td>
  <td ${this.tHead} colspan="5" rowspan="10" ${this.bt} ${this.blr} ${this.bb}>ШПИ (штриховой почтовый идентификатор</td>
  <td ${this.tHead} colspan="5" rowspan="10" ${this.bt} ${this.blr} ${this.bb}>Категория РПО</td>
  <td ${this.tHead} colspan="5" ${this.bt} ${this.blr} >Фактический</td>
  <td ${this.tHead} colspan="6" ${this.bt} ${this.blr} >Дополн. Услуги</td>
  <td ${this.tHead} colspan="4" rowspan="10" ${this.bt} ${this.blr} ${this.bb}>Сумма объявл. ценности (руб.)</td>
  <td ${this.tHead} colspan="4" rowspan="10" ${this.bt} ${this.blr} ${this.bb}>Сумма наложен-ного платежа (руб.)</td>
  <td ${this.tHead} colspan="5" rowspan="10" ${this.bt} ${this.blr} ${this.bb}>Сумма платы за пересылку (без доп. услуг), без НДС (руб.)</td>
  <td ${this.tHead} colspan="5" rowspan="10" ${this.bt} ${this.blr} ${this.bb}>Сумма платы за пересылку (без доп. услуг), с НДС (руб.)</td>
  <td ${this.tHead} colspan="5" rowspan="10" ${this.bt} ${this.blr} ${this.bb}>Сумма платы за доп. услуги, без НДС (руб.)</td>
  <td ${this.tHead} colspan="4" rowspan="10" ${this.bt} ${this.blr} ${this.bb}>Сумма платы за доп. услуги с НДС,(руб.)</td>
  <td ${this.tHead} colspan="5" rowspan="10" ${this.bt} ${this.blr} ${this.bb}>Примечание</td>
</tr>
<tr data-height="${this.hrTableHead}">
  <td ${this.tHead}colspan="12" ${this.blr} ${this.bb}>Почтовый адрес</td>
  <td ${this.tHead}colspan="5" ${this.blr} ${this.bb}>вес, (кг.)</td>
  <td ${this.tHead}colspan="6" ${this.bt} ${this.blr} ${this.bb}>Тип уведомления</td>
</tr>
<tr data-height="${this.hrTableHead}">
  <td ${this.tHead}colspan="12" ${this.bt} ${this.blr} >&nbsp;</td>
  <td ${this.tHead}colspan="5" ${this.bt} ${this.blr} >Объемный</td>
  <td ${this.tHead}colspan="6" ${this.bt} ${this.blr} >Проверка</td>
</tr>
<tr data-height="${this.hrTableHead}">
  <td ${this.tHead}colspan="12" ${this.blr}>Номер почтомата, наименование</td>
  <td ${this.tHead}colspan="5" ${this.blr} ${this.bb}>вес, (кг.)</td>
  <td ${this.tHead}colspan="6" ${this.blr}>соответствия</td>
</tr>
<tr data-height="${this.hrTableHead}">
  <td ${this.tHead}colspan="12"${this.blr}>населенного пункта, индекс</td>
  <td ${this.tHead}colspan="5" ${this.bt} ${this.blr} >Линейные</td>
  <td ${this.tHead}colspan="6" ${this.blr} ${this.bb}>описи Ф107</td>
</tr>
<tr data-height="${this.hrTableHead}">
  <td ${this.tHead}colspan="12" ${this.blr}>ОПС, обслуживающий почтомат</td>
  <td ${this.tHead}colspan="5" ${this.blr}>размеры</td>
  <td ${this.tHead}colspan="6" ${this.blr}>Доставка</td>
</tr>
<tr data-height="${this.hrTableHead}">
  <td ${this.tHead}colspan="12" ${this.blr} ${this.bb}>&nbsp;</td>
  <td ${this.tHead}colspan="5" ${this.blr} ${this.bb}>(ДхШхВ), (см.)</td>
  <td ${this.tHead}colspan="6" ${this.bt} ${this.blr}>по звонку</td>
</tr>
<tr data-height="${this.hrTableHead}">
  <td ${this.tHead}colspan="12" rowspan="3" ${this.blr} >Номер мобильного телефона адресата</td>
  <td ${this.tHead}colspan="5" ${this.bt} ${this.blr}>Типоразмер</td>
  <td ${this.tHead}colspan="6" ${this.bt} ${this.blr} >Проверка</td>
</tr>
<tr data-height="${this.hrTableHead}">
  <td ${this.tHead}colspan="5" rowspan="2" ${this.blr} >(S/M/L)</td>
  <td ${this.tHead}colspan="6" ${this.blr} ${this.bb}>комплектности</td>
</tr>
<tr data-height="${this.hrTableHead}">
  <td ${this.tHead}colspan="6" ${this.blr} ${this.bb}>Пакет SMS</td>
</tr>
<tr data-height="${this.hrTableHead}">
  <td ${this.tHead} ${this.bt} ${this.blr} ${this.bb}>1</td>
  <td ${this.tHead} ${this.bt} ${this.blr} ${this.bb} colspan="12">2</td>
  <td ${this.tHead} ${this.bt} ${this.blr} ${this.bb} colspan="5">3</td>
  <td ${this.tHead} ${this.bt} ${this.blr} ${this.bb} colspan="5">4</td>
  <td ${this.tHead} ${this.bt} ${this.blr} ${this.bb} colspan="5">5</td>
  <td ${this.tHead} ${this.bt} ${this.blr} ${this.bb} colspan="6">6</td>
  <td ${this.tHead} ${this.bt} ${this.blr} ${this.bb} colspan="4">7</td>
  <td ${this.tHead} ${this.bt} ${this.blr} ${this.bb} colspan="4">8</td>
  <td ${this.tHead} ${this.bt} ${this.blr} ${this.bb} colspan="5">9</td>
  <td ${this.tHead} ${this.bt} ${this.blr} ${this.bb} colspan="5">10</td>
  <td ${this.tHead} ${this.bt} ${this.blr} ${this.bb} colspan="5">11</td>
  <td ${this.tHead} ${this.bt} ${this.blr} ${this.bb} colspan="4">12</td>
  <td ${this.tHead} ${this.bt} ${this.blr} ${this.bb} colspan="5">13</td>
</tr>
    `;

    this.tabRow = `
    <tr data-height="${this.hrTableRow}" >
  <td ${this.tRow} rowspan="10" ${this.bt} ${this.bb} ${this.blr}>1</td>
  <td ${this.tRowLeft} colspan="12" ${this.bt} ${this.bb} ${this.blr} rowspan="4" >rowname</td>
  <td ${this.tRow} colspan="5" ${this.bt} ${this.bb} ${this.blr} rowspan="10">rowbarcode</td>
  <td ${this.tRow} colspan="5" ${this.bt} ${this.bb} ${this.blr} rowspan="10">posttype</td>
  <td ${this.tRow} colspan="5" ${this.bt} ${this.bb} ${this.blr} rowspan="2">postweight</td>
  <td ${this.tRow} colspan="6" ${this.bt} ${this.bb} ${this.blr} rowspan="2">servicei1i2label</td>
  <td ${this.tRow} colspan="4" ${this.bt} ${this.bb} ${this.blr} rowspan="10">sumoc</td>
  <td ${this.tRow} colspan="4" ${this.bt} ${this.bb} ${this.blr} rowspan="10">nalogeniplat</td>
  <td ${this.tRow} colspan="5" ${this.bt} ${this.bb} ${this.blr} rowspan="10">groundcoverval</td>
  <td ${this.tRow} colspan="5" ${this.bt} ${this.bb} ${this.blr} rowspan="10">groundcovervalnds</td>
  <td ${this.tRow} colspan="5" ${this.bt} ${this.bb} ${this.blr} rowspan="2">servicei1i2val</td>
  <td ${this.tRow} colspan="4" ${this.bt} ${this.bb} ${this.blr} rowspan="2">servicei1i2valnds</td>
  <td ${this.tRow} colspan="5" ${this.bt} ${this.bb} ${this.blr} rowspan="10">note</td>
</tr>
<tr data-height="${this.hrTableRow}" >
</tr>
<tr data-height="${this.hrTableRow}" >
  <td ${this.tRow} colspan="5" rowspan="2" ${this.bt} ${this.bb} ${this.blr}>-</td>
  <td ${this.tRow} colspan="6" rowspan="2" ${this.bt} ${this.bb} ${this.blr}>servicep1label</td>
  <td ${this.tRow} colspan="5" rowspan="2" ${this.bt} ${this.bb} ${this.blr}>servicep1val</td>
  <td ${this.tRow} colspan="4" rowspan="2" ${this.bt} ${this.bb} ${this.blr}>servicep1valnds</td>
</tr>
<tr data-height="${this.hrTableRow}" >
</tr>
 <tr data-height="${this.hrTableRow}" >
 <td ${this.tRowLeft} colspan="12" rowspan="4" ${this.bt} ${this.bb} ${this.blr}>rowaddr</td>
  <td ${this.tRow} colspan="5" rowspan="3" ${this.bt} ${this.bb} ${this.blr}>-</td>
  <td ${this.tRow} colspan="6" rowspan="2" ${this.bt} ${this.bb} ${this.blr}>-</td>
  <td ${this.tRow} colspan="5" rowspan="2" ${this.bt} ${this.bb} ${this.blr}>-</td>
  <td ${this.tRow} colspan="4" rowspan="2" ${this.bt} ${this.bb} ${this.blr}>-</td>
</tr>
<tr data-height="${this.hrTableRow}" >
</tr>
<tr data-height="${this.hrTableRow}" >
  <td ${this.tRow} colspan="6" rowspan="2" ${this.bt} ${this.bb} ${this.blr}>-</td>
  <td ${this.tRow} colspan="5" rowspan="2" ${this.bt} ${this.bb} ${this.blr}>-</td>
  <td ${this.tRow} colspan="4" rowspan="2" ${this.bt} ${this.bb} ${this.blr}>-</td>
</tr>
<tr data-height="${this.hrTableRow}">
  <td ${this.tRow} colspan="5" rowspan="3" ${this.bt} ${this.bb} ${this.blr}>boxtype</td>
</tr>
<tr data-height="${this.hrTableRow}" >
  <td ${this.tRow} colspan="12" ${this.bt} ${this.bb} ${this.blr}>-</td>
  <td ${this.tRow} colspan="6" rowspan="2" ${this.bt} ${this.bb} ${this.blr}>services3s4label</td>
  <td ${this.tRow} colspan="5" rowspan="2" ${this.bt} ${this.bb} ${this.blr}>services3s4val</td>
  <td ${this.tRow} colspan="4" rowspan="2" ${this.bt} ${this.bb} ${this.blr}>services3s4valnds</td>
</tr>
 <tr data-height="${this.hrTableRow}">
  <td ${this.tRow} colspan="12" ${this.bt} ${this.bb} ${this.blr}>-</td>
 </tr>
    `;
    this.totalRow = `
    <tr data-height="${this.hrTableTotalRow}" >
  <td colspan="34" ></td>
  <td colspan="4" ${this.tTotalRow}>totalsumoc</td>
  <td colspan="4" ${this.tTotalRow}>nalogenplat</td>
  <td colspan="5" ${this.tTotalRow}>totalcval</td>
  <td colspan="5" ${this.tTotalRow}>totalgcvalnds</td>
  <td colspan="5" ${this.tTotalRow}>totalsval</td>
  <td colspan="4" ${this.tTotalRow}>totalsvalnds</td>
  <td colspan="5" ></td>
</tr>
<tr data-height="${this.hrEmpty}" ><td colspan="66"></td></tr>
<tr data-height="${this.hrFooter}" ><td ${this.tRowFooter} colspan="66">Из них общее количество отправлений по категориям, а также сумма их платы за пересылку с учетом платы за объявленную ценность:</td></tr>
<tr data-height="${this.hrEmpty}" ><td colspan="66"></td></tr>
    `;

    this.totalByCategories = `
    <tr data-height="${this.hrFooter}" >
      <td ${this.tRowFooter} ${this.bb} colspan="16" data-a-h="center">#catname#</td>
      <td ${this.tRowFooter}  ${this.bb} colspan="50" data-a-v="middle">#catcount#</td>
    </tr>
    <tr data-height="${this.hrFooterCaption}" >
      <td ${this.tRowFooterCaption}  colspan="16">(Категория РПО)</td>
      <td ${this.tRowFooterCaption}   colspan="50">(Сумма цифрами и прописью)</td>
    </tr>
    <tr data-height="${this.hrFooter}" >
      <td ${this.tRowFooter}  colspan="12">Сумма платы за пересылку с НДС</td>
      <td ${this.tRowFooter}  ${this.bb} colspan="54" data-a-h="center">#valNds#</td>
   </tr>
   <tr data-height="${this.hrFooterCaption}" >
      <td ${this.tRowFooterCaption} colspan="12"></td>
      <td ${this.tRowFooterCaption}   colspan="54">(Сумма цифрами и прописью)</td>
    </tr>
     <tr data-height="${this.hrFooter}" >
      <td ${this.tRowFooter}  colspan="12">Сумма платы за пересылку без НДС</td>
      <td ${this.tRowFooter}  ${this.bb} colspan="54" data-a-h="center">#val#</td>
   </tr>
   <tr data-height="${this.hrFooterCaption}" >
      <td ${this.tRowFooterCaption} colspan="12"></td>
      <td ${this.tRowFooterCaption}   colspan="54">(Сумма цифрами и прописью)</td>
    </tr>
     <tr data-height="${this.hrFooter}" >
      <td ${this.tRowFooter}  colspan="12">В т.ч. НДС</td>
      <td ${this.tRowFooter}  ${this.bb} data-a-h="center" colspan="54">#nds#</td>
   </tr>
   <tr data-height="${this.hrFooterCaption}" >
      <td ${this.tRowFooterCaption} colspan="12"></td>
      <td ${this.tRowFooterCaption}   colspan="54">(Сумма цифрами и прописью)</td>
    </tr>
    `;
    this.footerDelveryTitle = `
    <tr data-height="${this.hrEmpty}" >
      <td colspan="66"></td>
    </tr>
    <tr data-height="${this.hrFooterCaptionBold}" >
      <td ${this.tRowFooterBold} colspan="66" data-f-bold="true">Плата за пересылку</td>
    </tr>
    <tr data-height="${this.hrEmpty}" >
      <td colspan="66"></td>
    </tr>
`;
    this.footerServiceTitle = `
    <tr data-height="${this.hrEmpty}" >
      <td colspan="66"></td>
    </tr>
    <tr data-height="${this.hrFooterCaptionBold}" >
      <td ${this.tRowFooterBold} colspan="66" data-f-bold="true">Дополнительные услуги</td>
    </tr>
    <tr data-height="${this.hrEmpty}" >
      <td colspan="66"></td>
    </tr>
`;

    this.footerItogTitle = `
    <tr data-height="${this.hrEmpty}" >
      <td colspan="66"></td>
    </tr>
    <tr data-height="${this.hrFooterCaptionBold}" >
      <td ${this.tRowFooterBold} colspan="66" data-f-bold="true">Всего к оплате</td>
    </tr>
    <tr data-height="${this.hrEmpty}" >
      <td colspan="66"></td>
    </tr>
`;

    this.footerSigns = `
    <tr  data-height="${this.hrFooter} >
      <td colspan="4"></td>
      <td colspan="9" ></td>
      <td colspan="1"></td>
      <td colspan="6" ></td>
      <td colspan="2"></td>

      <td colspan="4"></td>
      <td colspan="9" ></td>
      <td colspan="1"></td>
      <td colspan="6" ></td>
      <td colspan="2"></td>

      <td colspan="4"></td>
      <td  ${this.tRowFooterBold}  colspan="16">Получил счетом _______ штук</td>
      <td colspan="2"></td>
    </tr>
    <tr  data-height="${this.hrFooter} >
      <td colspan="4"></td>
      <td colspan="9" ></td>
      <td colspan="1"></td>
      <td colspan="6" ></td>
      <td colspan="2"></td>

      <td colspan="4"></td>
      <td colspan="9" ></td>
      <td colspan="1"></td>
      <td colspan="6" ></td>
      <td colspan="2"></td>

      <td  colspan="4"></td>
      <td  ${this.tRowFooterBold}  colspan="9">в исправной</td>
      <td colspan="1"></td>
      <td colspan="6"></td>
      <td colspan="2"></td>
    </tr>
    //Должность
    <tr data-height="${this.hrFooterCaptionBold}" >
      <td ${this.tRowFooterBold} colspan="4">Сдал</td>
      <td colspan="9" ${this.bb}></td>
      <td colspan="1"></td>
      <td colspan="6" ${this.bt} ${this.blr}></td>
      <td colspan="2"></td>

      <td ${this.tRowFooterBold} colspan="4">Принял</td>
      <td colspan="9" ${this.bb}></td>
      <td colspan="1"></td>
      <td colspan="6" ${this.bt} ${this.blr}></td>
      <td colspan="2"></td>

      <td ${this.tRowFooterBold} colspan="4">упаковке</td>
      <td colspan="9" ${this.bb}></td>
      <td colspan="1"></td>
      <td colspan="6" ${this.bt} ${this.blr}></td>
      <td colspan="2"></td>
    </tr>

    <tr data-height="${this.hrFooterCaption}" >
      <td  colspan="4"></td>
      <td colspan="9" ${this.tRowFooterCaption}>(Должность)</td>
      <td colspan="1"></td>
      <td colspan="6"  ${this.blr}></td>
      <td colspan="2"></td>

      <td  colspan="4"></td>
      <td colspan="9" ${this.tRowFooterCaption}>(Должность)</td>
      <td colspan="1"></td>
      <td colspan="6"  ${this.blr}></td>
      <td colspan="2"></td>

      <td colspan="4"></td>
      <td colspan="9" ${this.tRowFooterCaption}>(Должность)</td>
      <td colspan="1"></td>
      <td colspan="6"  ${this.blr}></td>
      <td colspan="2"></td>
    </tr>
    //Подпись
    <tr data-height="${this.hrFooterCaptionBold}" >
      <td  colspan="4"></td>
      <td colspan="9" ${this.bb}></td>
      <td colspan="1"></td>
      <td colspan="6"  ${this.blr}></td>
      <td colspan="2"></td>

      <td ${this.tRowFooterBold} colspan="4"></td>
      <td colspan="9" ${this.bb}></td>
      <td colspan="1"></td>
      <td colspan="6"  ${this.blr}></td>
      <td colspan="2"></td>

      <td ${this.tRowFooterBold} colspan="4"></td>
      <td colspan="9" ${this.bb}></td>
      <td colspan="1"></td>
      <td colspan="6"  ${this.blr}></td>
      <td colspan="2"></td>
    </tr>

    <tr data-height="${this.hrFooterCaption}" >
      <td  colspan="4"></td>
      <td colspan="9" ${this.tRowFooterCaption}>(Подпись)</td>
      <td colspan="1"></td>
      <td colspan="6"  ${this.blr}></td>
      <td colspan="2"></td>

      <td  colspan="4"></td>
      <td colspan="9" ${this.tRowFooterCaption}>(Подпись)</td>
      <td colspan="1"></td>
      <td colspan="6"  ${this.blr}></td>
      <td colspan="2"></td>

      <td colspan="4"></td>
      <td colspan="9" ${this.tRowFooterCaption}>(Подпись)</td>
      <td colspan="1"></td>
      <td colspan="6"  ${this.blr}></td>
      <td colspan="2"></td>
    </tr>

    //Фио
    <tr data-height="${this.hrFooterCaptionBold}" >
      <td  colspan="4"></td>
      <td colspan="9" ${this.bb}></td>
      <td colspan="1"></td>
      <td colspan="6"  ${this.blr}></td>
      <td colspan="2"></td>

      <td ${this.tRowFooterBold} colspan="4"></td>
      <td colspan="9" ${this.bb}></td>
      <td colspan="1"></td>
      <td colspan="6"  ${this.blr}></td>
      <td colspan="2"></td>

      <td ${this.tRowFooterBold} colspan="4"></td>
      <td colspan="9" ${this.bb}></td>
      <td colspan="1"></td>
      <td colspan="6"  ${this.blr}></td>
      <td colspan="2"></td>
    </tr>

    <tr data-height="${this.hrFooterCaption}" >
      <td  colspan="4"></td>
      <td colspan="9" ${this.tRowFooterCaption}>(ФИО)</td>
      <td colspan="1"></td>
      <td colspan="6"  ${this.blr} ${this.bb}></td>
      <td colspan="2"></td>

      <td  colspan="4"></td>
      <td colspan="9" ${this.tRowFooterCaption}>(ФИО)</td>
      <td colspan="1"></td>
      <td colspan="6"  ${this.blr} ${this.bb}></td>
      <td colspan="2"></td>

      <td colspan="4"></td>
      <td colspan="9" ${this.tRowFooterCaption}>(ФИО)</td>
      <td colspan="1"></td>
      <td colspan="6"  ${this.blr} ${this.bb}></td>
      <td colspan="2"></td>
    </tr>
    `;
  }

  InsertRow(params) {
    let text = this.tabRow;
    for (let key in params) {
      text = text.replace(key, params[key]);
    }
    return text;
  }

  InsertTotalRow(params) {
    let text = this.totalRow;
    for (let key in params) {
      text = text.replace(key, params[key]);
    }
    return text;
  }

  InsertFooterBlockByCategories(params) {
    let text = this.totalByCategories;
    for (let key in params) {
      text = text.replace("#" + key + "#", params[key]);
    }
    return text;
  }

  InesertBlockFooterDelivery(footerTotal, totals) {
    let res = ``;
    for (let key in footerTotal) {
      let titleWidth = Math.ceil(footerTotal[key].length / 3);
      let sum = (totals.total[key] / 100).toFixed(2);
      let rubStr = rubles(sum);
      if (!rubStr) {
        rubStr = "ноль рублей 00 копеек";
      }
      sum =
        sum + "  (" + rubStr.charAt(0).toUpperCase() + rubStr.slice(1) + ")";
      res += `<tr data-height="${this.hrFooter}">
        <td ${this.tRowFooter} colSpan="${titleWidth}">${footerTotal[key]}</td>
        <td ${this.tRowFooter} ${this.bb} data-a-h="center" colSpan="${66 -
        titleWidth}">${sum}</td>
      </tr>
      <tr data-height="${this.hrFooterCaption}">
        <td ${this.tRowFooterCaption} colSpan="${titleWidth}"></td>
        <td ${this.tRowFooterCaption} colSpan="${66 -
        titleWidth}">(Сумма цифрами и прописью)</td>
      </tr>`;
    }
    return res;
  }

  InesertBlockFooterService(footerService, totals) {
    let res = ``;
    for (let key in footerService) {
      let titleWidth = Math.ceil(footerService[key].length / 3);
      let sum = 0;
      let serviceName = key[0] + key[1];
      let serviceTariff = key.slice(2, key.length);
      if (serviceName == "s0") {
        if (totals.services.hasOwnProperty("20")) {
          sum += totals.services["20"][serviceTariff];
        }
        if (totals.services.hasOwnProperty("21")) {
          sum += totals.services["21"][serviceTariff];
        }
      } else if (serviceName == "st") {
        sum = totals.total["s" + serviceTariff];
      } else {
        let serviceId = this.pData.services.hasOwnProperty(serviceName)
          ? this.pData.services[serviceName].serviceId
          : null;
        if (serviceId !== null && totals.services.hasOwnProperty(serviceId)) {
          sum = totals.services[serviceId][serviceTariff];
        }
      }

      sum = (sum / 100).toFixed(2);

      let rubStr = rubles(sum);
      let sumStr = "";
      if (!rubStr) {
        sumStr = "---";
      } else {
        sumStr =
          sum + "  (" + rubStr.charAt(0).toUpperCase() + rubStr.slice(1) + ")";
      }

      res += `<tr data-height="${this.hrFooter}">
        <td ${this.tRowFooter} colSpan="${titleWidth}">${
        footerService[key]
      }</td>
        <td ${this.tRowFooter} ${this.bb} data-a-h="center" colSpan="${66 -
        titleWidth}">${sumStr}</td>
      </tr>
      <tr data-height="${this.hrFooterCaption}">
        <td ${this.tRowFooterCaption} colSpan="${titleWidth}"></td>
        <td ${this.tRowFooterCaption} colSpan="${66 -
        titleWidth}">(Сумма цифрами и прописью)</td>
      </tr>`;
    }
    return res;
  }

  InsertCommonTotals(totals, settings) {
    let res = ``;
    let gc = 0;
    let s = 0;
    let hasNds = 0;
    if (
      settings.basicPaymentType !== null &&
      settings.basicPaymentType == "beznal"
    ) {
      gc = totals.total.gcValNds;
      hasNds = 1;
    } else {
      gc = totals.total.gcVal;
    }

    if (
      settings.servicePaymentType !== null &&
      settings.servicePaymentType == "beznal"
    ) {
      s = totals.total.svalnds;
      hasNds = 1;
    } else {
      s = totals.total.sval;
    }
    let itog = ((gc + s) / 100).toFixed(2);

    if (!hasNds) {
      itog = (0).toFixed(2);
    }
    let rubStr = rubles(itog);
    if (!rubStr) {
      rubStr = "---";
      itog = "";
    }
    let digitText =
      itog + "  (" + rubStr.charAt(0).toUpperCase() + rubStr.slice(1) + ")";
    res += `<tr data-height="${this.hrFooter}">
        <td ${this.tRowFooter} colSpan="20">Сумма платы за пересылку (с учетом дополнительных услуг) с НДС</td>
        <td ${this.tRowFooter} ${this.bb} data-a-h="center" colSpan="46">${digitText}</td>
      </tr>
      <tr data-height="${this.hrFooterCaption}">
        <td ${this.tRowFooterCaption} colSpan="20"></td>
        <td ${this.tRowFooterCaption} colSpan="46">(Сумма цифрами и прописью)</td>
      </tr>`;
    ////////////
    itog = ((totals.total.gcVal + totals.total.sval) / 100).toFixed(2);
    rubStr = rubles(itog);
    if (!rubStr) {
      rubStr = "---";
      itog = "";
    }
    digitText =
      itog + "  (" + rubStr.charAt(0).toUpperCase() + rubStr.slice(1) + ")";
    res += `<tr data-height="${this.hrFooter}">
        <td ${this.tRowFooter} colSpan="20">Сумма платы за пересылку (с учетом дополнительных услуг) без НДС</td>
        <td ${this.tRowFooter} ${this.bb} data-a-h="center" colSpan="46">${digitText}</td>
      </tr>
      <tr data-height="${this.hrFooterCaption}">
        <td ${this.tRowFooterCaption} colSpan="20"></td>
        <td ${this.tRowFooterCaption} colSpan="46">(Сумма цифрами и прописью)</td>
      </tr>`;
    /////////
    gc = 0;
    s = 0;
    hasNds = 0;
    if (
      settings.basicPaymentType !== null &&
      settings.basicPaymentType == "beznal"
    ) {
      gc = totals.total.gcNds;
      hasNds = 1;
    } else {
      gc = 0;
    }

    if (
      settings.servicePaymentType !== null &&
      settings.servicePaymentType == "beznal"
    ) {
      s = totals.total.snds;
      hasNds = 1;
    } else {
      s = 0;
    }

    itog = ((gc + s) / 100).toFixed(2);
    if (!hasNds) {
      itog = (0).toFixed(2);
    }
    rubStr = rubles(itog);
    if (!rubStr) {
      rubStr = "---";
      itog = "";
    }
    digitText =
      itog + "  (" + rubStr.charAt(0).toUpperCase() + rubStr.slice(1) + ")";
    res += `<tr data-height="${this.hrFooter}">
        <td ${this.tRowFooter} colSpan="4">В т.ч. НДС</td>
        <td ${this.tRowFooter} ${this.bb} data-a-h="center" colSpan="62">${digitText}</td>
      </tr>
      <tr data-height="${this.hrFooterCaption}">
        <td ${this.tRowFooterCaption} colSpan="4"></td>
        <td ${this.tRowFooterCaption} colSpan="62">(Сумма цифрами и прописью)</td>
      </tr>`;
    return res;
  }
}
