<template>
  <div class="hcontrol">
    <div class="btitle">{{ title }}</div>
    <div class="ttip">
      <q-icon
        size="xs"
        class="cursor-pointer"
        name="help_outline"
        @click="$emit('tip', name)"
      />
    </div>
    <div class="hcbuttonsouter">
      <div
        class="bg-secondary bleft"
        @mousedown="goFast(-1)"
        @mouseup="stop"
        @mouseout="stopInterval"
      >
        <q-icon size="xs" name="arrow_left" class="text-white" />
      </div>
      <div
        class="bg-secondary bright"
        @mousedown="goFast(1)"
        @mouseup="stop"
        @mouseout="stopInterval"
      >
        <q-icon size="xs" name="arrow_right" class="text-white" />
      </div>
    </div>
    <q-tooltip
      anchor="center right"
      self="center middle"
      content-style="font-size: 16px"
      content-class="bg-accent"
      :offset="[30, 0]"
      >{{ value.toFixed(1) }} мм</q-tooltip
    >
  </div>
</template>


<script>
export default {
  props: {
    val: { type: Number, default: null },
    name: { type: String, default: null },
    title: { tybe: String, default: "" },
    step: { type: Number, default: 0 },
    min: { type: Number, default: -999 },
    max: { type: Number, default: 999 }
  },
  data() {
    return {
      tid: null,
      value: 0
    };
  },
  computed: {
    f() {
      return this.$store.getters.CURFORMAT;
    }
  },
  watch: {
    val: function() {
      this.value = this.val;
    },
    f: function() {
      this.value = this.val;
    }
  },
  mounted() {
    this.value = this.val;
  },
  methods: {
    goFast(direction) {
      this.value = this.val;
      this.tid = setInterval(() => {
        if (
          this.value + direction * this.step <= this.max &&
          this.value + direction * this.step >= this.min
        ) {
          this.value += direction * this.step;
        }
      }, 50);
    },
    stop() {
      clearInterval(this.tid);
      this.$store.commit("SET", {
        name: this.name,
        value: Math.floor(this.value * 10) / 10
      });
    },
    stopInterval() {
      clearInterval(this.tid);
    }
  }
};
</script>

<style lang="sass">
.hcontrol
  padding: 8px 8px
  overflow: hidden
  border-bottom: 1px dashed #b3b0b0

.hcbuttonsouter
  float: right
  margin-right: 10px
.btitle
  font-size: 13px
  margin-right: 7px
  max-width: 230px
.ttip
  float: left
.bleft
  float: left
  border-radius: 10px 0 0 10px
  border: 1px solid #127b7d
  cursor: pointer
  line-height: 100%
.bright
  float: left
  border-radius: 0 10px 10px 0
  border: 1px solid #127b7d
  cursor: pointer
  line-height: 100%
.bleft:hover, .bright:hover
  background: #119c9e!important
  border-color: #0c6f71
.bleft:active, .bright:active
  background: #e64c65 !important
  border-color: #843945

.btitle
  float: left
.hcontrol
  padding: 6px 8px
  overflow: hidden
.ttip
  cursor: pointer
.ttip i:hover
  color: #e64c65
.hcontrol:hover, .compositecontrols:hover
  background: #cecece
.q-expansion-item__content>.hcontrol:nth-last-child(1)
  border-bottom: none
  padding-bottom: 24px
.q-expansion-item__content>.hcontrol:nth-child(1)
  border-top: none
  padding-top: 24px
</style>