import { render, staticRenderFns } from "./TipDialog.vue?vue&type=template&id=6dc4f345"
import script from "./TipDialog.vue?vue&type=script&lang=js"
export * from "./TipDialog.vue?vue&type=script&lang=js"
import style0 from "./TipDialog.vue?vue&type=style&index=0&id=6dc4f345&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QCardSection,QRadio,QInput,QCardActions,QBtn});qInstall(component, 'directives', {ClosePopup});
