<template>
  <div class="lpnlpnouter recepmass">
    <RecepContextMenu
      @open-editor-from-context="openEditorFromContext"
      @delete-recep="sendDeleteToParent"
    />
    <VirtualArea
      v-if="recepientsLoaded"
      :list="list"
      :areaheight="641"
      :itemheight="24"
      :key="ukey"
    >
      <template v-slot:list="{ item }">
        <div
          class="recep bg-primary-light cursor-pointer"
          :class="item.id == activereceps[0] ? ' recepactive' : ''"
          @click="addToWorkList(item)"
          @contextmenu="setActive(item)"
          @mouseover.self="
            showInfo($event, item.addr, item.comment, item.comment2)
          "
          @mouseleave="hideInfo()"
          :style="item.selected ? 'opacity:0.5' : 'opacity:1'"
        >
          <q-icon
            name="check_circle"
            v-if="item.contentclass !== undefined"
            :class="
              typeof item.contentclass != 'undefined'
                ? 'freshicon q-mr-sm q-mt-xs'
                : ''
            "
            color="green-13"
          />
          <span
            :class="
              typeof item.contentclass != 'undefined' ? 'freshaddress' : ''
            "
            >{{ item.name }}</span
          >
          <q-icon
            @click.self.stop="openEditor(item)"
            bg-color="red"
            name="edit"
            class="printfromrecep editiconmass"
            right
            color="grey-4"
          />
          <div class="cmlo">
            <span
              v-if="s.list.showLbl && item.comment2 != ''"
              :class="
                'cml ' + (item.comment != '' && s.list.showCom ? 'chalf ' : '')
              "
              >{{ item.comment2 }}</span
            >
            <span
              v-if="item.comment != '' && s.list.showCom"
              :class="
                'cml ' + (item.comment2 && s.list.showLbl != '' ? 'chalf ' : '')
              "
              >{{ item.comment }}</span
            >
          </div>
        </div>
      </template>
    </VirtualArea>
    <div
      class="addrtip"
      v-bind:style="tipposition"
      v-html="hoveredrecep"
      v-show="showtip"
    ></div>
  </div>
</template>

<script>
import VirtualArea from "src/service/components/common/VirtualArea.vue";
import Filter from "src/service/components/mixins/Filter.vue";
import RecepContextMenu from "src/service/components/common/RecepContextMenu.vue";

export default {
  components: {
    VirtualArea,
    RecepContextMenu
  },
  mixins: [Filter],
  data() {
    return {
      showtip: false,
      tipposition: {
        left: "320px",
        top: 0
      },
      hoveredrecep: "",
      ukey: 0
    };
  },

  computed: {
    recepientsLoaded() {
      return this.$store.getters.IS_RECEPIENTS_LOADED;
    },
    recepients() {
      return this.$store.getters.GET_RECEPIENTS;
    },
    activereceps() {
      return this.$store.getters.GET_ACTIVERECEPS;
    },
    searchtpl() {
      return this.$store.getters.GET_SEARCHTPL;
    },
    s() {
      return this.$store.getters.SETTINGS_OBJECT;
    },
    allowDuplicateRecepients() {
      return this.$store.getters.GET_ALLOW_DUPLICATE_RECEPIENTS;
    }
  },

  methods: {
    showInfo(event, addr, comment = "", comment2 = "") {
      event.stopPropagation();
      this.tipposition.top = event.clientY - event.offsetY - 50 + "px";
      this.hoveredrecep =
        '<span class="addr">' +
        addr +
        "</span>" +
        (comment != "" ? '<span class="com">' + comment : "") +
        "</span>" +
        (comment2 != "" ? '<span class="tiplbl">' + comment2 + "</span>" : "");
      this.showtip = true;
    },
    hideInfo() {
      this.tipposition.top = 0;
      this.hoveredrecep = "";
      this.showtip = false;
    },
    printFromRecep() {
      this.$store.commit("PRINT_FROM_RECEP", {});
    },
    addToWorkList(item) {
      if (!item.selected || this.allowDuplicateRecepients) {
        this.$store.commit("SET_ACTIVE_RECEP", [item.id]);
        this.$store.commit("ADD_RECEPIENT_TO_LIST", item);
        this.$eventBus.$emit("add-recepient-to-selected-model");
      }
    },
    setActive(item) {
      this.$store.commit("SET_ACTIVE_RECEP", [item.id]);
    },
    openEditor(item) {
      this.$store.commit("SET_ACTIVE_RECEP", [item.id]);
      this.$emit("open-editor", "edit");
    },
    openEditorFromContext(mode) {
      this.$emit("open-editor", mode);
    },
    sendDeleteToParent() {
      this.$emit("delete-recep");
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === "SET_RECEPIENT_SELECTION") {
        this.ukey++;
      }
      if (mutation.type === "CLEAR_RECEPIENT_SELECTION_FORALL") {
        this.ukey++;
      }
    });
  },

  beforeDestroy() {
    this.unsubscribe();
  }
};
</script>
<style lang="sass">
.recep
  overflow: hidden
  padding: 2px 4px
  border-bottom: 1px solid rgba(255, 255, 255, 0.14)
  -webkit-transition: all 0.25s
  -moz-transition: all 0.25s
  -o-transition: all 0.25s
  transition: all 0.25s
.recep:hover
  background: rgba(0, 0, 0, 0.15)
.recepmass .recepactive
  background: none
.trecepsarea
  width: 100%
  height: 100%
.recep span
  width: 184px
  display: block
  overflow: hidden
  float: left
  text-overflow: ellipsis
.printfromrecep
  background: rgb(80, 89, 123)
  border-radius: 3px !important
  height: 100% !important
  width: 20px !important
  font-size: 18px !important
  margin-top: 1px !important
  padding: 1px 3px !important
  float: right !important
  color: rgb(216, 216, 216)
.printfromrecep:hover, .printfromrecep:active
  background: rgb(60, 67, 97)
.freshicon
  float: left
.recep span.freshaddress
  width: 164px

.addrtip
  width: 230px
  z-index: 10000
  background: rgba(202, 60, 83, 0.8)
  position: fixed
  border-radius: 4px
  padding: 10px
  clear: both
  float: none
.addrtip .addr
  display: block
  width: 100%
.addrtip .com
  display: block
  background: rgba(0, 0, 0, 0.7)
  float: left
  clear: both
  padding: 2px 7px
  border-radius: 4px
.addrtip .tiplbl
  margin-top: 10px
  display: block
  background: rgba(0, 0, 0, 0.7)
  float: left
  clear: both
  padding: 2px 7px
  border-radius: 4px
.cmlo
  overflow: hidden
  width: 184px
  float: left
.recep span.cml
  font-size: 12px
  max-width: 100%
  float: left
  width: auto
  padding-right: 5px
  color: #f7f7ae
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  box-sizing: border-box
  display: block
.recep span.cml.chalf
  max-width: 50%
.cmlo>.cml:first-child:before
  display: block
  float: left
  content: ""
  width: 11px
  height: 7px
  margin: 5px 3px 0 0
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAHCAYAAADebrddAAAACXBIWXMAAAsSAAALEgHS3X78AAAALUlEQVR4nGP48z/1Pz683ciUAYYZCClG1kCUYpgGohX/+Z/6n/omE+1m5NAAAAzN6Ii+mb4QAAAAAElFTkSuQmCC')
.editiconmass
  visibility: hidden
.recep:hover .editiconmass
  visibility: visible
.recepmass .printfromrecep
  background: #e64c65
</style> 