<template>
  <q-dialog
    v-model="emailRequiredDialog"
    persistent
    transition-show="scale"
    class="transall mwnone"
    transition-hide="scale"
  >
    <q-card class="bg-primary text-white transall mwnone">
      <q-card-section>
        <div class="text-h6 text-left">К аккаунту не привязан email</div>
      </q-card-section>
      <q-card-section
        class="overflow-hidden bg-primary-light q-px-lg q-pb-lg"
      >
        <q-form class="full-width" @submit="checkEmail">
          <div style="color: yellow">
            Добрый день
          </div>
          <br/>
          <div style="color: yellow">
            С момента регистрации вашего аккаунта прошло две недели.
          </div>
          <br/>
          <div style="color: yellow">
            Укажите пожалуйста адрес электронной почты, который <br/>вы сможете использовать для восстановления доступа
            к
            аккаунту.
          </div>
          <br/>
          <div>
            * Сейчас вы входите в личный кабинет автоматически. В случае очистки истории браузера, смены браузера или
            смены компьютера восстановить доступ к аккаунту без email будет проблематично.
            <br/><br/>
            ** Ваш email будет использован исключительно для работы с сервисом ПОЧТУМ.РФ
            <br/><br/>Надеемся, что
            использование сервиса окажется максимально удобным и полезным для вас
          </div>
          <br/>
          <div class="row items-stretch">
            <q-input
              color="grey-3"
              bg-color="white"
              outlined
              stretch
              dense
              class="ssinput q-pr-md"
              v-model="email"
              type="email"
              prefix="Email:"
            >
              <template v-slot:prepend>
                <q-icon name="mail"/>
              </template>
            </q-input>
            <q-btn
              stretch
              :disable="sending"
              unelevated
              no-caps
              color="secondary"
              class="ssbtnemail q-px-sm"
              label="Привязать email"
              type="submit"
            />
          </div>
        </q-form>
      </q-card-section>
      <q-linear-progress
        v-show="sending"
        indeterminate
        size="md"
        color="accent"
      />
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: {
    emailRequiredDialog: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      email: "",
      sending: false
    };
  },
  methods: {
    async checkEmail() {
      try {
        this.sending = true;
        await this.$store.dispatch("CHANGE_USER_SETTINGS", {
          email: this.email
        });
        setTimeout(() => {
          this.$store.commit("CONFIRM_EMAIL");
        }, 1500);
      } finally {
        setTimeout(() => {
          this.sending = false;
        }, 1500);
      }
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === "EMAIL_DONE") {
        alert("Email DONE")
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
</script>

<style lang="sass">
.eprogressouter
  min-height: 4px

.ssinput
  float: left

.ssbtnemail
  float: right

.ssbtngo
  float: right

.lpdata span
  font-size: 22px
  color: #FFF
  letter-spacing: 2px

.mwnone
  max-width: none
</style>
