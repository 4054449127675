 <template>
  <div class="q-pa-md logofinder bg-primary-light">
    <q-btn
      icon="close"
      class="abscloseicon"
      unelevated
      color="secondary"
      round
      dense
      v-close-popup
      size="xs"
      padding="xs"
    />
    <div class="row items-center">
      <div class="col-3">Введите название сайта</div>
      <div class="col-4">
        <q-input
          dense
          filled
          fullwidth
          v-model="site"
          type="text"
          prefix="www:"
          color="white"
          dark
          class="no-shadow"
        >
          <template v-slot:prepend>
            <q-icon color="yellow" name="language" />
          </template>
        </q-input>
      </div>
      <div>
        <q-btn
          :loading="loading"
          unelevated
          no-caps
          class="q-ml-md"
          color="primary"
          @click="fetchImages"
          style="width: 150px; height: 40px"
          :disable="!site"
          >Найти логотип</q-btn
        >
      </div>
    </div>
    <div class="overflow-hidden" v-if="images.length > 0">
      <div class="q-mt-lg q-mb-md">
        Мы нашли несколько изображений на вашем сайте. Если среди них есть
        логотип - выберите его.
      </div>
      <div class="row q-mt-lg content-start">
        <div
          v-for="(image, index) in images"
          :key="index"
          class="col-1 q-mr-xs q-mt-xs colilimg"
          @click="selectLogo(image)"
        >
          <img class="logoimage" :src="image" />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { api } from "src/boot/axios";
export default {
  components: {},
  data() {
    return {
      site: "",
      images: [],
      loading: false
    };
  },
  computed: {},
  watch: {},
  methods: {
    fetchImages() {
      this.site = this.site.replace("http://", "");
      this.site = this.site.replace("https://", "");
      this.site = this.site.replace("www.", "");
      this.site = this.site.replace("www1.", "");
      this.site = this.site.replace("www2", "");
      this.site = this.site.replace("www3", "");
      this.loading = true;
      this.images = [];
      api
        .post("/api/sender/", {
          action: "getpagehtml",
          url: this.site
        })
        .then(response => {
          if (response.data.error) {
            this.$store.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
            this.loading = false;
          } else {
            let rex = RegExp(/<img\s.*?src=(?:'|")([^'">]+)(?:'|")/gi);
            let m = [];
            let i = 0;
            while (i < 30 && (m = rex.exec(response.data.body.html))) {
              if (!~this.images.indexOf(m[1])) {
                if (!~m[1].indexOf(this.site) && !~m[1].indexOf("data:")) {
                  if (!~m[1].indexOf("http")) {
                    this.images.push(
                      response.data.body.protocol +
                        this.site +
                        (m[1][0] == "/" ? "" : "/") +
                        m[1]
                    );
                  } else {
                    this.images.push(m[1]);
                  }
                } else {
                  this.images.push(m[1]);
                }
                i++;
              }
            }
            this.loading = false;
          }
        })
        .catch(error => {
          this.$store.commit("SET_ERROR", {
            error: true,
            errortext: "Неудается установить соединение с указанным сайтом"
          });
          this.$store.dispatch("LOG_ERROR", {
            error: error,
            desc: "GET PAGE HTML action"
          });
          this.loading = false;
        });
    },
    selectLogo(sourse) {
      api
        .post("/api/sender/", {
          action: "getpagelogo",
          url: sourse
        })
        .then(response => {
          if (response.data.error) {
            this.$store.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            this.$emit("select-logo", response.data.body.html);
          }
        })
        .catch(error => {
          this.$store.commit("SET_ERROR", {
            error: true,
            errortext:
              "Неудается удается получить логотип. Информация передана разработчикам"
          });
          this.$store.dispatch("LOG_ERROR", {
            error: error,
            desc: "GET LOGO action"
          });
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="sass">
.logofinder
  width: 800px
  height: 600px
  max-width: 800px!important
  overflow: visible!important
  position: relative
.logoimage
  padding: 3px
  max-width: 100%
  max-height: 60px
.colilimg
  cursor: pointer
  border: 1px dashed rgba(255, 255, 255, 0.25)
.colilimg:hover
  background: #394264
</style>