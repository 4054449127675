//import Vue from "vue";
//import VueRouter from "vue-router";
//import VueCookie from "vue-cookie";

//import SignUp from "../platform/views/SignUp.vue";
//import Recovery from "../platform/views/Recovery.vue";

//import Single from "../service/views/Single.vue";

//Servise import
//import Editor from "../service/views/Editor.vue";
//import Import from "../service/views/Import.vue";

//Vue.use(VueRouter);
//Vue.use(VueCookie);

import Vue from "vue";
import VueRouter from "vue-router";

import routes from "./routes";

Vue.use(VueRouter);

export default function(/* { store, ssrContext } */) {
  const router = new VueRouter({
    scrollBehavior: () => ({ x: 0, y: 0 }),
    routes,

    // Leave these as they are and change in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    mode: process.env.VUE_ROUTER_MODE,
    base: process.env.VUE_ROUTER_BASE
  });

  return router;
}
