import { api } from "src/boot/axios";
import Address from "src/service/classes/address.js";
import Vue from "vue";
import progress from "./progress";
import List from "../classes/list";

export default {
  state: {
    recepients_list: [],
    massListUpdateFinished: true,
    allowDuplicateRecepients: false,
    workList: {
      id: null,
      name: ""
    }
  },

  getters: {
    GET_RECEPIENTS_LIST(state) {
      return state.recepients_list;
    },
    GET_MASS_LIST_UPDATE_FINISHED(state) {
      return state.massListUpdateFinished;
    },
    GET_ALLOW_DUPLICATE_RECEPIENTS(state) {
      return state.allowDuplicateRecepients;
    },
    GET_WORK_LIST(state) {
      return state.workList;
    }
  },
  mutations: {
    SET_WORK_LIST(state, payload) {
      state.workList = payload;
    },
    SET_ALLOW_DUPLICATES(state, payload) {
      state.allowDuplicateRecepients = payload;
    },
    SET_RECEPIENT_SELECTION(state, payload) {
      for (let i = 0, len = payload.receps.length; i < len; i++) {
        if (payload.receps[i].selected != payload.selected) {
          payload.receps[i].selected = payload.selected;
        }
      }
    },
    SET_SELECTED_FOR_SINGLE(state, payload) {
      if (payload.recep.selected != payload.selected) {
        payload.recep.selected = payload.selected;
      }
    },
    ADD_RECEPIENT_TO_LIST(state, payload) {
      let newRecord = new Address(payload);
      newRecord.ClearPayload();
      state.recepients_list.push(newRecord);
      if (!payload.selected) {
        Vue.set(payload, "selected", true);
      }
    },
    ADD_RECEPIENT_TO_LIST_FROM_SAVED(state, payload) {
      state.recepients_list.push(payload);
    },
    REMOVE_RECEPIENT_FROM_LIST(state, payload) {
      this.commit("UNSELECT_RECEP_BY_ID", payload.id);
      let index = state.recepients_list.findIndex(
        item => item.id == payload.id
      );
      state.recepients_list.splice(index, 1);
    },

    UNSELECT_RECEP(state, payload) {
      this.state.Recepients.recepients[payload].ClearPayload();
    },

    SET_INVOICE_TO_RECEP(state, payload) {
      payload.recep.invoicetype = payload.invoicetype;
      payload.recep.posttype = payload.posttype;
      payload.recep.postcategory = payload.postcategory;
      payload.recep.invoicetip = payload.invoicetip;
    },
    SET_INVENTORY_TO_RECEP(state, payload) {
      if (
        payload.recep.posttype == "e3" ||
        payload.recep.posttype == "e4" ||
        payload.recep.posttype == "e6" ||
        payload.recep.posttype == "e7" ||
        payload.recep.posttype == "b3" ||
        payload.recep.posttype == "b4" ||
        payload.recep.posttype == "b6" ||
        payload.recep.posttype == "b7" ||
        payload.recep.posttype == "p2" ||
        payload.recep.posttype == "p3" ||
        payload.recep.posttype == "p5" ||
        payload.recep.posttype == "p6" ||
        payload.recep.posttype == "p8" ||
        payload.recep.posttype == "p9" ||
        payload.recep.posttype == null
      ) {
        payload.recep.inventory = payload.inventory;
        payload.recep.inventorytotalcount = payload.inventorytotalcount;
        payload.recep.inventorytotalcost = payload.inventorytotalcost;
        payload.recep.cost = payload.cost;
      }
      if (
        payload.recep.posttype == "e4" ||
        payload.recep.posttype == "e7" ||
        payload.recep.posttype == "b4" ||
        payload.recep.posttype == "b7" ||
        payload.recep.posttype == "p3" ||
        payload.recep.posttype == "p6" ||
        payload.recep.posttype == "p9"
      ) {
        payload.recep.cod = payload.cost;
      }
    },
    SET_TIP_TO_RECEP(state, payload) {
      payload.recep.tip = payload.tip;
    },
    SET_BAR_TO_RECEP(state, payload) {
      payload.recep.barcode = payload.barcode;
      payload.recep.barcodemode = payload.barcodemode;
    },
    SET_COST_TO_RECEP(state, payload) {
      if (
        payload.recep.posttype == "e3" ||
        payload.recep.posttype == "e4" ||
        payload.recep.posttype == "e6" ||
        payload.recep.posttype == "e7" ||
        payload.recep.posttype == "b3" ||
        payload.recep.posttype == "b4" ||
        payload.recep.posttype == "b6" ||
        payload.recep.posttype == "b7" ||
        payload.recep.posttype == "p2" ||
        payload.recep.posttype == "p3" ||
        payload.recep.posttype == "p5" ||
        payload.recep.posttype == "p6" ||
        payload.recep.posttype == "p8" ||
        payload.recep.posttype == "p9" ||
        payload.recep.posttype == null
      ) {
        payload.recep.cost = payload.cost;
        payload.recep.inventory = [];
        payload.recep.inventorytotalcount = 0;
        payload.recep.inventorytotalcost = 0;
      }
      if (
        payload.recep.posttype == "e4" ||
        payload.recep.posttype == "e7" ||
        payload.recep.posttype == "b4" ||
        payload.recep.posttype == "b7" ||
        payload.recep.posttype == "p3" ||
        payload.recep.posttype == "p6" ||
        payload.recep.posttype == "p9"
      ) {
        payload.recep.cod = payload.cost;
      }
    },
    SET_RECEPTOSENDER_TO_RECEP(state, payload) {
      payload.recep.receptosender = payload.receptosender;
    },
    SET_SINGLE_PARAM_TO_RECEP(state, payload) {
      payload.recep[payload.parameterValueName] = payload.parameterValue;
    },
    SET_POST_DATA_TO_RECEP(state, payload) {
      Object.entries(payload.newData).forEach(([key, value]) => {
        if (value instanceof Array) {
          payload.recep[key] = value.slice(0);
        } else {
          payload.recep[key] = value;
        }
      });
    },

    SORT_LIST(state, payload) {
      state.recepients_list.sort((a, b) => {
        let aa = a.name.toLowerCase();
        let bb = b.name.toLowerCase();
        if (aa > bb) {
          return 1 * payload.sortOrder;
        }
        if (aa < bb) {
          return -1 * payload.sortOrder;
        }
        // a должно быть равным b
        return 0;
      });
    },

    REASSIGN_LIST(state, payload) {
      state.recepients_list = payload;
    }
  },
  actions: {
    async CHANGE_AND_REPLACE_RECEPIENTS_IN_LIST(context, payload) {
      //payload.selected = масссив индексов выбранных элементов
      //payload.newdata = массив key=value с переменными, которые нужно заменить для выбранных элементов
      //payload.progressText = текст для полосы прогресса
      let buff = [];
      let chunk = 1;
      let ptime = 30;
      let len = context.state.recepients_list.length;
      let progress = this._vm.$chunk.GetChunk(len);
      context.commit("START_PROGRESS", {
        count: len,
        title: payload.progressText
      });
      for (let i = 0; i < len; i++) {
        buff.push(new Address(context.state.recepients_list[i]));
        if (payload.selected.includes(i)) {
          if (
            payload.newData.keyName !== undefined &&
            payload.newData.values[i] !== undefined
          ) {
            if (payload.newData.values[i] instanceof Array) {
              buff[i][payload.newData.keyName] = payload.newData.values[
                i
              ].slice(0);
            } else {
              buff[i][payload.newData.keyName] = payload.newData.values[i];
            }
          } else {
            if (payload.newData.cost !== undefined) {
              if (
                context.state.recepients_list[i].posttype == "e3" ||
                context.state.recepients_list[i].posttype == "e4" ||
                context.state.recepients_list[i].posttype == "e6" ||
                context.state.recepients_list[i].posttype == "e7" ||
                context.state.recepients_list[i].posttype == "b3" ||
                context.state.recepients_list[i].posttype == "b4" ||
                context.state.recepients_list[i].posttype == "b6" ||
                context.state.recepients_list[i].posttype == "b7" ||
                context.state.recepients_list[i].posttype == "p2" ||
                context.state.recepients_list[i].posttype == "p3" ||
                context.state.recepients_list[i].posttype == "p5" ||
                context.state.recepients_list[i].posttype == "p6" ||
                context.state.recepients_list[i].posttype == "p8" ||
                context.state.recepients_list[i].posttype == "p9"
              ) {
                Object.entries(payload.newData).forEach(([key, value]) => {
                  if (value instanceof Array) {
                    buff[i][key] = value.slice(0);
                  } else {
                    buff[i][key] = value;
                  }
                });
                if (
                  context.state.recepients_list[i].posttype == "e4" ||
                  context.state.recepients_list[i].posttype == "e7" ||
                  context.state.recepients_list[i].posttype == "b4" ||
                  context.state.recepients_list[i].posttype == "b7" ||
                  context.state.recepients_list[i].posttype == "p3" ||
                  context.state.recepients_list[i].posttype == "p6" ||
                  context.state.recepients_list[i].posttype == "p9"
                ) {
                  buff[i]["cod"] =
                    payload.newData.cost > 0
                      ? payload.newData.cost
                      : payload.newData.inventorytotalcost;
                }
              }
            } else {
              Object.entries(payload.newData).forEach(([key, value]) => {
                if (value instanceof Array) {
                  buff[i][key] = value.slice(0);
                } else {
                  buff[i][key] = value;
                }
              });
            }
          }
        }
        if (i % progress.chunk == 0) {
          await new Promise(resolve => setTimeout(resolve, progress.ptime));
          context.commit("STEP_PROGRESS", progress.chunk);
        }
      }
      context.commit("STOP_PROGRESS");
      context.commit("REASSIGN_LIST", buff);
    },

    async ADD_RECEPIENTS_TO_LIST_FROM_FILTER(context, payload) {
      let buff = [];
      let len = payload.recepients.length;
      if (len > 0) {
        context.commit("START_PROGRESS", {
          count: len,
          title: "Добавление получателей в список"
        });
        for (
          let i = 0, count = context.state.recepients_list.length;
          i < count;
          i++
        ) {
          buff[i] = new Address(context.state.recepients_list[i]);
          buff[i].ClearPayload();
        }

        for (let i = 0; i < len; i++) {
          if (
            !payload.recepients[i].selected ||
            context.state.allowDuplicateRecepients
          ) {
            let aa = new Address(payload.recepients[i]);
            aa.ClearPayload();
            buff.push(aa);
          }
          if (i % payload.progress.chunk == 0) {
            await new Promise(resolve =>
              setTimeout(resolve, payload.progress.ptime)
            );
            context.commit("STEP_PROGRESS", payload.progress.chunk);
          }
        }
        context.commit("REASSIGN_LIST", buff);
        context.commit("SET_RECEPIENT_SELECTION", {
          receps: payload.recepients,
          selected: true
        });
      }
    },
    async UPDATE_PRICES(context, payload) {
      let buff = [];
      let chunk = 1;
      let ptime = 30;
      let len = context.state.recepients_list.length;

      let selecedInfo = {};
      for (let key in payload.map) {
        for (let i = 0, l = payload.map[key].recepients.length; i < l; i++) {
          let pEl = payload.map[key];
          selecedInfo[pEl.recepients[i]] = {};
          selecedInfo[pEl.recepients[i]].g = pEl.g / 100;
          selecedInfo[pEl.recepients[i]].gnds = pEl.gnds / 100;
          selecedInfo[pEl.recepients[i]].s = pEl.s / 100;
          selecedInfo[pEl.recepients[i]].snds = pEl.snds / 100;
          selecedInfo[pEl.recepients[i]].c = pEl.c / 100;
          selecedInfo[pEl.recepients[i]].cnds = pEl.cnds / 100;
          selecedInfo[pEl.recepients[i]].gc = pEl.gc / 100;
          selecedInfo[pEl.recepients[i]].gcnds = pEl.gcnds / 100;
          selecedInfo[pEl.recepients[i]].response = pEl.response;
          /* for (let key in pEl.serviceTarrifs) {
            selecedInfo[pEl.recepients[i]].serviceTarrifs[key] = {};
            selecedInfo[pEl.recepients[i]].serviceTarrifs[key]["val"] =
              pEl.serviceTarrifs[key]["val"] / 100;
            selecedInfo[pEl.recepients[i]].serviceTarrifs[key]["valnds"] =
              pEl.serviceTarrifs[key]["valnds"] / 100;
          } */
        }
      }

      let progress = this._vm.$chunk.GetChunk(len);
      context.commit("START_PROGRESS", {
        count: len,
        title: "Обновление списка"
      });
      for (let i = 0; i < len; i++) {
        buff.push(new Address(context.state.recepients_list[i]));
        if (payload.recepientIds.includes(i)) {
          buff[i].price.g = selecedInfo[i].g;
          buff[i].price.gnds = selecedInfo[i].gnds;
          buff[i].price.s = selecedInfo[i].s;
          buff[i].price.snds = selecedInfo[i].snds;
          buff[i].price.c = selecedInfo[i].c;
          buff[i].price.cnds = selecedInfo[i].cnds;
          buff[i].price.gc = selecedInfo[i].gc;
          buff[i].price.gcnds = selecedInfo[i].gcnds;
          buff[i].response = selecedInfo[i].response;
        }
        if (i % progress.chunk == 0) {
          await new Promise(resolve => setTimeout(resolve, progress.ptime));
          context.commit("STEP_PROGRESS", progress.chunk);
        }
      }
      context.commit("STOP_PROGRESS");
      context.commit("REASSIGN_LIST", buff);
    },
    async UNSELECT_SELECTED(context) {
      let buff = [];
      context.commit("CLEAR_RECEPIENT_SELECTION_FORALL");
      context.commit("REASSIGN_LIST", buff);
    },
    async LOAD_FROM_SAVED_LIST(context, payload) {
      api
        .post("/api/list/", {
          action: "loadlist",
          lid: payload.id
        })
        .then(async response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
            //context.commit("SET_ACCOUNT", response.data);
          } else {
            ///////////////////////////////////////////////////////
            let len = response.data.body.ids.length;
            let progress = this._vm.$chunk.GetChunk(len);
            let buff = [];
            context.commit("CLEAR_RECEPIENT_SELECTION_FORALL");
            context.commit("REASSIGN_LIST", []);

            if (len > 0) {
              context.commit("START_PROGRESS", {
                count: len,
                title: "Загрузка списка"
              });

              if (response.data.body.version_number == 1) {
                for (let i = 0; i < len; i++) {
                  let r = this.state.Recepients.recepients.find(
                    item => item.id == response.data.body.ids[i]
                  );
                  if (r) {
                    buff.push(new Address(r));
                    context.commit("SET_SELECTED_FOR_SINGLE", {
                      recep: r,
                      selected: true
                    });
                  }
                  if (i % progress.chunk == 0) {
                    await new Promise(resolve =>
                      setTimeout(resolve, progress.ptime)
                    );
                    context.commit("STEP_PROGRESS", progress.chunk);
                  }
                }
              } else if (response.data.body.version_number == 2) {
                let datas = JSON.parse(response.data.body.datas);
                let decoder = new Address({});

                for (let i = 0; i < len; i++) {
                  let r = this.state.Recepients.recepients.find(
                    item => item.id == response.data.body.ids[i]
                  );
                  buff.push(new Address(decoder.UnserializeObject(datas[i])));
                  if (r) {
                    context.commit("SET_SELECTED_FOR_SINGLE", {
                      recep: r,
                      selected: true
                    });
                  }
                  if (i % progress.chunk == 0) {
                    await new Promise(resolve =>
                      setTimeout(resolve, progress.ptime)
                    );
                    context.commit("STEP_PROGRESS", progress.chunk);
                  }
                }
              }

              context.commit("REASSIGN_LIST", buff);
              context.commit("SET_WORK_LIST", {
                id: response.data.body.id,
                name: response.data.body.listname
              });
              context.commit("STOP_PROGRESS");
            }
            ///////////////////////////////////////////////////////
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при загрузке получателей из сохраненного списка. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "LOAD_FROM_SAVED_LIST action"
          });
        });
    },
    async SAVE_WORKSPACE(context, payload) {
      payload.action = "savelist";
      api
        .post("/api/list/", payload)
        .then(async response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
            //context.commit("SET_ACCOUNT", response.data);
          } else {
            context.commit("SET_WORK_LIST", {
              id: response.data.body.id,
              name: response.data.body.listname
            });
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при сохранении списка. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "SAVE_WORKSPACE action"
          });
        });
    }
  },

  modules: {}
};
