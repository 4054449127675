<template>
  <q-dialog
    v-model="show"
    persistent
    transition-show="scale"
    transition-hide="scale"
  >
    <q-card class="mypdial text-white fdial">
      <q-card-section class="dialhead">
        <div class="text-h6">Настройки реестра партионной почты</div>
      </q-card-section>
      <q-card-section>
        <div class="row items-center q-mb-md">
          <div class="col-4 q-pr-lg">
            <q-input
              v-model.number="f103Params.listNumber"
              type="number"
              prefix="Список №:"
              debounce="1000"
              dense
              autofocus
              content-class="q-px-sm"
              filled
              bg-color="grey-4"
              color="accent"
              input-class="q-py-none"
            />
          </div>
          <div class="col-4 q-pr-lg">
            <q-input
              v-model.number="f103Params.partNumber"
              type="number"
              debounce="1000"
              prefix="Партия №:"
              dense
              content-class="q-px-sm"
              filled
              bg-color="grey-4"
              class=""
              input-class="q-py-none"
            />
          </div>
          <div class="col-4">
            <q-input
              v-model="f103Params.listDate"
              prefix="Дата:"
              debounce="1000"
              dense
              content-class="q-px-sm"
              filled
              bg-color="grey-4"
              class=""
              type="date"
              input-class="q-py-none"
            />
          </div>
        </div>
        <div class="row items-center">
          <div class="col-7">
            <div>Способ оплаты основной услуги:</div>
          </div>
          <div class="col-5">
            <q-radio
              desnse
              dark
              color="accent"
              v-model="f103Params.basicPaymentType"
              val="nal"
              label="марки"
              class="q-pr-lg"
            />
            <q-radio
              desnse
              color="accent"
              dark
              v-model="f103Params.basicPaymentType"
              val="beznal"
              label="безнал"
            />
          </div>
        </div>
        <div class="row items-center q-mb-md">
          <div class="col-7">
            <span>Способ оплаты дополнительных услуг:</span>
          </div>
          <div class="col-5">
            <q-radio
              desnse
              color="accent"
              dark
              v-model="f103Params.servicePaymentType"
              val="nal"
              label="марки"
              class="q-pr-lg"
            />
            <q-radio
              desnse
              color="accent"
              dark
              v-model="f103Params.servicePaymentType"
              val="beznal"
              label="безнал"
            />
          </div>
        </div>
        <div class="row items-center q-mb-md">
          <div class="col-8 q-pr-lg">
            <q-input
              v-model="f103Params.senderPhone"
              unmasked-value
              debounce="1000"
              prefix="+7"
              dense
              content-class="q-px-sm"
              filled
              bg-color="grey-4"
              mask="(###) ###-##-##"
              fill-mask
              class=""
              input-class="q-py-none"
            />
          </div>
          <div class="col-12">
            <q-checkbox
              dense
              dark
              v-model="f103Params.smsDeliveryToRecep"
              label="SMS-уведомление о вручении РПО"
              color="secondary"
              class="q-mt-sm q-mb-sm"
            /><br />
            <q-checkbox
              dense
              class="q-mb-sm"
              dark
              v-model="f103Params.smsDeliveryToPost"
              label="SMS-уведомление о поступлении РПО в адресное ОПС"
              color="secondary"
            /><br />
            <q-checkbox
              dense
              dark
              v-model="f103Params.smsPacket"
              label="SMS-пакет"
              color="secondary"
              class="q-mb-sm"
            /><br />
            <q-checkbox
              dense
              class="q-mb-sm"
              dark
              v-model="f103Params.courierTax"
              label="Курьерский сбор"
              color="secondary"
            />
          </div>
        </div>
        <div class="row items-center">
          <div class="col-8 q-pr-lg">
            <q-input
              v-model="f103Params.senderEmail"
              type="email"
              debounce="1000"
              placeholder="Email"
              dense
              content-class="q-px-sm"
              filled
              bg-color="grey-4"
              class=""
              input-class="q-py-none"
            />
          </div>
        </div>
      </q-card-section>

      <q-card-actions
        align="right"
        class="q-px-md q-pt-md q-pb-md text-white dialbtns"
      >
        <q-btn
          unelevated
          label="Печать"
          no-caps
          @click="printF103"
          color="accent"
        /><q-btn
          unelevated
          label="Сохранить в EXCEL"
          no-caps
          @click="saveExcelF103"
          color="accent"
        /><q-btn
          unelevated
          label="Сохранить в PDF"
          no-caps
          @click="savePDFF103"
          color="accent"
        />
        <q-btn
          unelevated
          label="Отмена"
          no-caps
          @click="close"
          color="accent"
          class="q-ml-md"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { date } from "quasar";
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    recepientsIds: {
      type: Array,
      default: null
    }
  },
  computed: {
    mass() {
      return this.$router.currentRoute.name == "mass";
    }
  },
  data() {
    return {
      f103Params: {
        basicPaymentType: "beznal",
        servicePaymentType: null,
        listDate: date.formatDate(Date.now(), "YYYY-MM-DD"),
        partNumber: 1,
        listNumber: 1,
        senderPhone: null,
        senderEmail: null,
        smsDeliveryToRecep: false,
        smsDeliveryToPost: false,
        smsPacket: false,
        courierTax: false
      }
    };
  },
  watch: {
    show(val) {
      if (val == true) {
      }
    }
  },

  methods: {
    printF103() {
      this.$emit("printF103", this.f103Params);
      this.$emit("update:show", false);
      this.f103Params.pdf = false;
      this.f103Params.xls = false;
    },
    savePDFF103() {
      this.f103Params.pdf = true;
      this.f103Params.xls = false;
      this.$emit("printF103", this.f103Params);
      this.$emit("update:show", false);
    },
    saveExcelF103() {
      this.f103Params.xls = true;
      this.f103Params.pdf = false;
      this.$emit("printF103", this.f103Params);
      this.$emit("update:show", false);
    },
    close() {
      this.$emit("update:show", false);
    }
  }
};
</script>

<style lang="sass">
.fdial
  width: 580px
  max-width: 680px!important
.dialhead, .dialbtns
  background: rgba(0,0,0,0.15)
</style>
