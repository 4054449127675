<template>
  <div class="fprop hcontrol">
    <div class="btitle">{{ title }}</div>

    <div class="row q-pb-md">
      <div class="col self-center">
        <q-btn
          padding="none"
          icon="arrow_back_ios"
          unelevated
          flat
          color="secondary"
          @click="rotateLeft"
        />
      </div>
      <div class="col-10">
        <q-img
          v-if="val >= 0"
          style="height: 110px; width: 220px"
          :src="senderviews[val]"
          spinner-color="white"
          class="spreview"
        />
        <div v-else class="text-center h110px">
          <img src="/images/sender/0.jpg" />
        </div>
      </div>
      <div class="col self-center">
        <q-btn
          padding="none"
          icon="arrow_forward_ios"
          unelevated
          flat
          color="secondary"
          @click="rotateRight"
        />
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    val: { type: Number, default: null },
    name: { type: String, default: null },
    title: { tybe: String, default: "" }
  },
  data() {
    return {
      senderviews: [
        "/images/sender/1.jpg",
        "/images/sender/2.jpg",
        "/images/sender/3.jpg",
        "/images/sender/4.jpg",
        "/images/sender/5.jpg",
        "/images/sender/6.jpg"
      ],
      parameters: [
        {
          sPrintLines: 1,
          sPrintSender: 1,
          sPrintTips: 1,
          sPrintTipsWhite: 0,
          sPrintZip: 1,
          autoChangeFSize: 0,
          zipClearFont: 0
        },
        {
          sPrintLines: 0,
          sPrintSender: 1,
          sPrintTips: 1,
          sPrintTipsWhite: 1,
          sPrintZip: 1,
          autoChangeFSize: 1,
          zipClearFont: 1
        },
        {
          sPrintLines: 1,
          sPrintSender: 1,
          sPrintTips: 0,
          sPrintTipsWhite: 0,
          sPrintZip: 1,
          autoChangeFSize: 0,
          zipClearFont: 0
        },
        {
          sPrintLines: 0,
          sPrintSender: 1,
          sPrintTips: 0,
          sPrintTipsWhite: 0,
          sPrintZip: 0,
          autoChangeFSize: 0,
          zipClearFont: 0
        },
        {
          sPrintLines: 1,
          sPrintSender: 0,
          sPrintTips: 1,
          sPrintTipsWhite: 0,
          sPrintZip: 0,
          autoChangeFSize: 1,
          zipClearFont: 0
        },
        {
          sPrintLines: 0,
          sPrintSender: 0,
          sPrintTips: 0,
          sPrintTipsWhite: 0,
          sPrintZip: 0,
          autoChangeFSize: 0,
          zipClearFont: 0
        }
      ]
    };
  },
  computed: {
    l() {
      return this.senderviews.length;
    }
  },
  methods: {
    rotateRight() {
      let p = (this.val + 1) % this.l;
      this.$store.commit("SET", {
        name: this.name,
        value: p
      });

      for (let key in this.parameters[p]) {
        this.$store.commit("SET", {
          name: key,
          value: this.parameters[p][key]
        });
      }
    },
    rotateLeft() {
      let p = (this.l + (this.val - 1)) % this.l;
      this.$store.commit("SET", {
        name: this.name,
        value: p
      });
      for (let key in this.parameters[p]) {
        this.$store.commit("SET", {
          name: key,
          value: this.parameters[p][key]
        });
      }
    }
  }
};
</script>

<style lang="sass">
.fprop .btitle
  clear: both
  float: none
  margin-bottom: 10px
.h110px
  height: 110px
</style>