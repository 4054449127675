<template>
  <div class="bg-dark no-shadow leftpanblock">
    <q-card class="q-pa-md no-border no-border-radius fit bg-dark">
      <q-tabs
        v-model="tab"
        dense
        no-caps
        align="left"
        class="text-grey-5 lptabs"
        active-color="white"
        indicator-color="accent"
        active-bg-color="primary"
        narrow-indicator
      >
        <q-tab name="recepients" label="Получатели" />
        <q-tab name="groups" label="Группы" v-if="!newuser" />
        <q-tab name="senders" label="Отправители" />
      </q-tabs>

      <q-tab-panels v-model="tab" animated class="pochttabs">
        <q-tab-panel
          name="recepients"
          class="receptabpanel q-ma-none q-pa-md bg-primary"
        >
          <Recepients />
        </q-tab-panel>

        <q-tab-panel
          name="groups"
          class="receptabpanel q-ma-none q-pa-md bg-primary"
        >
          <Groups />
        </q-tab-panel>

        <q-tab-panel
          name="senders"
          class="receptabpanel q-ma-none q-pa-md bg-primary"
        >
          <Senders />
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
  </div>
</template>

<script>
import Senders from "src/service/components/single-page/Senders.vue";
import Recepients from "src/service/components/single-page/Recepients.vue";
import Groups from "src/service/components/common/Groups.vue";
export default {
  components: {
    Senders,
    Recepients,
    Groups
  },
  data() {
    return {
      tab: "recepients",
      letter: "l2"
    };
  },
  computed: {
    newuser() {
      return this.$store.getters.NEWUSER;
    }
  }
};
</script>

<style lang="sass">
.receptabpanel
  min-height: 690px
.lptabs .q-tab
  background: rgba(255, 255, 255, 0.08)
  border-right: 1px solid #2b2b2b
.leftpanblock
  overflow: hidden
  float: left
  width: 361px
.pochttabs .q-panel
  width: 329px
  overflow: hidden
</style>
