export default class PostData {
  constructor() {
    this.mailCategories = {
      0: "Простое",
      1: "Заказное",
      2: "с ОЦ",
      3: "Обыкн",
      4: "с ОЦ и НП"
    };
    this.mailTypes = {
      2: "Письмо",
      15: "Письмо 1 класса",
      3: "Бандероль",
      16: "Бандероль 1 класса",
      27: "Посылка стандартная",
      29: "Посылка экспресс",
      47: "Послыка 1 класса"
    };
    this.types = {
      e1: {
        mailtype: 2,
        mailctg: 0,
        tname: "Письмо простое",
        label: "Простое",
        short: "Письмо простое",
        services: [],
        pack: [],
        object: 2000,
        maxWeight: 100,
        cat: "prost"
      },
      e2: {
        mailtype: 2,
        mailctg: 1,
        tname: "Письмо заказное",
        label: "Заказное",
        short: "Письмо заказное",
        services: ["i1", "i2", "s1", "s2"],
        pack: [],
        object: 2010,
        maxWeight: 100,
        cat: "zakaz"
      },
      e3: {
        mailtype: 2,
        mailctg: 2,
        tname: "Письмо с объявленной ценностью",
        label: "Ценное",
        short: "Письмо ценное",
        services: ["i1", "i2", "s1", "s2", "p1"],
        pack: [],
        object: 2020,
        maxWeight: 100,
        maxPrice: 100000,
        cat: "cenn"
      },
      e4: {
        mailtype: 2,
        mailctg: 4,
        tname: "Письмо с объявленной ценностью и наложенным платежом",
        label: "Ценное, с налож. платежом",
        short: "Письмо ценное с налож. платежом",
        services: ["i1", "i2", "s1", "s2", "p1"],
        pack: [],
        object: 2040,
        maxWeight: 100,
        maxPrice: 100000,
        cat: "cenn"
      },
      e5: {
        mailtype: 15,
        mailctg: 1,
        tname: "Письмо 1 класса заказное",
        label: "1 класса заказное",
        short: "Письмо 1 класса заказное",
        services: ["i1", "i2", "s1", "s2"],
        pack: [],
        object: 15010,
        maxWeight: 500,
        cat: "zakaz"
      },
      e6: {
        mailtype: 15,
        mailctg: 2,
        tname: "Письмо 1 класса с объявленной ценностью",
        label: "1 класса ценное",
        short: "Письмо 1 класса ценное",
        services: ["i1", "i2", "s1", "s2", "p1"],
        pack: [],
        object: 15020,
        maxWeight: 500,
        maxPrice: 100000,
        cat: "cenn"
      },
      e7: {
        mailtype: 2,
        mailctg: 4,
        tname: "Письмо 1 класса с объявленной ценностью и наложенным платежом",
        label: "1 класса ценное с налож. платежом",
        short: "Письмо 1 класса ценное с налож. платежом",
        services: ["i1", "i2", "s1", "s2", "p1"],
        pack: [],
        object: 15040,
        maxWeight: 500,
        maxPrice: 100000,
        cat: "cenn"
      },

      b1: {
        mailtype: 3,
        mailctg: 0,
        tname: "Бандероль простая",
        label: "Простая",
        short: "Бандероль простая",
        services: [],
        pack: [],
        object: 3000,
        maxWeight: 5000,
        cat: "prost"
      },
      b2: {
        mailtype: 3,
        mailctg: 1,
        tname: "Бандероль заказнае",
        label: "Заказная",
        short: "Бандероль заказная",
        services: ["i1", "i2", "s1", "s2"],
        pack: [],
        object: 3010,
        maxWeight: 5000,
        cat: "zakaz"
      },
      b3: {
        mailtype: 3,
        mailctg: 2,
        tname: "Бандероль с объявленной ценностью",
        label: "Ценная",
        short: "Бандероль ценная",
        services: ["i1", "i2", "s1", "s2", "p1"],
        pack: [],
        object: 3020,
        maxWeight: 5000,
        maxPrice: 10000,
        cat: "cenn"
      },
      b4: {
        mailtype: 3,
        mailctg: 4,
        tname: "Бандероль с объявленной ценностью и наложенным платежом",
        label: "Ценная с налож. платежом",
        short: "Бандероль ценная с налож. платежом",
        services: ["i1", "i2", "s1", "s2", "p1"],
        pack: [],
        object: 3040,
        maxWeight: 5000,
        maxPrice: 10000,
        cat: "cenn"
      },
      b5: {
        mailtype: 16,
        mailctg: 1,
        tname: "Бандероль 1 класса заказное",
        label: "1 класса заказная",
        short: "Бандероль 1 класса заказная",
        services: ["i1", "i2", "s1", "s2"],
        pack: [],
        object: 16010,
        maxWeight: 2500,
        cat: "zakaz"
      },
      b6: {
        mailtype: 16,
        mailctg: 2,
        tname: "Бандероль 1 класса с объявленной ценностью",
        label: "1 класса ценная",
        short: "Бандероль 1 класса ценная",
        services: ["i1", "i2", "s1", "s2", "p1"],
        pack: [],
        object: 16020,
        maxWeight: 2500,
        maxPrice: 100000,
        cat: "cenn"
      },
      b7: {
        mailtype: 16,
        mailctg: 4,
        tname:
          "Бандероль 1 класса с объявленной ценностью и наложенным платежом",
        label: "1 класса ценная с налож. платежом",
        short: "Бандероль 1 класса ценная с налож. платежом",
        services: ["i1", "i2", "s1", "s2", "p1"],
        pack: [],
        object: 16040,
        maxWeight: 2500,
        maxPrice: 100000,
        cat: "cenn"
      },

      p1: {
        mailtype: 27,
        mailctg: 3,
        tname: "Посылка стандартная обыкновенная",
        label: "Стандартная обыкновенная",
        short: "Посылка стандартная обыкн.",
        pack: ["k1", "k2", "k3", "k4", "k5", "k6", "k7", "k8", "k9", "k10"],
        services: ["s3", "s4"],
        object: 27030,
        maxWeight: 10000,
        cat: "obikn"
      },
      p2: {
        mailtype: 27,
        mailctg: 2,
        tname: "Посылка стандартная с объявленной ценностью",
        label: "Стандартная ценная",
        short: "Посылка стандартная ценная",
        pack: ["k1", "k2", "k3", "k4", "k5", "k6", "k7", "k8", "k9", "k10"],
        services: ["s3", "s4", "p1"],
        object: 27020,
        maxWeight: 10000,
        maxPrice: 50000,
        cat: "cenn"
      },
      p3: {
        mailtype: 27,
        mailctg: 4,
        tname:
          "Посылка стандартная с объявленной ценностью и наложенным платежом",
        label: "Стандартная ценная с налож. платежом",
        short: "Посылка ценн. с налож. платежом",
        pack: ["k1", "k2", "k3", "k4", "k5", "k6", "k7", "k8", "k9", "k10"],
        services: ["s3", "s4", "p1"],
        object: 27040,
        maxWeight: 10000,
        maxPrice: 50000,
        cat: "cenn"
      },
      p4: {
        mailtype: 29,
        mailctg: 3,
        tname: "Посылка экспресс обыкновенная",
        label: "Экспресс обыкновенная",
        short: "Посылка экспресс",
        pack: ["k1", "k2", "k3", "k4", "k5", "k6", "k7", "k8", "k9", "k10"],
        services: ["s3", "s4"],
        object: 29030,
        maxWeight: 10000,
        cat: "obikn"
      },
      p5: {
        mailtype: 29,
        mailctg: 2,
        tname: "Посылка экспресс с объявленной ценностью",
        label: "Экспресс ценная",
        short: "Посылка экспресс ценн.",
        pack: ["k1", "k2", "k3", "k4", "k5", "k6", "k7", "k8", "k9", "k10"],
        services: ["s3", "s4", "p1"],
        object: 29020,
        maxWeight: 10000,
        maxPrice: 50000,
        cat: "cenn"
      },
      p6: {
        mailtype: 29,
        mailctg: 4,
        tname: "Посылка экспресс с объявленной ценностью и наложенным платежом",
        label: "Экспресс ценная с налож. платежом",
        short: "Посылка экспресс ценн. с налож. платежом",
        pack: ["k1", "k2", "k3", "k4", "k5", "k6", "k7", "k8", "k9", "k10"],
        services: ["s3", "s4", "p1"],
        object: 29040,
        maxWeight: 10000,
        maxPrice: 50000,
        cat: "cenn"
      },
      p7: {
        mailtype: 47,
        mailctg: 3,
        tname: "Посылка 1 класса",
        label: "1 класса",
        short: "Посылка 1 класса",
        pack: ["k1", "k2", "k3", "k4", "k5", "k6", "k7", "k8", "k9", "k10"],
        services: ["s3", "s4"],
        object: 47030,
        cat: "obikn"
      },
      p8: {
        mailtype: 47,
        mailctg: 2,
        tname: "Посылка 1 класса с объявленной ценностью",
        label: "1 класса ценная",
        short: "Посылка 1 класса ценная",
        pack: ["k1", "k2", "k3", "k4", "k5", "k6", "k7", "k8", "k9", "k10"],
        services: ["s3", "s4", "p1"],
        object: 47020,
        maxPrice: 100000,
        cat: "cenn"
      },
      p9: {
        mailtype: 47,
        mailctg: 4,
        tname: "Посылка 1 класса с объявленной ценностью и наложенным платежом",
        label: "1 класса с налож. платежом",
        short: "Посылка 1 класса ценная с налож. платежом",
        pack: ["k1", "k2", "k3", "k4", "k5", "k6", "k7", "k8", "k9", "k10"],
        services: ["s3", "s4", "p1"],
        object: 47040,
        maxPrice: 100000,
        cat: "cenn"
      }
    };

    this.boxTypes = {
      k1: {
        tname: "Коробка «S»",
        label: "Коробка «S»",
        short: "S",
        boxId: 10
      },
      k2: {
        tname: "Пакет полиэтиленовый «S»",
        label: "Пакет полиэтиленовый «S»",
        short: "S",
        boxId: 11
      },
      k3: {
        tname: "Конверт с воздушно-пузырчатой пленкой «S»",
        label: "Конверт с воздушно-пузырчатой пленкой «S»",
        short: "S",
        boxId: 12
      },
      k4: {
        tname: "Коробка «M»",
        label: "Коробка «M»",
        short: "M",
        boxId: 20
      },
      k5: {
        tname: "Пакет полиэтиленовый «M»",
        label: "Пакет полиэтиленовый «M»",
        short: "M",
        boxId: 21
      },
      k6: {
        tname: "Конверт с воздушно-пузырчатой пленкой «M»",
        label: "Конверт с воздушно-пузырчатой пленкой «M»",
        short: "M",
        boxId: 22
      },
      k7: {
        tname: "Коробка «L»",
        label: "Коробка «L»",
        short: "L",
        boxId: 30
      },
      k8: {
        tname: "Пакет полиэтиленовый «L»",
        label: "Пакет полиэтиленовый «L»",
        short: "L",
        boxId: 31
      },
      k9: {
        tname: "Коробка «XL»",
        label: "Коробка «XL»",
        short: "XL",
        boxId: 40
      },
      k10: {
        tname: "Пакет полиэтиленовый «XL»",
        label: "Пакет полиэтиленовый «XL»",
        short: "XL",
        boxId: 41
      }
    };

    this.services = {
      i1: {
        tname: "Простое уведомление о вручении",
        label: "Простое уведомление о вручении",
        short: "c простым уведомлением",
        f107Label: "Простое",
        serviceId: 1
      },
      i2: {
        tname: "Заказное уведомление о вручении",
        label: "Заказное уведомление о вручении",
        short: "с заказным уведомлением",
        serviceId: 2,
        f107Label: "Заказное"
      },
      i3: {
        tname: "Электронное уведомление о вручении",
        label: "Электронное уведомление о вручении",
        short: "+ электронное увед.",
        serviceId: 62,
        f107Label: "Заказное"
      },
      s1: {
        tname: "СМС-уведомление о прибытии в отделение",
        label: "СМС-уведомление о прибытии в отделение",
        short: "СМС о прибытии",
        serviceId: 20,
        f107Label: ""
      },
      s2: {
        tname: "СМС-уведомление о вручении",
        label: "СМС-уведомление о вручении",
        short: "СМС о вручении",
        serviceId: 21,
        f107Label: ""
      },
      s3: {
        tname: "Пакет SMS уведомлений отправителю при партионном приеме",
        label: "Пакет SMS уведомлений отправителю при партионном приеме",
        short: "пакет SMS отправителю",
        serviceId: 43,
        f107Label: "Отпр"
      },
      s4: {
        tname: "Пакет SMS уведомлений получателю при партионном приеме",
        label: "Пакет SMS уведомлений получателю при партионном приеме",
        short: "пакет SMS получателю",
        serviceId: 44,
        f107Label: "Получ"
      },
      p1: {
        tname: "Проверка соответствия вложения описи",
        label: "Проверка соответствия вложения описи",
        short: "c проверкой описи",
        serviceId: 22,
        f107Label: "Проверка описи"
      },
      p2: {
        tname: "Проверка комплектности",
        label: "Проверка комплектности",
        short: "c проверкой компл.",
        serviceId: 38,
        f107Label: "Проверка компл."
      },
      k1: {
        tname: "Курьерский сбор",
        label: "Курьерский сбор",
        short: "курьер",
        serviceId: 65,
        f107Label: "Курьерский сбор"
      }
    };
  }

  getShortName(item) {
    if (item.posttype !== null) {
      if (item.posttype[0] == "p") {
        let str = this.types[item.posttype].short.replace(
          "Посылка",
          "Посылка " +
            ' <span class="packspan">' +
            this.boxTypes[item.postpack].short +
            "</span>"
        );
        for (let it of item.postservices) {
          str +=
            ' <span class="servspan">' + this.services[it].short + "</span>";
        }
        return str;
      } else {
        let str = this.types[item.posttype].short;
        if (item.postservices.length > 0) {
          for (let it of item.postservices) {
            str +=
              ' <span class="servspan">' + this.services[it].short + "</span>";
          }
        }
        return str;
      }
    } else {
      return "";
    }
  }
  getMailCatName(type) {
    if (type) {
      return this.mailCategories[this.types[type].mailctg];
    } else {
      return null;
    }
  }
  getMailTypeName(type) {
    return this.mailTypes[this.types[type].mailtype];
  }
  getMailCat(type) {
    return this.types[type].mailctg;
  }
}
