<template>
  <div class="overflow-hidden">
    <div class="q-mb-md row panelrow">
      <q-btn
        color="primary-light"
        unelevated
        dense
        icon="person_add_alt_1"
        size="md"
        :class="
          newuser
            ? 'q-mr-sm animatedpulse fs12px wd32px'
            : 'q-mr-sm fs12px wd32px'
        "
        @click="openEditor('create')"
      >
        <q-tooltip
          v-if="!newuser"
          transition-show="flip-right"
          transition-hide="flip-left"
          content-class="bg-accent fs14px"
          >Создать получателя</q-tooltip
        >
        <q-tooltip
          v-if="newuser"
          content-class="bg-indigo tip-first-recep"
          :value="true"
          anchor="bottom right"
          :offset="[105, 0]"
        >
          <q-img src="/images/common/create-recep-tip.png" />
        </q-tooltip>
      </q-btn>

      <q-btn
        dense
        color="primary-light"
        unelevated
        size="md"
        icon="person_remove"
        class="q-mr-sm fs12px wd32px"
        @click="deleteRecep"
      >
        <q-tooltip
          transition-show="flip-right"
          transition-hide="flip-left"
          content-class="bg-accent fs14px"
          >Удалить получателя</q-tooltip
        >
      </q-btn>
      <q-btn-dropdown
        v-if="!newuser"
        class="gdropdown bg-primary-light group-selector roundedbtn"
        no-caps
        unelevated
        stretch
        auto-close
        :label="activegroup ? activegroup.gname : 'Группа - все получатели'"
        v-model="groupListVisibility"
      >
        <div class="bg-dark q-pa-sm">
          <div
            @click="clearSelectedGroup"
            class="
              grouprowsel
              bg-dark
              text-accent
              cursor-pointer
              q-px-sm q-pt-sm q-pb-sm
            "
          >
            Все получатели
          </div>
          <div
            class="grouprowsel bg-dark cursor-pointer q-px-sm q-pt-sm q-pb-sm"
            v-for="(group, index) in groups"
            :key="index"
            @click="selectGroup(index)"
          >
            {{ group.gname }}
          </div>
        </div>
      </q-btn-dropdown>
    </div>
    <div class="q-mb-md row panelrow" v-if="!newuser">
      <q-input
        v-model="search"
        input-class="text-left"
        class="q-mb-md searchinp"
        bg-color="primary-light"
        placeholder="Поиск.."
        color="yellow"
        debounce="700"
        dark
        @input="OffAnotherParams"
      >
        <template v-slot:prepend>
          <q-icon v-if="search === ''" name="search" color="grey-3" />
          <q-icon
            v-else
            name="clear"
            class="cursor-pointer"
            color="accent"
            @click="clearSearch"
          />
        </template>
      </q-input>
    </div>
    <Letters :activeletter.sync="activeletter" @change-letter="changeLetter" />
    <div
      class="trdiv bg-primary-light q-pt-sm"
      tabindex="0"
      @keyup.delete="deleteRecep"
    >
      <component
        :is="currenttpl"
        @open-editor="openEditor"
        @delete-recep="deleteRecep"
        :activeletter="activeletter"
        :activegroup="activegroup"
        :search="search"
      />
    </div>
    <q-dialog persistent position="left" v-model="editor" color="text-grey-7">
      <Editor :editor.sync="editor" :record="contragent" :editmode="editmode" />
    </q-dialog>

    <q-dialog
      v-model="deletedialog"
      transition-show="scale"
      transition-hide="scale"
      content-class="pochtumd"
    >
      <q-card>
        <q-card-section>
          <div>Удалить получателя?</div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            unelevated
            no-caps
            dense
            color="accent"
            autofocus
            label="Да"
            @click="deleteRecepFromDialog"
          />
          <q-btn
            unelevated
            no-caps
            dense
            color="accent"
            label="Нет"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import Address from "src/service/classes/address.js";
import Editor from "src/service/components/common/Editor.vue";
import Letters from "src/service/components/common/Letters.vue";
import RecepientsList from "src/service/components/single-page/RecepientsList.vue";
import RecepientsListMass from "src/service/components/mass-page/RecepientsListMass.vue";
export default {
  components: {
    Editor,
    RecepientsList,
    RecepientsListMass,
    Letters
  },
  data() {
    return {
      //editor variables
      editor: false,
      editmode: false,
      contragent: new Address({
        name: "",
        fullname: "",
        zip: "",
        addr: "",
        comment: "",
        comment2: "",
        autoformat: 0,
        digitzip: 0,
        gids: []
      }),
      //List variables
      activeletter: -1,
      activegroup: null,
      search: "",

      deletedialog: false,
      //groups
      groupListVisibility: false
    };
  },
  computed: {
    groups() {
      return this.$store.getters.GET_GROUPS;
    },
    recepients() {
      return this.$store.getters.GET_RECEPIENTS;
    },
    senders() {
      return this.$store.getters.GET_SENDERS;
    },
    activereceps() {
      return this.$store.getters.GET_ACTIVERECEPS;
    },
    newuser() {
      return this.$store.getters.NEWUSER;
    },
    currenttpl() {
      if (this.$router.currentRoute.name !== "mass") {
        return "RecepientsList";
      } else {
        return "RecepientsListMass";
      }
    }
  },
  methods: {
    //search methods
    OffAnotherParams() {
      this.activeletter = -1;
      this.activegroup = null;
    },
    clearSearch() {
      this.search = "";
    },
    //letters methods
    changeLetter() {
      this.clearSearch();
    },

    //group methods
    selectGroup(index) {
      this.search = "";
      this.activegroup = this.groups[index];
      this.groupListVisibility = false;
    },
    clearSelectedGroup() {
      this.activegroup = null;
      this.groupListVisibility = false;
    },

    deleteRecep() {
      if (this.activereceps.length > 0) {
        this.deletedialog = true;
      } else {
        this.$store.commit("SET_ERROR", {
          error: true,
          errortext: "Сначала выберите получателя, которого хотите удалить"
        });
      }
    },
    deleteRecepFromDialog() {
      this.contragent.id = this.activereceps[0];
      this.$store.dispatch("DELETE_RECEPIENT", {
        action: "deleterecepient",
        rec: this.contragent.Export()
      });
      this.deletedialog = false;
    },
    //Editor block
    openEditor(mode) {
      let record = "";
      if (mode == "create") {
        this.editmode = false;
      } else if (mode == "edit") {
        this.editmode = true;
        record = this.recepients.find(item => item.id == this.activereceps[0]);
      }
      this.editor = true;
      this.contragent.fullname = this.editmode ? record.fullname : "";
      this.contragent.name = this.editmode ? record.name : "";
      this.contragent.zip = this.editmode ? record.zip : "";
      this.contragent.addr = this.editmode ? record.addr : "";
      this.contragent.comment = this.editmode ? record.comment : "";
      this.contragent.comment2 = this.editmode ? record.comment2 : "";
      this.contragent.autoformat = this.editmode ? record.autoformat : 1;
      this.contragent.digitzip = this.editmode ? record.digitzip : 1;
      this.contragent.gids = this.editmode ? record.gids : new Array();
      this.contragent.id = this.editmode ? record.id : undefined;
    }
  }
};
</script>

<style lang="sass">
.trdiv
  border: 1px solid rgba(0, 0, 0, 0.35)
  border-left-width: 0
  box-sizing: border-box
  width: 271px
  float: left
  height: 638px
  border-radius: 0 4px 0 0

.hiderec
  display: none

.q-btn.group-selector
  width: 215px
  padding: 0
  .q-btn_wrapper
    padding-left: 10px
    padding-right: 10px
  .q-btn__content div
    width: 100%
    text-overflow: ellipsis
    overflow: hidden
  .block
    width: 100%
    text-overflow: ellipsis
    word-break: break-word
    overflow: hidden

.active-group
  color: red
  background: #F2C037
.mlminus
  margin-left: -10px
.grouprowsel
  border-radius: 0
  border-bottom: 1px solid rgba(255, 255, 255, 0.1)
  max-width: 250px
  text-overflow: ellipsis
  white-space: nowrap
  overflow: hidden
.grouprowsel:hover
  background: rgba(0, 0, 0, 0.2)!important
.grouprowsel, .q-btn.roundedbtn
  border-radius: 4px!important

.gdropdown .q-btn__content
  text-align: left
  font-size: 14px!important
.gdropdown .q-btn__wrapper
  padding: 0px 4px 0 10px !important
  min-height: 1px !important
.panelrow
  height: 32px
.drded.gdropdown
  padding-left: 14px!important
.grsel .q-chip
  margin-left: 0
  width: 284px
.fresh
  background: red
.eprogressouter
  min-height: 4px

.transall
  -webkit-transition: all 0.5s
  -moz-transition: all 0.5s
  -o-transition: all 0.5s
  transition: all 0.5s
.digitzipinput
  width: 180px
  float: left

.trdiv:focus
  outline: none
.q-panel > div.receptabpanel
  height: 792px
.q-panel > div.receptabpanel .trdiv
  height: 661px
  padding-bottom: 10px
  position: relative
.searchinp
  width: 295px
  height: 32px
  .q-field__marginal
    height: 32px
    font-size: 22px
  input
    padding: 0
  .q-field__control
    height: 32px
    border-radius: 4px
    padding: 0 6px
.searchinp.q-field--dark .q-field__control:before
  border-color: rgb(52, 58, 80)
.searchinp.q-field--dark .q-field__control:hover:before
  border-color: rgb(52, 58, 80)
.animatedpulse
  animation-name: pulsemaintip
  animation-duration: 1.5s
  animation-iteration-count: infinite
.tip-first-recep
  width: 275px
  padding: 0
  background: transparent!important
</style>
