export default class Interleaved {
  constructor(w) {
    this.seqs = ["00110", "10001", "01001", "11000", "00101", "10100", "01100", "00011", "10010", "01010"];
    this.start = "0000";
    this.end = "100";

    this.height = w / 4;
    this.width = w;
    this.thin = 0.5;
    this.wide = 2.5 * this.thin;
    this.number = "0";
  }

  pad(num, size) {
    var s = num + "";
    while (s.length < size) s = "0" + s;
    return s;
  }

  getNumberBacode() {
    return this.number;
  }

  setNumberBarcode(num) {
    this.number = num;
  }

  createNumBarcode(postID, rangeCur) {
    var d = new Date();
    var month = d.getMonth() + 1;
    var year = d.getFullYear();
    var monthcode = ((year - 2000) * 12 + month) % 99;
    if (monthcode < 10) {
      monthcode = "0" + monthcode;
    } else {
      monthcode = monthcode + "";
    }
    var barcode = "" + postID + monthcode + this.pad(rangeCur, 5);
    var l = barcode.length;
    var chet = 0;
    var nechet = 0;
    for (var i = 0; i < l; i++) {
      if ((i + 1) % 2 == 0) {
        chet += parseInt(barcode[i]);
      } else {
        nechet += parseInt(barcode[i]);
      }
    }
    nechet = nechet * 3;
    var diff = (chet + nechet) % 10;
    var res = diff > 0 ? 10 - diff : 0;
    barcode += res;
    this.number = barcode;
  }

  sequence(num) {
    let snum = num.toString();
    let seq = "";
    let len = snum.length;
    for (let i = 0; i < len; i++) {
      seq += this.seqs[snum[i]];
    }

    let intl = "";
    len = seq.length;
    for (let i = 0; i < len; i++) {
      intl += seq[i] + seq[i + 5];
      if ((i + 1) % 5 == 0) i += 5;
    }

    return this.start + intl + this.end;
  }

  pnt(digit) {
    return (digit * 72) / 25.4;
  }

  drawBar(dest, startx, starty, fontsize, fontfamily) {
    let seq = this.sequence(this.number);
    let width = 0;
    let len = seq.length;
    for (var i = 0; i < len; i++) {
      width += seq[i] == 0 ? 1 : 3;
    }
    this.thin = this.width / width;
    this.wide = this.thin * 3;

    let x = 0;
    dest.rect(startx, starty, this.width + 40 * this.thin, (5.1 * this.height) / 3, "S");
    for (let i = 0; i < len; i++) {
      let w = seq[i] == 0 ? this.thin : this.wide;
      if (i % 2 == 0) {
        dest.setFillColor(0, 0, 0);
        dest.rect(startx + 20 * this.thin + x, starty + this.height / 3, w, this.height, "F");
      }
      x += w;
    }
    dest.setFontSize(this.pnt((0.8 * this.height) / 3));
    dest.setFont(fontfamily);

    dest.text(startx + 20 * this.thin, starty + this.height / 3 - 0.8, "ПОЧТА РОССИИ");

    let num = this.number;
    let fh = (0.7 * this.height) / 3;
    dest.setFontSize(this.pnt((0.85 * this.height) / 3));

    dest.text(
      startx + 20 * this.thin + 4 * this.thin,
      fh + starty + (4 * this.height) / 3 + 2 * this.thin,
      num[0] + num[1] + num[2] + num[3] + num[4] + num[5]
    );
    dest.text(
      startx + 20 * this.thin + 4 * this.thin + (3 * this.width) / 8,
      fh + starty + (4 * this.height) / 3 + 2 * this.thin,
      num[6] + num[7]
    );
    dest.text(
      startx + 20 * this.thin + 4 * this.thin + (4.7 * this.width) / 8,
      fh + starty + (4 * this.height) / 3 + 2 * this.thin,
      num[8] + num[9] + num[10] + num[11] + num[12]
    );
    dest.text(
      startx + 20 * this.thin + 4 * this.thin + (7.4 * this.width) / 8,
      fh + starty + (4 * this.height) / 3 + 2 * this.thin,
      num[13]
    );
  }
}
