<template>
  <div class="mwmass">
    <div class="row">
      <LeftPanel />
      <div class="q-mr-md mchevrons">
        <q-btn
          unelevated
          color="secondary"
          icon="chevron_right"
          size="sm"
          round
          class="q-mb-md"
          @click="moveSelected"
        /><br />
        <q-btn
          unelevated
          color="secondary"
          icon="chevron_left"
          size="sm"
          round
          @click="removeSelected"
        />
      </div>
      <q-page class="singlemode col-grow">
        <PrintSettings /> <WorkList />
      </q-page>
    </div>

    <ProgressDialog />
  </div>
</template>

<script>
import LeftPanel from "src/service/components/single-page/LeftPanel.vue";
import WorkList from "src/service/components/mass-page/WorkList.vue";
import PrintSettings from "src/service/components/common/PrintSettings.vue";
import ProgressDialog from "src/service/components/mass-page/ProgressDialog.vue";
export default {
  meta: {
    // sets document title
    title:
      "Опись вложения бланк Ф-107 | Заполнить онлайн | Распечатать | Удобнее WORD",
    // meta tags
    meta: {
      description: {
        name: "description",
        content:
          "Заполнить бланк описи вложения Ф-107 стало еще удобнее. Забудьте WORD. Заполните бланк онлайн, скачайте или распечатайте без регистрации. Бланк Ф-107 по форме Почты России."
      },
      keywords: {
        name: "keywords",
        content:
          "опись вложения, бланк, ф 107, заполнить, онлайн, скачать, распечатать, почта"
      },
      ogTitle: {
        name:
          "Опись вложения бланк Ф-107 | Заполнить онлайн | Распечатать | Удобнее WORD"
      }
    }
  },
  components: {
    LeftPanel,
    PrintSettings,
    WorkList,
    ProgressDialog
  },
  data() {
    return { psdialog: true };
  },
  computed: {},
  methods: {
    moveSelected() {
      this.$eventBus.$emit("move-selected");
    },
    removeSelected() {
      this.$eventBus.$emit("remove-selected");
    }
  }
};
</script>

<style lang="sass">
.mchevrons
  margin-top: 300px
</style>