var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-4 text-h4 q-mb-md"},[_vm._v("Реквизиты")]),_c('div',{staticClass:"col-8"},[_c('q-btn-toggle',{staticClass:"no-shadow float-right",attrs:{"no-caps":"","content-class":"q-px-md","color":"secondary-transparent","toggle-color":"secondary","options":[
          { label: 'Юридическое лицо', value: 'company' },
          { label: 'Физическое лицо', value: 'people' },
        ]},model:{value:(_vm.req.companytype),callback:function ($$v) {_vm.$set(_vm.req, "companytype", $$v)},expression:"req.companytype"}})],1)]),_c('q-form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){return _vm.saveReq()}}},[(_vm.req.companytype != 'company')?_c('div',[_c('q-input',{attrs:{"label":_vm.req.companytype == 'company' ? 'Наименование организации' : 'ФИО',"dense":"","dark":"","color":"yellow-5","lazy-rules":"","rules":[(val) => String(val).length > 0 || 'Значение не заполнено']},model:{value:(_vm.req.companyname),callback:function ($$v) {_vm.$set(_vm.req, "companyname", $$v)},expression:"req.companyname"}})],1):_c('div',[_c('div',{staticClass:"row q-col-gutter-x-md justify-between"},[_c('div',{staticClass:"col-2"},[_c('q-input',{attrs:{"label":"ИНН","dense":"","dark":"","color":"yellow-5","lazy-rules":"","rules":[(val) => _vm.validateInn(val)]},model:{value:(_vm.req.inn),callback:function ($$v) {_vm.$set(_vm.req, "inn", $$v)},expression:"req.inn"}})],1),_c('div',{staticClass:"col-2"},[_c('q-input',{attrs:{"label":"КПП","dense":"","dark":"","color":"yellow-5","lazy-rules":"","rules":[(val) => _vm.validateKpp(val)]},model:{value:(_vm.req.kpp),callback:function ($$v) {_vm.$set(_vm.req, "kpp", $$v)},expression:"req.kpp"}})],1),_c('div',{staticClass:"col-8"},[_c('q-input',{attrs:{"label":_vm.req.companytype == 'company'
                ? 'Наименование организации'
                : 'ФИО',"dense":"","dark":"","color":"yellow-5","lazy-rules":"","rules":[
              (val) => String(val).length > 0 || 'Значение не заполнено',
            ]},model:{value:(_vm.req.companyname),callback:function ($$v) {_vm.$set(_vm.req, "companyname", $$v)},expression:"req.companyname"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('q-input',{attrs:{"label":"Юридический адрес","dense":"","dark":"","color":"yellow-5","lazy-rules":"","rules":[(val) => _vm.validateAddr(val)]},model:{value:(_vm.req.address),callback:function ($$v) {_vm.$set(_vm.req, "address", $$v)},expression:"req.address"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('q-input',{attrs:{"label":"Почтовый адрес","dense":"","dark":"","color":"yellow-5","lazy-rules":"","rules":[(val) => _vm.validatePostAddr(val)]},model:{value:(_vm.req.postaddress),callback:function ($$v) {_vm.$set(_vm.req, "postaddress", $$v)},expression:"req.postaddress"}})],1)])]),_c('q-btn',{staticClass:"q-mt-md wd265",attrs:{"loading":_vm.changereqprogress,"unelevated":"","no-caps":"","type":"submit","color":"secondary"},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('q-spinner-hourglass',{staticClass:"on-left"}),_vm._v("Сохранение..\n      ")]},proxy:true}])},[_vm._v("\n      Сохранить\n      ")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.success),expression:"success"}],staticClass:"q-mt-lg q-pa-md rounded-borders tip-success"},[_vm._v("\n    Изменения сохранены\n  ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }