<template>
  <q-header elevated class="bg-primary qhmw">
    <q-toolbar>
      <q-toolbar-title>
        <router-link to="/" class="text-white">
          <q-avatar rounded class="q-mr-sm q-mb-xs float-left">
            <img src="../img/logo.png" />
          </q-avatar>
          <div class="q-pt-xs float-left gt-sm">ПОЧТУМ</div>
        </router-link>
      </q-toolbar-title>
      <q-separator dark vertical></q-separator>

      <q-btn stretch flat icon="phone">
        <q-tooltip
          content-style="background-color: #e64c65; font-size: 14px"
          content-class="q-pa-md"
          anchor="bottom middle"
          self="top middle"
        >
          <div class="row no-wrap items-center">
            <div class="column">
              <div class="text-h6 q-mb-sm">Техподдержка</div>
              <div>9:00 - 17:00 МСК</div>
              <div>+7 (953) 415-22-27</div>
              <div>mail.pochtum@gmail.com</div>
            </div>

            <q-separator vertical inset class="q-mx-md" />

            <div class="column items-center">
              <q-spinner-bars color="white" size="56px" />
            </div>
          </div>
        </q-tooltip>
      </q-btn>

      <q-separator dark vertical></q-separator>
      <a href="/support/" target="_blank" class="suppref">Руководство</a>
      <q-separator dark vertical></q-separator>
      <q-btn
        stretch
        flat
        label="Настройки"
        v-if="accInfo.usertype === 'active'"
        @click="openSettings"
      ></q-btn>
      <q-separator
        dark
        vertical
        v-if="accInfo.usertype === 'active'"
      ></q-separator>
      <q-btn-dropdown
        stretch
        flat
        no-caps
        :label="$q.screen.gt.sm ? 'МЕНЮ: ' + menuname : 'МЕНЮ'"
        v-if="accInfo.usertype !== 'finished'"
      >
        <q-list class="bg-secondary q-py-md">
          <q-item
            clickable
            v-close-popup
            tabindex="0"
            to="/"
            exact
            active-class="text-white bg-accent"
          >
            <q-item-section avatar>
              <q-avatar icon="person" color="white" text-color="accent" />
            </q-item-section>
            <q-item-section>
              <q-item-label class="text-weight-bold"
                >Простая печать</q-item-label
              >
              <q-item-label caption class="text-grey-4"
                >по 1 документу</q-item-label
              >
            </q-item-section>
          </q-item>
          <q-item
            clickable
            v-close-popup
            tabindex="0"
            to="/mass/"
            active-class="text-white bg-accent"
          >
            <q-item-section avatar>
              <q-avatar icon="people_alt" color="white" text-color="accent" />
            </q-item-section>
            <q-item-section>
              <q-item-label class="text-weight-bold"
                >Пакетная печать</q-item-label
              >
              <q-item-label caption class="text-grey-4"
                >до 5000 получателей за раз</q-item-label
              >
            </q-item-section>
          </q-item>
          <q-item
            clickable
            v-close-popup
            tabindex="0"
            to="/editor/"
            active-class="text-white bg-accent"
          >
            <q-item-section avatar>
              <q-avatar icon="folder" color="white" text-color="accent" />
            </q-item-section>
            <q-item-section>
              <q-item-label class="text-weight-bold"
                >Редактирование получателей</q-item-label
              >
              <q-item-label caption class="text-grey-4"
                >удаление, перенос в группы</q-item-label
              >
            </q-item-section>
          </q-item>
          <q-item
            clickable
            v-close-popup
            tabindex="0"
            to="/fromexcel/"
            active-class="text-white bg-accent"
          >
            <q-item-section avatar>
              <q-avatar icon="speed" color="white" text-color="accent" />
            </q-item-section>
            <q-item-section>
              <q-item-label class="text-weight-bold"
                >Загрузка получателей</q-item-label
              >
              <q-item-label class="text-grey-4" caption
                >импорт из EXCEL</q-item-label
              >
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
      <q-separator dark vertical></q-separator>
      <Chat />
      <q-separator dark vertical></q-separator>

      <q-separator
        v-if="accInfo.usertype === 'new'"
        dark
        vertical
      ></q-separator>
      <Signin
        v-if="accInfo.usertype === 'new' || accInfo.usertype === 'undefined'"
        dark
        vertical
      />
      <q-separator
        v-if="accInfo.usertype === 'new' || accInfo.usertype === 'undefined'"
        dark
        vertical
      ></q-separator>

      <q-btn
        stretch
        flat
        label="Регистрация"
        router-link
        to="/signup/"
        v-if="this.$store.getters.ALLOWREGISTRATION"
      ></q-btn>
      <q-separator
        v-if="accInfo.usertype === 'new'"
        dark
        vertical
      ></q-separator>

      <Account
        v-if="accInfo.usertype == 'active' || accInfo.usertype == 'finished'"
      />
    </q-toolbar>
    <ErrorPopups />
    <Metrica />
    <EmailRequiredPopup :email-required-dialog="userEmailRequired"/>
  </q-header>
</template>

<script>
import Signin from "src/platform/components/Signin.vue";
import Account from "src/platform/components/Account.vue";
import ErrorPopups from "src/platform/components/ErrorPopups.vue";
import Chat from "src/platform/components/Chat.vue";
import Metrica from "src/platform/components/Metrica.vue";
import EmailRequiredPopup from "src/service/components/common/EmailRequiredPopup.vue";

export default {
  name: "AppHeader",
  components: {
    Signin,
    Account,
    ErrorPopups,
    Chat,
    Metrica,
    EmailRequiredPopup
  },


  methods: {
    logout() {
      this.$store.dispatch("LOGOUT");
    },
    openSettings() {
      this.$eventBus.$emit("open-tab-settings", {
        tabName: null
      });
    },
    extractParamValue(url, name) {
      var pos = url.indexOf(name + "=") + name.length + 1;
      let end = url.length;
      if (~url.indexOf("&", pos)) {
        end = url.indexOf("&", pos);
      }
      var value = url.substring(pos, end);
      return value;
    }
  },

  mounted() {
    this.$store.dispatch("INITIALIZE");
  },
  data() {
    return {
      pagenames: {
        editor: "Редактирование получателей",
        home: "Простая печать",
        signup: "Вход для пользователей",
        recovery: "Восстановление доступа",
        import: "Импорт списка получателей",
        mass: "Пакетная печать"
      },
    };
  },
  computed: {
    accInfo() {
      return this.$store.getters.ACCOUNT;
    },
    menuname() {
      return this.pagenames[this.$route.name];
    },
    isactive() {
      return this.$store.getters.ACTIVEUSER;
    },
    userType() {
      return this.$store.getters.USERTYPE;
    },
    userEmailRequired() {
      return this.$store.getters.ACCOUNT?.usertype === "active" && !this.$store.getters.ACCOUNT?.email
    }
  },
  watch: {
    $route(to, from) {
      if (to.name == "home") {
        this.$store.commit("UPDATE_CANVAS");
      }
    },
    userType(nv) {
      if (nv == "new" && !this.$q.cookies.get("psourse")) {
        let url = document.referrer;
        let adv = this.extractParamValue(location.search, "utm_source");
        let browser =
          this.$q.platform.is.name + " " + this.$q.platform.is.versionNumber;
        this.$q.cookies.set(
          "psourse",
          JSON.stringify({ referrer: url, browser: browser }),
          {
            expires: "1y",
            path: "/"
          }
        );
        this.$q.cookies.set("adv", adv, {
          expires: "1y",
          path: "/"
        });
      } else if (
        nv == "active" &&
        this.$q.cookies.get("psourse") &&
        this.$q.cookies.get("psourse") !== 1
      ) {
        this.$store.dispatch("SEND_REFERER_INFO", {
          refer: JSON.stringify(this.$q.cookies.get("psourse")),
          adv: this.$q.cookies.get("adv")
        });
        this.$q.cookies.set("psourse", "1", {
          expires: "1y",
          path: "/"
        });
      }
    }
  },
  created() {
    /* if (!this.$q.cookies.get("PHPSESSID")) {
			let luid = uid();
			this.$q.cookies.set("PHPSESSID", luid, {
				expires: "24h",
				sameSite: "none",
				secure: true,
				path: "/"
			});
		} */
  }
};
</script>
<style lang="sass" scoped>
.suppref
  color: #e9e9ec
  text-decoration: none
  text-transform: uppercase
  padding: 14px

.phonetip
  background-color: red
  font-size: 18px
</style>
