export default {
  state: {
    showProg: false,
    progress: 0,
    progressLen: 100,
    progressStep: 50,
    progressHeader: "Пргресс выполнения"
  },

  getters: {
    GET_PROGRESS: state => {
      return state.progress;
    },
    GET_PROGRESSLEN: state => {
      return state.progressLen;
    },
    GET_PROGRESSHEADER: state => {
      return state.progressHeader;
    },
    GET_SHOWPROGRESS: state => {
      return state.showProg;
    }
  },
  mutations: {
    START_PROGRESS(state, payload) {
      //if (payload.count < 30) return;
      state.showProg = true;
      state.progress = 0;
      state.progressLen = payload.count;
      state.progressHeader = payload.title;
    },
    STEP_PROGRESS(state, payload) {
      if (typeof payload != undefined) {
        state.progressStep = payload;
      }
      state.progress += state.progressStep;
      if (state.progress >= state.progressLen) {
        state.showProg = false;
        state.progress = 0;
        state.progressLen = 100;
        state.progressHeader = "Прогресс выполнения";
      }
    },
    STOP_PROGRESS(state) {
      state.showProg = false;
      state.progress = 0;
      state.progressLen = 100;
      state.progressHeader = "Пргресс выполнения";
    }
  }
};
