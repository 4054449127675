<template>
  <q-page class="modeimport">
    <q-card
      class="pimporter q-pa-md no-border no-border-radius bg-dark"
      v-if="!this.$store.getters.FINISHED"
    >
      <q-tabs
        v-model="tab"
        dense
        no-caps
        align="left"
        class="text-grey-5"
        active-color="white"
        indicator-color="accent"
        active-bg-color="primary"
        narrow-indicator
      >
        <q-tab name="recepients" label="Получатели" />
        <q-tab name="groups" label="Группы" />
      </q-tabs>

      <q-tab-panels v-model="tab" animated>
        <q-tab-panel name="recepients" class="q-ma-none q-pa-md bg-primary">
          <Importer />
        </q-tab-panel>

        <q-tab-panel
          name="groups"
          class="receptabpanel massive q-ma-none q-pa-md bg-primary"
        >
          <Groups />
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
  </q-page>
</template>


<script>
import Importer from "src/service/components/import-page/Importer.vue";
import Groups from "src/service/components/common/Groups.vue";
export default {
  meta: {
    // sets document title
    title: "ПОЧТУМ - импорт адресов для конвертов из файла EXCEL",
    // meta tags
    meta: {
      description: {
        name: "description",
        content:
          "ПОЧТУМ - быстрый и удобный импорт адресов из тектового файла, EXCEL или PDF"
      },
      keywords: {
        name: "keywords",
        content: "импорт списка адресов"
      },
      ogTitle: {
        name: "ПОЧТУМ - импорт адресов для конвертов из файла EXCEL"
      }
    }
  },
  components: {
    Importer,
    Groups
  },
  data() {
    return {
      tab: "recepients"
    };
  },
  computed: {}
};
</script>

<style lang="sass">
</style>