import { render, staticRenderFns } from "./Single.vue?vue&type=template&id=3821c132"
import script from "./Single.vue?vue&type=script&lang=js"
export * from "./Single.vue?vue&type=script&lang=js"
import style0 from "./Single.vue?vue&type=style&index=0&id=3821c132&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QBtn});
