//import Vue from 'vue'
//import axios from 'axios'

//Vue.prototype.$axios = axios
function get_cookie(cookie_name) {
  var results = document.cookie.match(
    "(^|;) ?" + cookie_name + "=([^;]*)(;|$)"
  );

  if (results) return unescape(results[2]);
  else return null;
}

import Vue from "vue";
//import App from "src/App.vue";
//import router from "src/router";
//import store from "src/store";
//import "./quasar";
import Axios from "axios";
import Bugsnag from "@bugsnag/js";
/* import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
Bugsnag.start({
  apiKey: "143d30f5f62b8b27b0f6af7b55d9f8a6",
  plugins: [new BugsnagPluginVue()],
  onError: function(event) {
    console.log("sdfsdfsdf");
  }
});
const bugsnagVue = Bugsnag.getPlugin("vue");
bugsnagVue.installVueErrorHandler(Vue);
Vue.prototype.$bugsnag = Bugsnag; */

const api = Axios.create({
  mode: "cors",
  baseURL:
    process.env.NODE_ENV == "production"
      ? "https://777.xn--l1adcnfw.xn--p1ai"
      : "https://777.xn--l1adcnfw.xn--p1ai",
  withCredentials: true,
  timeout: 20000
});

api.interceptors.request.use(
  config => {
    /* config.headers.common["Authorization"] =
      get_cookie("PHPSESSID") +
      "#" +
      get_cookie("token") +
      "#" +
      get_cookie("series") +
      "#1"; */
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
api.interceptors.response.use(
  response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    Vue.prototype.$bugsnag.notify(error);
    //Bugsnag.report("bad request");
    return Promise.reject(error);
  }
);

Vue.prototype.$api = api;

import VueCookies from "vue-cookie";

import * as pdfjs from "pdfjs-dist/es5/build/pdf";
pdfjs.GlobalWorkerOptions.workerSrc = "worker/pdf.worker.js";

Vue.use(VueCookies);
Vue.config.productionTip = false;
Vue.directive("focus", {
  inserted: function(el) {
    el.focus();
  }
});
let sconfig = {
  logopath:
    process.env.NODE_ENV == "production"
      ? "https://777.xn--l1adcnfw.xn--p1ai"
      : "https://777.xn--l1adcnfw.xn--p1ai"
};

Vue.prototype.$pdfjs = pdfjs;

Vue.prototype.$eventBus = new Vue();

import jsPDF from "jspdf";
//import "jspdf/dist/polyfills.es.js";
import { Play } from "src/service/assets/js/play-normal.js";
import { Zip } from "src/service/assets/js/ZIP-normal.js";
import { ZipClear } from "src/service/assets/js/ZIPclear-normal.js";
import CommonTemplate from "src/service/classes/printCommon.js";

let callAddFont = function() {
  this.addFileToVFS("Play-normal.ttf", Play);
  this.addFont("Play-normal.ttf", "Play", "normal");
};
jsPDF.API.events.push(["addFonts", callAddFont]);

callAddFont = function() {
  this.addFileToVFS("Play-normal.ttf", Play);
  this.addFont("Play-normal.ttf", "Play", "bold");
};
jsPDF.API.events.push(["addFonts", callAddFont]);

callAddFont = function() {
  this.addFileToVFS("ZIP-normal.ttf", Zip);
  this.addFont("ZIP-normal.ttf", "ZIP", "normal");
};
jsPDF.API.events.push(["addFonts", callAddFont]);

callAddFont = function() {
  this.addFileToVFS("ZIPclear-normal.ttf", ZipClear);
  this.addFont("ZIPclear-normal.ttf", "ZIPclear", "normal");
};
jsPDF.API.events.push(["addFonts", callAddFont]);

import Chunk from "src/service/classes/chunk.js";
/* import Bugsnag from "@bugsnag/js"; */
Vue.prototype.$chunk = new Chunk();
//Vue.prototype.$jspdf = new jsPDF();

/*window.onerror = function(message, source, line, column, error) {
  alert(error);
}
Vue.config.errorHandler = function(err, vm, info) {
  alert("errorhandler"+err+info+vm);
}
*/
//new Vue({
//  router,
//  store,
//  render: h => h(App)
//}).$mount("#app");

export { api, sconfig };
