<template>
  <div>
    <VirtualArea :list="list" :areaheight="641" :itemheight="24">
      <template v-slot:list="{ item }">
        <div
          class="recep bg-primary-light cursor-pointer"
          :class="item.id == activereceps[0] ? ' recepactive' : ''"
          @click="setActive(item.id)"
          @dblclick="$emit('open-editor')"
        >
          <span
            :class="
              'sedname ' +
              (typeof item.contentclass !== 'undefined' ? 'newaddress' : '')
            "
          >
            <q-icon
              :name="
                isSelected(item.id) ? 'check_box' : 'check_box_outline_blank'
              "
              left
              color="yellow"
              class="icheck"
              @click="selectUnselect(item.id)"
            />
            <q-icon
              name="check_circle"
              v-if="item.contentclass != undefined"
              left
              color="green-13"
              class="q-mt-xs float-left q-mr-xs"
            />
            <span class="nametext">{{ item.name }}</span>
            <q-icon
              name="close"
              class="directdelete"
              left
              color="accent"
              @click="deleteItem(item.id)"
            />
          </span>
          <span class="sedzip">{{ item.zip }}</span>
          <span class="sedaddr">{{ item.addr }}</span>
          <span class="sedcomment">{{ item.comment }}</span>
          <span class="sedcomment2">{{ item.comment2 }}</span>
          <span class="sedgids">
            <span v-for="(gid, index) in item.gids" :key="index">{{
              groupsbyid[gid]
            }}</span>
          </span>
        </div>
      </template>
    </VirtualArea>
  </div>
</template>

<script>
import VirtualArea from "src/service/components/common/VirtualArea.vue";
import Filter from "src/service/components/mixins/Filter.vue";
export default {
  props: {
    rset: { type: Array, default: new Array() },
    selectall: { type: Boolean, default: false }
  },
  components: {
    VirtualArea
  },
  mixins: [Filter],
  data() {
    return {};
  },

  watch: {
    selectall: function() {
      this.$emit("select-all", this.list);
    }
  },

  computed: {
    recepients() {
      return this.$store.getters.GET_RECEPIENTS;
    },
    activereceps() {
      return this.$store.getters.GET_ACTIVERECEPS;
    },
    searchtpl() {
      return this.$store.getters.GET_SEARCHTPL;
    },
    groupsbyid() {
      let gr = [];
      let groups = this.$store.getters.GET_GROUPS;
      for (let group of groups) {
        gr[group.id] = group.gname;
      }
      return gr;
    }
  },

  methods: {
    isSelected(id) {
      return ~this.rset.indexOf(id);
    },
    selectUnselect(id) {
      this.$emit("select-item", id);
    },
    setActive(id) {
      this.$store.commit("SET_ACTIVE_RECEP", [id]);
    },
    deleteItem(id) {
      this.$emit("delete-item", id);
    }
  }
};
</script>
<style lang="sass">
</style>