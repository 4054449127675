<template>
  <q-dialog
    v-model="show"
    persistent
    transition-show="scale"
    transition-hide="scale"
  >
    <q-card class="mypdial max-width: 700px">
      <q-card-section class="inventheader">
        <div class="text-h6">Сведения о ценности отправления</div>
        <div class="text-h7">
          Получатель: <span class="text-italic">{{ recepname }}</span>
        </div>
      </q-card-section>

      <q-card-section>
        <div class="row">
          <div class="col-auto q-mr-md">
            <q-radio
              class="mrtoleft"
              color="accent"
              dark
              v-model="costType"
              val="cost"
              label="Указать ценность суммой"
            />
          </div>
          <div class="col-2">
            <q-input
              dense
              standout
              :disable="costType != 'cost'"
              maxlength="6"
              v-model.number="costP"
              type="number"
              color="accent"
              bg-color="white"
              label-color="black"
              input-class="text-black"
              dark
            />
          </div>
        </div>
        <q-radio
          v-model="costType"
          class="mrtoleft q-mt-sm q-mb-xs"
          val="inventory"
          label="Заполнить опись вложения Ф-119"
          color="accent"
          dark
        />
        <div class="row invtableheader">
          <div class="col-1 text-center">№</div>
          <div class="col-6">Наименование предмета</div>
          <div class="col-2 text-center">Кол-во предметов</div>
          <div class="col-3 text-right">Объявленная ценность, руб.</div>
        </div>
        <q-scroll-area
          :class="costType != 'inventory' ? 'disable-events' : ''"
          :thumb-style="thumbStyle"
          :bar-style="barStyle"
          style="height: 300px; max-width: 700px"
        >
          <div
            v-for="(item, index) in inventory"
            :key="index"
            class="row bg-white invtable"
          >
            <div class="col-1 text-center">{{ index + 1 }}</div>
            <div class="col-6">
              {{ item.name }}
              <q-popup-edit
                content-class="bg-yellow-3 text-primary q-py-xs q-px-sm"
                v-model="item.name"
                auto-save
              >
                <q-input
                  v-model="item.name"
                  debounce="1000"
                  dense
                  autofocus
                  class="inventorypop"
                  input-class="q-py-none"
                />
              </q-popup-edit>
            </div>
            <div class="col-2 text-center">
              {{ item.count }}
              <q-popup-edit
                content-class="bg-yellow-3 text-primary q-py-xs q-px-sm winvcostpop"
                v-model="item.count"
                auto-save
              >
                <q-input
                  v-model.number="item.count"
                  type="number"
                  debounce="1000"
                  dense
                  autofocus
                  class="inventorypop"
                  input-class="q-py-none"
                />
              </q-popup-edit>
            </div>
            <div class="col-3 text-right q-pr-md">
              {{ item.cost }}
              <q-popup-edit
                content-class="bg-yellow-3 text-primary q-py-xs q-px-sm winvcostpop"
                v-model="item.cost"
                auto-save
              >
                <q-input
                  v-model.number="item.cost"
                  type="number"
                  debounce="1000"
                  dense
                  autofocus
                  class="inventorypop"
                  input-class="q-py-none"
                />
              </q-popup-edit>
              <q-btn
                v-show="
                  item.name !== '' || item.count != '' || item.cost !== ''
                "
                icon="close"
                class="q-ml-sm"
                flat
                color="accent"
                size="xs"
                unelevated
                padding="xs"
                @click.stop="clearRow(item)"
              />
            </div>
          </div>
        </q-scroll-area>
        <div class="row q-mt-md inventtotals">
          <div class="col-7 text-right q-pr-md">Итого</div>
          <div class="col-2 text-center">{{ totalcount }} шт</div>
          <div class="col-3 text-right q-pr-md">{{ totalcost }} руб</div>
        </div>
      </q-card-section>

      <q-card-actions align="right" class="inventbtns text-white q-pa-md">
        <q-btn
          unelevated
          no-caps
          label="Ок"
          v-close-popup
          @click="done(true)"
          dense
          color="accent"
        />
        <q-btn
          unelevated
          no-caps
          label="Отмена"
          v-close-popup
          @click="done(false)"
          dense
          color="accent"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>


<script>
import Address from "src/service/classes/address.js";
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    recepientsIds: {
      type: Array,
      default: null
    },
    openedRecep: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      columns: [
        { name: "number", align: "left", label: "№" },
        { name: "name", align: "left", label: "Наименование предмета" },
        { name: "count", align: "left", label: "Количество" },
        { name: "cost", align: "left", label: "Объявленная ценность" }
      ],
      thumbStyle: {
        right: "1px",
        borderRadius: "1px",
        backgroundColor: "#e64c65",
        width: "7px",
        opacity: 0.75
      },

      barStyle: {
        backgroundColor: "#0e8f92",
        width: "9px",
        opacity: 0.2
      },
      inventory: [],
      costType: "cost",
      costP: null
    };
  },
  computed: {
    totalcount() {
      let total = 0;
      for (let elem of this.inventory) {
        total += Number(elem.count);
      }
      return total;
    },
    recepname() {
      return this.openedRecep !== null ? this.openedRecep.fullname : "";
    },
    totalcost() {
      let total = 0;
      for (let elem of this.inventory) {
        total += Number(elem.cost);
      }
      return total;
    },
    mass() {
      return this.$router.currentRoute.name == "mass";
    }
  },
  watch: {
    show(val) {
      this.show = val;
      if (val == true) {
        this.inventory = [];
        this.costP = null;
        this.costType = "cost";
        if (this.openedRecep !== null && this.openedRecep.cost > 0) {
          this.costP = this.openedRecep.cost;
          this.costType = "cost";
        } else if (
          this.openedRecep !== null &&
          this.openedRecep.inventory.length > 0
        ) {
          this.costType = "inventory";
          for (let inv of this.openedRecep.inventory) {
            this.inventory.push(JSON.parse(JSON.stringify(inv)));
          }
        }
        while (this.inventory.length < 20) {
          this.inventory.push({
            name: "",
            count: "",
            cost: ""
          });
        }
      }
    }
  },
  methods: {
    clearRow(item) {
      item.name = "";
      item.cost = "";
      item.count = "";
    },
    generateSingleInventory() {
      if (this.costType == "inventory") {
        let f107 = [];
        for (let elem of this.inventory) {
          if (elem.count > 0 && elem.name != "") {
            f107.push(elem);
          }
        }
        this.$store.commit("SET_INVENTORY_TO_RECEP", {
          recep: this.openedRecep,
          inventory: JSON.parse(JSON.stringify(f107)),
          inventorytotalcount: this.totalcount,
          inventorytotalcost: this.totalcost,
          cost: 0
        });
      } else {
        this.$store.commit("SET_COST_TO_RECEP", {
          recep: this.openedRecep,
          cost: this.costP === null ? 0 : this.costP,
          inventory: [],
          inventorytotalcount: 0,
          inventorytotalcost: 0
        });
      }
    },
    async generateMassInventory(clear = false) {
      if (clear) {
        let f107 = [];
        this.$store.dispatch("CHANGE_AND_REPLACE_RECEPIENTS_IN_LIST", {
          selected: this.recepientsIds,
          newData: {
            inventory: f107,
            inventorytotalcount: null,
            inventorytotalcost: null,
            cost: 0
          },
          progressText: "Удаление сведений о ценности отправлений"
        });
      } else {
        if (this.costType == "inventory") {
          let f107 = [];
          for (let elem of this.inventory) {
            if (elem.count > 0 && elem.name != "") {
              f107.push(elem);
            }
          }
          this.$store.dispatch("CHANGE_AND_REPLACE_RECEPIENTS_IN_LIST", {
            selected: this.recepientsIds,
            newData: {
              inventory: JSON.parse(JSON.stringify(f107)),
              inventorytotalcount: this.totalcount,
              inventorytotalcost: this.totalcost,
              cost: 0
            },
            progressText: "Добавление описей"
          });
        } else if (this.costType == "cost") {
          this.$store.dispatch("CHANGE_AND_REPLACE_RECEPIENTS_IN_LIST", {
            selected: this.recepientsIds,
            newData: {
              cost: this.costP === null ? 0 : this.costP,
              inventory: [],
              inventorytotalcount: 0,
              inventorytotalcost: 0
            },
            progressText: "Добавление сведений о ценности"
          });
        }
      }
    },
    done(payload) {
      if (payload == true) {
        if (this.mass && this.openedRecep === null) {
          this.generateMassInventory();
        } else {
          this.generateSingleInventory();
          this.$store.commit("UPDATE_CANVAS");
          this.$emit("print-inventory");
        }
      }
      this.$emit("update:show", false);
      if (this.mass) {
        this.$emit("update:openedRecep", null);
      }
    },
    clearInventory(payload) {
      this.$store.commit("SET_INVENTORY_TO_RECEP", {
        recep: payload.recep,
        inventory: [],
        inventorytotalcount: null,
        inventorytotalcost: null
      });
      this.$store.commit("SET_COST_TO_RECEP", {
        recep: payload.recep,
        cost: 0
      });
    },
    clearInventoryMass() {
      this.generateMassInventory(true);
    }
  },
  mounted() {
    this.$eventBus.$on("clear-inventory", this.clearInventory);
    this.$eventBus.$on("clear-inventory-mass", this.clearInventoryMass);
  },
  beforeDestroy() {
    this.$eventBus.$off("clear-inventory");
    this.$eventBus.$off("clear-inventory-mass");
  }
};
</script>

<style lang="sass">
.invtable>div
  border-left: 1px solid #dcdcdc
  border-bottom: 1px solid #dcdcdc
  padding: 2px 5px
  color: #5d5d5d
  cursor: pointer
  font-size: 12px
.invtableheader>div
  color: #5d5d5d
  padding: 2px 5px
  border-left: 1px solid #dcdcdc
  border-bottom: 1px solid #dcdcdc
  border-top: 1px solid #dcdcdc
  background: #f3f3f3
  font-weight: 700
  font-size: 12px
  padding-top: 13px
  line-height: normal
.invtable>div:nth-last-child(1)
  border-right: 1px solid #dcdcdc
  padding-right: 10px
.invtableheader>div:nth-last-child(1)
  border-right: 1px solid #dcdcdc
  padding-right: 10px
  padding: 6px 10px 6px 6px
.invtableheader>div:nth-last-child(2)
  padding-top: 5px
.inventorypop, .inventorypop .q-field__control-container
  height: 24px
.winvcostpop
  width: 116px
.inventbtns, .inventheader
  background: rgba(0,0,0,0.15)
.inventtotals
  font-size: 18px
.disable-events
  pointer-events: none
.mrtoleft
  margin-left: -8px
</style>