<template>
  <div :class="'hcontrol svct '+contentclass">
    <div class="btitle">{{title}}</div>
    <div class="ttip">
      <q-icon size="xs" name="help_outline" v-if="title!=''" @click="$emit('tip', name)" />
    </div>
    <div class="switcherouter">
      <q-toggle
        size="sm"
        v-model="state"
        checked-icon="check"
        color="secondary"
        unchecked-icon="clear"
        :true-value="truev"
        :false-value="falsev"
        @input="changeToggle"
      />
    </div>
  </div>
</template>


<script>
export default {
  props: {
    val: { type: Number, default: null },
    truev: { type: Number, default: null },
    falsev: { type: Number, default: null },
    name: { type: String, default: null },
    title: { tybe: String, default: "" },
    contentclass: { tybe: String, default: "" },
    watched: { type: Boolean, default: false },
    settingtype: { tybe: String, default: "format" }
  },
  data() {
    return {
      state: this.val
    };
  },
  watch: {
    val: {
      immediate: true,
      deep: true,
      handler(newValue) {
        //if (this.watched) {
        this.state = newValue;
        //}
      }
    }
  },
  methods: {
    changeToggle(val) {
      if (this.settingtype == "common") {
        this.$store.commit("SETCOMMON", {
          name: this.name,
          value: val
        });
      } else {
        this.$store.commit("SET", {
          name: this.name,
          value: val
        });
      }
    }
  }
};
</script>

<style lang="sass">
.switcherouter
  overflow: hidden
  float: right
  margin-right: 5px
.svct .btitle
  margin-top: 8px
  margin-right: 10px
.svct .ttip
  margin-top: 8px
.noborderbottom
  border-bottom: none
.lh120
  line-height: 120%
</style>