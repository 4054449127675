export default class Group {
  constructor(obj) {
    this._gname = obj.gname;
    this._id = obj.id;
  }

  get id() {
    return this._id;
  }
  set id(val) {
    this._id = val;
  }

  get gname() {
    return this._gname;
  }
  set gname(val) {
    this._gname = val;
  }
}
