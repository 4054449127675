<template>
  <div>
    <div>
      <LeftPanel />
      <q-page class="singlemode">
        <Canvas />
        <div class="full-width q-px-md q-mt-md">
          <q-btn
            class="q-px-sm q-mr-md"
            to="/mass/"
            label="Пакетная печать"
            outline
            color="grey-6"
            no-caps
            dense
          />
          <q-btn
            class="q-px-sm q-mr-md"
            to="/fromexcel/"
            label="Загрузка адресов из EXCEL"
            outline
            color="grey-6"
            no-caps
            dense
          />
        </div>
        <div class="q-px-md">
          <div class="text-h6 q-pt-md">Часто задаваемые вопросы</div>
          <div class="row">
            <div class="col-6">
              <ul class="q-px-none nolst">
                <li>
                  <a
                    target="_blank"
                    class="text-yellow-4"
                    href="/support/envelope/lined-envelope/"
                    >Настройки для линованного конверта</a
                  >
                </li>
                <li>
                  <a
                    class="text-yellow-4"
                    target="_blank"
                    href="/support/tag/logo/"
                    >Как добавить логотип?</a
                  >
                </li>
                <li>
                  <a
                    class="text-yellow-4"
                    target="_blank"
                    href="/support/senders/change-logo-size/"
                    >Как уменьшить логотип?</a
                  >
                </li>
                <li>
                  <a
                    class="text-yellow-4"
                    target="_blank"
                    href="/support/printersettings/"
                    >Как настроить принтер?</a
                  >
                </li>
                <li>
                  <a
                    class="text-yellow-4"
                    target="_blank"
                    href="/support/f107/blank-f-107/"
                    >Как заполнить опись?</a
                  >
                </li>
                <li>
                  <a
                    class="text-yellow-4"
                    target="_blank"
                    href="/support/invoice/blank-f-119/"
                    >Как напечатать уведомление?</a
                  >
                </li>
              </ul>
            </div>
            <div class="col-6">
              <ul class="nolst">
                <li>
                  <a
                    class="text-yellow-4"
                    target="_blank"
                    href="/support/facilities/import-from-excel/"
                    >Как загрузить адреса из EXCEL?</a
                  >
                </li>
                <li>
                  <a
                    class="text-yellow-4"
                    target="_blank"
                    href="/support/envelope/lined-envelope/"
                    >Как изменить логин и пароль?</a
                  >
                </li>
                <li>
                  <a
                    class="text-yellow-4"
                    target="_blank"
                    href="/support/sign/recovery-using-email/"
                    >Пропали адреса. Как восстановить?</a
                  >
                </li>
                <!-- <li>
                  <a
                    class="text-yellow-4"
                    target="_blank"
                    href="/support/envelope/lined-envelope/"
                    >Пакетная печать</a
                  >
								</li>-->
                <li>
                  <a
                    class="text-yellow-4"
                    target="_blank"
                    href="/support/editor/"
                    >Пакетное редактирование получателей</a
                  >
                </li>
              </ul>
            </div>
          </div>
        </div>
        <PrintSettings />
      </q-page>
    </div>
    <div class="singlepagestext q-pa-lg">
      <div class="row">
        <div class="col-12">
          <h1>Программа для печати конвертов ОНЛАЙН</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p>
            Добрый день! Сервис «Почтум» приветствует вас! Для вас уже создан
            личный кабинет. И вы уже в него вошли. Никаких дополнительных
            действий для регистрации не требуется. Продолжайте работу. Все
            введенные адреса сохраняются в вашем личном кабинете. Рекомендуем
            лишь привязать адрес электронной почты к вашему кабинету.
            Электронный адрес может пригодиться для воостановления доступа.
          </p>
          <p>
            Мы рады, что можем помочь вам в выполнении такой рутинной работы,
            как заполнение и печать почтовых конвертов и уведомлений. Сервис
            предназначен для автоматизации вашей работы. После того, как вы
            распечатаете 10 конвертов вы поймете, что у вас стало уходить
            существенно меньше времени на подготовку и отправку писем.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h2>Как тратить минимум времени на работу с почтой?</h2>
        </div>
      </div>
      <div class="row q-col-gutter-lg q-mb-lg">
        <div class="col-6">
          <span class="promoprheader"
            >Купите чистые конверты без разлиновки</span
          >Наиболее удобный формат конвертов - С5. С самоклеющейся полосой, без
          разлиновки. В такой конверт вы положите накладные и счета фактуры,
          сложенные пополам. Мы покупаем их в магазине "Офисная планета".
          Филиалы есть практически в каждом городе. Посмотрите товар здесь.
        </div>
        <div class="col-6">
          <span class="promoprheader">Купите большой запас марок</span>Для
          юридических лиц Почта России предлагает марки по безналичному расчету.
          Вы будете покупать их один-два раза в год. Что это дает? Конверты с
          наклеенными марками вы можете отдать сотруднику Почты России без
          очереди, или положить их в почтовый ящик. Не нужно стоять в очередях.
          Как это сделать смотрите здесь.
        </div>
      </div>
      <div class="row q-col-gutter-lg q-mb-lg">
        <div class="col-6">
          <span class="promoprheader"
            >Используйте пакетную печать конвертов.</span
          >
          Пакетная печать позволяет заполнять и печатать любое количество
          конвертов за один раз. Для этого необходимо выбрать нужных получателей
          и положить пачку конвертов в принтер.
        </div>
        <div class="col-6">
          <span class="promoprheader">Автоматическая загрузка адресов.</span>
          Подготовьте список получателей в формате EXCEL. Перейдите на страницу
          загрузки адресов. Сервис проверит правильность заполнения адресов и
          наименований. Доступна функция автоматического извлечения индекса из
          адреса.
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <h2>Возможности программы</h2>
        </div>
      </div>
      <div class="row q-col-gutter-lg q-mb-lg">
        <div class="col-6">
          <span class="promoprheader">Хранение адресов.</span> Все введенные
          вами адреса сохраняются на сервере. Резервное копирование -
          ежедневное. Доступ к личному кабинету с любого компьютера,
          подключенного к Интернету.
        </div>
        <div class="col-6">
          <span class="promoprheader"
            >Автоматическое заполнение конвертов.</span
          >
          Адрес отправителя, адрес получателя, индексы заполняются
          автоматически. Теперь не нужно обводить индекс ручкой. Весь процесс
          занимает несколько секунд.
        </div>
      </div>
      <div class="row q-col-gutter-lg q-mb-lg">
        <div class="col-6">
          <span class="promoprheader"
            >Поддержка различных форматов конвертов.</span
          >
          Вы можете печатать конверты всех допустимых форматов: C6, DL, C5, C4,
          B4. На обычном принтере можно распечатать C6, DL, C5. Для печати в
          формате C4 и B4 необходим принтер, позволяющий печатать листы размером
          А3. В такие конверты листы А4 умещаются целиком.
        </div>

        <div class="col-6">
          <span class="promoprheader"
            >Автоматическое заполнение уведомлений о вручении.</span
          >
          Для отправки заказных писем неободимо заполнять уведомления о
          вручении. В сервисе «Почтум» этот процесс полностью автоматизирован.
          На листе А4 можно распечатать 4 уведомления о вручении Ф-119. Сначала
          печатается лицевая сторона, затем - оборотная.
        </div>
      </div>
      <div class="row q-col-gutter-lg q-mb-lg">
        <div class="col-6">
          <span class="promoprheader">Настройка логотипа.</span> В левом верхнем
          углу над адресом отправителя можно расположить логотип вашей компании.
          Вы можете загрузить свой логотип или выбрать один из предложенных.
          Более подробно о создании логотипа смотрите наше видео.
        </div>
        <div class="col-6">
          <span class="promoprheader">Пакетная печать.</span> Пакетная печать
          позволяет за один раз сформировать и выдать на печать до 500 конвертов
          или уведомлений. Необходимо лишь выбрать нужных получателей. Вы можете
          объединять получателей в списки и сохранять их. Это могут быть,
          например, "Арендаторы", "Поставщики" и т.п.
        </div>
      </div>
    </div>
    <ProgressDialog />
  </div>
</template>

<script>
import LeftPanel from "src/service/components/single-page/LeftPanel.vue";
import Canvas from "src/service/components/single-page/Canvas.vue";
import PrintSettings from "src/service/components/common/PrintSettings.vue";
import ProgressDialog from "src/service/components/mass-page/ProgressDialog.vue";

export default {
  meta: {
    // sets document title
    title:
      "ПОЧТУМ - печать конвертов и уведомлений онлайн на принтере. Новая программа",
    // meta tags
    meta: {
      description: {
        name: "description",
        content:
          "ПОЧТУМ - сервис для печати адресов на конвертах онлайн. Программа печати конвертов и уведомлений о вручении на принтере. Ф 119. Хранение адресов."
      },
      keywords: {
        name: "keywords",
        content:
          "программа, почтум, печать, конвертов, уведомлений, о вручении, ф 119, онлайн, на принтере"
      },
      ogTitle: {
        name:
          "ПОЧТУМ - печать конвертов и уведомлений онлайн на принтере. Новая программа"
      }
    }
  },
  components: {
    LeftPanel,
    PrintSettings,
    Canvas,
    ProgressDialog
  },
  data() {
    return { psdialog: true, priceDialog: false };
  },
  computed: {
    f() {
      return this.$store.getters.CURFORMAT_OBJECT;
    }
  }
};
</script>

<style lang="sass">
.singlemode
  height: 846px
  float: left
.singlepagestext
  clear: both
  max-width: 1050px
  color: #cacaca
.singlepagestext h1
  font-size: 26px
  line-height: 140%
  margin-top: 32px
  margin-bottom: 16px
.singlepagestext h2
  font-size: 26px
  line-height: 140%
  margin-top: 32px
  margin-bottom: 16px
.singlepagestext
  font-size: 14px
.promoprheader
  display: block
  color: #feffab
  padding: 0 0 6px 0
  font-size: 18px
.nolst
  list-style-type: none
</style>
