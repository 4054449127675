var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(this.$store.getters.ACTIVEUSER)?_c('q-card',{staticClass:"formsignup q-pa-md q-mx-auto q-mt-xl"},[_c('q-card-section',{staticClass:"row q-py-xs"},[_c('div',{staticClass:"fs16 q-mx-auto h6formheader text-center"},[_vm._v("\n        Вы зарегистрированы. Вход выполнен.\n      ")]),_c('q-btn',{staticClass:"signinbtn full-width q-mt-lg",attrs:{"router-link":"","to":"/","label":"Продолжить работу","unelevated":"","color":"accent"}})],1)],1):_vm._e(),(this.$store.getters.ALLOWREGISTRATION)?_c('q-card',{staticClass:"formsignup q-pa-lg q-mx-auto q-mt-xl bg-primary-light"},[_c('q-card-section',{staticClass:"row q-py-xs"},[_c('div',{staticClass:"no-caps text-h6 q-mx-auto h6formheader"},[_vm._v("Регистрация")])]),_c('q-card-section',{staticClass:"q-mt-none"},[_c('q-form',{attrs:{"autocomplete":"off"},on:{"submit":_vm.onSubmit}},[_c('q-input',{staticClass:"q-mb-md",attrs:{"filled":"","color":"grey-2","dark":"","type":"email","placeholder":"Email","dense":"","required":""},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"name":"email"}})]},proxy:true}],null,false,3271653952),model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('q-input',{staticClass:"q-mb-md",attrs:{"filled":"","color":"grey-2","dark":"","placeholder":"Логин","dense":"","required":"","lazy-rules":"","rules":[
            val => (val && val.length > 0) || 'Логин не указан',
            val =>
              !/[а-яА-ЯёЁ]/g.test(val) ||
              'Пожалуйста, не используйте русские буквы'
          ]},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"name":"account_circle"}})]},proxy:true}],null,false,3991180320),model:{value:(_vm.login),callback:function ($$v) {_vm.login=$$v},expression:"login"}}),_c('q-input',{attrs:{"filled":"","color":"grey-3","dark":"","type":_vm.isPwd ? 'password' : 'text',"placeholder":"Пароль","dense":"","required":"","lazy-rules":"","rules":[
            val => (val && val.length > 0) || 'Пароль не указан',
            val =>
              !/[а-яА-ЯёЁ]/g.test(val) ||
              'Пожалуйста, не используйте русские буквы'
          ]},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"name":"lock"}})]},proxy:true},{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",attrs:{"name":_vm.isPwd ? 'visibility_off' : 'visibility'},on:{"click":function($event){_vm.isPwd = !_vm.isPwd}}})]},proxy:true}],null,false,3050512400),model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('q-input',{staticClass:"q-mb-md",attrs:{"filled":"","color":"grey-3","dark":"","type":_vm.isPwd ? 'password' : 'text',"placeholder":"Пароль (еще раз)","dense":"","required":"","lazy-rules":"","rules":[
            val => (val && val.length > 0) || 'Пароль не указан',
            val => (val && val == this.password) || 'Пароли не совпадают',
            val =>
              !/[а-яА-ЯёЁ]/g.test(val) ||
              'Пожалуйста, не используйте русские буквы'
          ]},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"name":"lock"}})]},proxy:true},{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",attrs:{"name":_vm.isPwd ? 'visibility_off' : 'visibility'},on:{"click":function($event){_vm.isPwd = !_vm.isPwd}}})]},proxy:true}],null,false,3050512400),model:{value:(_vm.passwordconfirm),callback:function ($$v) {_vm.passwordconfirm=$$v},expression:"passwordconfirm"}}),_c('q-btn',{staticClass:"signinbtn full-width",attrs:{"loading":_vm.signupinprogress,"type":"submit","unelevated":"","no-caps":"","disable":!(_vm.accepting || _vm.$q.cookies.has('cookieaccepted'))},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('q-spinner-hourglass',{staticClass:"on-left"}),_vm._v("Аккаунт создается..\n          ")]},proxy:true}],null,false,334502726)},[_vm._v("\n          Создать аккаунт\n          ")]),_c('q-separator',{staticClass:"sep1 q-mt-md"}),_c('q-separator',{staticClass:"sep2"}),_c('div',{staticClass:"q-mt-md",staticStyle:{"line-height":"125%"},on:{"click":function($event){_vm.accepting = !_vm.accepting}}},[_c('a',{staticClass:"col text-white",staticStyle:{"text-decoration":"none"},attrs:{"href":"https://777.xn--l1adcnfw.xn--p1ai/api/authcookie/","target":"_blank"}},[_c('q-icon',{staticClass:"col-auto q-mr-sm q-mb-lg float-left",attrs:{"name":!(_vm.accepting || _vm.$q.cookies.has('cookieaccepted'))
                  ? 'check_box_outline_blank'
                  : 'check_box',"size":"md"}}),_vm._v("Согласие с политикой конфиденциальности")],1)])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }