<template>
  <div class="chatwnd">
    <transition name="bounce">
      <div
        v-if="showchatwnd"
        class="fchat bg-accent"
        v-bind:style="{ right: dx, bottom: dy }"
      >
        <q-toolbar
          class="text-primary"
          @mousedown="startDrag"
          @mousemove="doDrag"
        >
          <q-toolbar-title flat class="text-white">
            {{ chatheader }}
          </q-toolbar-title>
          <q-btn
            flat
            round
            color="primary"
            class="text-white"
            icon="clear"
            @click="closeChat"
          />
        </q-toolbar>
        <q-separator class="q-sep color-black-op50" />
        <q-card flat class="bg-accent">
          <q-card-section class="row">
            <q-scroll-area
              ref="chatScrollArea"
              class="fchatwnd"
              :thumb-style="thumbStyle"
            >
              <q-chat-message
                class="q-mx-sm"
                v-for="msg in messages"
                :key="msg.lastid"
                :name="msg.isadmin ? adminname : 'Вы'"
                :text="[msg.mtext]"
                :sent="msg.isadmin ? false : true"
                :stamp="getStamp(msg.mtime)"
              />
              <q-resize-observer @resize="onResizeChat" />
            </q-scroll-area>
            <q-input
              v-model="newmessage"
              placeholder="Пишите..."
              filled
              type="textarea"
              class="chattarea q-mt-md"
              bg-color="white"
            />
            <q-space />
            <q-btn
              flat
              color="white"
              icon="send"
              @click="sendMessage"
              class="q-mt-md"
            />
          </q-card-section>
        </q-card>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    showchatwnd: { type: Boolean, default: false },
    chatheader: { type: String, default: "Чат с техподдержкой" },
    messages: { type: Array, default: new Array() },
    adminname: { type: String, default: "Кирилл" }
  },
  data() {
    return {
      newmessage: "",
      dragging: false,
      x: 0,
      y: 0,
      dx: "16px",
      dy: "16px",
      startx: 0,
      starty: 0,
      areaheight: 0,

      thumbStyle: {
        right: "px",
        borderRadius: "5px",
        backgroundColor: "#902335",
        width: "7px",
        opacity: 0.75
      }
    };
  },
  methods: {
    getStamp(time) {
      let diff = new Date() - new Date(time); // разница в миллисекундах

      if (diff < 1000) {
        // меньше 1 секунды
        return "прямо сейчас";
      }

      let sec = Math.floor(diff / 1000); // преобразовать разницу в секунды

      if (sec < 60) {
        return sec + " сек. назад";
      }

      let min = Math.floor(diff / 60000); // преобразовать разницу в минуты
      if (min < 60) {
        return min + " мин. назад";
      }

      return new Date(time).toLocaleString("ru", {
        hour: "numeric",
        minute: "numeric",
        month: "long",
        day: "numeric"
      });
    },
    closeChat() {
      this.$emit("close-chat");
    },
    startDrag(event) {
      this.dragging = true;
      this.x = event.clientX;
      this.y = event.clientY;
      this.startx = this.dx;
      this.starty = this.dy;
    },
    stopDrag() {
      this.dragging = false;
      //this.x = this.y = 0;
    },
    doDrag(event) {
      if (this.dragging) {
        //this.x = event.clientX;
        //this.y = event.clientY;
        this.dx = parseInt(this.startx) + this.x - event.clientX + "px";
        this.dy = parseInt(this.starty) + this.y - event.clientY + "px";
      }
    },
    sendMessage() {
      this.$emit("send", this.newmessage);
      this.newmessage = "";
    },
    onResizeChat(size) {
      setTimeout(() => {
        if (this.areaheight == 0) {
          this.$refs.chatScrollArea.setScrollPosition(100000);
          this.areaheight = 1;
        } else {
          this.$refs.chatScrollArea.setScrollPosition(size.height, 500);
        }
      }, 1000);
    }
  },
  mounted() {
    window.addEventListener("mouseup", this.stopDrag);
  }
};
</script>

<style lang="sass">
@-webkit-keyframes animatefs
  0%
  transform: rotate(15deg)
  10%
  transform: rotate(-15deg)
  20%
  transform: rotate(15deg)
  30%
  transform: rotate(-15deg)
  40%
  transform: rotate(15deg)
  50%
  transform: rotate(-15deg)
  100%
  transform: rotate(0deg)

@-moz-keyframes animatefs
  0%
  transform: rotate(15deg)
  10%
  transform: rotate(-15deg)
  20%
  transform: rotate(15deg)
  30%
  transform: rotate(-15deg)
  40%
  transform: rotate(15deg)
  50%
  transform: rotate(-15deg)
  100%
  transform: rotate(0deg)

@-o-keyframes animatefs
  0%
  transform: rotate(15deg)
  10%
  transform: rotate(-15deg)
  20%
  transform: rotate(15deg)
  30%
  transform: rotate(-15deg)
  40%
  transform: rotate(15deg)
  50%
  transform: rotate(-15deg)
  100%
  transform: rotate(0deg)

@keyframes animatefs
  0%
    transform: rotate(15deg)
  10%
    transform: rotate(-15deg)
  20%
    transform: rotate(15deg)
  30%
    transform: rotate(-15deg)
  40%
    transform: rotate(15deg)
  50%
    transform: rotate(-15deg)
  100%
    transform: rotate(0deg)
.chataref
  color: #1e1690
  font-weight: 700
.animatednote
  transform-origin: center top
  -webkit-animation: animatefs 1s infinite
  -moz-animation: animatefs 1s infinite
  -o-animation: animatefs 1s infinite
  animation: animatefs 1s infinite
.color-black-op50
  background-color: rgba(0,0,0, 0.5)
.q-message-sent .q-message-text-content
  white-space: pre-line
  line-height: 140%
.fchat
  border-radius: 5px
  z-index: 1000
  position: fixed
  right: 16px
  bottom: 16px
  width: 450px
.q-message-received .q-message-text
  color: #fffb89
.fchaticon
  font-size: 24px
.q-toolbar
  background: rgba(0, 0, 0, 0.20)
  cursor: move
q-sep
  background: rgb(179, 47, 68)
.fchatwnd
  padding: 8px 12px 8px 8px
  background: rgba(0, 0, 0, 0.10)
  height: 320px
  width: 100%
  border-radius: 4px
  border: 1px solid rgba(0, 0, 0, 0.05)
.chattarea
  width: 85%
.chattarea.q-textarea .q-field__native
  padding-top: 4px
  height: 52px
.chattarea.q-textarea .q-field__control
  border-radius: 4px

.bounce-leave-active
  animation: bounce-in .5s reverse
.bounce-enter-active
  animation: bounce-in .5s

@keyframes bounce-in
  0%
    transform: scale(0)
  50%
    transform: scale(1.5)
  100%
    transform: scale(1)
</style>
