<template>
  <div class="overflow-hidden">
    <div class="q-mb-md overflow-hidden spaneleditor">
      <div class="row">
        <div class="col-5 q-pr-md">
          <q-input
            dense
            dark
            flat
            v-model="search"
            no-shadow
            input-class="text-left"
            class="no-shadow searchinp ed float-left"
            bg-color="primary-light-imp"
            @input="OffAnotherParams"
            placeholder="Поиск по названию.."
            debounce="700"
          >
            <template v-slot:prepend>
              <q-icon v-if="search === ''" name="search" color="grey-3" />
              <q-icon
                v-else
                name="clear"
                class="cursor-pointer"
                color="accent"
                @click="clearSearch"
              />
            </template>
          </q-input>
        </div>
      </div>
      <div class="row">
        <div class="col-7">
          <q-btn-dropdown
            class="
              gdropdown
              drded
              bg-primary-light
              group-selector
              ed
              q-pl-sm q-mr-sm q-mt-md
              float-left
            "
            flat
            no-caps
            :label="
              activegroup
                ? activegroup.gname
                : 'Поиск по группе - все получатели'
            "
            v-model="groupListVisibility"
          >
            <div class="bg-dark q-pa-sm">
              <div
                @click="clearSelectedGroup"
                class="
                  grouprowsel
                  bg-dark
                  text-accent
                  cursor-pointer
                  q-px-sm q-pt-sm q-pb-sm
                "
              >
                Все получатели
              </div>
              <div
                class="
                  grouprowsel
                  bg-dark
                  cursor-pointer
                  q-px-sm q-pt-sm q-pb-sm
                "
                v-for="(group, index) in groups"
                :key="index"
                @click="selectGroup(index)"
              >
                {{ group.gname }}
              </div>
            </div>
          </q-btn-dropdown>
        </div>
      </div>
      <div class="row q-mt-md">
        <div class="col-4">
          <q-btn
            unelevated
            dense
            icon="person_add"
            class="bg-primary-light q-mr-sm float-left"
            style="margin-right: 10px"
            @click="openEditor('create')"
          >
            <q-tooltip
              transition-show="flip-right"
              transition-hide="flip-left"
              content-class="bg-accent fs14px"
              >Создать получателя</q-tooltip
            >
          </q-btn>
          <q-icon
            :name="selectall ? 'check_box' : 'check_box_outline_blank'"
            left
            color="yellow"
            class="iselall float-left q-mt-sm q-mr-xs"
            @click="selectall = !selectall"
          />
          <span class="float-left q-mt-sm">Выбрать все</span>
          <span class="float-left q-mt-sm q-ml-sm text-yellow"
            >({{ rset.length }} из {{ recepients.length }} получателей)</span
          >
        </div>
        <div class="col-1 q-pr-md"></div>
        <div class="col-7 q-pr-md">
          <span class="q-mr-md q-mt-sm">Действия с выбранными:</span>
          <q-btn
            style="min-width: 121px"
            dense
            no-caps
            flat
            label="Удалить"
            :disable="rset.length == 0"
            class="bg-primary-light q-mr-md q-px-md"
            @click="massdeletedialog = !massdeletedialog"
          />
          <q-btn
            dense
            no-caps
            flat
            label="Назначить группы"
            :disable="rset.length == 0"
            class="bg-primary-light q-mr-md q-px-md"
            @click="massgroupdialog = !massgroupdialog"
          />
        </div>
      </div>
    </div>
    <div class="edtabheadouter">
      <div class="edtabhead">
        <span class="sedname">Наименование</span>
        <span class="sedzip">Индекс</span>
        <span class="sedaddr">Адрес получателя</span>
        <span class="sedcomment">Комментарий</span>
        <span class="sedcomment2">Пометка</span>
        <span class="sedgids">Группы</span>
      </div>
    </div>
    <Letters :activeletter.sync="activeletter" @change-letter="changeLetter" />

    <div class="trdiv ed bg-primary-light q-pt-sm">
      <RecepientsListForEditor
        @open-editor="openEditor('edit')"
        @select-item="selectUnselect"
        @delete-item="deleteDirect"
        @select-all="selectUnselectAll"
        :activeletter="activeletter"
        :activegroup="activegroup"
        :search="search"
        :rset="rset"
        :selectall="selectall"
      />
    </div>
    <q-dialog persistent position="left" v-model="editor" color="text-grey-7">
      <Editor :editor.sync="editor" :record="contragent" :editmode="editmode" />
    </q-dialog>

    <q-dialog
      v-model="massdeletedialog"
      transition-show="scale"
      transition-hide="scale"
      content-class="pochtumd"
    >
      <q-card>
        <q-card-section>
          <div>Удалить получателей ({{ rset.length }} шт)?</div>
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            unelevated
            no-caps
            dense
            :disable="massdeletedialogprocess"
            color="accent"
            label="Да"
            @click="massDeleteRecepFromDialog"
          />
          <q-btn
            unelevated
            no-caps
            dense
            :disable="massdeletedialogprocess"
            color="accent"
            label="Нет"
            v-close-popup
          />
        </q-card-actions>
        <q-linear-progress
          v-show="massdeletedialogprocess"
          indeterminate
          color="accent"
        />
      </q-card>
    </q-dialog>
    <q-dialog
      v-model="massgroupdialog"
      transition-show="scale"
      content-class="pochtumd"
      transition-hide="scale"
      @hide="newgroups = []"
    >
      <q-card>
        <q-card-section>
          <div>Изменить группу у получателей ({{ rset.length }} шт)?</div>
        </q-card-section>
        <q-card-section>
          <q-toggle
            v-for="(group, index) in groups"
            :key="index"
            color="accent"
            :label="String(group.gname)"
            v-model="newgroups"
            :val="group.id"
            class="w33p"
          />
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            unelevated
            no-caps
            dense
            label="Да"
            color="accent"
            @click="massSetGroupRecepFromDialog"
          />
          <q-btn
            no-caps
            unelevated
            label="Нет"
            dense
            v-close-popup
            color="accent"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import Address from "src/service/classes/address.js";
import Editor from "src/service/components/common/Editor.vue";
import Letters from "src/service/components/common/Letters.vue";
import RecepientsListForEditor from "src/service/components/editor-page/RecepientsListForEditor.vue";
export default {
  components: {
    Editor,
    RecepientsListForEditor,
    Letters
  },
  data() {
    return {
      //editor variables
      editor: false,
      editmode: false,
      contragent: new Address({
        name: "",
        fullname: "",
        zip: "",
        addr: "",
        comment: "",
        comment2: "",
        gids: []
      }),
      //List variables
      activeletter: -1,
      activegroup: null,
      search: "",

      deletedialog: false,
      //groups
      groupListVisibility: false,

      selectall: false,
      rset: [],
      massdeletedialog: false,
      massdeletedialogprocess: false,
      massgroupdialog: false,
      newgroups: []
    };
  },
  computed: {
    groups() {
      return this.$store.getters.GET_GROUPS;
    },
    recepients() {
      return this.$store.getters.GET_RECEPIENTS;
    },
    activereceps() {
      return this.$store.getters.GET_ACTIVERECEPS;
    },
    activeuser() {
      return this.$store.getters.ACTIVEUSER;
    }
  },
  methods: {
    massDeleteRecepFromDialog() {
      this.massdeletedialogprocess = true;
      let payload = {
        action: "massdeleterecepients",
        id: this.rset
      };

      this.$store.dispatch("MASS_DELETE_RECEPIENTS", payload);
      this.selectall = false;
      this.rset = [];
    },
    massSetGroupRecepFromDialog() {
      let payload = {
        action: "massgroupchange",
        id: this.rset,
        gids: this.newgroups
      };
      this.$store.dispatch("MASS_SETGROUPS_RECEPIENTS", payload);
    },
    selectUnselectAll(receps) {
      if (this.selectall) {
        this.rset = new Array();
        for (let recep of receps) {
          this.rset.push(recep.id);
        }
      } else {
        this.rset = new Array();
      }
    },
    selectUnselect(id) {
      let find = this.rset.indexOf(id);
      if (~find) {
        this.rset.splice(find, 1);
      } else {
        this.rset.push(id);
      }
    },
    //search methods
    OffAnotherParams() {
      this.activeletter = -1;
      this.activegroup = null;
    },
    clearSearch() {
      this.search = "";
    },
    //letters methods
    changeLetter() {
      this.clearSearch();
    },

    //group methods
    selectGroup(index) {
      this.search = "";
      this.activegroup = this.groups[index];
      this.groupListVisibility = false;
    },
    clearSelectedGroup() {
      this.activegroup = null;
      this.groupListVisibility = false;
    },

    deleteDirect(id) {
      this.activereceps[0] = id;
      this.contragent.id = id;
      this.$store.dispatch("DELETE_RECEPIENT", {
        action: "deleterecepient",
        rec: this.contragent.Export()
      });
    },
    //Editor block
    openEditor(mode) {
      if (!this.activeuser) {
        this.$store.commit("SET_ERROR", {
          error: true,
          errortext:
            "Для начала <br/>необходимо добавить отправителя или получателя<br/><br/> либо войти в личный кабинет, если вы пользовались сервисом ранее."
        });
        return;
      }
      let record = "";
      if (mode == "create") {
        this.editmode = false;
      } else if (mode == "edit") {
        this.editmode = true;
        record = this.recepients.find(item => item.id == this.activereceps[0]);
      }
      this.editor = true;
      this.contragent.fullname = this.editmode ? record.fullname : "";
      this.contragent.name = this.editmode ? record.name : "";
      this.contragent.zip = this.editmode ? record.zip : "";
      this.contragent.addr = this.editmode ? record.addr : "";
      this.contragent.comment = this.editmode ? record.comment : "";
      this.contragent.comment2 = this.editmode ? record.comment2 : "";
      this.contragent.autoformat = this.editmode ? record.autoformat : 1;
      this.contragent.digitzip = this.editmode ? record.digitzip : 1;
      this.contragent.gids = this.editmode ? record.gids : new Array();
      this.contragent.id = this.editmode ? record.id : undefined;
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === "MASS_SETGROUPS_SUCCESS") {
        this.$store.commit("CHANGE_GROUPS", {
          ids: this.rset,
          gids: this.newgroups
        });
        this.massgroupdialog = false;
        this.newgroups = [];
        this.rset = new Array();
        this.selectall = false;
      } else if (mutation.type === "DELETE_RECEP") {
        this.$store.commit("SET_ACTIVE_RECEP", []);
      } else if (mutation.type === "DELETE_RECEPS_FROM_ARRAY_FINISHED") {
        this.massdeletedialog = false;
        this.massdeletedialogprocess = false;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
</script>
<style lang="sass">
.trdiv.ed
  width: calc(100% - 2em)
  border-radius: 0 0px 0 0
.letter.ed
  width: 2em
.ed .recep
  overflow: hidden
.ed .recep span
  display: block
  float: left
  box-sizing: border-box
  min-height: 1px
.edtabheadouter
  margin-left: 26px
  background: #343d5f
  margin-right: 2px
  border: 1px solid #2c3450
  border-bottom: none
  border-radius: 5px 5px 0 0
.editormode .q-panel > div.receptabpanel
  height: 878px
.edtabhead
  overflow: hidden
  padding-left: 10px
  padding-right: 17px
.edtabhead span
  display: block
  float: left
  box-sizing: border-box
  min-height: 1px
  border-left: 1px solid #2c3450
  border-bottom-width: 0
  border-right-width: 0
  padding-top: 6px!important
  padding-bottom: 6px!important
  color: #f3eec2
.edtabhead span.sedname
  padding-left: 30px
  border-radius: 5px 0 0 0
  border-left: none
.edtabhead span.sedzip
  text-align: center
.edtabhead span.sedgids
  border-radius: 0 5px 0 0
span.sedname
  width: 25%
  overflow: hidden
span.sedzip
  width: 7%
  padding: 0 5px
span.sedaddr
  width: 35%
  padding: 0 5px
  text-overflow: ellipsis
  overflow: hidden
  white-space: nowrap
span.sedcomment
  width: 9%
  padding: 0 5px
  text-overflow: ellipsis
  overflow: hidden
  white-space: nowrap
span.sedcomment2
  width: 9%
  padding: 0 5px
  text-overflow: ellipsis
  overflow: hidden
  white-space: nowrap
span.sedgids
  width: 15%
  padding: 0 5px
span.sedgids span
  width: auto
  display: block
  float: left
  font-size: 10px
  margin: 2px 4px 2px 0
  padding: 1px 3px
  border-radius: 4px
  background: rgba(0, 0, 0, 0.4)

span.sedname .on-left.icheck
  padding-right: 8px
  margin-right: 0
  font-size: 18px
  display: block
  float: left
  margin-top: 1px
  width: 26px
span.sedname .nametext
  width: calc(100% - 60px)
span.sedname.newaddress .nametext
  width: calc(100% - 68px)
.letter.ed
  clear: both
.group-selector.ed
  width: 300px
.searchinp.ed .q-field__control
  height: 36px
.searchinp.ed
  width: 300px
.iselall

  font-size: 18px!important
  cursor: pointer!important
.mlml
  margin-left: 80px
.w33p
  width: 33.333%
.directdelete.on-left
  border: 1px solid transparent
  border-radius: 20px
  padding: 1px
  width: 18px!important
  height: 18px!important
  margin-right: 0
.directdelete:hover
  border: 1px solid #e64c65
  background: #e64c65
  color: #FFF!important
.ed.searchinp.q-field--dense .q-field__control
  height: 36px
  padding-left: 6px
.ed.searchinp.q-field--dense .q-field__marginal
  height: 36px
.spaneleditor .gdropdown .q-btn__wrapper
  padding: 5px 10px 6px 0px !important
.spaneleditor .q-btn .q-icon, .q-btn .q-spinner
  font-size: 1.515em
</style>
