<template>
  <q-dialog v-model="show" persistent content-class="progressdial">
    <div class="progressdiv q-pa-md bg-primary">
      <div class="q-mb-sm">{{ progressHeader }}</div>
      <q-linear-progress
        track-color="dark"
        size="35px"
        rounded
        :value="progressNorm"
        color="secondary"
      >
        <div class="absolute-full flex flex-center">
          <q-badge color="white" text-color="accent" :label="progressLabel" />
        </div>
      </q-linear-progress>
    </div>
  </q-dialog>
</template>
<script>
export default {
  computed: {
    progress() {
      return this.$store.getters.GET_PROGRESS;
    },
    progressLen() {
      return this.$store.getters.GET_PROGRESSLEN;
    },
    show() {
      return this.$store.getters.GET_SHOWPROGRESS;
    },
    progressHeader() {
      return this.$store.getters.GET_PROGRESSHEADER;
    },
    progressLabel() {
      return this.progress + " из " + this.progressLen;
    },
    progressNorm() {
      return this.progress / this.progressLen;
    }
  }
};
</script>
<style lang="sass">
.progressdial
  background: rgba(0,0,0,0.65)

.progressdiv
  width: 300px
.progressdiv .q-badge
  color: #222b4a!important
  font-weight: 700
</style>