<template>
  <div></div>
</template>
<script>
export default {
  props: {
    recepToSenderP: {
      type: Boolean,
      default: false
    },
    recepientsIds: {
      type: Array,
      default: null
    },
    openedRecep: {
      type: Object,
      default: null
    }
  },
  data() {
    return {};
  },
  computed: {
    mass() {
      return this.$router.currentRoute.name == "mass";
    }
  },
  watch: {
    recepToSenderP(val) {
      if (val == true) {
        if (this.mass && this.openedRecep === null) {
          this.generateMassRecepToSender();
        } else {
          this.generateSingleRecepToSender();
          this.$store.commit("UPDATE_CANVAS");
        }
      }
      if (this.mass) {
        this.$emit("update:openedRecep", null);
      }
    }
  },

  methods: {
    generateSingleRecepToSender() {
      this.$store.commit("SET_INVENTORY_TO_RECEP", {
        recep: this.openedRecep,
        receptosender: this.recepToSenderP
      });
    },
    async generateMassRecepToSender(clear = false) {
      this.$store.dispatch("CHANGE_AND_REPLACE_RECEPIENTS_IN_LIST", {
        selected: this.recepientsIds,
        newData: {
          receptosender: clear ? false : true
        },
        progressText: clear
          ? "Возвращаем отправителя и получателя на свои места"
          : "Меняем отправителя и получателя местами"
      });
      if (clear && this.mass) {
        this.$emit("update:recepToSenderP", false);
      }
    },

    clearRecepToSender() {
      this.$store.commit("SET_RECEPTOSENDER_TO_RECEP", {
        recep: this.openedRecep,
        receptosender: false
      });
    },
    clearRecepToSenderMass() {
      this.generateMassRecepToSender(true);
    }
  },
  mounted() {
    this.$eventBus.$on("clear-receptosender", this.clearRecepToSender);
    this.$eventBus.$on("clear-receptosender-mass", this.clearRecepToSenderMass);
  },
  beforeDestroy() {
    this.$eventBus.$off("clear-receptosender");
    this.$eventBus.$off("clear-receptosender-mass");
  }
};
</script>

<style lang="sass">
.invtable>div
  border-left: 1px solid #dcdcdc
  border-bottom: 1px solid #dcdcdc
  padding: 2px 5px
  color: #5d5d5d
  cursor: pointer
  font-size: 12px
.invtableheader>div
  color: #5d5d5d
  padding: 2px 5px
  border-left: 1px solid #dcdcdc
  border-bottom: 1px solid #dcdcdc
  border-top: 1px solid #dcdcdc
  background: #f3f3f3
  font-weight: 700
  font-size: 12px
  padding-top: 13px
  line-height: normal
.invtable>div:nth-last-child(1)
  border-right: 1px solid #dcdcdc
  padding-right: 10px
.invtableheader>div:nth-last-child(1)
  border-right: 1px solid #dcdcdc
  padding-right: 10px
  padding: 6px 10px 6px 6px
.invtableheader>div:nth-last-child(2)
  padding-top: 5px
.inventorypop, .inventorypop .q-field__control-container
  height: 24px
.winvcostpop
  width: 116px
.inventbtns, .inventheader
  background: rgba(0,0,0,0.15)
.inventtotals
  font-size: 18px
</style>