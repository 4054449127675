var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-dialog',{attrs:{"persistent":"","transition-show":"scale","transition-hide":"scale"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('q-card',{staticClass:"mypdial text-white posttypedial"},[_c('q-card-section',{staticClass:"dialhead"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"text-h6"},[_vm._v("Вид почтового отправления")])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"text-h6 q-ml-sm"},[_vm._v("Дополнительные услуги")])])])]),_c('q-card-section',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col q-mb-xs"},[_c('q-btn-toggle',{staticClass:"bg-ptdbg q-mb-sm",attrs:{"toggle-color":"accent","unelevated":"","no-caps":"","options":[
                  { label: 'Письмо', value: 'pisma' },
                  { label: 'Бандероль', value: 'banderoli' },
                  { label: 'Посылка', value: 'posilki' }
                ]},model:{value:(_vm.selector),callback:function ($$v) {_vm.selector=$$v},expression:"selector"}})],1)]),_c('div',{staticClass:"row"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selector == 'pisma'),expression:"selector == 'pisma'"}],staticClass:"col"},[_c('q-option-group',{attrs:{"size":"xs","dark":"","options":_vm.pisma,"color":"accent"},model:{value:(_vm.postType),callback:function ($$v) {_vm.postType=$$v},expression:"postType"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selector == 'banderoli'),expression:"selector == 'banderoli'"}],staticClass:"col"},[_c('q-option-group',{attrs:{"size":"xs","dark":"","options":_vm.banderoli,"color":"accent"},model:{value:(_vm.postType),callback:function ($$v) {_vm.postType=$$v},expression:"postType"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.selector == 'posilki'),expression:"selector == 'posilki'"}],staticClass:"col"},[_c('q-option-group',{attrs:{"size":"xs","dark":"","options":_vm.posilki,"color":"accent"},model:{value:(_vm.postType),callback:function ($$v) {_vm.postType=$$v},expression:"postType"}})],1)])]),_c('div',{staticClass:"col-6"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.invoices.length > 0),expression:"invoices.length > 0"}]},[_vm._l((_vm.invoices),function(qitem,index){return _c('q-toggle',{key:index,ref:'i' + index,refInFor:true,attrs:{"size":"xs","color":"accent","label":qitem.label,"val":qitem.value},on:{"input":function($event){_vm.postServices =
                  _vm.postServices.length > 0
                    ? _vm.postServices.filter(item => {
                        if (qitem.value[0] !== 'i') {
                          return true;
                        } else {
                          if (item[0] == 'i') {
                            if (qitem.value == item) {
                              return true;
                            } else {
                              return false;
                            }
                          } else {
                            return true;
                          }
                        }
                      })
                    : _vm.postServices}},model:{value:(_vm.postServices),callback:function ($$v) {_vm.postServices=$$v},expression:"postServices"}})}),_c('q-input',{directives:[{name:"show",rawName:"v-show",value:(_vm.selector != 'posilki'),expression:"selector != 'posilki'"}],staticClass:"q-mt-sm q-ml-sm",attrs:{"dense":"","bg-color":"white","color":"accent","clearable":"","label":"Дополнительная отметка отправителя","filled":"","disable":_vm.getInvoiceTipFromRecep},model:{value:(_vm.invoiceTip),callback:function ($$v) {_vm.invoiceTip=$$v},expression:"invoiceTip"}}),_c('q-toggle',{attrs:{"size":"xs","color":"accent","label":"Использовать пометку получателя для отметки отправителя"},model:{value:(_vm.getInvoiceTipFromRecep),callback:function ($$v) {_vm.getInvoiceTipFromRecep=$$v},expression:"getInvoiceTipFromRecep"}})],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.postType !== null && _vm.postType[0] == 'p' && _vm.selector == 'posilki'
            ),expression:"\n              postType !== null && postType[0] == 'p' && selector == 'posilki'\n            "}]},[_c('q-select',{staticClass:"q-mt-md",attrs:{"color":"black","bg-color":"white","popup-content-class":"text-black","filled":"","options":_vm.pack,"dense":"","label":"Выберите тип упаковки","emit-value":"","map-options":""},model:{value:(_vm.postPack),callback:function ($$v) {_vm.postPack=$$v},expression:"postPack"}})],1)])])]),_c('q-card-actions',{staticClass:"q-px-md q-pt-md q-pb-md text-white dialbtns",attrs:{"align":"right"}},[_c('q-btn',{attrs:{"unelevated":"","label":"Ок","no-caps":"","color":"accent"},on:{"click":function($event){return _vm.done(true)}}}),_c('q-btn',{staticClass:"q-ml-md",attrs:{"unelevated":"","label":"Отмена","no-caps":"","color":"accent"},on:{"click":function($event){return _vm.done(false)}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }