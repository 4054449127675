import { api } from "src/boot/axios";
let defaultChat = {
  status: "waitadmin",
  messages: [
    {
      date: { seconds: new Date().getTime() / 1000 },
      admin: true,
      text: "Добрый день! Чем могу вам помочь?"
    }
  ]
};
export default {
  state: {
    chat: {
      admin: {
        adminonline: false,
        adminname: ""
      },
      state: {
        ncm: 0,
        nam: 0,
        opened: false
      },
      messages: [],
      lastid: null,
      chatexist: false
    }
  },

  getters: {
    CHAT_LASTID: state => {
      return state.chat.lastid;
    },
    CHAT_OPENED: state => {
      return state.chat.state.opened;
    },
    CHAT_NCM: state => {
      return state.chat.state.ncm;
    },
    CHAT_NAM: state => {
      return state.chat.state.nam;
    },
    CHAT_ADMIN_ONLINE: state => {
      return state.chat.admin.adminonline;
    },
    CHAT_ADMIN_NAME: state => {
      return state.chat.admin.adminname;
    },
    CHAT_MESSAGES: state => {
      return state.chat.messages;
    },
    CHAT_CHATID: state => {
      return state.chat.state.id;
    },
    CHAT_CHAT_EXIST: state => {
      return state.chat.chatexist;
    }
  },
  mutations: {
    SET_CHAT_STATE(state, payload) {
      if (payload == null) {
        state.chat.state.ncm = 0;
        state.chat.state.nam = 0;
        state.chat.state.opened = false;
        state.chat.messages = [];
        state.chat.lastid = null;
        state.chat.chatexist = false;
      } else {
        state.chat.state = payload;
        state.chat.chatexist = true;
      }
    },
    ADD_NEW_CHAT_MESSAGES(state, payload) {
      for (let i = 0, count = payload.chatmessages.length; i < count; i++) {
        let l = state.chat.messages.length;
        if (l == 0) {
          state.chat.messages.push(payload.chatmessages[i]);
        } else if (
          state.chat.messages[l - 1].lastid < payload.chatmessages[i].lastid
        ) {
          state.chat.messages.push(payload.chatmessages[i]);
        }
      }
      state.chat.lastid = payload.lastid;
      state.chat.state.opened = true;
    },
    SET_CHAT_ADMIN(state, payload) {
      state.chat.admin = payload.admin;
    }
  },
  actions: {
    GET_CHAT_STATE(context, payload) {
      api
        .post("/api/chat/?ts=" + Math.floor(Math.random() * 10000), payload)
        .then(response => {
          if (response.data.error) {
            /* context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            }); */
          } else {
            if (typeof response.data.body.chatstate !== "undefined") {
              context.commit("SET_CHAT_STATE", response.data.body.chatstate);
            }
          }
        })
        .catch(error => {
          if (error.response && error.response.status != "500") {
            /* context.dispatch("LOG_ERROR", {
              error: error,
              desc: "GET_CHAT_STATE action"
            }); */
          }
        });
    },
    GET_CHAT_MESSAGES(context, payload) {
      api
        .post("/api/chat/?ts=" + Math.floor(Math.random() * 10000), payload)
        .then(response => {
          if (response.data.error) {
            /* context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            }); */
          } else {
            context.commit("ADD_NEW_CHAT_MESSAGES", response.data.body);
          }
        })
        .catch(error => {
          if (error.response && error.response.status != "500") {
            /* context.dispatch("LOG_ERROR", {
              error: error,
              desc: "GET_CHAT_MESSAGES action"
            }); */
          }
        });
    },
    GET_CHAT_ADMIN(context, payload) {
      api
        .post("/api/chat/?ts=" + Math.floor(Math.random() * 10000), payload)
        .then(response => {
          if (response.data.error) {
            /* context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            }); */
          } else {
            context.commit("SET_CHAT_ADMIN", response.data.body);
          }
        })
        .catch(error => {
          if (error.response && error.response.status != "500") {
            /* context.commit("SET_ERROR", {
              error: true,
              errortext:
                "Ошибка при получении списка администраторов чата. Попробуйте выполнить действие позже. Информация передана разработчикам"
            });
            context.dispatch("LOG_ERROR", {
              error: error,
              desc: "GET_CHAT_ADMIN action"
            }); */
          }
        });
    },
    SEND_CHAT_MESSAGE(context, payload) {
      api
        .post("/api/chat/?ts=" + Math.floor(Math.random() * 10000), payload)
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            context.commit("ADD_NEW_CHAT_MESSAGES", response.data.body);
          }
        })
        .catch(error => {
          if (error.response && error.response.status != "500") {
            context.commit("SET_ERROR", {
              error: true,
              errortext:
                "Ошибка при работе с чатом. Попробуйте выполнить действие позже. Информация передана разработчикам"
            });
            context.dispatch("LOG_ERROR", {
              error: error,
              desc: "SEND_CHAT_MESSAGE action"
            });
          }
        });
    }
  }
};
