var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"text-h4 q-mb-md"},[_vm._v("Личные данные")]),_c('div',{staticStyle:{"max-width":"300px"}},[_c('q-form',{attrs:{"autocomplete":"off"},on:{"submit":function($event){return _vm.saveSettings()}}},[_c('q-input',{staticClass:"q-mb-md",attrs:{"label":"Email","dark":"","color":"yellow-5","type":"email","required":"","dense":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('q-input',{staticClass:"q-mb-md q-pb-none",attrs:{"dense":"","label":"Логин","dark":"","color":"yellow-5","lazy-rules":"","rules":[
          (val) => (val && val.length > 0) || 'Логин не указан',
          (val) =>
            !/[а-яА-ЯёЁ]/g.test(val) ||
            'Пожалуйста, не используйте русские буквы',
        ]},model:{value:(_vm.login),callback:function ($$v) {_vm.login=$$v},expression:"login"}}),_c('q-input',{staticClass:"q-mb-lg",attrs:{"label":"Пароль","dark":"","color":"yellow-5","type":_vm.isPwd ? 'password' : 'text',"lazy-rules":"","dense":"","rules":[
          (val) =>
            !/[а-яА-ЯёЁ]/g.test(val) ||
            'Пожалуйста, не используйте русские буквы',
        ]},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",attrs:{"name":_vm.isPwd ? 'visibility_off' : 'visibility'},on:{"click":function($event){_vm.isPwd = !_vm.isPwd}}})]},proxy:true}]),model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('q-btn',{staticClass:"full-width q-mt-lg",attrs:{"loading":_vm.accountchangeprogress,"color":"secondary","unelevated":"","no-caps":"","type":"submit"},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('q-spinner-hourglass',{staticClass:"on-left"}),_vm._v("Изменение данных..\n        ")]},proxy:true}])},[_vm._v("\n        Изменить\n        ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.success),expression:"success"}],staticClass:"q-mt-lg q-pa-md rounded-borders tip-success"},[_vm._v("\n        Изменения сохранены\n      ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }