<template>
	<div>
		<q-card class="receditor" color="text-grey-7">
			<q-card-section
				class="row items-center q-px-lg q-py-md bg-secondary"
				color="text-grey-7"
			>
				<div class="text-h6">{{ header }}</div>
				<q-space />
				<q-btn icon="close" flat round dense v-close-popup />
			</q-card-section>
			<q-card-section class="row items-center q-pa-lg" color="text-grey-7">
				<q-form class="full-width" @submit="onSubmit">
					<q-select
						v-if="!issender"
						v-model="record.gids"
						:options="groups"
						label="Группа получателей"
						option-value="id"
						option-label="gname"
						multiple
						emit-value
						map-options
						options-dense
						class="q-mb-md grsel"
						use-chips
					>
						<template v-slot:option="scope">
							<q-item
								class="text-grey-8"
								v-bind="scope.itemProps"
								v-on="scope.itemEvents"
							>
								<q-item-section>
									<q-item-label v-html="scope.opt.gname"></q-item-label>
								</q-item-section>
								<q-item-section side>
									<q-toggle
										color="secondary"
										v-model="record.gids"
										:val="scope.opt.id"
									/>
								</q-item-section>
							</q-item>
						</template>
					</q-select>
					<q-input
						dense
						v-model="record.fullname"
						class="q-mb-sm"
						label="Наименование для печати"
						:required="issender ? false : true"
						color="text-grey-7"
						@blur="setInnerName"
					/>
					<q-input
						color="text-grey-7"
						dense
						v-model="record.name"
						class="q-mb-sm"
						label="Наименование"
						required
					/>
					<div class="overflow-hidden q-mb-sm">
						<q-input
							digitzipinput
							dense
							v-model="record.zip"
							class="digitzipinput"
							label="Индекс"
							:required="issender ? false : true"
							v-bind:mask="record.digitzip ? '######' : ''"
						/>
						<q-toggle
							v-model="record.digitzip"
							keep-color
							checked-icon="check"
							color="accent digitziptoggle"
							class="text-grey-8 q-mt-xs float-right mlminus float-left"
							label="Только цифры"
							unchecked-icon="clear"
							:true-value="1"
							:false-value="0"
						>
							<q-tooltip
								transition-show="flip-right"
								transition-hide="flip-left"
								content-class="bg-accent fs14px"
								>Выключите, если в индексе содержатся буквы</q-tooltip
							>
						</q-toggle>
					</div>

					<q-input
						dense
						v-model="record.addr"
						class="q-mb-sm"
						label="Адрес"
						:required="issender ? false : true"
					/>
					<q-input
						dense
						v-model="record.comment"
						class="q-mb-sm"
						label="Комментарий"
					/>
					<q-input
						v-if="!issender"
						dense
						v-model="record.comment2"
						class="q-mb-sm"
						label="Дополнительная пометка"
					/>
					<q-toggle
						v-model="record.autoformat"
						keep-color
						checked-icon="check"
						color="accent"
						class="text-grey-8 q-mt-md mlminus float-left"
						label="Автоформат"
						unchecked-icon="clear"
						:true-value="1"
						:false-value="0"
					>
						<q-tooltip
							transition-show="flip-right"
							transition-hide="flip-left"
							content-class="bg-accent fs14px"
							content-style="width: 330px"
						>
							Автоматическое удаление пробелов,
							<br />замена кавычек елочек на простые кавычки, <br />извлечение
							индекса из адреса
						</q-tooltip>
					</q-toggle>
					<q-space />
					<q-btn
						flat
						type="submit"
						class="bg-secondary q-mt-md float-right"
						no-caps
						:label="btneditorlabel"
						:disable="editorprocess"
					/>
				</q-form>
			</q-card-section>
			<div class="eprogressouter">
				<q-linear-progress
					indeterminate
					v-show="editorprocess"
					size="sm"
					color="secondary"
				/>
			</div>
		</q-card>
		<q-dialog
			v-model="autosignupdialog"
			persistent
			transition-show="scale"
			class="transall mwnone"
			transition-hide="scale"
		>
			<AutoSignUp
				persistent
				:dosignup="dosignup"
				@autosignup-success="onSubmitAfterSignUp"
				@autosignup-error="onSubmitAfterSignUpError"
			/>
		</q-dialog>
	</div>
</template>

<script>
import Address from "src/service/classes/address.js";
import Sender from "src/service/classes/sender.js";
import AutoSignUp from "src/service/components/common/AutoSignUp.vue";
export default {
	components: {
		AutoSignUp
	},
	props: {
		issender: { type: Boolean, default: false },
		record: {
			type: Object,
			default: () => (this.issender ? new Sender({}) : new Address({}))
		},
		editor: { type: Boolean, default: false },
		editmode: { type: Boolean, default: false }
	},
	data() {
		return {
			editorprocess: false,
			dosignup: false,
			autosignupdialog: false
		};
	},
	computed: {
		groups() {
			return this.$store.getters.GET_GROUPS;
		},
		btneditorlabel() {
			return this.editmode ? "Изменить" : "Создать";
		},
		header() {
			return this.editmode
				? this.issender
					? "Редактирование отправителя"
					: "Редактирование получателя"
				: this.issender
				? "Создание отправителя"
				: "Создание получателя";
		}
	},
	methods: {
		onSubmit() {
			this.record.datadigit = this.record.ExtractDigit(this.record.name);
			if (this.record.autoformat) {
				this.record.comment = this.record.ClearAddress(this.record.comment);
				this.record.addr = this.record.ClearAddress(this.record.addr);
				this.record.ExtractZipFromAddress();
			} else {
				this.record.comment = this.record.ClearRN(this.record.comment);
				this.record.addr = this.record.ClearRN(this.record.addr);
			}

			if (!this.editmode) {
				if (this.$store.getters.NEWUSER || this.$store.getters.UNDEFINEDUSER) {
					this.autosignupdialog = true;
					this.editorprocess = true;
					this.$nextTick(() => {
						this.dosignup = true;
					});
				} else if (this.$store.getters.ACTIVEUSER) {
					if (this.issender) {
						this.editorprocess = true;
						this.$store.dispatch("CREATE_SENDER", {
							action: "createsender",
							rec: this.record.Export()
						});
					} else {
						this.editorprocess = true;
						//this.$store.commit("SET_ACTIVE_RECEP", []);
						this.$store.dispatch("CREATE_RECEPIENT", {
							action: "createrecepient",
							rec: this.record.Export()
						});
					}
				}
			} else {
				if (this.issender) {
					this.editorprocess = true;
					this.$store.dispatch("UPDATE_SENDER", {
						action: "updatesender",
						rec: this.record.Export()
					});
				} else {
					this.editorprocess = true;
					this.$store.dispatch("UPDATE_RECEPIENT", {
						action: "updaterecepient",
						rec: this.record.Export()
					});
				}
			}
			setTimeout(() => {
				this.editorprocess = false;
			}, 5000);
		},
		onSubmitAfterSignUp() {
			if (!this.editmode) {
				if (this.issender) {
					this.editorprocess = true;
					this.$store.dispatch("CREATE_SENDER", {
						action: "createsender",
						rec: this.record.Export()
					});
				} else {
					this.editorprocess = true;
					//this.$store.commit("SET_ACTIVE_RECEP", []);
					this.$store.dispatch("CREATE_RECEPIENT", {
						action: "createrecepient",
						rec: this.record.Export()
					});
				}
				setTimeout(() => {
					this.editorprocess = false;
				}, 5000);
			}
		},
		onSubmitAfterSignUpError() {
			this.closeEditor();
		},

		setInnerName() {
			if (this.record.autoformat) {
				this.record.fullname = this.record.ClearFullName(this.record.fullname);
				this.record.name = this.record.ExtractName(this.record.fullname);
			} else {
				this.record.name = this.record.fullname;
			}
		},
		controlZip() {
			if (this.record.digitzip) {
				this.record.zip = this.record.FormatDigitZip(this.record.zip);
			}
		},
		closeEditor() {
			this.editorprocess = false;
			this.$emit("update:editor", false);
		}
	},
	created() {
		this.unsubscribe = this.$store.subscribe(mutation => {
			if (mutation.type === "ADD_NEW_RECEP" && this.autosignupdialog == false) {
				setTimeout(() => {
					this.closeEditor();
				}, 800);
			} else if (mutation.type === "UPDATE_RECEP") {
				setTimeout(() => {
					this.closeEditor();
				}, 800);
			} else if (
				mutation.type === "ADD_NEW_SENDER" &&
				this.autosignupdialog == false
			) {
				setTimeout(() => {
					this.closeEditor();
				}, 800);
			} else if (mutation.type === "UPDATE_SENDER") {
				setTimeout(() => {
					this.closeEditor();
				}, 800);
			}
		});
	},
	beforeDestroy() {
		this.unsubscribe();
	}
};
</script>

<style lang="sass">
.receditor
  width: 400px
  max-height: none!important
  background: #f8ff90!important
.receditor input
  font-weight: 700
.editorname
  width: 100%
  font-size: 18px
.receditor input
  color: #333
.receditor .q-field--dense .q-field__label, .receditor .q-field--standard .q-field__control:after
  color: #525252
.receditor .grsel .q-chip
  margin-left: 0
  width: auto
  float: left
.receditor .grsel .q-field__label
  font-size: 15px
  color: #525252
</style>
