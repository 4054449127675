import { rubles } from "rubles";
export default class Address {
  constructor(obj = {}) {
    this._name = obj.name != undefined ? obj.name : "";
    this._fullname = obj.fullname != undefined ? obj.fullname : "";
    this._addr = obj.addr != undefined ? obj.addr : "";
    this._zip = obj.zip != undefined ? obj.zip : "";
    this._comment = obj.comment != undefined ? obj.comment : "";
    this.datadigit = obj.datadigit != undefined ? obj.datadigit : "";
    this._gids = obj.gids != undefined ? obj.gids : [];
    this.autoformat =
      obj.autoformat != undefined ? parseInt(obj.autoformat) : 1;
    this.digitzip = obj.digitzip != undefined ? parseInt(obj.digitzip) : 1;
    this._comment2 = obj.comment2 != undefined ? obj.comment2 : "";
    this.id = obj.id != undefined ? parseInt(obj.id) : null;
    //this.invoicetype = obj.invoicetype != undefined ? obj.invoicetype : null; //Вид уведомления простое или заказное
    this.posttype = obj.posttype != undefined ? obj.posttype : null; //Вид отправления простое или заказное
    this.postpack = obj.postpack != undefined ? obj.postpack : null;
    this.postservices = obj.postservices != undefined ? obj.postservices : [];
    //this.postcategory = obj.postcategory != undefined ? obj.postcategory : null; //Категория отправления простое или заказное
    this.invoicetip = obj.invoicetip != undefined ? obj.invoicetip : null;
    this.barcode = obj.barcode != undefined ? obj.barcode : null; //Категория отправления простое или заказное
    this.printbar = obj.printbar != undefined ? obj.printbar : false; //Печатать шпи
    this.receptosender =
      obj.receptosender != undefined ? obj.receptosender : false; //Поменять получателя и отправителя местами
    this.inventory =
      obj.inventory != undefined
        ? JSON.parse(JSON.stringify(obj.inventory))
        : []; //{name: "", count: "", cost:""}
    this.inventorytotalcount = null;
    this.inventorytotalcost = null;
    if (obj.inventory != undefined && obj.inventory.length > 0) {
      this.inventorytotalcount = 0;
      this.inventorytotalcost = 0;
      for (let elem of this.inventory) {
        this.inventorytotalcount += Number(elem.count);
        this.inventorytotalcost += Number(elem.cost);
      }
    }
    this._tip = obj.tip != undefined ? obj.tip : "";

    this.weight = obj.weight != undefined ? obj.weight : 0;
    this.cost = obj.cost != undefined ? obj.cost : 0;
    if (obj.price !== undefined) {
      this.price = {
        g: obj.price.g !== undefined ? obj.price.g : 0, //ground - стоимость пересылки
        gnds: obj.price.gnds !== undefined ? obj.price.gnds : 0,
        s: obj.price.s !== undefined ? obj.price.s : 0, //service - стоимость допов
        snds: obj.price.snds !== undefined ? obj.price.snds : 0,
        c: obj.price.c !== undefined ? obj.price.c : 0, //cover - наложенный платеж
        cnds: obj.price.cnds !== undefined ? obj.price.cnds : 0,
        gc: obj.price.gc !== undefined ? obj.price.gc : 0, //cover - наложенный платеж
        gcnds: obj.price.gcnds !== undefined ? obj.price.gcnds : 0
      };
    } else {
      this.price = {
        g: 0, //ground - стоимость пересылки
        gnds: 0,
        s: 0, //service - стоимость допов
        snds: 0,
        c: 0, //cover - наложенный платеж
        cnds: 0,
        gc: 0, //cover - наложенный платеж
        gcnds: 0
      };
    }

    this.note = obj.note != undefined ? obj.note : "";
    this.cod = obj.cod != undefined ? obj.cod : 0;

    if (obj.hasOwnProperty("response")) {
      this.response = obj.response;
    }
    //this.selected = obj.selected != undefined ? obj.selected : false;
  }

  isCosted() {
    if (
      this.posttype == "e3" ||
      this.posttype == "e4" ||
      this.posttype == "e6" ||
      this.posttype == "e7" ||
      this.posttype == "b3" ||
      this.posttype == "b4" ||
      this.posttype == "b6" ||
      this.posttype == "b7" ||
      this.posttype == "p2" ||
      this.posttype == "p3" ||
      this.posttype == "p5" ||
      this.posttype == "p6" ||
      this.posttype == "p8" ||
      this.posttype == "p9"
    ) {
      return true;
    } else {
      return false;
    }
  }

  invoiceCorrectText() {
    let text = "";
    let errors = false;

    if (this.posttype == null) {
      text += "Вид почтового отправления<br/>";
      errors = true;
    }
    if (
      !~this.postservices.indexOf("i1") &&
      !~this.postservices.indexOf("i2")
    ) {
      text += "Тип уведомления (простое или заказное).<br/>";
      errors = true;
    }
    if (errors) {
      text =
        'Для получателя "' +
        this.name +
        '" необходимо заполнить:<br/><br/>' +
        text +
        ' <br/><br/>Для этого нажмите кнопку "Заполнить"';
    }
    return text;
  }

  GetSerializedObject() {
    let obj = [];
    obj.push(this.name);
    obj.push(this.fullname);
    obj.push(this.addr);
    obj.push(this.zip);
    obj.push(this.comment);
    obj.push(this.id);
    obj.push(this.posttype);
    obj.push(this.postpack);
    obj.push(this.postservices);
    obj.push(this.invoicetip);
    obj.push(this.barcode);
    obj.push(this.inventory);
    obj.push(this.weight || 0);
    obj.push(this.cost || 0);
    obj.push(this.price.c || 0);
    obj.push(this.price.cnds || 0);
    obj.push(this.price.g || 0);
    obj.push(this.price.gnds || 0);
    obj.push(this.price.gc || 0);
    obj.push(this.price.gcnds || 0);
    obj.push(this.price.s || 0);
    obj.push(this.price.snds || 0);
    obj.push(this.cod || 0);
    obj.push(this.note);

    let resp = null;

    if (this.hasOwnProperty("response")) {
      resp = [];
      resp[0] = this.response.hasOwnProperty("version")
        ? this.response.version
        : null;
      resp[1] = this.response.hasOwnProperty("isservice")
        ? this.response.isservice
        : null;
      resp[2] = this.response.hasOwnProperty("ground")
        ? this.response.ground
        : null;
      resp[3] = this.response.hasOwnProperty("cover")
        ? this.response.cover
        : null;
      resp[4] = this.response.hasOwnProperty("sumoc")
        ? this.response.sumoc
        : null;
      resp[5] = [];
      if (this.response.hasOwnProperty("items")) {
        for (let i = 0, l = this.response.items.length; i < l; i++) {
          let it = {};
          it.id = this.response.items[i].id;
          if (this.response.items[i].hasOwnProperty("tariff")) {
            it.tariff = this.response.items[i].tariff;
          }
          if (this.response.items[i].hasOwnProperty("serviceon")) {
            it.serviceon = this.response.items[i].serviceon;
          }
          resp[5].push(it);
        }
      }
    }

    obj.push(resp);

    //push version
    obj.push(1);
    return obj;
  }
  UnserializeObject(obj) {
    let nob = {};
    let version = obj.pop();
    if (version == 1) {
      let resp = obj.pop();
      if (resp !== null) {
        nob.response = {};
        nob.response.items = resp.pop();
        nob.response.sumoc = resp.pop();
        nob.response.cover = resp.pop();
        if (nob.response.cover === null) {
          nob.response.cover = {};
          nob.response.cover.val = null;
          nob.response.cover.valnds = null;
          nob.response.cover.nds = null;
        }
        nob.response.ground = resp.pop();
        nob.response.isservice = resp.pop();
        nob.response.version = resp.pop();
      }
      nob.note = obj.pop();
      nob.cod = obj.pop();
      nob.price = {};
      nob.price.snds = obj.pop();
      nob.price.s = obj.pop();
      nob.price.gcnsd = obj.pop();
      nob.price.gc = obj.pop();
      nob.price.gnds = obj.pop();
      nob.price.g = obj.pop();
      nob.price.cnds = obj.pop();
      nob.price.c = obj.pop();
      nob.cost = obj.pop();
      nob.weight = obj.pop();
      nob.inventory = obj.pop();
      nob.barcode = obj.pop();
      nob.invoicetip = obj.pop();
      nob.postservices = obj.pop();
      nob.postpack = obj.pop();

      nob.posttype = obj.pop();
      nob.id = obj.pop();
      nob.comment = obj.pop();
      nob.zip = obj.pop();
      nob.addr = obj.pop();
      nob.fullname = obj.pop();
      nob.name = obj.pop();
    }
    return nob;
  }

  NameWithComment() {
    return this.comment != ""
      ? this.fullname + ", " + this.comment
      : this.fullname;
  }
  FullAddress() {
    return this.zip != "" ? this.zip + ", " + this.addr : this.addr;
  }

  ClearPayload() {
    if (this._tip !== null) {
      this._tip = "";
    }
    /* if (this.invoicetype !== null) {
      this.invoicetype = null;
    } */
    if (this.posttype !== null) {
      this.posttype = null;
    }
    if (this.postpack !== null) {
      this.postpack = null;
    }
    if (this.postservices !== null) {
      this.postservices = [];
    }
    /* if (this.postcategory !== null) {
      this.postcategory = null;
    } */
    if (this.barcode !== null) {
      this.barcode = null;
    }
    if (this.printbar !== null) {
      this.printbar = null;
    }
    this.receptosender = false;
    if (this.inventory.length > 0) {
      this.inventory = [];
    }
    if (this.inventorytotalcount !== null) {
      this.inventorytotalcount = null;
    }
    if (this.inventorytotalcost !== null) {
      this.inventorytotalcost = null;
    }
    if (this.selected != undefined) {
      this.selected = false;
    }

    if (this.weight != 0) {
      this.weight = 0;
    }
    if (this.cost != undefined) {
      this.cost = 0;
    }
    this.price = {
      g: 0, //ground - стоимость пересылки
      gnds: 0,
      s: 0, //service - стоимость допов
      snds: 0,
      c: 0, //cover - наложенный платеж
      cnds: 0
    };
    if (this.note != undefined) {
      this.note = "";
    }
    if (this.cod != undefined) {
      this.cod = "";
    }
  }

  get zip() {
    return this._zip;
  }
  set zip(val) {
    this._zip = val !== null ? String(val) : "";
  }
  get tip() {
    return this._tip;
  }
  set tip(val) {
    this._tip = val !== null ? val : "";
  }

  get comment2() {
    return this._comment2;
  }
  set comment2(val) {
    val = val + "";
    this._comment2 = val;
  }

  get name() {
    return this._name;
  }
  set name(val) {
    val = val + "";
    this._name = val.replace(/(\r\n|\n|\r)/gm, " ").trim();
  }

  get fullname() {
    return this._fullname;
  }
  set fullname(val) {
    val = val + "";
    this._fullname = val.replace(/(\r\n|\n|\r)/gm, " ").trim();
  }

  get addr() {
    return this._addr;
  }
  set addr(val) {
    val = val + "";
    this._addr = val.replace(/(\r\n|\n|\r)/gm, " ").trim();
  }

  get comment() {
    return this._comment;
  }
  set comment(val) {
    val = val + "";
    this._comment = val.replace(/(\r\n|\n|\r)/gm, " ").trim();
  }

  get gids() {
    return this._gids;
  }
  set gids(val) {
    this._gids = val.slice();
  }

  clearIndividualSettings() {
    /*  this.invoicetype = null;
    this.postcategory = null; */
    this.posttype = null;
    this.postpack = null;
    this.postservices = [];
    this.barcode = null;
    this.inventory = [];
    this.receptosender = false;
    this.printbar = false;
    this.tip = "";
    this.receptosender = false;
    this.weight = 0;
    this.cost = 0;
    this.price = {
      g: 0, //ground - стоимость пересылки
      gnds: 0,
      s: 0, //service - стоимость допов
      snds: 0,
      c: 0, //cover - наложенный платеж
      cnds: 0
    };
    this.note = "";
    this.cod = 0;
  }

  ClearFullName(str) {
    if (!str) return "";
    let startres = str;
    let res = str;
    res = res + "";
    //Удаление всех типов разрыва подстрок
    res = res.replace(/(\r\n|\n|\r)/gm, " ");
    //Удаление enter, tab
    res = res.replace(/\t/gm, " ");
    //Удаление неразрывного пробела
    res = res.replace(/\xA0/g, "\x20");
    res = res.replace(/\xC2/g, "\x20");
    //Удаление двойных пробелов, тройных пробелов
    res = res.replace(/\x20\x20\x20/g, "\x20");
    res = res.replace(/\x20\x20/g, "\x20");
    //Замена ёлочек на кавычки
    res = res.replace(/(\xab|\xbb)/g, "\x22");
    //Замена неправильной расстановки пробелов рядом с кавычками
    res = res.replace(/(\x20\x22\x20|\x22\x20|\x20\x22)/g, "\x22");
    //Удалить пробелы в начале и конце
    res = res.trim();
    let qpos = res.indexOf('"');
    if (~qpos && qpos < res.length) {
      let namearr = res.split('"');
      let quotes = namearr.length - 1;
      if (quotes == 1) {
        res = namearr[0] + ' "' + namearr[1];
      }
      if (quotes == 2) {
        res =
          namearr[0] +
          ' "' +
          namearr[1] +
          '"' +
          (namearr[2] != "" ? " " + namearr[2] : "");
      } else if (quotes == 3) {
        res =
          namearr[0] +
          ' "' +
          namearr[1] +
          ' "' +
          namearr[2] +
          '"' +
          (namearr[3] != "" ? " " + namearr[3] : "");
      } else {
        res = startres;
      }
    }
    return res;
  }
  ClearAddress(str) {
    if (!str) return "";
    let res = str;
    res = res + "";
    //Удаление всех типов разрыва подстрок
    res = res.replace(/(\r\n|\n|\r)/gm, " ");
    //Удаление enter, tab
    res = res.replace(/\t/gm, " ");
    //Добавление пробелов после запятой и точки
    res = res.replace(/\x2c/g, "\x2c\x20");
    res = res.replace(/\x2e/g, "\x2e\x20");
    res = res.replace(/\x20\x2c/g, "\x2c");
    res = res.replace(/\x20\x2e/g, "\x2e");
    //Удаление двойных пробелов, тройных пробелов
    res = res.replace(/\x20\x20\x20/g, "\x20");
    res = res.replace(/\x20\x20/g, "\x20");

    //Замена ёлочек на кавычки
    res = res.replace(/(\xab|\xbb)/g, "\x20");
    //Удалить пробелы в начале и конце
    res = res.trim();
    return res;
  }

  ClearRN(str) {
    if (!str) return "";
    let res = str;
    res = res + "";
    //Удаление всех типов разрыва подстрок
    res = res.replace(/(\r\n|\n|\r)/gm, " ");
    res = res.replace(/\t/gm, " ");
    return res;
  }

  ExtractDigit(str) {
    if (!str) return;
    let digit = 0;
    let alphabet = "АБВГДЕЖЗИКЛМНОПРСТУФХЦЧШЩЭЮЯ";
    let dpos = alphabet.indexOf(str[0].toUpperCase());

    if (~dpos) {
      digit = dpos + 1;
    } else {
      digit = 0;
    }
    return digit;
  }

  ExtractZipFromAddress() {
    let clearzip = "";
    let matches = this.addr.match(/\d{6}(,)*( )*(,)*/);
    if (matches) {
      let zip = matches[0];
      if (zip !== undefined && zip != "") {
        clearzip = zip.match(/\d{6}/)[0];
        this.zip = clearzip;
        this.addr = this.addr.replace(zip, " ");
      }
    }
  }

  ExtractName(str) {
    if (!str) return "";
    let name = str;
    let qpos = str.indexOf('"');
    if (~qpos && qpos < str.length) {
      let namearr = str.split('"');
      let quotes = namearr.length - 1;
      if (quotes == 1) {
        name = namearr[1];
      }
      if (quotes == 2) {
        name = namearr[1] + (namearr[2] != "" ? " - " + namearr[2].trim() : "");
      } else if (quotes == 3) {
        name =
          namearr[1].trim() +
          ' "' +
          namearr[2] +
          '"' +
          (namearr[3] != "" ? " - " + namearr[3].trim() : "");
      }
    }
    return name;
  }
  FormatDigitZip(str) {
    str = str + "";
    return str.replace(/[^0-9]/g, "").substr(0, 6);
  }

  Export() {
    return {
      name: this.name,
      fullname: this.fullname,
      zip: this.zip,
      addr: this.addr,
      comment: this.comment,
      comment2: this.comment2,
      datadigit: this.datadigit,
      gids: this.gids,
      id: this.id,
      autoformat: this.autoformat,
      digitzip: this.digitzip
    };
  }

  ExportWithoutId() {
    return {
      name: this.name,
      fullname: this.fullname,
      zip: this.zip,
      addr: this.addr,
      comment: this.comment,
      comment2: this.comment2,
      datadigit: this.datadigit,
      gids: this.gids,
      autoformat: this.autoformat,
      digitzip: this.digitzip
    };
  }

  State() {
    let empty = 0;
    let full = 0;
    let warningzip = 0;

    if (this.name == "") {
      empty++;
    } else {
      full++;
    }

    if (this.fullname == "") {
      empty++;
    } else {
      full++;
    }

    if (this.zip == "") {
      empty++;
    } else if (this.zip != "" && this.zip.length != 6) {
      warningzip++;
      full++;
    } else {
      full++;
    }

    if (this.addr == "") {
      empty++;
    } else {
      full++;
    }

    if (empty > 0 && full > 0) {
      return "halfempty";
    } else if (empty == 0 && full > 0) {
      if (warningzip) {
        return "badzip";
      } else {
        return "done";
      }
    } else if (empty > 0 && full == 0) {
      return "empty";
    }
  }

  ErrorZip() {
    if (this.zip != "" && this.zip.length != 6) {
      return true;
    } else {
      return false;
    }
  }

  static formatAddress() {}
  static formarZip() {}
  static setLetter() {}

  /* InvoiceTypeStr() {
    if (this.invoicetype == 0) {
      return "Простое";
    }
    if (this.invoicetype == 1) {
      return "Заказное";
    }
  }

  PostTypeStr() {
    if (this.posttype == 0) {
      return "Письмо";
    }
    if (this.posttype == 1) {
      return "Бандероль";
    }
    if (this.posttype == 2) {
      return "Посылка";
    }
    if (this.posttype == 3) {
      return "1 класс";
    }
  }
  PostCategoryStr() {
    if (this.postcategory == 0) {
      return "Заказное";
    }
    if (this.postcategory == 1) {
      return "Ценное";
    }
    if (this.postcategory == 2) {
      return "Обыкн";
    }
  } */
  GetVidRPO() {
    if (this.posttype[0] == "e") {
    } else if (this.posttype[0] == "b") {
    } else if (this.posttype[0] == "p") {
    } else if (this.posttype[0] == "e") {
    }
  }
  GenerateTip() {
    let tip = "";
    if (this.posttype == "e2") {
      if (
        ~this.postservices.indexOf("i1") ||
        ~this.postservices.indexOf("i2")
      ) {
        tip = "Заказное с уведомлением";
      } else {
        tip = "Заказное";
      }
    } else if (
      this.posttype == "e3" ||
      this.posttype == "e4" ||
      this.posttype == "e6" ||
      this.posttype == "e7"
    ) {
      tip = "Объявленной ценностью";
      if (this.inventorytotalcost > 0) {
        tip +=
          " " +
          this.inventorytotalcost +
          " (" +
          rubles(this.inventorytotalcost) +
          ")";
      } else if (this.cost > 0) {
        tip += " " + this.cost + " (" + rubles(this.cost) + ")";
      }
      if (
        ~this.postservices.indexOf("i1") ||
        ~this.postservices.indexOf("i2")
      ) {
        tip += " с уведомлением";
      }
    }

    /* let wp = "";
    if (addPriceAndWeight) {
      if (this.weight > 0) {
        wp = " " + this.weight + " г.";
      }
      if (this.price.g > 0) {
        wp += " " + this.price.g + " руб. марками";
      }
     
    }

    if (wp !== "") {
      tip += wp;
    } */
    /* if (this.postcategory == 0) {
        tip = "Заказное";
      } else if (this.postcategory == 1) {
        tip = "Объявленной ценностью";
        if (this.inventorytotalcost > 0) {
          tip +=
            " " +
            this.inventorytotalcost +
            " (" +
            rubles(this.inventorytotalcost) +
            ")";
        }
      } */
    //}
    return tip;
  }

  GenerateTipSimple(tipText = "") {
    let tip = tipText;
    /* let wp = "";
    if (addPriceAndWeight) {
      if (this.weight > 0) {
        wp = " " + this.weight + " г.";
      }
      if (this.price.g > 0) {
        wp += " " + this.price.g + " руб. марками";
      }
    }

    if (wp !== "") {
      tip += wp;
    } */
    return tip;
  }

  GeneratePriceWeight(addNdsToPrice = false, addNdsToPriceServices = false) {
    let priceWeight = [];
    if (this.weight > 0) {
      priceWeight.push(this.weight + " г.");
    }
    let grow = addNdsToPrice ? this.price.gnds : this.price.g;
    let services = addNdsToPriceServices
      ? this.price.snds + this.price.cnds
      : this.price.s + this.price.c;
    let res = grow + services;
    //let val = this.price.g + this.price.s + this.price.c;
    //let valnds = this.price.gnds + this.price.snds + this.price.cnds;
    if (res > 0) {
      priceWeight.push(res + " руб");
    }
    return priceWeight;
  }
}
