export default class PostParser {
  constructor() {
    this.resp = {};
  }
  getServicePrice(id, tariffKey) {
    if (
      this.resp &&
      this.resp.hasOwnProperty("version") &&
      this.resp.hasOwnProperty("isservice")
    ) {
      if (~this.resp.isservice.indexOf(id)) {
        let service = this.resp.items.find(item => item.serviceon[0] == id);

        if (service && service.hasOwnProperty("tariff")) {
          return (service.tariff[tariffKey] / 100).toFixed(2);
        } else {
          return 0;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
  getServicePriceNum(id, tariffKey) {
    if (
      this.resp &&
      this.resp.hasOwnProperty("version") &&
      this.resp.hasOwnProperty("isservice")
    ) {
      if (~this.resp.isservice.indexOf(id)) {
        let service = this.resp.items.find(item => item.serviceon[0] == id);
        if (service && service.hasOwnProperty("tariff")) {
          return service.tariff[tariffKey];
        } else {
          return 0;
        }
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
  getServicesArray() {
    let arr = {};

    if (
      this.resp &&
      this.resp.hasOwnProperty("version") &&
      this.resp.hasOwnProperty("isservice") &&
      this.resp.isservice !== null
    ) {
      for (let sId of this.resp.isservice) {
        let service = this.resp.items.find(item => item.serviceon[0] == sId);
        let sval = 0;
        let svalnds = 0;
        let snds = 0;
        if (service && service.hasOwnProperty("tariff")) {
          sval = service.tariff["val"] ? service.tariff["val"] : 0;
          svalnds = service.tariff["valnds"] ? service.tariff["valnds"] : 0;
          snds = svalnds - sval;
        }

        arr[sId.toString()] = {
          val: sval,
          valnds: svalnds,
          nds: snds
        };
      }

      return arr;
    }
  }
  getGround(tariffKey) {
    if (this.resp && this.resp.hasOwnProperty("ground")) {
      return (this.resp.ground[tariffKey] / 100).toFixed(2);
    } else {
      return null;
    }
  }
  getCover(tariffKey) {
    if (this.resp && this.resp.hasOwnProperty("cover")) {
      return (this.resp.cover[tariffKey] / 100).toFixed(2);
    } else {
      return null;
    }
  }
  getGroundNum(tariffKey) {
    if (this.resp && this.resp.hasOwnProperty("ground")) {
      return this.resp.ground[tariffKey];
    } else {
      return null;
    }
  }
  getCoverNum(tariffKey) {
    if (this.resp && this.resp.hasOwnProperty("cover")) {
      return this.resp.cover[tariffKey];
    } else {
      return null;
    }
  }
  getGroundCover(tariffKey) {
    let res = 0;
    if (this.resp && this.resp.hasOwnProperty("cover")) {
      if (undefined !== this.resp.cover[tariffKey]) {
        res += this.resp.cover[tariffKey];
      } else {
        res += 0;
      }
    }
    if (this.resp && this.resp.hasOwnProperty("ground")) {
      res += this.resp.ground[tariffKey];
    }
    if (res) {
      return (res / 100).toFixed(2);
    } else {
      return null;
    }
  }
  getGroundCoverNum(tariffKey) {
    let res = 0;
    if (this.resp && this.resp.hasOwnProperty("cover")) {
      res += this.resp.cover[tariffKey];
    }
    if (this.resp && this.resp.hasOwnProperty("ground")) {
      res += this.resp.ground[tariffKey];
    }
    if (res) {
      return res;
    } else {
      return null;
    }
  }
  getSumOc() {
    if (this.resp && this.resp.hasOwnProperty("sumoc")) {
      return (this.resp.sumoc / 100).toFixed(2);
    } else {
      return null;
    }
  }
  getSumOcNum() {
    if (this.resp && this.resp.hasOwnProperty("sumoc")) {
      return this.resp.sumoc;
    } else {
      return null;
    }
  }
  setResponseObject(object) {
    if (typeof object !== undefined) {
      this.resp = object;
    }
  }
  getGroundNum(tariffKey) {
    if (this.resp && this.resp.hasOwnProperty("ground")) {
      return this.resp.ground[tariffKey];
    } else {
      return null;
    }
  }
}
