import { render, staticRenderFns } from "./AppHeader.vue?vue&type=template&id=a0843b84&scoped=true"
import script from "./AppHeader.vue?vue&type=script&lang=js"
export * from "./AppHeader.vue?vue&type=script&lang=js"
import style0 from "./AppHeader.vue?vue&type=style&index=0&id=a0843b84&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0843b84",
  null
  
)

export default component.exports
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QSpinnerBars from 'quasar/src/components/spinner/QSpinnerBars.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QHeader,QToolbar,QToolbarTitle,QAvatar,QSeparator,QBtn,QTooltip,QSpinnerBars,QBtnDropdown,QList,QItem,QItemSection,QItemLabel});qInstall(component, 'directives', {ClosePopup});
