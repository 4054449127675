export default class Chunk {
  constructor() {}

  GetChunk(len) {
    let chunk = 1;
    let ptime = 1000;
    if (len > 0 && len < 5) {
      chunk = 1;
      ptime = 700;
    } else if (len >= 5 && len < 10) {
      chunk = 1;
      ptime = 200;
    } else if (len >= 10 && len < 50) {
      chunk = 5;
      ptime = 200;
    } else if (len >= 50 && len < 100) {
      chunk = 10;
      ptime = 200;
    } else if (len >= 100 && len < 500) {
      chunk = 20;
      ptime = 100;
    } else if (len >= 500) {
      chunk = 50;
      ptime = 50;
    }

    return {
      chunk: chunk,
      ptime: ptime
    };
  }
}
