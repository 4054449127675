<template>
  <div class="q-mx-lg " style="min-width: 1032px;">
    <h4 class="q-mt-xl q-mb-md text-white">Тестовый период завершен</h4>
    <p class="text-white q-mb-lg display-block">
      Дальнейшее использование сервиса возможно на коммерческой основе
    </p>
    <div class="overflow-hidden">
      <q-card
        class="my-card no-shadow pf-cards-small cardphys float-left q-mr-lg"
      >
        <q-card-section>
          <div class="text-h6 q-mb-md">Оплата по QR-коду</div>

          <div class="text-subtitle2">
            <p>Для оплаты через мобильное приложение банка.</p>

            <p>
              QR-код содержит все реквизиты и номер вашего аккаунта.
              <a
                class="text-yellow-6"
                href="/support/pay/payment-people/"
                target="_blank"
                >Инструкция по оплате.</a
              >
            </p>
            <div v-html="qrsource"></div>
          </div>
          <q-btn
            v-if="delaypeople == 0"
            unelevated
            class="q-mt-sm bg-primary full-width"
            icon="notifications_active"
            label="Я оплатил, срочно включите!"
            no-caps
            @click="getFreeDaysForQR"
          />
        </q-card-section>
      </q-card>
      <q-card
        class="my-card bg-primary-light no-shadow pf-cards cardjur float-left q-mr-lg"
      >
        <q-card-section>
          <div class="text-h6 q-mb-md">Оплата по счету</div>
          <div class="text-subtitle2">
            <p>
              После создания счета вам автоматически <br />добавится 10 дней.
            </p>
            <p>
              Вы сможете сразу продолжить работу, <br />не дожидаясь поступления
              оплаты.

              {{
                delaypayment == "1" ? " (Автопродление уже использовано)" : ""
              }}
            </p>
          </div>
        </q-card-section>

        <q-card-section class=" q-pt-none">
          <q-list>
            <q-item clickable>
              <q-item-section avatar>
                <q-avatar color="secondary">1</q-avatar>
              </q-item-section>

              <q-item-section>
                <q-item-label>Заполните реквизиты</q-item-label>
                <q-item-label class="text-yellow-1" caption lines="1"
                  >в меню "Реквизиты"</q-item-label
                >
              </q-item-section>

              <q-item-section side>
                <q-btn color="secondary" unelevated @click="showrecscompany"
                  >Заполнить</q-btn
                >
              </q-item-section>
            </q-item>
            <q-item class="q-my-sm" clickable>
              <q-item-section avatar>
                <q-avatar color="secondary">2</q-avatar>
              </q-item-section>

              <q-item-section>
                <q-item-label>Создайте новый счет</q-item-label>
                <q-item-label caption lines="1" class="text-yellow-1"
                  >в меню "Счета и акты"</q-item-label
                >
              </q-item-section>

              <q-item-section side>
                <q-btn color="secondary" unelevated @click="showbills"
                  >Создать</q-btn
                >
              </q-item-section>
            </q-item>
            <q-item class="q-my-sm" clickable>
              <q-item-section avatar>
                <q-avatar color="secondary" text-color="white">3</q-avatar>
              </q-item-section>

              <q-item-section>
                <q-item-label>Распечатайте счет</q-item-label>
                <q-item-label caption lines="1"></q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </q-card-section>
      </q-card>
      <q-card
        class="my-card no-shadow pf-cards-middle bg-primary float-left cardprice"
      >
        <q-card-section class="bg-primary-light">
          <div class="text-h6 q-mb-md">
            Стоимость использования сервиса
          </div>
          <div class="text-subtitle2">
            <p class="oldprice text-accent">1200 руб</p>
            <p class="newprice text-yellow">980 руб</p>
            <p>
              Годовая лицензия <br />Количество компьютеров неограничено
              <br />Импорт адресов из EXCEL
            </p>
          </div>
          <q-card class="my-card bg-secondary no-shadow pf-cards card-h320">
            <q-card-section>
              <div class="text-h6 q-mb-xs" style="line-height: 140%;">
                Бесплатное использование сервиса доступно для организаций из
                сферы образования
              </div>
              <p class="q-mt-md">
                Для получения лицензии напишите в техподдержку
              </p>
            </q-card-section>
          </q-card>
        </q-card-section>
      </q-card>
    </div>
    <q-dialog
      v-model="delayforcompanies_dialog"
      transition-show="scale"
      transition-hide="scale"
    >
      <q-card
        class="bg-secondary text-white"
        style="width: 640px; max-width: 700px"
      >
        <q-card-section class="inventheader">
          <div class="text-h6">Новый счет создан</div>
        </q-card-section>

        <q-card-section>
          Вы создали новый счет. Ваш аккаунт автоматически продлен на 10 дней.
          <br />Чтобы продолжить работу распечатайте счет и обновите
          страницу.</q-card-section
        >

        <q-card-actions align="right" class="inventbtns text-white q-pa-md">
          <q-btn
            unelevated
            no-caps
            label="Распечатать счет"
            icon="print"
            @click="printBill"
            dense
            color="accent"
          />
          <q-btn
            unelevated
            no-caps
            label="Обновить страницу"
            @click="refreshPage"
            dense
            color="accent"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog
      v-model="delayforqr_dialog"
      transition-show="scale"
      transition-hide="scale"
    >
      <q-card
        class="bg-secondary text-white"
        style="width: 640px; max-width: 700px"
      >
        <q-card-section class="inventheader">
          <div class="text-h6">Аккаунт активирован</div>
        </q-card-section>

        <q-card-section>
          Платежи по QR-коду приходят на следующий рабочий день. <br />Мы
          активировали аккаунт на 2 дня, чтобы вы могли продолжить работу прямо
          сейчас. <br />Обновите страницу.</q-card-section
        >

        <q-card-actions align="right" class="inventbtns text-white q-pa-md">
          <q-btn
            unelevated
            no-caps
            label="Обновить страницу"
            @click="refreshPage"
            dense
            color="accent"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tab: "company",
      delayforcompanies_dialog: false,
      delayforqr_dialog: false
    };
  },
  computed: {
    qrsource() {
      return this.$store.getters.QR;
    },
    showaccount: {
      get() {
        return this.$store.getters.SHOWACCOUNT;
      },
      set(value) {
        this.$store.commit("SET_SHOWACCOUNT", value);
      }
    },
    delaypayment() {
      return this.$store.getters.DELAYPAYMENT;
    },
    delaypeople() {
      return this.$store.getters.DELAYPEOPLE;
    }
  },
  methods: {
    showrecscompany() {
      this.showaccount = true;
      this.$eventBus.$emit("change-acc-tab", { tab: "req", ctype: "company" });
      //this.$eventBus.$emit("change-user-type", "company");
    },
    showrecspeople() {
      this.showaccount = true;
      this.$eventBus.$emit("change-acc-tab", { tab: "req", ctype: "people" });
      //this.$eventBus.$emit("change-user-type", "people");
    },
    showbills() {
      this.showaccount = true;
      this.$eventBus.$emit("change-acc-tab", { tab: "bills" });
    },
    showDelayInfoForCompanies() {},
    printBill() {
      this.$eventBus.$emit("print-last-bill");
    },
    refreshPage() {
      let l = "/";
      window.location = l;
    },
    getFreeDaysForQR() {
      this.$store.dispatch("GET_FREE_DAYS_FOR_QR", {
        action: "addfreedaysfromqr"
      });
    }
  },

  mounted() {
    this.$store.dispatch("GET_QR");
    this.$store.commit("LICENCE_FINISHED");
  },
  created() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === "SHOW_DIALOG_FREE_DAYS_ADDED") {
        this.delayforcompanies_dialog = true;
      } else if (mutation.type === "SHOW_DIALOG_FREE_DAYS_QR_ADDED") {
        this.delayforqr_dialog = true;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
</script>

<style lang="sass">
.oldprice
  font-size: 32px
  text-decoration: line-through
  font-weight: 700
.newprice
  font-size: 22px
.cardphys
  width: 300px
  background-color: #9e0a47
.cardjur
  width: 380px
.cardprice
  width: 300px
</style>
