<template>
  <q-dialog
    v-model="show"
    persistent
    transition-show="scale"
    transition-hide="scale"
  >
    <q-card class="mypdial text-white bardial">
      <q-card-section class="dialhead">
        <div class="text-h6">Почтовый идентификатор (ШПИ)</div>
      </q-card-section>
      <q-card-section>
        <div class="row" v-show="!mass || (mass && openedRecep !== null)">
          <div class="col">
            <q-radio
              v-model="mode"
              dark
              color="white"
              val="auto"
              label="Выбрать из диапазона ШПИ"
            />
            <q-radio
              class="q-ml-md"
              color="white"
              v-model="mode"
              dark
              val="hand"
              label="Ввести внучную"
            />
          </div>
        </div>
        <div class="row q-mx-sm" v-if="mode == 'auto'">
          <div class="col">
            <div class="q-my-xs">
              Диапазон: <span class="text-yellow">{{ range }}</span>
              <q-badge
                color="accent"
                class="cursor-pointer q-py-xs q-ml-md"
                @click="openBarSettings"
              >
                Настроить
              </q-badge>
            </div>
            <div class="q-my-xs">
              Номер последнего отправления:
              <span class="text-yellow">{{ last }}</span>
            </div>
            <div class="q-my-xs">
              Номер текущего отправления:
              <span class="text-yellow">{{ cur }}</span>
            </div>
          </div>
        </div>
        <div
          class="row q-mx-sm q-mt-sm"
          v-show="!mass || (mass && openedRecep !== null)"
        >
          <div class="col-7">
            <q-input
              dense
              bg-color="white"
              color="accent"
              :readonly="mode == 'auto'"
              clearable
              filled
              class="q-my-xs"
              v-model="inputBarcode"
              prefix="ШПИ: "
              mask="######  ##  #####  #"
              unmasked-value
            />
          </div>
        </div>
      </q-card-section>
      <q-card-actions
        align="right"
        class="q-px-md q-pt-md q-pb-md text-white dialbtns"
      >
        <q-btn
          unelevated
          dense
          label="Установить"
          no-caps
          @click="done(true)"
          color="accent"
        />
        <q-btn
          unelevated
          dense
          label="Отмена"
          v-close-popup
          no-caps
          @click="done(false)"
          color="accent"
          class="q-ml-md"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import Interleaved from "src/service/classes/interleaved.js";

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    recepientsIds: {
      type: Array,
      default: null
    },
    openedRecep: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      mode: "auto",
      inputBarcode: null
    };
  },
  computed: {
    s() {
      return this.$store.getters.SETTINGS_OBJECT;
    },
    mass() {
      return this.$router.currentRoute.name == "mass";
    },
    range() {
      if (
        this.s.get("rangeStart") !== null &&
        this.s.get("rangeEnd") != null &&
        !isNaN(this.s.get("rangeStart")) &&
        !isNaN(this.s.get("rangeEnd"))
      ) {
        return this.s.get("rangeStart") + " - " + this.s.get("rangeEnd");
      } else {
        return "не установлен";
      }
    },
    last() {
      if (this.s.get("rangeCur") != null && !isNaN(this.s.get("rangeCur"))) {
        return this.s.get("rangeCur");
      } else {
        return "не установлен";
      }
    },
    cur() {
      if (
        this.s.get("rangeCur") != null &&
        this.s.get("rangeStart") != null &&
        this.s.get("rangeEnd") != null &&
        !isNaN(this.s.get("rangeCur")) &&
        !isNaN(this.s.get("rangeStart")) &&
        !isNaN(this.s.get("rangeEnd"))
      ) {
        return this.s.get("rangeCur") + 1;
      } else {
        return "не установлен";
      }
    },
    state() {
      let error = false;
      let errorText = "";
      if (this.s.get("postID") == "") {
        errorText += "Не указан индекс отделения связи<br/>";
        error = true;
      }
      if (this.s.get("rangeCur") == "") {
        errorText += "Не указан номер текущего отправления<br/>";
        error = true;
      }
      if (this.s.get("rangeStart") == "") {
        errorText += "Не указано начало диапазона ШПИ<br/>";
        error = true;
      }
      if (this.s.get("rangeEnd") == "") {
        errorText += "Не указан конец диапазона ШПИ<br/>";
        error = true;
      }
      if (this.s.get("rangeStart") >= this.s.get("rangeEnd")) {
        errorText +=
          "Начальное значение диапазона номеров ШПИ должно быть меньше конечного значения диапазона<br/>";
        error = true;
      }
      if (
        this.s.get("rangeCur") < this.s.get("rangeStart") ||
        this.s.get("rangeCur") >= this.s.get("rangeEnd")
      ) {
        errorText +=
          "Номер текущего отправления выходит за границы диапазона ШПИ<br/>";
        error = true;
      }

      if (this.error) {
        errorText +=
          "<br/>Проверьте все пункты в настройках почтового идентификатора";
        //this.openSettings();
        //this.$store.commit("SET_ERROR", { error: true, errortext: error });
      }
      return {
        error: error,
        errorText: errorText
      };
    }
  },

  watch: {
    show(val) {
      if (val == true) {
        this.inputBarcode = null;
        if (this.openedRecep !== null) {
          if (this.openedRecep.barcode != null) {
            this.mode = "hand";
            this.inputBarcode = this.openedRecep.barcode;
          } else {
            this.mode = "auto";
            if (!this.state.error) {
              this.inputBarcode = this.calculateBarCode(
                this.s.get("rangeCur") + 1
              );
            }
          }
        }
      }
    },
    mode(nv) {
      if (nv == "auto") {
        this.inputBarcode = null;
        if (!this.state.error) {
          this.inputBarcode = this.calculateBarCode(this.s.get("rangeCur") + 1);
        }
      }
    }
  },
  methods: {
    calculateBarCode(currentPostNumber) {
      let bar = new Interleaved(34);
      bar.createNumBarcode(this.s.get("postID"), currentPostNumber);
      return bar.getNumberBacode();
    },
    openBarSettings() {
      this.$eventBus.$emit("open-tab-settings", {
        tabName: "Bar"
      });
      this.$emit("update:show", false);
    },
    updateCommonSettings(rangeCur) {
      this.$store.commit("SETCOMMON", {
        name: "rangeCur",
        value: rangeCur
      });
      this.$store.dispatch("SAVE_SETTINGS", {
        id: this.s.getId(),
        settings: JSON.stringify(this.s.getSettingsObject()),
        action: "savesettings",
        common: 1
      });
    },
    async generateMassBarcode(clear = false) {
      let newNum = this.s.get("rangeCur") + 1;
      let rangeEnd = this.s.get("rangeEnd");
      let postID = this.s.get("postID");
      let newBarcodes = [];
      for (let i = 0, len = this.recepientsIds.length; i < len; i++) {
        if (newNum > rangeEnd) {
          this.$store.commit("SET_ERROR", {
            error: true,
            errortext:
              "Достигнут конец диапазона почтовых идентификаторов. <br/>Нескольким отправлениям не удалось присвоить ШПИ. "
          });
          break;
        } else {
          newBarcodes[this.recepientsIds[i]] = this.calculateBarCode(newNum);
          newNum++;
        }
      }

      this.$store.dispatch("CHANGE_AND_REPLACE_RECEPIENTS_IN_LIST", {
        selected: this.recepientsIds,
        newData: {
          keyName: "barcode",
          values: newBarcodes
        },
        progressText: clear
          ? "Удаление почтовых идентификаторов"
          : "Добавление почтовых идентификаторов"
      });

      this.updateCommonSettings(newNum - 1);
    },
    showError(errorText) {
      this.$store.commit("SET_ERROR", {
        error: true,
        errortext: errorText
      });
    },
    commitBarcode(recep, barcode) {
      if (typeof barcode == "string" && barcode.length == 0) {
        barcode = null;
      }
      this.$store.commit("SET_BAR_TO_RECEP", {
        recep: recep,
        barcode: barcode,
        barcodemode: this.mode
      });
    },
    done(payload) {
      if (payload == true) {
        if (this.mass && this.openedRecep === null) {
          if (this.state.error) {
            this.showError(this.state.errorText);
            return;
          } else {
            this.generateMassBarcode();
          }
        } else {
          if (this.mode == "auto") {
            if (this.state.error) {
              this.showError(this.state.errorText);
              return;
            } else {
              this.commitBarcode(this.openedRecep, this.inputBarcode);
              this.updateCommonSettings(this.s.get("rangeCur") + 1);
            }
          } else {
            if (
              this.inputBarcode !== null &&
              this.inputBarcode.length > 0 &&
              this.inputBarcode.length < 6
            ) {
              this.showError("ШПИ должен состоять из 14 цифр");
              return;
            } else {
              this.commitBarcode(this.openedRecep, this.inputBarcode);
            }
          }
          this.$store.commit("UPDATE_CANVAS");
        }
      }
      this.$emit("update:show", false);
      if (this.mass) {
        this.$emit("update:openedRecep", null);
      }
    },
    clearBarcode(payload) {
      this.$store.commit("SET_BAR_TO_RECEP", {
        recep: payload.recep,
        barcode: null,
        barcodemode: "auto"
      });
    },
    async clearBarcodeMass() {
      this.$store.dispatch("CHANGE_AND_REPLACE_RECEPIENTS_IN_LIST", {
        selected: this.recepientsIds,
        newData: {
          barcode: null
        },
        progressText: "Удаление почтовых идентификаторов"
      });
    }
  },
  mounted() {
    this.$eventBus.$on("clear-barcode", this.clearBarcode);
    this.$eventBus.$on("clear-barcode-mass", this.clearBarcodeMass);
  },
  beforeDestroy() {
    this.$eventBus.$off("clear-barcode");
    this.$eventBus.$off("clear-barcode-mass");
  }
};
</script>

<style lang="sass">
.dialhead, .dialbtns
  background: rgba(0,0,0,0.15)
.bardial
  width: 480px
</style>
