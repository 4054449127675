<template>
  <div class="dispflex self-stretch">
    <q-btn
      stretch
      ref="openaccountbtnheader"
      flat
      :label="$q.screen.gt.sm ? 'Мой аккаунт' : ''"
      icon-right="account_circle"
      @click="showaccount = true"
    >
      <q-badge :color="badgecolor" class="my-qbadge" floating>{{
        balance
      }}</q-badge>
    </q-btn>
    <q-dialog v-model="showaccount">
      <q-card class="p-qcard mh accmw overflow-visible">
        <q-btn
          icon="close"
          class="acccloser"
          color="accent"
          round
          dense
          v-close-popup
        />
        <div class="fit row wrap justify-start items-stretch content-start">
          <div class="mh col-3 bg-p-cyan-light-darken text-center">
            <div class="bg-p-cyan-light q-pa-lg">
              <q-avatar size="100px" font-size="100px" icon="account_circle" />
              <div class="q-mt-sm">{{ username }}</div>
              <div class="q-mt-md">{{ usertypename }}</div>
              <div>
                Осталось дней:
                <q-badge
                  class="q-ml-xs q-py-xs q-px-sm"
                  :color="badgepanecolor"
                  >{{ balance }}</q-badge
                >
              </div>
            </div>
            <div class="q-my-lg q-pl-lg">
              <q-tabs
                content-class="justifyleft"
                ref="tabsaccount"
                v-model="tab"
                vertical
                inline-label
                align="justify"
                active-color="yellow-3"
                no-caps
                indicator-color="yellow-5"
              >
                <q-tab
                  content-class="justifyleft"
                  name="profile"
                  icon="mail"
                  label="Личные данные"
                />
                <q-tab
                  content-class="justifyleft"
                  name="req"
                  icon="alarm"
                  label="Реквизиты"
                />
                <q-tab
                  content-class="justifyleft"
                  name="bills"
                  icon="movie"
                  label="Счета и акты"
                />
              </q-tabs>
            </div>
            <q-btn
              label="Выйти из аккаунта"
              no-caps
              unelevated
              color="secondary"
              icon="logout"
              class="q-px-none"
              @click="logout"
            />
          </div>
          <q-scroll-area
            class="col-9 q-pa-lg bg-primary-light"
            :thumb-style="thumbStyle"
          >
            <div class="iiouter">
              <q-tab-panels
                v-model="tab"
                animated
                transition-prev="scale"
                transition-next="scale"
                class="bg-primary-light"
              >
                <q-tab-panel name="profile" class="bg-primary-light">
                  <UserSettings />
                </q-tab-panel>

                <q-tab-panel name="req" class="bg-primary-light">
                  <UserReq />
                </q-tab-panel>

                <q-tab-panel name="bills" class="bg-primary-light">
                  <UserBills />
                </q-tab-panel>
              </q-tab-panels>
            </div>
          </q-scroll-area>
        </div>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import UserBills from "src/platform/components/account/UserBills.vue";
import UserReq from "src/platform/components/account/UserReq.vue";
import UserSettings from "src/platform/components/account/UserSettings.vue";

export default {
  components: {
    UserBills,
    UserReq,
    UserSettings
  },
  data: function() {
    return {
      tab: "profile",
      thumbStyle: {
        right: "px",
        borderRadius: "5px",
        backgroundColor: "#12ced2",
        width: "7px",
        opacity: 0.75
      }
    };
  },
  computed: {
    badgecolor() {
      return this.$store.getters.BALANCE > 30 ? "secondary" : "accent";
    },
    badgepanecolor() {
      return this.$store.getters.BALANCE > 30 ? "primary" : "accent";
    },
    balance() {
      return this.$store.getters.BALANCE;
    },
    usertypename() {
      let bills = this.$store.getters.BILLS;
      if (bills.length > 0) {
        if (bills[0].billpayed == "1") {
          return "Коммерческая лицензия";
        }
      }
      return "Пробная версия";
    },
    username() {
      let bills = this.$store.getters.BILLS;
      if (bills.length > 0) {
        if (bills[0].billpayed == "1") {
          return "Профессиональный пользователь";
        }
      }
      return "Зарегистрированный пользователь";
    },
    showaccount: {
      get() {
        return this.$store.getters.SHOWACCOUNT;
      },
      set(value) {
        this.$store.commit("SET_SHOWACCOUNT", value);
      }
    }
  },
  mounted() {
    this.$eventBus.$on("change-acc-tab", obj => this.changeTab(obj));
  },
  beforeDestroy() {
    this.$eventBus.$off("change-acc-tab");
  },
  methods: {
    logout() {
      this.$store.dispatch("LOGOUT");
    },
    changeTab(obj) {
      this.tab = obj.tab;
      setTimeout(
        () => this.$eventBus.$emit("change-user-type", obj.ctype),
        100
      );
    }
  }
};
</script>

<style lang="sass">
.p-qcard
  width: 960px
  max-width: 80vw!important
  overflow: visible!important
.iiouter
  overflow: auto
.mh
  min-height: 530px
.justifyleft, .justifyleft>.q-tab
  justify-content: flex-start!important
.acccloser
  position: absolute
  right: -16px
  top: -16px
  z-index: 100
.accmw
  min-width: 894px
</style>
