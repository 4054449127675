<script>
export default {
  props: {
    activeletter: {
      type: Number,
      default: -1
    },
    activegroup: { type: Object, default: null },
    search: { type: String, default: "" }
  },
  computed: {
    filteredrecepients() {
      let arr = [];
      if (this.search != "") {
        for (let index of this.indexToShow) {
          arr.push(this.recepients[index]);
        }
      } else if (this.activeletter != -1 || this.activegroup != null) {
        arr = this.recepients.filter(item => {
          return (
            (item.datadigit == this.activeletter || this.activeletter == -1) &&
            (this.activegroup == null ||
              ~item.gids.indexOf(this.activegroup.id))
          );
        });
      }

      return arr;
    },
    usefilter() {
      return (
        this.activeletter != -1 || this.activegroup != null || this.search != ""
      );
    },
    list() {
      return this.usefilter ? this.filteredrecepients : this.recepients;
    },
    indexToShow() {
      let arr = [];
      let rg = new RegExp(
        '"where="[^>]*' + this.search + '[^>]*id="(\\d+)">',
        "gi"
      );
      let result = null;
      while ((result = rg.exec(this.searchtpl))) {
        arr.push(parseInt(result[1]));
      }
      return arr;
    }
  },
  methods: {
    moveSelectedToMassList() {
      this.$store.dispatch("ADD_RECEPIENTS_TO_LIST_FROM_FILTER", {
        recepients: this.list,
        progress: this.$chunk.GetChunk(this.list.length)
      });
    }
  },
  mounted() {
    this.$eventBus.$on("move-selected", this.moveSelectedToMassList);
  },
  beforeDestroy() {
    this.$eventBus.$off("move-selected");
  }
};
</script>
<style lang="sass">
</style>