<template>
  <div class="fprop hcontrol">
    <div class="btitle">{{ title }}</div>

    <div class="row q-pb-md">
      <div class="col self-center">
        <q-btn
          padding="none"
          icon="arrow_back_ios"
          unelevated
          flat
          color="secondary"
          @click="rotateLeft"
        />
      </div>
      <div class="col-10">
        <q-img
          v-if="val >= 0"
          style="height: 140px; width: 260px"
          :src="recepviews[val]"
          spinner-color="white"
          class="spreview"
        />
        <div v-else class="text-center h140px">
          <img src="/images/recep/0.jpg" />
        </div>
      </div>
      <div class="col self-center">
        <q-btn
          padding="none"
          icon="arrow_forward_ios"
          unelevated
          flat
          color="secondary"
          @click="rotateRight"
        />
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    val: { type: Number, default: null },
    name: { type: String, default: null },
    title: { tybe: String, default: "" }
  },
  data() {
    return {
      recepviews: [
        "/images/recep/1.jpg",
        "/images/recep/2.jpg",
        "/images/recep/3.jpg",
        "/images/recep/4.jpg"
      ],
      parameters: [
        {
          rPrintLines: 1,
          rPrintRecep: 1,
          rPrintTips: 1,
          rPrintTipsWhite: 0,
          zipClearFont: 0,
          autoChangeFSize: 0
        },
        {
          rPrintLines: 0,
          rPrintRecep: 1,
          rPrintTips: 1,
          rPrintTipsWhite: 1,
          zipClearFont: 1,
          autoChangeFSize: 1
        },
        {
          rPrintLines: 1,
          rPrintRecep: 0,
          rPrintTips: 1,
          rPrintTipsWhite: 0,
          zipClearFont: 0,
          autoChangeFSize: 1
        },
        {
          rPrintLines: 0,
          rPrintRecep: 0,
          rPrintTips: 0,
          rPrintTipsWhite: 0,
          zipClearFont: 0,
          autoChangeFSize: 0
        }
      ]
    };
  },
  computed: {
    l() {
      return this.recepviews.length;
    }
  },
  methods: {
    rotateRight() {
      let p = (this.val + 1) % this.l;
      this.$store.commit("SET", {
        name: this.name,
        value: p
      });
      for (let key in this.parameters[p]) {
        this.$store.commit("SET", {
          name: key,
          value: this.parameters[p][key]
        });
      }
    },
    rotateLeft() {
      let p = (this.l + (this.val - 1)) % this.l;
      this.$store.commit("SET", {
        name: this.name,
        value: p
      });
      for (let key in this.parameters[p]) {
        this.$store.commit("SET", {
          name: key,
          value: this.parameters[p][key]
        });
      }
    }
  }
};
</script>

<style lang="sass">
.fprop .btitle
  clear: both
  float: none
  margin-bottom: 10px
.h110px
  height: 110px
.h140px
  height: 140px
</style>