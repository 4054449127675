<template>
  <div class="dispflex self-stretch">
    <q-btn stretch flat @click="openChat">
      <span>Техподдержка</span>
      <span :class="adminstateclass">{{ adminstatestatus }}</span>
      <q-badge
        v-show="bell"
        class="q-pt-sm q-mx-xs q-pr-none"
        color="transparent"
        floating
      >
        <q-icon
          name="notification_important"
          size="xs"
          color="accent"
          class="animatednote"
        />
      </q-badge>
    </q-btn>
    <ChatWindow
      :showchatwnd.sync="showchatwnd"
      @close-chat="closeChat"
      @send="sendMessage"
      chatheader="Чат с техподдержкой"
      :adminname="adminname"
      :messages="messages"
    />
  </div>
</template>

<script>
import ChatWindow from "./ChatWindow.vue";
export default {
  components: {
    ChatWindow
  },
  data() {
    return {
      showchatwnd: false,
      notify: false,
      askadmininterval: 180000, //Интервал опроса админа. Онлайн или нет
      askchatstateinterval: 15000, // Интервал опроса состояния чата
      askadmin: false,
      askchatstate: false,
      bell: false //Колокольчик
    };
  },
  computed: {
    activeuser() {
      return this.$store.getters.ACTIVEUSER || this.$store.getters.FINISHED;
    },
    cursender_name() {
      let cs = this.$store.getters.GET_CURSENDER;
      if (cs === null) {
        return "Новый";
      } else {
        return cs.name;
      }
    },
    chatid() {
      return this.$store.getters.CHAT_ID;
    },
    messages() {
      return this.$store.getters.CHAT_MESSAGES;
    },
    ncm() {
      return this.$store.getters.CHAT_NCM;
    },
    nam() {
      return this.$store.getters.CHAT_NAM;
    },
    adminonline() {
      return this.$store.getters.CHAT_ADMIN_ONLINE;
    },
    adminname() {
      return this.$store.getters.CHAT_ADMIN_NAME;
    },
    opened() {
      return this.$store.getters.CHAT_OPENED;
    },
    lastid() {
      return this.$store.getters.CHAT_LASTID;
    },
    adminstateclass() {
      if (!this.activeuser) {
        return "adminstate online";
      } else {
        return this.adminonline ? "adminstate online" : "adminstate offline";
      }
    },
    adminstatestatus() {
      if (!this.activeuser) {
        return "online";
      } else {
        return this.adminonline ? "online" : "offline";
      }
    },
    chatexist() {
      return this.$store.getters.CHAT_CHAT_EXIST;
    }
  },

  methods: {
    openChat() {
      if (!this.activeuser) {
        this.$store.commit("SET_ERROR", {
          error: true,
          errortext: "Для использования чата необходимо зарегистрироваться."
        });
      } else {
        this.showchatwnd = true;
        this.bell = false;
      }
    },
    closeChat() {
      this.showchatwnd = false;
    },
    getChatAdmin() {
      if (this.activeuser) {
        this.$store.dispatch("GET_CHAT_ADMIN", {
          action: "getadmin",
          rand: Math.floor(Math.random() * 10000)
        });
      }
    },
    getChatState() {
      if (this.activeuser) {
        this.$store.dispatch("GET_CHAT_STATE", {
          action: "getchatstate",
          isadmin: false
        });
      }
    },
    sendMessage(text) {
      if (this.activeuser) {
        this.$store.dispatch("SEND_CHAT_MESSAGE", {
          action: "send",
          text: text,
          lastid: this.lastid,
          isadmin: false,
          companyname: this.cursender_name
        });
      }
    },
    getMessages() {
      if (this.activeuser) {
        this.$store.dispatch("GET_CHAT_MESSAGES", {
          action: "getmessages",
          lastid: this.lastid
        });
      }
    }
  },
  watch: {
    nam(nv) {
      if (nv > 0) {
        this.getMessages();
        if (this.showchatwnd == false) {
          this.bell = true;
        }
      }
    },
    chatexist: {
      handler: function(nv, ov) {
        if (nv > ov) {
          this.getMessages();
        }
      }
    },
    activeuser: {
      handler: function(nv, ov) {
        if (nv > ov) {
          this.getChatAdmin();
          this.getChatState();
        }
      }
    }
  },
  mounted() {
    this.getChatAdmin();
    this.getChatState();
    this.askadmin = setInterval(() => {
      this.getChatAdmin();
    }, this.askadmininterval);
    this.askchatstate = setInterval(() => {
      if (this.adminonline && this.opened && this.activeuser) {
        this.getChatState();
      }
    }, this.askchatstateinterval);
  }
};
</script>

<style lang="sass">
.adminstate
  font-size: 9px
  letter-spacing: 1px
  background: #11a8ab
  padding: 2px 3px
  margin-left: 6px
  line-height: 100%
  border-radius: 4px
  margin-top: -3px

.adminstate.online
  background: #11a8ab
.adminstate.offline
  background: #e64c65
</style>
