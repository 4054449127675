<template>
  <q-dialog
    persistent
    content-class="psdialog"
    v-model="psdialog"
    v-if="psdialog"
    position="left"
  >
    <div class="psdcontent">
      <q-btn
        class="psdialogcloser"
        icon="close"
        size="sm"
        square
        dense
        @click="closeSettings"
        color="accent"
      />

      <div class="psformatselector q-px-sm q-py-md">
        <q-select
          outlined
          dark
          class="psselect q-mb-sm"
          color="accent"
          map-options
          dense
          :options-dense="true"
          v-model="curformat"
          :options="formatlist"
          emit-value
          :display-value="
            `Настройки шаблона: ${curformat ? curformat.label : '...'}`
          "
        >
          <template v-slot:option="scope">
            <q-item v-bind="scope.itemProps" v-on="scope.itemEvents">
              <q-item-section>{{ scope.opt.label }}</q-item-section>
              <q-item-section avatar>
                <q-btn
                  class="q-ml-sm"
                  icon="close"
                  unelevated
                  dense
                  flat
                  size="xs"
                  color="accent"
                  v-if="!scope.opt.default && scope.opt.value != curfid"
                  @click.stop="deleteFormat(scope.opt.value)"
                />
              </q-item-section>
            </q-item>
          </template>
        </q-select>
        <div>
          <q-btn
            size="sm"
            icon="add"
            unelevated
            dense
            flat
            color="white"
            @click="openCreateDialog"
          >
            <q-tooltip
              anchor="center right"
              self="center left"
              transition-show="flip-right"
              transition-hide="flip-left"
              content-class="bg-accent fs14px"
              :offset="[10, 10]"
              >Создать новый шаблон</q-tooltip
            >
          </q-btn>
          <q-btn
            size="sm"
            class="q-ml-sm"
            icon="content_copy"
            unelevated
            dense
            flat
            color="white"
            @click="openCopyDialog"
          >
            <q-tooltip
              anchor="center right"
              self="center left"
              transition-show="flip-right"
              transition-hide="flip-left"
              content-class="bg-accent fs14px"
              :offset="[10, 10]"
              >Копировать шаблон</q-tooltip
            >
          </q-btn>

          <q-btn
            size="sm"
            class="q-ml-sm"
            icon="settings_backup_restore"
            unelevated
            dense
            flat
            color="white"
            @click="restoreSettings"
          >
            <q-tooltip
              content-class="bg-accent fs14px"
              anchor="center right"
              self="center left"
              transition-show="flip-right"
              transition-hide="flip-left"
              :offset="[10, 10]"
              >Восстановить значения по-умолчанию</q-tooltip
            >
          </q-btn>
          <q-btn
            size="sm"
            :disable="!f.isModyfied() && !s.isModyfied()"
            :class="
              f.isModyfied() || s.isModyfied()
                ? 'bg-accent float-right'
                : 'float-right'
            "
            icon="save"
            unelevated
            dense
            flat
            @click="saveSettings"
            label="Сохранить"
          >
          </q-btn>
        </div>
      </div>

      <q-scroll-area
        :thumb-style="thumbStyle"
        :bar-style="barStyle"
        class="settingsscroll"
      >
        <q-list bordered class="explist" ref="senderblock">
          <q-expansion-item
            v-model="tabModel.Sender"
            expand-icon-class="sticonopen"
            icon="home"
            label="Настройки отправителя"
            group="somegroup"
          >
            <SenderView
              :val="f.list.sview"
              name="sview"
              title="Выберите внешний вид блока отправителя"
            />
            <Horizontal
              :val="f.list.l5"
              name="l5"
              :step="1"
              title="Ширина блока отправителя"
              @tip="showTip"
            />
            <HorizontalVertical
              :horizontal="{
                val: f.list.sMoveX,
                step: 1,
                name: 'sMoveX',
                min: -999,
                max: 999
              }"
              :vertical="{
                val: f.list.sMoveY,
                step: 1,
                name: 'sMoveY',
                min: -999,
                max: 999
              }"
              title="Смещение блока отправителя"
              @tip="showTip"
            />
            <HorizontalVertical
              :horizontal="{
                val: f.list.sZipMoveX,
                step: 1,
                name: 'sZipMoveX',
                min: -999,
                max: 999
              }"
              :vertical="{
                val: f.list.sZipMoveY,
                step: 1,
                name: 'sZipMoveY',
                min: -999,
                max: 999
              }"
              title="Смещение индекса отправителя"
              @tip="showTip"
            />
            <Horizontal
              :val="f.list.sRowHeight"
              name="sRowHeight"
              :step="0.2"
              title="Межстрочный индервал"
              @tip="showTip"
            />
            <Switcher
              :val="f.list.addrFieldsOrder"
              name="addrFieldsOrder"
              title="Сначала адрес"
              :truev="1"
              :falsev="2"
              @tip="showTip"
            />

            <HorizontalVertical
              :horizontal="{
                val: f.list.zakazMoveX,
                step: 1,
                name: 'zakazMoveX',
                min: -999,
                max: 999
              }"
              :vertical="{
                val: f.list.zakazMoveY,
                step: 1,
                name: 'zakazMoveY',
                min: -999,
                max: 999
              }"
              title="Смещение пометки"
              @tip="showTip"
            />
            <Switcher
              :val="f.list.printLogo"
              name="printLogo"
              title="Печатать логотип"
              :truev="1"
              :falsev="0"
              @tip="showTip"
            />
            <Switcher
              :val="f.list.logoFirst"
              name="logoFirst"
              title="Логотип перед отправителем"
              :truev="1"
              :falsev="0"
              @tip="showTip"
            />
            <HorizontalVertical
              :horizontal="{
                val: f.list.logoMoveX,
                step: 1,
                name: 'logoMoveX',
                min: -999,
                max: 999
              }"
              :vertical="{
                val: f.list.logoMoveY,
                step: 1,
                name: 'logoMoveY',
                min: -999,
                max: 999
              }"
              title="Смещение логотипа"
              @tip="showTip"
            />
            <HSlider
              :val="f.list.logoSize"
              name="logoSize"
              @tip="showTip"
              title="Размер логотипа"
            />
            <Horizontal
              :val="f.list.SfsName"
              name="SfsName"
              :step="0.1"
              title="Размер шрифта имени отправителя"
              @tip="showTip"
            />
            <Horizontal
              :val="f.list.SfsAddr"
              name="SfsAddr"
              :step="0.1"
              title="Размер шрифта адреса отправителя"
              @tip="showTip"
            />
            <Switcher
              :val="f.list.autoChangeFSize"
              :watched="true"
              name="autoChangeFSize"
              title="Автоматически уменьшать размер шрифта для длинных имен и адресов. Необходимо включить для конвертов с разлиновкой"
              :truev="1"
              :falsev="0"
              @tip="showTip"
              contentclass="lh120"
            />
          </q-expansion-item>
          <q-separator color="#a7a7a7" />
          <q-expansion-item
            icon="perm_identity"
            label="Настройки получателя"
            v-model="tabModel.Recepient"
            group="somegroup"
            expand-icon-class="sticonopen"
          >
            <RecepView
              :val="f.list.rview"
              name="rview"
              title="Выберите внешний вид блока отправителя"
            />
            <HorizontalVertical
              :horizontal="{
                val: f.list.rMoveX,
                step: 1,
                name: 'rMoveX',
                min: -999,
                max: 999
              }"
              :vertical="{
                val: f.list.rMoveY,
                step: 1,
                name: 'rMoveY',
                min: -999,
                max: 999
              }"
              title="Смещение блока получателя"
              @tip="showTip"
            />
            <HorizontalVertical
              :horizontal="{
                val: f.list.rZipMoveX,
                step: 1,
                name: 'rZipMoveX',
                min: -999,
                max: 999
              }"
              :vertical="{
                val: f.list.rZipMoveY,
                step: 1,
                name: 'rZipMoveY',
                min: -999,
                max: 999
              }"
              title="Смещение индекса получателя"
              @tip="showTip"
            />
            <Switcher
              :val="f.list.printComment"
              name="printComment"
              title="Печатать комментарий"
              :truev="1"
              :falsev="0"
              @tip="showTip"
            />
            <Horizontal
              :val="f.list.rRowHeight"
              name="rRowHeight"
              :step="0.1"
              title="Межстрочный индервал"
              @tip="showTip"
            />
            <Horizontal
              :val="f.list.Rfs"
              name="Rfs"
              :step="0.1"
              title="Размер шрифта получателя"
              @tip="showTip"
            />
            <Switcher
              :val="f.list.autoChangeFSize"
              :watched="true"
              name="autoChangeFSize"
              title="Автоматически уменьшать размер шрифта для длинных имен и адресов. Необходимо включить для конвертов с разлиновкой"
              :truev="1"
              :falsev="0"
              @tip="showTip"
              contentclass="lh120"
            />
          </q-expansion-item>
          <q-separator color="#a7a7a7" />
          <q-expansion-item
            v-model="tabModel.Zip"
            icon="money"
            label="Настройки пунктирного индекса"
            group="somegroup"
            expand-icon-class="sticonopen"
          >
            <Switcher
              :val="f.list.printZip"
              name="printZip"
              title="Печатать пунктирный индекс"
              :truev="1"
              :falsev="0"
              @tip="showTip"
            />
            <Switcher
              :val="f.list.zipClearFont"
              :watched="true"
              name="zipClearFont"
              title="Использовать шрифт для конвертов с разлиновкой"
              :truev="1"
              :falsev="0"
              @tip="showTip"
            />
            <Horizontal
              :val="f.list.ZIPfontsize"
              name="ZIPfontsize"
              :step="0.1"
              title="Высота шрифта"
              @tip="showTip"
            />
            <Horizontal
              :val="f.list.ZIPletterspace"
              name="ZIPletterspace"
              :step="0.1"
              title="Расстояние между цифрами"
              @tip="showTip"
            />
            <HorizontalVertical
              :horizontal="{
                val: f.list.zMoveX,
                step: 1,
                name: 'zMoveX',
                min: -999,
                max: 999
              }"
              :vertical="{
                val: f.list.zMoveY,
                step: 1,
                name: 'zMoveY',
                min: -999,
                max: 999
              }"
              title="Смещение индекса"
              @tip="showTip"
            />
          </q-expansion-item>
          <q-separator color="#a7a7a7" />
          <q-expansion-item
            icon="print"
            v-model="tabModel.Printer"
            label="Настройки принтера"
            group="somegroup"
            expand-icon-class="sticonopen"
          >
            <OutputFormat :useholst="f.list.useholst" :posnum="f.list.posnum" />
          </q-expansion-item>
          <q-separator color="#a7a7a7" />
          <q-expansion-item
            v-model="tabModel.Bar"
            icon="money"
            label="Настройки почтового идентификатора (ШПИ)"
            group="somegroup"
            expand-icon-class="sticonopen"
          >
            <SettingsTextInput
              :val="s.list.postID"
              name="postID"
              title="Номер отделения связи"
              contentclass="floatrightinp"
              @tip="showTip"
              settingtype="common"
              :num="true"
            />
            <SettingsTextInput
              :val="s.list.rangeStart"
              name="rangeStart"
              title="Начало диапазона"
              contentclass="floatrightinp"
              @tip="showTip"
              settingtype="common"
              :num="true"
            />
            <SettingsTextInput
              :val="s.list.rangeEnd"
              name="rangeEnd"
              title="Конец диапазона"
              contentclass="floatrightinp"
              @tip="showTip"
              settingtype="common"
              :num="true"
            />
            <SettingsTextInput
              :val="s.list.rangeCur"
              name="rangeCur"
              title="Номер последнего отправления"
              contentclass="floatrightinp"
              @tip="showTip"
              settingtype="common"
              :num="true"
            />
            <HorizontalVertical
              :horizontal="{
                val: f.list.barMoveX,
                step: 1,
                name: 'barMoveX',
                min: -999,
                max: 999
              }"
              :vertical="{
                val: f.list.barMoveY,
                step: 1,
                name: 'barMoveY',
                min: -999,
                max: 999
              }"
              title="Положение штрих-кода на конверте"
              @tip="showTip"
              settingtype="format"
            />
          </q-expansion-item>
          <q-separator color="#a7a7a7" />
          <q-expansion-item
            icon="text_fields"
            label="Настройки интерфейса"
            v-model="tabModel.Interface"
            group="somegroup"
            expand-icon-class="sticonopen"
          >
            <Switcher
              class="usprm"
              :val="s.list.showLbl"
              :watched="true"
              name="showLbl"
              title="Показывать дополнительные пометки в списке получателей"
              :truev="1"
              :falsev="0"
              @tip="showTip"
              settingtype="common"
            />
            <Switcher
              class="usprm"
              :val="s.list.showCom"
              :watched="true"
              name="showCom"
              title="Показывать комментарии в списке получателей"
              :truev="1"
              :falsev="0"
              @tip="showTip"
              settingtype="common"
            />
          </q-expansion-item>
        </q-list>
        <q-dialog v-model="pscreatedialog" persistent>
          <q-card style="min-width: 350px" class="bg-white">
            <q-card-section class="bg-secondary q-px-lg q-py-md">
              <div class="text-h6">
                {{ pscreatemode === "new" ? "Создание" : "Копирование" }}
                шаблона
              </div>
            </q-card-section>

            <q-card-section class="text-grey-8 q-pa-lg">
              <q-select
                v-if="pscreatemode === 'new'"
                filled
                class="q-mb-md"
                color="secondary"
                popup-content-class="psselectpopup"
                map-options
                dense
                :options-dense="true"
                v-model="newformat"
                :options="formatslist"
                emit-value
                :display-value="
                  `${
                    newformat
                      ? 'Размер: ' + newformat.toUpperCase()
                      : 'Выберите размер'
                  }`
                "
              ></q-select>
              <div v-else class="q-mb-md">
                Копия шаблона "{{ f.getName() }}"
              </div>
              <q-input
                dense
                filled
                v-model="sname"
                @keyup.enter="createNewSettings"
                label="Введите название шаблона"
                color="secondary"
              />
            </q-card-section>

            <q-card-actions
              align="right"
              class="text-primary q-px-lg q-pb-md q-pt-none"
            >
              <q-btn
                flat
                no-caps
                label="Отмена"
                v-close-popup
                color="grey-10"
              />
              <q-btn
                flat
                no-caps
                label="Создать"
                @click="createNewSettings"
                color="grey-10"
              />
            </q-card-actions>
            <div class="progress-outer">
              <q-linear-progress
                v-show="createprogress"
                indeterminate
                color="accent"
              />
            </div>
          </q-card>
        </q-dialog>
      </q-scroll-area>
    </div>
    <q-dialog v-model="showtip">
      <q-card class="scard tipimages">
        <q-card-section>
          <img alt="Описание настройки" :src="tip_filename" />
        </q-card-section>

        <q-card-actions class="q-pa-md" align="right">
          <q-btn
            unelevated
            label="Понятно"
            no-caps
            color="accent"
            v-close-popup
          />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-dialog v-model="closedialog" persistent>
      <q-card class="bg-accent">
        <q-card-section class="bg-secondary text-h6"
          >Вы изменили следующие шаблоны</q-card-section
        >
        <q-card-section class="bg-primary-light q-py-sm">
          <ol>
            <li v-for="(item, index) in unsaved" :key="index">
              {{
                item.getName() === "common" ? "Общие настройки" : item.getName()
              }}
            </li>
          </ol>
        </q-card-section>

        <q-card-actions align="right" class="q-pa-md bg-primary">
          <q-btn
            class="q-mr-sm"
            color="secondary"
            unelevated
            dense
            no-caps
            label="Сохранить"
            @click="saveUnsaved()"
          />
          <q-btn
            color="secondary"
            unelevated
            dense
            no-caps
            label="Не сохранять"
            @click="noSaveUnsaved()"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </q-dialog>
</template>

<script>
import Horizontal from "src/service/components/controls/Horizontal.vue";
import HSlider from "src/service/components/controls/HSlider.vue";
import HorizontalVertical from "src/service/components/controls/HorizontalVertical.vue";
import Switcher from "src/service/components/controls/Switcher.vue";
import SenderView from "src/service/components/controls/SenderView.vue";
import RecepView from "src/service/components/controls/RecepView.vue";
import SettingsTextInput from "src/service/components/controls/SettingsTextInput.vue";
import OutputFormat from "src/service/components/controls/OutputFormat.vue";

export default {
  components: {
    Horizontal,
    HorizontalVertical,
    Switcher,
    SenderView,
    RecepView,
    SettingsTextInput,
    OutputFormat,
    HSlider
  },
  data() {
    return {
      showtip: false,
      tip_filename: "",
      pscreatedialog: false,
      pscreatemode: "new",
      formatslist: [
        { label: "C6", value: "c6" },
        { label: "DL", value: "dl" },
        { label: "C5", value: "c5" },
        { label: "C4", value: "c4" },
        { label: "B4", value: "b4" }
      ],
      newformat: null,
      sname: null,
      createprogress: false,
      thumbStyle: {
        right: "4px",
        backgroundColor: "#157577",
        width: "4px",
        opacity: 1,
        top: "3px"
      },

      barStyle: {
        backgroundColor: "#FFF",
        width: "12px",
        opacity: 0.8
      },
      closedialog: false,
      unsaved: [],
      tabModel: {
        Recepient: false,
        Sender: false,
        Bar: false,
        Zip: false,
        Printer: false,
        Interface: false
      }
    };
  },
  computed: {
    settings() {
      return this.$store.getters.SETTINGS;
    },
    formats() {
      return this.$store.getters.FORMATS;
    },
    formatlist() {
      return this.$store.getters.FORMAT_LIST;
    },
    curfid() {
      return this.$store.getters.CURFORMAT;
    },
    curformat: {
      get: function() {
        return this.formatlist.filter(pair => pair.value === this.curfid)[0];
      },
      set: function(newval) {
        this.$store.commit("SET_FORMAT", newval);
      }
    },
    f() {
      return this.$store.getters.CURFORMAT_OBJECT;
    },
    s() {
      return this.$store.getters.SETTINGS_OBJECT;
    },
    psdialog: {
      get: function() {
        return this.$store.getters.PSDIALOG;
      },
      set: function() {
        this.$store.commit("CLOSE_SETTINGS");
      }
    }
  },
  methods: {
    openCreateDialog() {
      this.pscreatemode = "new";
      this.pscreatedialog = true;
    },
    openCopyDialog() {
      this.pscreatemode = "copy";
      this.pscreatedialog = true;
    },
    createNewSettings() {
      if (this.pscreatemode === "new") {
        if (this.newformat && this.sname) {
          this.createprogress = true;
          this.$store.dispatch("CREATE_NEW_SETTINGS", {
            format: this.newformat,
            name: this.sname,
            action: "createsettings"
          });
        }
      } else {
        if (this.sname) {
          this.createprogress = true;
          this.$store.dispatch("CREATE_NEW_SETTINGS", {
            id: this.f.getId(),
            name: this.sname,
            action: "copysettings"
          });
        }
      }
    },
    saveSettings() {
      if (this.f.isModyfied()) {
        this.$store.dispatch("SAVE_SETTINGS", {
          id: this.f.getId(),
          settings: JSON.stringify(this.f.getSettingsObject()),
          action: "savesettings",
          common: 0
        });
      }
      if (this.s.isModyfied()) {
        this.$store.dispatch("SAVE_SETTINGS", {
          id: this.s.getId(),
          settings: JSON.stringify(this.s.getSettingsObject()),
          action: "savesettings",
          common: 1
        });
      }
      if (this.$refs.saveTooltip !== undefined) {
        this.$refs.saveTooltip.hide();
      }
    },
    restoreSettings() {
      this.$store.dispatch("LOAD_DEFAULT_SETTINGS", {
        fname: this.f.getFormatName(),
        action: "loaddefaultsettings"
      });
    },
    deleteFormat(fid) {
      this.$store.dispatch("DELETE_SETTINGS", {
        fid: fid,
        action: "deletesettings"
      });
    },
    showTip(imagesrc) {
      let tip = new Image();
      tip.onload = () => {
        this.showtip = true;
        this.tip_filename = "images/stips/" + imagesrc + ".png";
      };
      tip.src = "images/stips/" + imagesrc + ".png";
    },
    closeSettings() {
      this.unsaved = this.formats.filter(obj => obj.isModyfied());
      if (this.s.isModyfied()) {
        this.unsaved.push(this.s);
      }
      if (this.unsaved.length > 0) {
        this.closedialog = true;
      } else {
        this.psdialog = false;
      }
    },

    saveUnsaved() {
      for (let set of this.unsaved) {
        this.$store.dispatch("SAVE_SETTINGS", {
          id: set.getId(),
          settings: JSON.stringify(set.getSettingsObject()),
          action: "savesettings",
          common: set.isCommon() ? 1 : 0
        });
        this.closedialog = false;
        this.psdialog = false;
      }
    },
    noSaveUnsaved() {
      this.unsaved = [];
      this.closedialog = false;
      this.psdialog = false;
    },
    updateView() {
      this.$store.commit("UPDATE_CANVAS");
    },
    openTab(what) {
      this.$store.commit("OPEN_SETTINGS");
      if (what) {
        this.tabModel[what.tabName] = true;
      }
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === "ADD_NEW_FORMAT") {
        setTimeout(() => {
          this.pscreatedialog = false;
          this.createprogress = false;
          this.newformat = null;
          this.sname = null;
        }, 2000);
      }
    });
  },

  mounted() {
    this.$eventBus.$on("open-tab-settings", what => this.openTab(what));
  },

  beforeDestroy() {
    this.$eventBus.$off("open-tab-settings");
    this.unsubscribe();
  }
};
</script>

<style lang="sass">
.psdialogcloser
  width: 23px
.q-dialog__inner--minimized > div.scard
  max-width: 780px
.settingsscroll
  bottom: 0
  top: 95px
  left: 0
  right: 0
  position: absolute!important
  padding-right: 12px
  background: #477382
.settingsscroll .scroll
  background: #d2d2d2
.psdialog .q-dialog__inner
  padding: 0!important
.q-dialog__inner > div.psdcontent
  height: 100%
  box-shadow: none
  -webkit-box-shadow: none
  max-height: 100%!important
  width: 346px
  background: #345763
.psdialogcloser
  position: absolute!important
  left: 346px
  top: 3px
.psformatselector
  overflow: hidden
  margin-right: 12px
  background: #3e6e7d
.psselectpopup
  background: #f1ffff!important
  color: #1b1b1b!important
.psformatselector .q-field__control-container .q-field__native span
  text-overflow: ellipsis
  white-space: nowrap
  overflow: hidden
.psformatselector .q-field--auto-height.q-field--dense .q-field__control
  height: 32px
  min-height: 32px
.psformatselector .q-field--dense .q-field__marginal
  height: 32px
.psformatselector .q-field--auto-height.q-field--dense .q-field__control, .psformatselector .q-field--auto-height.q-field--dense .q-field__native
  min-height: 32px
.progress-outer
  height: 4px
.spreview
  margin: 0 auto
  display: block!important
.explist .q-expansion-item__content
  background: #e6e6e6
  font-weight: 400
.explist
  background: #cccccc
  color: #345763
  font-weight: 700
.explist .q-item
  padding: 16px 8px
.explist .q-separator.q-expansion-item__border.q-expansion-item__border--bottom
  display: none
.explist .q-item__section--avatar
  min-width: 40px
.fpropheader
  font-size: 12px
  padding-bottom: 8px
.psdialog .q-dialog__backdrop
  background: none
.compositecontrols
  border-bottom: 1px dashed #b3b0b0
  overflow: hidden
.sticonopen
  color: #345763
.previewbt
  font-size: 11px!important
  padding: 0 6px!important
  float: right
.q-expansion-item__content > .hcontrol.usprm:nth-child(1)
  padding-top: 4px
  padding-bottom: 12px
.q-expansion-item__content > .hcontrol.usprm:nth-last-child(1)
  padding-bottom: 12px
.tipimages img
  max-width: 100%
</style>
