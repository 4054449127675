import { api } from "src/boot/axios";
import Address from "src/service/classes/address.js";
import Vue from "vue";
export default {
  state: {
    recepients: [],
    activereceps: []
  },

  getters: {
    GET_RECEPIENTS: state => {
      return state.recepients;
    },
    GET_ACTIVERECEPS: state => {
      return state.activereceps;
    },
    GET_SEARCHTPL: state => {
      let i = 0;
      let searchtpl = "";
      for (let recep of state.recepients) {
        searchtpl +=
          '"where="' +
          recep.name +
          recep.zip +
          recep.comment +
          recep.comment2 +
          '"id="' +
          i++ +
          '">';
      }
      return searchtpl;
    },
    GET_ACTIVERECEP_OBJECT: state => {
      let recep = null;

      if (state.activereceps[0]) {
        recep = state.recepients.find(item => item.id == state.activereceps[0]);
      }
      return recep;
    }
  },
  mutations: {
    SET_RECEPIENTS(state, payload) {
      state.recepients = [];
      state.recepients = payload;
      payload = [];
    },
    SET_ACTIVE_RECEP_FOR_NEW_USERS(state) {
      if (state.recepients.length == 1) {
        state.activereceps[0] = state.recepients[0].id;
      }
    },
    CLEAR_RECEPIENTS(state) {
      state.recepients = [];
    },
    PUSH_RECEPIENT(state, payload) {
      state.recepients.push(new Address(payload));
    },
    PRINT_FROM_RECEP() {},
    SET_ACTIVE_RECEP(state, payload) {
      if (state.activereceps[0]) {
        let recep = state.recepients.find(
          item => item.id == state.activereceps[0]
        );
        let sel = null;
        if (recep.hasOwnProperty("selected")) {
          sel = recep.selected;
          recep.ClearPayload();
          recep.selected = sel;
        } else {
          recep.ClearPayload();
        }
      }
      state.activereceps = payload;
    },
    ADD_NEW_RECEP(state, payload) {
      let newrec = new Address(payload);
      newrec.contentclass = "fresh";
      state.recepients.unshift(newrec);
    },
    UPDATE_RECEP(state, payload) {
      let newrec = new Address(payload);
      let index = state.recepients.findIndex(
        item => item.id == state.activereceps[0]
      );

      Vue.set(newrec, "selected", state.recepients[index].selected);
      Vue.set(state.recepients, index, newrec);
    },
    DELETE_RECEP(state) {
      let index = state.recepients.findIndex(
        item => item.id == state.activereceps[0]
      );
      if (index >= 0) {
        state.recepients.splice(index, 1);
        state.activereceps = [];
      }
    },
    CLEAR_RECEPIENT_SELECTION_FORALL(state) {
      for (let i = 0, len = state.recepients.length; i < len; i++) {
        if (
          state.recepients[i].selected != undefined &&
          state.recepients[i].selected != false
        ) {
          state.recepients[i].selected = false;
        }
      }
    },
    MASS_SETGROUPS_SUCCESS() {},
    IMPORT_PART_SUCCESS() {},
    IMPORT_PART_FAILURE() {},
    CHANGE_GROUPS(state, payload) {
      for (let id of payload.ids) {
        let recep = state.recepients.find(item => item.id == id);
        if (recep) {
          recep.gids = payload.gids;
        }
      }
    },
    SET_PARAM_TO_ACTIVERECEP(state, payload) {
      if (state.activereceps[0]) {
        let recep = state.recepients.find(
          item => item.id == state.activereceps[0]
        );
        //recep[payload.field] = payload.val;
        Vue.set(recep, payload.field, payload.val);
      }
    },
    DELETE_RECEPS_FROM_ARRAY(state, payload) {
      state.recepients = state.recepients.filter(item => {
        return !payload.includes(item.id);
      });
      state.activereceps = [];
      this.commit("DELETE_RECEPS_FROM_ARRAY_FINISHED");
    },
    DELETE_RECEPS_FROM_ARRAY_FINISHED: () => {},
    UNSELECT_RECEP_BY_ID(state, payload) {
      let rec = state.recepients.find(item => item.id == payload);
      if (rec) {
        rec.ClearPayload();
      }
    }
  },
  actions: {
    LOAD_RECEPIENTS(context) {
      api
        .post("/api/recepient/", {
          action: "loadrecepients"
        })
        .then(async response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
            //context.commit("SET_ACCOUNT", response.data);
          } else {
            if (response.data.body.length == 0) return;
            context.commit("CLEAR_RECEPIENTS");
            let chunk = 1;
            let ptime = 30;
            let len = response.data.body.length;
            let progress = this._vm.$chunk.GetChunk(len);
            context.commit("START_PROGRESS", {
              count: len,
              title: "Загрузка получателей"
            });

            await new Promise(resolve => setTimeout(resolve, 500));
            let buffer = [];
            for (let i = 0; i < len; i++) {
              //context.commit("PUSH_RECEPIENT", response.data.body[i]);
              buffer.push(new Address(response.data.body[i]));
              if (i % progress.chunk == 0) {
                await new Promise(resolve =>
                  setTimeout(resolve, progress.ptime)
                );
                context.commit("STEP_PROGRESS", progress.chunk);
              }
            }

            context.commit("SET_RECEPIENTS", buffer);
            context.commit("SET_RECEPIENTS_LOADED");
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при загрузке получателей. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "LOAD_RECEPIENTS action"
          });
        });
    },
    CREATE_RECEPIENT(context, payload) {
      api
        .post("/api/recepient/", payload)
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            context.commit("ADD_NEW_RECEP", response.data.body.record);
            context.commit("SET_ACTIVE_RECEP", [response.data.body.record.id]);
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при создании получателя. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "CREATE_RECEPIENTS action"
          });
        });
    },
    UPDATE_RECEPIENT(context, payload) {
      api
        .post("/api/recepient/", payload)
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            context.commit("UPDATE_RECEP", response.data.body.record);
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при обновлении получателя. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "UPDATE_RECEPIENT action"
          });
        });
    },
    DELETE_RECEPIENT(context, payload) {
      api
        .post("/api/recepient/", payload)
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            context.commit("DELETE_RECEP");
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при удалении получателя. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "DELETE_RECEPIENT action"
          });
        });
    },
    MASS_DELETE_RECEPIENTS(context, payload) {
      api
        .post("/api/recepient/", payload)
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
            context.commit("DELETE_RECEPS_FROM_ARRAY_FINISHED");
          } else {
            context.commit(
              "DELETE_RECEPS_FROM_ARRAY",
              response.data.body.record.deleted_ids
            );
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при удалении нескольких получателей. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "MASS_DELETE_RECEPIENTS action"
          });
        });
    },
    MASS_SETGROUPS_RECEPIENTS(context, payload) {
      api
        .post("/api/recepient/", payload)
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            context.commit("MASS_SETGROUPS_SUCCESS");
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при попытке назначить группы. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "MASS_SETGROUPS_RECEPIENTS action"
          });
        });
    },
    IMPORT_PART(context, payload) {
      api
        .post("/api/recepient/", payload)
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
            context.commit("IMPORT_PART_FAILURE");
          } else {
            context.commit("IMPORT_PART_SUCCESS");
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при попытке импортировать получателей. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "IMPORT_PART action"
          });
          context.commit("IMPORT_PART_FAILURE");
        });
    }
  },
  modules: {}
};
