var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dispflex self-stretch"},[_c('q-btn',{attrs:{"stretch":"","flat":"","label":"Вход"},on:{"click":_vm.showSigninDialog}}),_c('q-dialog',{attrs:{"content-style":"background:rgba(0,0,0,0.5)"},model:{value:(_vm.showsignin),callback:function ($$v) {_vm.showsignin=$$v},expression:"showsignin"}},[_c('q-card',{staticClass:"formsignin q-pa-md"},[_c('q-card-section',{staticClass:"row q-py-xs"},[_c('div',{staticClass:"text-h6 q-mx-auto h6formheader"},[_vm._v("Вход")])]),_c('q-card-section',{staticClass:"q-mt-sm"},[_c('q-form',{attrs:{"autocomplete":"off"},on:{"submit":_vm.onSubmit}},[_c('q-input',{attrs:{"color":"purple-12","outlined":"","bg-color":"white","placeholder":"Логин","dense":"","required":"","lazy-rules":"","rules":[
              val => (val && val.length > 0) || 'Логин не указан',
              val =>
                !/[а-яА-ЯёЁ]/g.test(val) ||
                'Пожалуйста, не используйте русские буквы'
            ]},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"name":"account_circle"}})]},proxy:true}]),model:{value:(_vm.login),callback:function ($$v) {_vm.login=$$v},expression:"login"}}),_c('q-input',{staticClass:"q-mb-lg",attrs:{"outlined":"","bg-color":"white","type":_vm.isPwd ? 'password' : 'text',"placeholder":"Пароль","dense":"","required":"","lazy-rules":"","rules":[
              val => (val && val.length > 0) || 'Пароль не указан',
              val =>
                !/[а-яА-ЯёЁ]/g.test(val) ||
                'Пожалуйста, не используйте русские буквы'
            ]},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('q-icon',{attrs:{"name":"lock"}})]},proxy:true},{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",attrs:{"name":_vm.isPwd ? 'visibility_off' : 'visibility'},on:{"click":function($event){_vm.isPwd = !_vm.isPwd}}})]},proxy:true}]),model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!(_vm.accepting || _vm.$q.cookies.has('cookieaccepted'))),expression:"!(accepting || $q.cookies.has('cookieaccepted'))"}],staticClass:"q-mt-md",staticStyle:{"line-height":"125%"},on:{"click":_vm.acceptCookie}},[_c('a',{staticClass:"col text-white",staticStyle:{"text-decoration":"none"},attrs:{"href":"https://777.xn--l1adcnfw.xn--p1ai/api/authcookie/","target":"_blank"}},[_c('q-icon',{staticClass:"col-auto q-mr-sm q-mb-lg float-left",attrs:{"name":!(_vm.accepting || _vm.$q.cookies.has('cookieaccepted'))
                    ? 'check_box_outline_blank'
                    : 'check_box',"size":"md"}})],1),_vm._v("\n            Согласие с политикой конфиденциальности\n          ")]),_c('q-btn',{staticClass:"signinbtn full-width",attrs:{"disable":!(_vm.accepting || _vm.$q.cookies.has('cookieaccepted')),"loading":_vm.loginprogress,"type":"submit","unelevated":""}},[_vm._v("Войти")]),_c('q-separator',{staticClass:"sep1 q-mt-lg"}),_c('q-separator',{staticClass:"sep2"}),_c('q-btn',{staticClass:"q-mt-sm full-width text-center fs12",attrs:{"router-link":"","to":"/recovery/","label":"Забыли пароль? Нажмите сюда","no-caps":"","flat":""}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }