import { api } from "src/boot/axios";

export default {
  state: {
    senders_loaded: false,
    recepients_loaded: false,
    groups_loaded: false,
    settings_loaded: false,

    user: {
      account: {
        usertype: null,
        bugfix: null,
        email: "",
        login: "",
        password: "",
        balance: "",
        delaypayment: 0,
        delaypeople: 0,
        token: "",
        logo: "",
        created: null,

        req: {
          companyname: "",
          inn: "",
          kpp: "",
          address: "",
          postaddress: "",
          rs: "",
          ks: "",
          bic: "",
          bankname: "",
          companytype: "company"
        }
      },
      bills: [],
      qr: null
    },
    showsignin: false,
    signupautostate: "empty"
  },

  getters: {
    INITIALIZED: state => {
      return (
        state.senders_loaded &&
        state.recepients_loaded &&
        state.groups_loaded &&
        state.settings_loaded
      );
    },
    IS_RECEPIENTS_LOADED: state => {
      return state.recepients_loaded;
    },
    GET_SIGNUPAUTOSTATE: state => {
      return state.signupautostate;
    },
    LOGO: state => {
      return state.user.account.logo;
    },
    BUGFIX: state => {
      return state.user.account.bugfix;
    },
    SIGNUPAUTO_STATE: state => {
      return state.signupautostate;
    },
    ACCOUNT: state => {
      return state.user.account;
    },
    ACCOUNT_CREATED: state => {
      return state.user.account.created;
    },
    REQ: state => {
      return state.user.account.req;
    },
    BALANCE: state => {
      return state.user.account.balance;
    },
    BILLS: state => {
      return state.user.bills;
    },
    SIGNINWND: state => {
      return state.showsignin;
    },
    FINISHED: state => {
      return state.user.account.usertype == "finished";
    },
    NEWUSER: state => {
      return state.user.account.usertype == "new";
    },
    ACTIVEUSER: state => {
      return state.user.account.usertype == "active";
    },
    UNDEFINEDUSER: state => {
      return state.user.account.usertype == "undefined";
    },
    USERTYPE: state => {
      return state.user.account.usertype;
    },
    QR: state => {
      return state.user.qr;
    },
    DETAILS: state => {
      return {
        email: state.user.account.email,
        login: state.user.account.login,
        password: state.user.account.password
      };
    },
    ALLOWREGISTRATION: state => {
      return (
        state.user.account.usertype == "new" ||
        state.user.account.usertype == "undefined"
      );
    },
    ISCOMMERCIAL: state => {
      let bills = state.user.bills;
      if (bills.length > 0) {
        if (bills[0].billpayed == "1") {
          return true;
        }
      }
      return false;
    },
    DELAYPAYMENT: state => {
      return state.user.account.delaypayment;
    },
    DELAYPEOPLE: state => {
      return state.user.account.delaypeople;
    }
  },
  mutations: {
    //Metrika GOALS
    BILL_PRINTED: () => {},
    BILL_CREATED: () => {},
    TOO_MANY_ACCOUNTS: () => {},
    /////////////////
    SET_SENDERS_LOADED: state => {
      state.senders_loaded = true;
    },
    SET_RECEPIENTS_LOADED: state => {
      state.recepients_loaded = true;
    },
    SET_SETTINGS_LOADED: state => {
      state.settings_loaded = true;
    },
    SET_GROUPS_LOADED: state => {
      state.groups_loaded = true;
    },
    SET_BUGFIX(state, payload) {
      state.user.account.bugfix = payload;
    },
    SET_ACCOUNT: function(state, payload) {
      if (payload.error) {
        state.user.account.usertype = "undefined";
      } else {
        state.user.account = payload.body;
        if (
          payload.body.usertype != "new" &&
          payload.body.usertype != "undefined"
        ) {
          this.dispatch("GET_BILLS");
          this.commit("SET_SIGNINWND", false);
        }
      }
    },
    INITIALIZE_ACCOUNT: function(state, payload) {
      this.dispatch("GEN_MARK");
      if (payload.error) {
        if (
          (this.$router.currentRoute.name == "recovery" ||
            this.$router.currentRoute.name == "signup") &&
          payload.errortext.includes("У вас уже есть созданный ранее аккаунт")
        ) {
        } else {
          this.commit("SET_ERROR", {
            error: payload.error,
            errortext: payload.errortext
          });
        }
        state.user.account.usertype = "undefined";
        this.dispatch("LOAD_DEFAULT_PRINT_SETTINGS");
      } else {
        state.user.account = payload.body;
        if (
          payload.body.usertype != "new" &&
          payload.body.usertype != "undefined"
        ) {
          this.dispatch("GET_BILLS");
          this.dispatch("SEND_MARK_SERVER");
          this.commit("SET_SIGNINWND", false);
          if (payload.body.usertype == "active") {
            this.dispatch("LOAD_SENDERS");
            this.dispatch("LOAD_GROUPS");
            this.dispatch("LOAD_RECEPIENTS");
            this.dispatch("LOAD_SETTINGS");
          }
        } else if (
          payload.body.usertype == "new" ||
          payload.body.usertype == "undefined"
        ) {
          this.dispatch("LOAD_DEFAULT_PRINT_SETTINGS");
        }
      }
    },
    SET_BILLS: (state, payload) => {
      state.user.bills = payload.body.bills;
    },
    SET_SIGNUPAUTO_USERDATA: (state, payload) => {
      state.user.account.login = payload.login;
      state.user.account.password = payload.password;
    },
    SET_SIGNUPAUTO_STATE: (state, payload) => {
      state.signupautostate = payload;
    },
    EMAIL_DONE: () => {},
    SET_SIGNINWND: (state, payload) => {
      state.showsignin = payload;
    },
    SET_QR: (state, payload) => {
      state.user.qr = payload;
    },
    SHOW_DIALOG_FREE_DAYS_ADDED: () => {},
    SIGNIN: () => {},
    SIGNUP_LIVE: () => {},
    SIGNUP_AUTO: () => {},
    HASMANYACCOUNTS: () => {},

    SHOW_DIALOG_FREE_DAYS_QR_ADDED: () => {},
    SET_COOKIE(state, payload) {
      /*  this._vm.$q.cookies.set("token", payload.token, {
        expires: "365d",
        sameSite: "none",
        secure: true,
        path: "/"
      });
      this._vm.$q.cookies.set("series", payload.series, {
        expires: "365d",
        sameSite: "none",
        secure: true,
        path: "/"
      }); */
    },
    SET_COOKIE_LOGOUT() {
      this._vm.$q.cookies.set("token", "logout", {
        expires: "-1h",
        sameSite: "None",
        secure: true,
        path: "/"
      });
    }
  },
  actions: {
    GET_ACCOUNT: context => {
      api
        .post("/api/auth/")
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          }
          /* if (typeof response.data.body.token !== "undefined") {
            context.commit("SET_COOKIE", {
              token: response.data.body.token,
              series: response.data.body.series
            });
          } */
          context.commit("SET_ACCOUNT", response.data);
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при получении информации об аккаунте. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "GET_ACCOUNT action"
          });
        });
    },

    INITIALIZE: context => {
      api
        .post("/api/auth/")
        .then(response => {
          /* if (typeof response.data.body.token !== "undefined") {
            context.commit("SET_COOKIE", {
              token: response.data.body.token,
              series: response.data.body.series
            });
          } */
          context.commit("INITIALIZE_ACCOUNT", response.data);
        })
        .catch(error => {
          /* context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при попытке авторизации. Попробуйте выполнить действие позже. Информация передана разработчикам.."
          }); */
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "INITIALIZE action"
          });
        });
    },

    SIGNIN: async (context, payload) => {
      api
        .post("/api/signin/", payload)
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
            //context.commit("SET_ACCOUNT", response.data);
          } else {
            /* context.commit("SET_COOKIE", {
              token: response.data.body.token,
              series: response.data.body.series
            }); */
            context.commit("SIGNIN");
            context.dispatch("INITIALIZE");
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при попытке войти в аккаунт. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "GET_QR action"
          });
        });
    },

    SIGNUPLIVE: async (context, payload) => {
      api
        .post("/api/signup/", payload)
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
            if (response.data.body.hasmanyaccounts !== undefined) {
              context.commit("HASMANYACCOUNTS");
            }
          } else {
            context.commit("SIGNUP_LIVE");
            /* context.commit("SET_COOKIE", {
              token: response.data.body.token,
              series: response.data.body.series
            }); */
            let l = "/";
            window.location = l;
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при попытке регистрации пользователя. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "GET_QR action"
          });
        });
    },

    SIGNUPAUTO: (context, payload) => {
      api
        .post("/api/signup/", payload)
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
            if (response.data.body.hasmanyaccounts !== undefined) {
              context.commit("HASMANYACCOUNTS");
            }
            context.commit("SET_SIGNUPAUTO_STATE", "error");
          } else {
            /* context.commit("SET_COOKIE", {
              token: response.data.body.token,
              series: response.data.body.series
            }); */
            context.commit("SIGNUP_AUTO");
            setTimeout(() => {
              context.commit("SET_SIGNUPAUTO_USERDATA", response.data.body);
              context.commit("SET_SIGNUPAUTO_STATE", "success");
            }, 2000);
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при попытке регистрации пользователя. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "GET_QR action"
          });
        });
    },

    LOGOUT: async context => {
      await api
        .post("/api/logout/")
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            context.commit("SET_COOKIE_LOGOUT");
            let l = "/";
            window.location = l;
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Невозможно выйти из аккаунта. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "LOGOUT action"
          });
        });
    },

    GET_BILLS: async context => {
      await api
        .post("/api/bills/", {
          action: "getbills"
        })
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            context.commit("SET_BILLS", response.data);
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Невозможно получить список счетов. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "GET_BILLS action"
          });
        });
    },

    CREATE_BILL: async context => {
      await api
        .post("/api/bills/", {
          action: "createbill"
        })
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            if (response.data.body.needupdate) {
              context.commit("SHOW_DIALOG_FREE_DAYS_ADDED");
            }
            context.dispatch("GET_BILLS");
            context.commit("BILL_CREATED");
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при создании счета. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "CREATE_BILL action"
          });
        });
    },

    GET_FREE_DAYS_FOR_QR: async context => {
      await api
        .post("/api/bills/", {
          action: "addfreedaysfromqr"
        })
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            if (response.data.body.needupdate) {
              context.commit("SHOW_DIALOG_FREE_DAYS_QR_ADDED");
            }
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при попытке продлить лицензию. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "GET_FREE_DAYS_FOR_QR action"
          });
        });
    },

    DELETE_BILL: async context => {
      await api
        .post("/api/bills/", {
          action: "deletebill"
        })
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            context.dispatch("GET_BILLS");
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошбика при удалении счета. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "DELETE_BILLS action"
          });
        });
    },

    CHANGE_REQ: async (context, payload) => {
      api
        .post("/api/req/", payload)
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            context.dispatch("GET_ACCOUNT");
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка сервера при попытке изменить учетные данные. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "CHANGE_USER_SETTINGS action"
          });
        });
    },

    CHANGE_USER_SETTINGS: async (context, payload) => {
      api
        .post("/api/userdetails/", payload)
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            context.dispatch("GET_ACCOUNT");
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка сервера при попытке изменить учетные данные. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "CHANGE_USER_SETTINGS action"
          });
        });
    },
    CHANGE_USER_SETTINGS_FROM_AUTOSIGNUP: async (context, payload) => {
      api
        .post("/api/userdetails/", payload)
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            context.commit("EMAIL_DONE");
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка сервера при попытке изменить учетные данные. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "CHANGE_USER_SETTINGS action"
          });
        });
    },

    RESET_PASSWORD: async (context, payload) => {
      let { data } = await api.post("/api/recovery/", payload);
      if (data.error) {
        context.commit("SET_ERROR", {
          error: data.error,
          errortext: data.errortext
        });
      } else if (data.body !== null && typeof data.body.series !== undefined) {
        /* context.commit("SET_COOKIE", {
          token: data.body.token,
          series: data.body.series
        }); */
      }
      return data;
    },

    PRINT_DOC: async (context, payload) => {
      let { data } = await api.post("/api/documents/", payload);
      if (data.error) {
        context.commit("SET_ERROR", {
          error: data.error,
          errortext: data.errortext
        });
      }
      context.commit("BILL_PRINTED");
      return data;
    },
    SEND_REFERER_INFO: (context, payload) => {
      api.post("/api/referer/", payload);
    },
    GET_QR: context => {
      api
        .post("/api/qr/")
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            context.commit("SET_QR", response.data.body);
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Произошла ошибка на сервере. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "GET_QR action"
          });
        });
    }
  },
  modules: {}
};
