import { api } from "src/boot/axios";

export default {
  state: {
    error: null,
    errortext: ""
  },
  mutations: {
    SET_ERROR(state, payload) {
      state.error = payload.error;
      state.errortext = payload.errortext;
    },
    CLEAN_ERROR(state) {
      state.error = null;
      state.errortext = "";
    }
  },
  actions: {
    LOG_ERROR: (context, payload) => {
      /* api.post("/api/log/", {
        message: payload.error.message,
        stack: payload.error.stack,
        desc: payload.desc
      }); */
      //payload.error.message = payload.desc + " : " + payload.error.message;
      //throw payload.error;
    }
  },
  getters: {
    GET_ERROR: state => state.error,
    GET_ERRORTEXT: state => state.errortext
  }
};
