<template>
  <div class="bg-primary-darken cvblock q-mx-md q-mt-md q-mb-sm">
    <q-card class="no-border no-border-radius fit bg-dark no-shadow">
      <q-tabs
        v-model="currentPrintFunction.name"
        dense
        no-caps
        align="left"
        class="text-grey-5 lptabs"
        active-color="white"
        indicator-color="accent"
        active-bg-color="primary"
        narrow-indicator
      >
        <q-tab
          name="Envelope"
          label="Конверт"
          @click="selectPrintFunctionByIndex(0)"
        />
        <q-tab
          name="Invoice"
          label="Уведомление"
          @click="selectPrintFunctionByIndex(1)"
        />
        <q-tab
          name="Inventory"
          label="Опись вложений"
          @click="selectPrintFunctionByIndex(2)"
        />
      </q-tabs>

      <q-tab-panels v-model="currentPrintFunction.name" animated class="">
        <q-tab-panel
          name="Envelope"
          class=" q-ma-none q-px-md q-pt-md q-pb-sm bg-primary"
        >
          <div class="row q-mb-md">
            <div class="col-auto q-mr-sm">
              <q-btn-dropdown
                v-if="f !== undefined"
                color="primary-light"
                class="canvaformselector text-left"
                content-class="fheightdrop"
                unelevated
                dense
                no-caps
              >
                <template v-slot:label>
                  <q-btn
                    flat
                    left
                    dense
                    icon="settings"
                    size="sm"
                    padding="none"
                    color="secondary"
                    @click.stop="openSettings"
                  >
                    <q-tooltip
                      transition-show="flip-right"
                      transition-hide="flip-left"
                      content-class="bg-accent fs14px"
                      >Настройки печати</q-tooltip
                    >
                  </q-btn>
                  <span>Тип конверта: {{ curformat.label }}</span>
                </template>
                <div class="row no-wrap q-pa-md pagradient">
                  <div class="column">
                    <q-list dense>
                      <q-item
                        v-for="(item, index) in formatlist"
                        :key="index"
                        clickable
                        v-close-popup
                        @click="
                          curformat = item.value;
                          updateCommonSettings();
                        "
                        active-class="selected-frm"
                        :active="curformat.value == item.value"
                        class="fflist"
                      >
                        <q-item-section>
                          <q-item-label class="cuttedlabel">
                            {{ item.label }}
                          </q-item-label>
                        </q-item-section>
                        <q-item-section side>
                          <div class="text-grey-8 q-gutter-xs">
                            <q-btn
                              flat
                              dense
                              round
                              icon="edit"
                              size="sm"
                              color="white"
                              @click="openSettings"
                            />
                          </div>
                        </q-item-section>
                      </q-item>
                    </q-list>
                    <a
                      class="addformatbtn"
                      href="/support/envelope/create-format/"
                      target="_blank"
                      >Создать свой макет</a
                    >
                  </div>

                  <q-separator
                    v-show="false"
                    vertical
                    inset
                    class="q-mx-md whitesep"
                  />

                  <div v-show="false" class="column items-left">
                    <div class="text-h6 q-mb-sm q-pl-sm">Способ печати</div>
                    <OutputFormat
                      :useholst="f.list.useholst"
                      :posnum="f.list.posnum"
                      :minimal="true"
                    />
                  </div>
                </div>
              </q-btn-dropdown>
            </div>
            <div class="col-auto q-mr-sm">
              <q-btn
                icon="print"
                color="accent"
                label="Печать"
                class="q-pr-sm q-pl-xs"
                unelevated
                dense
                no-caps
                @click="printEnvelope"
              />
            </div>
            <div class="col-auto">
              <q-btn
                icon="save"
                color="accent"
                unelevated
                dense
                no-caps
                @click="savePDF"
              >
                <q-tooltip
                  anchor="center right"
                  content-class="bg-accent fs14px"
                  self="center left"
                  :offset="[10, 10]"
                  >Сохранить в PDF</q-tooltip
                >
              </q-btn>
            </div>
            <div class="col-auto self-end">
              <q-toggle
                v-model="receptosender"
                label="Поменять отправителя c получателем"
                dark
                color="green-13"
                size="xs"
                class="poschanger"
                @input="setRecepToSender"
              />
            </div>
          </div>
          <q-separator class="pseptop" />
          <q-separator class="psepbot" />
          <div class="row q-mt-sm">
            <div class="col-auto ">
              <q-btn
                :label="'Указать категорию'"
                flat
                no-caps
                dense
                size="13px"
                class="q-mr-sm nopl"
                :color="
                  currecep !== null &&
                  currecep.posttype !== null &&
                  currecep.postcategory !== null
                    ? 'green-13'
                    : 'grey-6'
                "
                :icon="
                  currecep !== null &&
                  currecep.posttype !== null &&
                  currecep.postcategory !== null
                    ? 'check_box'
                    : 'check_box_outline_blank'
                "
                @click="openPostTypeDialog"
              />
            </div>
            <div class="col-auto ">
              <q-btn
                :label="'Добавить пометку'"
                flat
                no-caps
                dense
                size="13px"
                class="q-mr-sm"
                :color="
                  currecep !== null && currecep.tip !== ''
                    ? 'green-13'
                    : 'grey-6'
                "
                :icon="
                  currecep !== null && currecep.tip !== ''
                    ? 'check_box'
                    : 'check_box_outline_blank'
                "
                @click="openTipDialog"
              />
            </div>
            <div class="col-auto ">
              <q-btn
                :label="'Штрих-код ШПИ'"
                flat
                no-caps
                dense
                size="13px"
                class="q-mr-sm"
                @click="openBarCodeDialog"
                :color="
                  currecep !== null && currecep.tip !== ''
                    ? 'green-13'
                    : 'grey-6'
                "
                :icon="
                  currecep !== null && currecep.barcode !== null
                    ? 'check_box'
                    : 'check_box_outline_blank'
                "
              />
            </div>
            <div class="col">
              <div
                class="howmanymarks float-right"
                @click="priceDialog = !priceDialog"
              >
                Сколько клеить марок?
              </div>
            </div>
          </div>
        </q-tab-panel>

        <q-tab-panel
          name="Invoice"
          class=" q-ma-none q-px-md q-pt-md q-pb-sm bg-primary"
        >
          <div class="row q-mb-md">
            <div class="col-auto">
              <q-btn
                no-caps
                dense
                color="accent"
                icon="print"
                label="Лицевая сторона"
                class="q-px-xs q-mr-sm"
                unelevated
                @click="printInvoice('front')"
              />
              <q-btn
                class="q-px-xs  q-mr-sm"
                color="accent"
                no-caps
                dense
                unelevated
                icon="print"
                label="Оборотная"
                @click="printInvoice('back')"
              />
              <q-btn
                color="accent"
                class="q-px-xs q-mr-sm"
                no-caps
                unelevated
                dense
                label="Двусторонняя печать"
                @click="printInvoice('all')"
              />

              <q-btn
                icon="save"
                color="accent"
                unelevated
                dense
                no-caps
                @click="savePDF"
              >
                <q-tooltip
                  anchor="center right"
                  content-class="bg-accent fs14px"
                  self="center left"
                  :offset="[10, 10]"
                  >Сохранить в PDF</q-tooltip
                >
              </q-btn>
            </div>
          </div>
          <q-separator class="pseptop" />
          <q-separator class="psepbot" />
          <div class="row q-mt-sm">
            <div class="col-auto">
              <q-btn
                :label="'Указать категорию отправления и тип уведомления'"
                flat
                no-caps
                dense
                size="13px"
                class="q-mr-sm nopl"
                :color="
                  currecep !== null &&
                  currecep.posttype !== null &&
                  currecep.postcategory !== null
                    ? 'green-13'
                    : 'grey-6'
                "
                :icon="
                  currecep !== null &&
                  currecep.posttype !== null &&
                  currecep.postcategory !== null
                    ? 'check_box'
                    : 'check_box_outline_blank'
                "
                @click="openPostTypeDialog"
              />
            </div>
            <div class="col-auto">
              <q-btn
                :label="'Штрих-код ШПИ'"
                flat
                no-caps
                dense
                size="13px"
                class="q-mr-sm"
                @click="openBarCodeDialog"
                :color="
                  currecep !== null && currecep.tip !== ''
                    ? 'green-13'
                    : 'grey-6'
                "
                :icon="
                  currecep !== null && currecep.barcode !== null
                    ? 'check_box'
                    : 'check_box_outline_blank'
                "
              />
            </div>
          </div>
        </q-tab-panel>

        <q-tab-panel
          name="Inventory"
          class=" q-ma-none q-px-md q-pt-md q-pb-sm bg-primary"
        >
          <div class="row q-mb-md">
            <div class="col-auto">
              <q-btn
                icon="print"
                color="accent"
                label="Печать"
                unelevated
                class="q-pl-xs q-pr-sm q-mr-sm"
                dense
                no-caps
                @click="printInventory"
              />
              <q-btn
                icon="save"
                color="accent"
                unelevated
                dense
                no-caps
                @click="savePDF"
              >
                <q-tooltip
                  anchor="center right"
                  content-class="bg-accent fs14px"
                  self="center left"
                  :offset="[10, 10]"
                  >Сохранить в PDF</q-tooltip
                >
              </q-btn>
            </div>
          </div>
          <q-separator class="pseptop" />
          <q-separator class="psepbot" />
          <div class="row q-mt-sm">
            <div class="col-auto">
              <q-btn
                label="Указать тип отправления"
                flat
                no-caps
                dense
                size="13px"
                class="q-mr-sm nopl"
                :color="
                  currecep !== null &&
                  currecep.posttype !== null &&
                  currecep.postcategory !== null
                    ? 'green-13'
                    : 'grey-6'
                "
                :icon="
                  currecep !== null &&
                  currecep.posttype !== null &&
                  currecep.postcategory !== null
                    ? 'check_box'
                    : 'check_box_outline_blank'
                "
                @click="openPostTypeDialog"
              />
            </div>
            <div class="col-auto">
              <q-btn
                :label="'Заполнить табличную часть описи'"
                flat
                no-caps
                dense
                size="13px"
                class="q-mr-sm"
                :color="
                  currecep !== null &&
                  (currecep.cost > 0 || currecep.inventorytotalcost > 0)
                    ? 'green-13'
                    : 'grey-5'
                "
                :icon="
                  currecep !== null &&
                  (currecep.cost > 0 || currecep.inventorytotalcost > 0)
                    ? 'check_box'
                    : 'check_box_outline_blank'
                "
                @click="openInventoryDialog"
              />
            </div>
            <div class="col-auto">
              <q-btn
                :label="'Штрих-код ШПИ'"
                flat
                no-caps
                dense
                size="13px"
                class="q-mr-sm"
                @click="openBarCodeDialog"
                :color="
                  currecep !== null && currecep.tip !== ''
                    ? 'green-13'
                    : 'grey-6'
                "
                :icon="
                  currecep !== null && currecep.barcode !== null
                    ? 'check_box'
                    : 'check_box_outline_blank'
                "
              />
            </div>
          </div>
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
    <q-separator class="pseptopt" />

    <q-btn-toggle
      v-show="currentPrintFunction.name == 'Invoice'"
      class="sidechanger"
      content-class="q-px-sm"
      v-model="invoiceSide"
      color="primary"
      text-color="white"
      toggle-color="secondary"
      toggle-text-color="white"
      no-caps
      dense
      :options="[
        { label: 'Лицевая', value: 1 },
        { label: 'Оборотная', value: 2 }
      ]"
    />
    <q-btn-toggle
      v-show="
        currentPrintFunction.name == 'Inventory' &&
          currecep !== null &&
          container.doc.internal.getNumberOfPages() > 1
      "
      class="pagechanger"
      content-class="q-px-sm"
      v-model="inventoryPage"
      color="primary"
      text-color="white"
      toggle-color="secondary"
      toggle-text-color="white"
      no-caps
      dense
      :options="[
        { label: 'Страница 1', value: 1 },
        { label: 'Страница 2', value: 2 }
      ]"
    />

    <div id="pdfCanvasOuter" class="pdfcanvasouter q-mx-md q-py-md">
      <div class="pdfcanvasloader">
        <q-linear-progress
          v-show="canvasUpdateProgress"
          indeterminate
          color="accent"
          size="10px"
        />
      </div>
      <div class="lslouter">
        <LogoSizeSlider
          v-if="
            currentPrintFunction.name == 'Envelope' &&
              activeUser &&
              typeof f !== 'undefined' &&
              f.list.printLogo &&
              cursender.logo64 !== null
          "
          :val="f.list.logoSize"
        />
      </div>
      <div>
        <div></div>
        <div></div>
      </div>
      <canvas id="pdfCanvas"></canvas>
    </div>

    <PostTypeDialog :show.sync="postTypeDialog" :openedRecep.sync="currecep" />
    <BarCodeDialog :show.sync="barCodeDialog" :openedRecep="currecep" />
    <InventoryDialog :show.sync="inventoryDialog" :openedRecep="currecep" />
    <TipDialog :show.sync="tipDialog" :openedRecep="currecep" />
    <FirstPrintDialog :show.sync="firstPrintDialog" @print="printEnvelope" />
    <PostPriceDialog
      v-if="f !== undefined"
      :show.sync="priceDialog"
      :envelopeFormat="f.list.fname"
    />
  </div>
</template>

<script>
import CommonTemplate from "src/service/classes/printCommon.js";
import EnvelopeTemplate from "src/service/classes/printEnvelope.js";
import InvoiceTemplate from "src/service/classes/printInvoice.js";
import InventoryTemplate from "src/service/classes/printInventory.js";
import Address from "src/service/classes/address.js";
import Sender from "src/service/classes/sender.js";
import OutputFormat from "src/service/components/controls/OutputFormat.vue";
import PostTypeDialog from "src/service/components/dialogs/PostTypeDialog";
import InventoryDialog from "src/service/components/dialogs/InventoryDialog.vue";
import BarCodeDialog from "src/service/components/dialogs/BarCodeDialog.vue";
import TipDialog from "src/service/components/dialogs/TipDialog.vue";
import FirstPrintDialog from "src/service/components/dialogs/FirstPrintDialog.vue";
import LogoSizeSlider from "src/service/components/controls/LogoSizeSlider.vue";
import PostPriceDialog from "src/service/components/dialogs/PostPriceDialog.vue";
import { debounce } from "quasar";
export default {
  components: {
    OutputFormat,
    PostTypeDialog,
    InventoryDialog,
    BarCodeDialog,
    TipDialog,
    FirstPrintDialog,
    LogoSizeSlider,
    PostPriceDialog
  },
  data() {
    return {
      tabBlanks: "envelope",
      tab: "recepients",
      invoiceSide: 1,
      inventoryPage: 1,
      priceDialog: false,
      printfunctions: [
        {
          name: "Envelope",
          btnText: "Конверт"
        },
        {
          name: "Invoice",
          btnText: "Уведомление"
        },
        {
          name: "Inventory",
          btnText: "Опись вложений"
        }
      ],
      currentPrintFunction: {
        name: "Envelope",
        btnText: "Конверт"
      },
      invoiceDialog: false,
      inventoryDialog: false,
      barCodeDialog: false,
      postTypeDialog: false,
      tipDialog: false,
      rendering: false,
      firstPrintDialog: false,
      canvasUpdateProgress: false
    };
  },
  computed: {
    allreceps() {
      return this.$store.getters.GET_RECEPIENTS;
    },
    activeUser() {
      return this.$store.getters.ACTIVEUSER;
    },
    initialized() {
      return this.$store.getters.INITIALIZED;
    },
    browser() {
      return {
        name: this.$q.platform.is.name,
        version: this.$q.platform.is.versionNumber
      };
    },
    needregister() {
      return this.$store.getters.ALLOWREGISTRATION;
    },
    formatlist() {
      return this.$store.getters.FORMAT_LIST;
    },
    curfid() {
      return this.$store.getters.CURFORMAT;
    },
    curformat: {
      get: function() {
        return this.formatlist.filter(pair => pair.value == this.curfid)[0];
      },
      set: function(newval) {
        this.$store.commit("SET_FORMAT", newval);
      }
    },
    f() {
      return this.$store.getters.CURFORMAT_OBJECT;
    },
    s() {
      return this.$store.getters.SETTINGS_OBJECT;
    },
    container: {
      cache: false,
      get() {
        let obj = new CommonTemplate();
        if (typeof this.f != "undefined") {
          if (this.currentPrintFunction.name == "Envelope") {
            obj = new EnvelopeTemplate(this.f, this.s, false);
            obj.add(this.cursender, this.currecep, 0);
          } else if (this.currentPrintFunction.name == "Invoice") {
            obj = new InvoiceTemplate(this.s);
            obj.add(this.cursender, this.currecep, 0);
          } else if (this.currentPrintFunction.name == "Inventory") {
            obj = new InventoryTemplate(this.s);
            obj.add(this.cursender, this.currecep, 0);
          }
        }
        return obj;
      },
      set(val) {
        this.conainer = val;
      }
    },
    cursender() {
      return this.$store.getters.GET_CURSENDER || new Sender({});
    },
    /* currecep() {
      if (this.needregister) {
        return new Address();
      } else {
        return this.$store.getters.GET_RECEPIENTS.filter(
          item => item.id == this.$store.getters.GET_ACTIVERECEPS[0]
        )[0];
      }}, */
    currecep() {
      return this.$store.getters.GET_ACTIVERECEP_OBJECT;
    },

    receptosender: {
      get() {
        return this.currecep !== null ? this.currecep.receptosender : false;
      },
      set(value) {
        this.$store.commit("SET_PARAM_TO_ACTIVERECEP", {
          field: "receptosender",
          val: value
        });
      }
    },
    rt() {
      return this.$route.name;
    },
    accountCreatedDate() {
      return this.$store.getters.ACCOUNT_CREATED;
    },
    dateNow() {
      let d = new Date();
      return (
        d.getFullYear() +
        "-" +
        (d.getMonth() + 1 > 9 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1)) +
        "-" +
        d.getDate()
      );
    }
  },

  watch: {
    currecep() {
      if (this.initialized) {
        this.updateView();
      }
    },
    cursender() {
      if (this.initialized) {
        this.updateView();
      }
    },
    curformat() {
      this.updateView();
    },
    invoiceSide() {
      this.updateView();
    },
    inventoryPage() {
      this.updateView();
    },
    initialized: function(ov, nv) {
      if (nv) {
        this.updateView();
      }
    }
  },

  methods: {
    setRecepToSender() {
      if (this.isRegistered() && this.isRecepientSelected()) {
        this.currentPrintFunction = this.printfunctions.find(
          item => item.name == "Envelope"
        );
        this.updateView();
      }
    },
    openBarCodeDialog() {
      if (this.isRegistered() && this.isRecepientSelected()) {
        this.currentPrintFunction = this.printfunctions.find(
          item => item.name == "Envelope"
        );
        this.updateView();
        this.barCodeDialog = true;
      }
    },
    openTipDialog() {
      if (this.isRegistered() && this.isRecepientSelected()) {
        this.currentPrintFunction = this.printfunctions.find(
          item => item.name == "Envelope"
        );
        this.updateView();
        this.tipDialog = true;
      }
    },
    openPostTypeDialog() {
      if (this.isRegistered() && this.isRecepientSelected()) {
        /* this.currentPrintFunction = this.printfunctions.find(
          item => item.name == "Invoice"
        ); */
        this.updateView();
        this.postTypeDialog = true;
      }
    },
    openInventoryDialog() {
      if (this.isRegistered() && this.isRecepientSelected()) {
        if (this.currecep.isCosted()) {
          this.currentPrintFunction = this.printfunctions.find(
            item => item.name == "Inventory"
          );
          this.updateView();
          this.inventoryDialog = true;
        } else {
          this.$store.commit("SET_ERROR", {
            error: true,
            errortext:
              'Для заполнениня описи выберите <br/>"Тип отправления" - ЦЕННОЕ'
          });
        }
      }
    },
    updateCommonSettings() {
      if (this.activeUser) {
        this.$store.dispatch("SAVE_SETTINGS", {
          id: this.s.getId(),
          settings: JSON.stringify(this.s.getSettingsObject()),
          action: "savesettings",
          common: 1
        });
      }
    },
    printEnvelope() {
      if (!this.$q.cookies.get("firstprint") && this.activeUser) {
        if (this.accountCreatedDate == this.dateNow) {
          this.$q.cookies.set("firstprint", "1", {
            expires: "5y",
            path: "/"
          });
          this.firstPrintDialog = true;
          return;
        }
      }

      this.container.print(this.browser);
      this.$store.commit("SINGLEPRINT_ENVELOPE");
    },
    printInventory() {
      if (this.currecep == null) {
        this.container.print(this.browser);
        this.$store.commit("SINGLEPRINT_INVENTORY");
      } else {
        if (this.currecep.isCosted()) {
          if (this.currecep.inventorytotalcost > 0) {
            this.container.print(this.browser);
            this.$store.commit("SINGLEPRINT_INVENTORY");
          } else {
            this.$store.commit("SET_ERROR", {
              error: true,
              errortext: 'Для заполнениня описи нажмите <br/>"+ Опись вложения"'
            });
          }
        } else {
          this.$store.commit("SET_ERROR", {
            error: true,
            errortext:
              'Для заполнениня описи выберите <br/>"Тип отправления" - ЦЕННОЕ'
          });
        }
      }
    },
    printInvoice(pages) {
      if (this.checkInvoiceParams()) {
        let obj = new InvoiceTemplate(this.s);
        obj.add(this.cursender, this.currecep, 0, pages);
        obj.print(this.browser);
        this.$store.commit("SINGLEPRINT_INVOICE");
      }
    },
    selectPrintFunction(object) {
      this.currentPrintFunction = object;
      this.updateView();
    },
    selectPrintFunctionByIndex(indx) {
      this.currentPrintFunction = this.printfunctions[indx];
      this.updateView();
    },
    printFromRecep() {
      if (this.currentPrintFunction.name == "Envelope") {
        this.$store.commit("SINGLEPRINT_ENVELOPE");
        this.container.print(this.browser);
      } else if (this.currentPrintFunction.name == "Invoice") {
        if (this.checkInvoiceParams()) {
          this.container.print(this.browser);
        }
        this.$store.commit("SINGLEPRINT_INVOICE");
      } else if (this.currentPrintFunction.name == "Inventory") {
        if (this.currecep.isCosted()) {
          if (this.currecep.inventorytotalcost > 0) {
            this.container.print(this.browser);
            this.$store.commit("SINGLEPRINT_INVENTORY");
          } else {
            this.$store.commit("SET_ERROR", {
              error: true,
              errortext: 'Для заполнениня описи нажмите <br/>"+ Опись вложения"'
            });
          }
        } else {
          this.$store.commit("SET_ERROR", {
            error: true,
            errortext:
              'Для заполнениня описи выберите <br/>"Тип отправления" - ЦЕННОЕ'
          });
        }
        this.$store.commit("SINGLEPRINT_INVENTORY");
      }
    },
    checkInvoiceParams() {
      let text = "";
      let errors = false;
      if (this.currecep !== null) {
        if (this.currecep.posttype == null) {
          text += "Заполните вид почтового отправления<br/>";
          errors = true;
        }
        if (
          !~this.currecep.postservices.indexOf("i1") &&
          !~this.currecep.postservices.indexOf("i2")
        ) {
          text += "Заполните тип уведомления (простое или заказное).<br/>";
          errors = true;
        }
        text += '<br/>Для этого нажмите кнопку "+Тип и категория отправления"';
        if (errors) {
          this.$store.commit("SET_ERROR", { error: true, errortext: text });
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    savePDF() {
      let rname = "";
      if (this.currecep !== null) {
        rname = this.currecep.name;
      }
      let blank = "";
      if (this.currentPrintFunction.name == "Envelope") {
        blank = "Конверт";
      } else if (this.currentPrintFunction.name == "Invoice") {
        blank = "Уведомление";
      } else if (this.currentPrintFunction.name == "Inventory") {
        blank = "Опись";
      }
      this.container.save(rname + (rname !== "" ? " " : "") + blank);
    },

    openSettings() {
      if (this.isRegistered()) {
        this.$store.commit("OPEN_SETTINGS");
      }
    },

    isRegistered() {
      if (this.needregister) {
        this.$store.commit("SET_ERROR", {
          error: true,
          errortext:
            "Для начала <br/>необходимо добавить отправителя или получателя<br/><br/> либо войти в личный кабинет, если вы пользовались сервисом ранее."
        });
        return false;
      } else {
        return true;
      }
    },
    isRecepientSelected() {
      if (this.currecep == null) {
        this.$store.commit("SET_ERROR", {
          error: true,
          errortext: "Выберите получателя в списке слева."
        });
        return false;
      } else {
        return true;
      }
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    async updateView() {
      if (this.rendering) {
        return;
      }

      if (typeof this.curformat == "undefined") {
        return;
      }

      if (
        this.cursender.logo != null &&
        this.cursender.logo_uid !== null &&
        this.cursender.logo_uid !== ""
      ) {
        while (
          this.cursender.logo === null ||
          this.cursender.logo.height === 0
        ) {
          await this.sleep(300);
        }
      }
      let pdf = this.container.getJSPDFObject();
      let pdfPath = pdf.output("arraybuffer");

      let previewPage = 1;
      if (this.currentPrintFunction.name == "Invoice") {
        previewPage = this.invoiceSide;
      } else if (this.currentPrintFunction.name == "Inventory") {
        previewPage = this.inventoryPage;
      }
      this.rendering = true;

      var scoperendering = this;
      let loadingTask = this.$pdfjs.getDocument(pdfPath);
      var thim = this;
      loadingTask.promise
        .then(function(pdfDocument) {
          // Request a first page
          return pdfDocument.getPage(previewPage).then(function(pdfPage) {
            // Display page on the existing canvas with 100% scale.
            let canvas = document.getElementById("pdfCanvas");
            let canvasOuter = document.getElementById("pdfCanvasOuter");
            let viewport = pdfPage.getViewport({ scale: 1 });
            let pres = canvasOuter.clientWidth / viewport.width;

            viewport = pdfPage.getViewport({ scale: 1 * pres });
            canvas.width = viewport.width;
            canvas.height = viewport.height;
            let ctx = canvas.getContext("2d");
            let renderTask = pdfPage.render({
              canvasContext: ctx,
              viewport: viewport
            });
            var completeCallback = renderTask._internalRenderTask.callback;
            renderTask._internalRenderTask.callback = function(error) {
              //Step 2: what you want to do before calling the complete method
              completeCallback.call(this, error);
              scoperendering.rendering = false;
              thim.canvasUpdateProgress = false;
            };
            return renderTask.promise;
          });
        })
        .catch(function(reason) {});
    },
    updateCanvas() {
      this.$store.commit("UPDATE_CANVAS");
    }
  },
  created() {
    this.updateCanvas = debounce(this.updateCanvas, 1200);
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === "UPDATE_CANVAS") {
        this.updateView();
      } else if (mutation.type === "SET_RECEPIENTS_LOADED") {
        if (this.allreceps && this.allreceps.length == 1) {
          this.$store.commit("SET_ACTIVE_RECEP", [this.allreceps[0].id]);
          this.updateView();
        }
      } else if (mutation.type === "UPDATE_LOGO_FINISHED") {
        this.updateView();
      } else if (mutation.type === "SET") {
        this.canvasUpdateProgress = true;
        this.updateCanvas();
      }
    });
  },
  mounted() {
    let paramsUrl = this.$store.$router.history.current.fullPath;
    if (~paramsUrl.indexOf("clickinvoice")) {
      this.selectPrintFunctionByIndex(1);
    }
    if (~paramsUrl.indexOf("clickinventory")) {
      this.selectPrintFunctionByIndex(2);
    }
    this.updateView();
    this.$eventBus.$on("print-from-recep", this.printFromRecep);
  },

  beforeDestroy() {
    this.unsubscribe();
    this.$eventBus.$off("print-from-recep");
  }
};
</script>

<style lang="sass">
.pagradient
  background-image: linear-gradient(to bottom, #0a8b8e 0%, #252c44 100%)!important
.sidechanger
  position: absolute
  left: 257px
  z-index: 1
  top: 400px
.sidechanger span.block
  padding: 0 8px
.cvblock
  height: auto
  width: 640px
.cvpanel
  min-height: 40px
.psselect.cv
  width: 200px
  float: left
.psselect.cv .q-field__marginal
  height: 30px
.q-field--auto-height.q-field--dense.psselect.cv .q-field__control
  min-height: 30px
  padding-left: 3px
  padding-right: 3px
.q-field--auto-height.q-field--dense.psselect.cv .q-field__native
  min-height: 30px
.selected-frm
  background: #e64c65
  color: #FFF!important
  border-radius: 5px
  border: 1px solid #a73346
.minimal .henvelope
  width: 90px
  height: 63px
.minimal .withholst
  width: 130px
  height: 90px
.minimal .holstname
  font-size: 18px
.minimal .envelopesize
  font-size: 18px
  left: 52px
  top: 10px
.minimal .fullhost
  width: 90px
  height: 63px
.minimal .fullenvelope
  width: 90px
  height: 63px
.minimal
  width: 286px
.minimal .fullenvelopesize
  font-size: 18px
  left: 50px
  top: 13px
.minimal .activef, .minimal .activef:hover
  outline: 6px solid #e64c65 !important
.q-btn.canvaformselector
  width: 240px
.q-btn.canvaformselector .q-btn__content span
  white-space: nowrap
  overflow: hidden
  padding: 5px
  text-overflow: ellipsis
  width: 100%
  text-align: left
  padding-top: 0
  padding-bottom: 0
.cuttedlabel
  white-space: nowrap
  overflow: hidden
  padding: 5px
  text-overflow: ellipsis
  width: 196px
  text-align: left
.q-list--dense > .q-item.fflist
  padding: 2px 8px
.q-menu.fheightdrop
  max-height: none
  min-height: 117px
.fs14px
  font-size: 14px!important
.q-separator.whitesep
  background: rgba(255,255,255, 0.43)
.canvprbtn>.q-btn-dropdown--current
  padding: 0 6px
.addpanelbgr
  background: rgba(0,0,0,0.15)
  font-size: 13px
.pagechanger
  position: absolute
  left: 253px
  top: 400px
.pagechanger span.block
  padding: 0px 8px

.bg-primary-darken
  background: #323a58
.bg-accent-darken
  background: #cc4157 !important
.bg-accent-darken-darken
  background: #b3384c !important
.addformatbtn
  background: #1c4a5b
  border: 1px solid #165e6b
  text-decoration: none !important
  color: #bdbdbd
  width: 100%
  max-width: 300px
  margin-top: 24px
  text-align: center
  padding: 6px 8px
  border-radius: 4px
.lslouter
  width: 150px
  position: absolute
  opacity: 0.7
  margin: 24px
.lslouter:hover
  opacity: 1
.pdfcanvasouter
  position: relative
.pdfcanvasloader
  position: absolute
  top: 16px
  bottom: 22px
  right: 0
  left: 0
.pseptop
  background: rgba(0,0,0,0.2)
.pseptopt
  background: rgba(0,0,0,0.08)
.psepbot
  background: rgba(255,255,255,0.12)
.q-btn--dense.nopl .q-btn__wrapper
  padding-left: 0
.poschanger
  font-size: 12px
  max-width:180px
  line-height:125%
  margin-left: 42px
.howmanymarks
  cursor: pointer
  color: #f7ff79
  border-bottom: 1px dashed #f7ff79
  margin-top: 4px
  font-size: 13px
</style>
