import { render, staticRenderFns } from "./ListDialog.vue?vue&type=template&id=23bfe6b4"
import script from "./ListDialog.vue?vue&type=script&lang=js"
export * from "./ListDialog.vue?vue&type=script&lang=js"
import style0 from "./ListDialog.vue?vue&type=style&index=0&id=23bfe6b4&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QCardSection,QSpace,QBtn,QInput,QIcon,QScrollArea,QTooltip});qInstall(component, 'directives', {ClosePopup});
