<template>
  <q-dialog
    v-model="show"
    persistent
    transition-show="scale"
    transition-hide="scale"
  >
    <q-card class="mypdial text-white invdial mylisttial">
      <q-card-section class="row dialhead">
        <div class="text-h6">Сохраненные списки</div>
        <q-space />
        <q-btn
          icon="close"
          unelevated
          padding="xs"
          color="accent"
          round
          dense
          @click="close"
          v-close-popup
        />
      </q-card-section>
      <q-card-section>
        <q-input
          filled
          v-model="search"
          label="Поиск по имени получателя"
          dense
          class="q-mb-md"
          color="accent"
          bg-color="white"
          @keyup.enter="findListsByRecepientName"
          ><template v-if="search" v-slot:append>
            <q-icon
              name="cancel"
              @click.stop="loadAllLists"
              class="cursor-pointer"
            />
          </template>
          <template v-slot:prepend> <q-icon name="search" /> </template
          ><template v-slot:after>
            <q-btn
              no-caps
              color="accent"
              unelevated
              dense
              class="q-ml-sm q-py-xs q-px-sm"
              label="Найти"
              @click="findListsByRecepientName"
            />
          </template>
        </q-input>
        <div class="row lrheader q-px-md q-py-xs">
          <div class="col-7">Наименование</div>
          <div class="col-2 text-center">Создан</div>
          <div class="col-2 text-center">Изменен</div>
          <div class="col-1 text-center"></div>
        </div>
        <q-scroll-area
          ref="chatScrollArea"
          class="q-pa-md lists-s-area"
          :thumb-style="trthumbStyle"
        >
          <div
            v-for="item in lists"
            :key="item.id"
            class="row q-mb-xs list-item-row"
          >
            <div class="col-7" @click="loadList(item.id)">
              <div class="row">
                <div
                  class="col-12 text-underline q-pr-md text-yellow-3 ellipsised"
                >
                  {{ item.listName }}
                </div>
              </div>
            </div>
            <div class="text-center col-2 fs-12 q-pt-xs">
              {{ item.created_at }}
            </div>
            <div class="text-center col-2 fs-12 q-pt-xs">
              {{ item.updated_at !== "00.00.0000" ? item.updated_at : "" }}
            </div>
            <div class="text-center col-1 fs-12 q-pt-xs">
              <q-icon
                class="icopycls q-mr-xs q-ml-sm"
                name="content_copy"
                @click.stop="copyList(item.id)"
              >
                <q-tooltip
                  content-class="bg-accent"
                  content-style="font-size: 14px"
                  :offset="[10, 10]"
                >
                  Копировать
                </q-tooltip>
              </q-icon>
              <q-icon
                class="icopycls"
                name="close"
                @click.stop="deleteList(item.id)"
              >
                <q-tooltip
                  content-class="bg-accent"
                  content-style="font-size: 14px"
                  :offset="[10, 10]"
                >
                  Удалить
                </q-tooltip>
              </q-icon>
            </div>
          </div>
        </q-scroll-area>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    lists() {
      return this.$store.getters.GET_LISTS;
    }
  },
  data() {
    return {
      trthumbStyle: {
        right: "px",
        borderRadius: "5px",
        backgroundColor: "#11a8ab",
        width: "7px",
        opacity: 0.75
      },
      search: ""
    };
  },
  watch: {
    show(val) {
      if (val == true) {
        this.$store.dispatch("LOAD_LISTS");
      }
    }
  },

  methods: {
    close() {
      this.search = null;
      this.$emit("update:show", false);
    },
    async loadList(lid) {
      await this.$store.dispatch("LOAD_FROM_SAVED_LIST", { id: lid });
      this.$emit("update:show", false);
    },
    async deleteList(id) {
      await this.$store.dispatch("DELETE_SAVED_LIST", { id: id });
      this.$q.notify({
        message: "Список удален",
        color: "red-10",
        multiLine: true,
        icon: "announcement",
        progress: true,
        html: true,
        timeout: 9000
      });
    },
    async copyList(id) {
      await this.$store.dispatch("COPY_LIST", { id: id });
      this.$q.notify({
        message: "Список скопирован",
        color: "red-10",
        multiLine: true,
        icon: "announcement",
        progress: true,
        html: true,
        timeout: 9000
      });
    },
    findListsByRecepientName() {
      this.$store.dispatch("LOAD_LISTS_BY_RECEP_NAME", { name: this.search });
    },
    loadAllLists() {
      this.search = null;
      this.$store.dispatch("LOAD_LISTS");
    }
  }
};
</script>

<style lang="sass">
.mylisttial
  height: 650px
  max-width: 800px!important
  width: 600px!important
.lists-s-area
  height: 465px
  background: rgba(0,0,0,0.1)
.hidden-delete-btn
  display: none
.list-item-row:hover .hidden-delete-btn
  display: block
.list-item-row
  cursor: pointer
  border-bottom: 1px dashed rgba(255, 255, 255,0.12)
.text-underline
  text-decoration: underline
  padding-top: 3px
  padding-bottom: 5px
.lrheader
  background: rgba(0,0,0,0.18)
.fs-12
  font-size: 12px
.qmtpx
  margin-top: 1px
.icopycls
  font-size: 15px
</style>
