import jsPDF from "jspdf";
import "jspdf/dist/polyfills.es.js";
import Interleaved from "src/service/classes/interleaved.js";
import Address from "src/service/classes/address.js";
import Sender from "src/service/classes/sender.js";
import CommonTemplate from "src/service/classes/printCommon.js";
import { api } from "src/boot/axios";
//import SilentPrint from "src/service/classes/silentPrint.js";
/**const toDataURL = (url) =>
  fetch(url)
    .then((response) => response.blob())
    .then(
      (blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );
    */
export default class EnvelopeTemplate extends CommonTemplate {
  constructor(
    formatObject,
    settingsObject,
    addPrice = false,
    addNdsToPrice = false,
    addNdsToPriceServices = false
  ) {
    super();
    this.addPrice = addPrice;
    this.addNdsToPrice = addNdsToPrice;
    this.addNdsToPriceServices = addNdsToPriceServices;
    this.frm = formatObject;
    this.settings = settingsObject;
    this.fname = this.frm.getFormatName();
    this.pxtomm = 0.2845;
    this.mmtopx = 3.5149;
    this.stipswidth = 16;
    this.rtipswidth = 11;
    this.envsize = {
      c6: { L: 162, H: 114 },
      dl: { L: 220, H: 110 },
      c5: { L: 229, H: 162 },
      c4: { L: 324, H: 229 },
      b4: { L: 353, H: 250 }
    };
    this.H = { c6: 114, dl: 110, c5: 162, c4: 229, b4: 250 };
    this.L = { c6: 162, dl: 220, c5: 229, c4: 324, b4: 353 };
    this.l1 = { c6: 147, dl: 205, c5: 214, c4: 290, b4: 320 };
    this.l2 = { c6: 5, dl: 5, c5: 0, c4: 0, b4: 0 };
    this.l3 = { c6: 66, dl: 95, c5: 100, c4: 170, b4: 190 };
    this.l4 = { c6: 70, dl: 95, c5: 100, c4: 120, b4: 120 };
    this.l7 = { c6: 35, dl: 35, c5: 40, c4: 50, b4: 50 };
    this.l8 = { c6: 30, dl: 30, c5: 35, c4: 50, b4: 50 };
    this.h1 = { c6: 75, dl: 75, c5: 120, c4: 180, b4: 200 };
    this.h2 = { c6: 58.5, dl: 60.5, c5: 90, c4: 145, b4: 165 };
    this.h3 = { c6: 8, dl: 8, c5: 8, c4: 10, b4: 10 };
    this.h4 = { c6: 6.5, dl: 6.5, c5: 8, c4: 10, b4: 10 };
    this.h5 = { c6: 19.5, dl: 13, c5: 19.5, c4: 19.5, b4: 19.5 };
    this.ZIPspace = { c6: 1, dl: 1, c5: 1, c4: 1, b4: 1 };
    this.left = { c6: 10, dl: 10, c5: 10, c4: 10, b4: 10 };
    this.lineheight = { c6: 6.5, dl: 6.5, c5: 6.5, c4: 6.5, b4: 6.5 };
    this.tipFontSize = { c6: 10, dl: 14, c5: 14, c4: 14, b4: 14 };
    this.tipWidth = { c6: 80, dl: 120, c5: 120, c4: 120, b4: 120 };

    this.Sff = "Play";
    this.Rff = "Play";
    this.MZff = "Play";
    this.Tff = "Play";

    this.formats = ["c6", "dl", "c5", "c4", "b4"];

    this.fromtext = "От кого";
    this.wheretext = "Откуда";
    this.totext = "Кому";
    this.towheretext = "Куда";

    this.xmove = 0;
    this.ymove = 0;
    this.sender = {
      name: {
        text: "",
        lines: [],
        count: 0,
        fsize: 4.5
      },
      addr: {
        text: "",
        lines: [],
        count: 0,
        fsize: 4.5
      },
      zip: "",
      comment: "",
      logo: null,
      logouid: null
    };

    this.recep = {
      name: {
        text: "",
        lines: [],
        count: 0,
        fsize: 4.5
      },
      addr: {
        text: "",
        lines: [],
        count: 0,
        fsize: 4.5
      },
      zip: "",
      comment: ""
    };

    let holstSize = {
      a4: { w: 297, h: 210 },
      a3: { w: 420, h: 297 },
      c5: { w: 229, h: 165 },
      dl: { w: 220, h: 110 },
      c6: { w: 162, h: 114 },
      c4: { w: 324, h: 229 },
      b4: { w: 353, h: 250 }
    };
    let positions = {
      "1": { x: 0, y: 0 },
      "2": { x: 1, y: 0 },
      "3": { x: 0, y: 0.5 },
      "4": { x: 1, y: 0.5 },
      "5": { x: 0, y: 1 },
      "6": { x: 1, y: 1 }
    };

    let holst =
      this.fname == "c5" || this.fname == "dl" || this.fname == "c6"
        ? "a4"
        : "a3";
    this.xmove =
      this.frm.get("useholst") *
      (positions[this.frm.get("posnum")]["x"] *
        (holstSize[holst]["w"] - this.envsize[this.fname]["L"]) +
        5);
    this.ymove =
      this.frm.get("useholst") *
      (positions[this.frm.get("posnum")]["y"] *
        (holstSize[holst]["h"] - this.envsize[this.fname]["H"]));

    this.doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: this.frm.get("useholst") == 0 ? this.fname : holst
    });
  }

  pnt(digit) {
    return (digit * 72) / 25.4;
  }
  pxtopnt(digit) {
    return (digit * 25.4) / 72;
  }

  calcField(srez1, srez2, fzize, width) {
    width = width - 3.8;
    this[srez1][srez2].lines = [];
    this[srez1][srez2].count = 0;
    this.doc.setFontSize(this.pnt(fzize));
    let options = { textIndent: 0 };
    if (srez1 == "sender") {
      if (this.frm.get("sPrintTips")) options.textIndent = this.stipswidth;
    } else {
      if (this.frm.get("rPrintTips")) options.textIndent = this.rtipswidth;
    }
    this[srez1][srez2].lines = this.doc.splitTextToSize(
      this[srez1][srez2].text,
      width,
      options
    );
    this[srez1][srez2].count = this[srez1][srez2].lines.length;
    this[srez1][srez2].fsize = fzize;
  }

  prepareSender(sender) {
    this.sender.addr.text =
      this.frm.get("sPrintZip") == 1
        ? sender.addr
        : sender.zip +
          (sender.addr != "" && sender.zip != "" ? ", " : "") +
          sender.addr;
    this.sender.name.text = sender.fullname;
    this.sender.zip = sender.zip + "";
    this.sender.comment = sender.comment;
    //doc.saveGraphicsState();
    this.doc.setFont(this.Sff);

    this.calcField(
      "sender",
      "addr",
      this.frm.get("SfsAddr"),
      this.frm.get("l5")
    );
    this.calcField(
      "sender",
      "name",
      this.frm.get("SfsName"),
      this.frm.get("l5")
    );

    if (this.frm.get("autoChangeFSize")) {
      while (this.sender.addr.count > 3 && this.sender.addr.fsize > 0.5) {
        this.calcField(
          "sender",
          "addr",
          this.sender.addr.fsize - 0.1,
          this.frm.get("l5")
        );
      }
      while (this.sender.name.count > 1 && this.sender.name.fsize > 0.5) {
        this.calcField(
          "sender",
          "name",
          this.sender.name.fsize - 0.1,
          this.frm.get("l5")
        );
      }
      while (this.sender.addr.count < 3) {
        this.sender.addr.lines.push("");
        this.sender.addr.count++;
      }
      while (this.sender.name.count < 1) {
        this.sender.name.lines.push("");
        this.sender.name.count++;
      }
    }
    if (typeof sender.id == "undefined") {
      while (this.sender.addr.count < 3) {
        this.sender.addr.lines.push("");
        this.sender.addr.count++;
      }
      while (this.sender.name.count < 1) {
        this.sender.name.lines.push("");
        this.sender.name.count++;
      }
    }
  }

  prepareRecep(recep) {
    this.recep.addr.text = recep.addr;
    this.recep.name.text =
      this.frm.get("printComment") && recep.comment != ""
        ? recep.fullname + ", " + recep.comment
        : recep.fullname;
    this.recep.zip = recep.zip + "";
    this.doc.setFont(this.Rff);

    this.calcField("recep", "addr", this.frm.get("Rfs"), this.l4[this.fname]);
    this.calcField("recep", "name", this.frm.get("Rfs"), this.l4[this.fname]);

    if (this.frm.get("autoChangeFSize")) {
      while (this.recep.addr.count > 4 && this.recep.addr.fsize > 0.5) {
        this.calcField(
          "recep",
          "addr",
          this.recep.addr.fsize - 0.1,
          this.l4[this.fname]
        );
      }
      while (this.recep.name.count > 2 && this.recep.name.fsize > 0.5) {
        this.calcField(
          "recep",
          "name",
          this.recep.name.fsize - 0.1,
          this.l4[this.fname]
        );
      }
    }
    while (this.recep.addr.count < 4) {
      this.recep.addr.lines.push("");
      this.recep.addr.count++;
    }
    while (this.recep.name.count < 2) {
      this.recep.name.lines.push("");
      this.recep.name.count++;
    }
  }

  addSender(startx, starty) {
    let fname = this.frm.getFormatName();
    let fromdy = 0;
    let wheredy = 0;
    if (this.frm.get("addrFieldsOrder") == 2) {
      fromdy = 0;
      wheredy = this.sender.name.count;
    } else {
      fromdy = this.sender.addr.count;
      wheredy = 0;
    }

    if (this.frm.get("sPrintSender")) {
      let textIdent = this.frm.get("sPrintTips") ? this.stipswidth : 0;

      this.doc.setFont(this.Sff);
      this.doc.setFontSize(this.pnt(this.sender.name.fsize));

      for (let i = 0; i < this.sender.name.count; i++) {
        this.doc.text(
          this.sender.name.lines[i],
          startx + (i == 0 ? textIdent : 0),
          starty + this.frm.get("sRowHeight") * (i + 1 + fromdy) - 0.7
        );
      }

      this.doc.setFontSize(this.pnt(this.sender.addr.fsize));
      for (let i = 0; i < this.sender.addr.count; i++) {
        this.doc.text(
          this.sender.addr.lines[i],
          startx + (i == 0 ? textIdent : 0),
          starty + this.frm.get("sRowHeight") * (i + 1 + wheredy) - 0.7
        );
      }
    }
    if (this.frm.get("sPrintLines")) {
      let textIdent = this.frm.get("sPrintTips") ? this.stipswidth : 0;
      for (let i = 0; i < this.sender.name.count; i++) {
        this.doc.line(
          startx + (i == 0 ? textIdent : 0),
          starty + this.frm.get("sRowHeight") * (i + 1 + fromdy),
          startx + this.frm.get("l5"),
          starty + this.frm.get("sRowHeight") * (i + 1 + fromdy)
        );
      }
      for (let i = 0; i < this.sender.addr.count; i++) {
        this.doc.line(
          startx + (i == 0 ? textIdent : 0),
          starty + this.frm.get("sRowHeight") * (i + 1 + wheredy),
          startx + this.frm.get("l5"),
          starty + this.frm.get("sRowHeight") * (i + 1 + wheredy)
        );
      }
      this.doc.line(
        startx + this.frm.get("l5"),
        starty +
          this.frm.get("sRowHeight") *
            (this.sender.name.count + this.sender.addr.count),
        startx + this.frm.get("l5"),
        this.h4[fname] +
          starty +
          this.frm.get("sRowHeight") *
            (this.sender.name.count + this.sender.addr.count)
      );
      this.doc.line(
        startx + this.frm.get("l5"),
        this.h4[fname] +
          starty +
          this.frm.get("sRowHeight") *
            (this.sender.name.count + this.sender.addr.count),
        startx + this.frm.get("l5") - this.l8[fname],
        this.h4[fname] +
          starty +
          this.frm.get("sRowHeight") *
            (this.sender.name.count + this.sender.addr.count)
      );
      this.doc.line(
        startx + this.frm.get("l5") - this.l8[fname],
        this.h4[fname] +
          starty +
          this.frm.get("sRowHeight") *
            (this.sender.name.count + this.sender.addr.count),
        startx + this.frm.get("l5") - this.l8[fname],
        starty +
          this.frm.get("sRowHeight") *
            (this.sender.name.count + this.sender.addr.count)
      );
    }
    if (this.frm.get("sPrintTips")) {
      this.doc.setFont(this.Tff);
      this.doc.setFontSize(this.pnt(this.frm.get("Tfs")));
      if (this.frm.get("sPrintTipsWhite")) this.doc.setTextColor(255, 255, 255);
      this.doc.text(
        this.fromtext,
        startx,
        starty + this.frm.get("sRowHeight") * (1 + fromdy)
      );
      this.doc.text(
        this.wheretext,
        startx,
        starty + this.frm.get("sRowHeight") * (1 + wheredy)
      );
      this.doc.setTextColor(0, 0, 0);
    }
    if (this.frm.get("sPrintZip")) {
      this.doc.setFont(this.MZff);
      this.doc.setFontSize(this.pnt(this.frm.get("MZfs")));
      this.doc.text(
        this.sender.zip,
        startx +
          this.frm.get("l5") -
          this.l8[fname] +
          8 +
          this.frm.get("sZipMoveX"),
        -1 +
          starty +
          this.frm.get("sRowHeight") *
            (1 + this.sender.addr.count + this.sender.name.count) +
          this.frm.get("sZipMoveY")
      );
    }
  }

  addRecep(startx, starty) {
    let rowcount = this.recep.name.count + this.recep.addr.count;
    if (this.frm.get("rPrintLines")) {
      let textIdent = this.frm.get("rPrintTips") ? this.rtipswidth : 0;
      for (let i = 0; i < this.recep.name.count; i++) {
        this.doc.line(
          startx + (i == 0 ? textIdent : 0),
          starty - this.frm.get("rRowHeight") * (rowcount - i - 1),
          startx + this.l4[this.fname],
          starty - this.frm.get("rRowHeight") * (rowcount - i - 1)
        );
      }
      for (let i = 0; i < this.recep.addr.count; i++) {
        this.doc.line(
          startx + (i == 0 ? textIdent : 0),
          starty - this.frm.get("rRowHeight") * (this.recep.addr.count - i - 1),
          startx + this.l4[this.fname],
          starty - this.frm.get("rRowHeight") * (this.recep.addr.count - i - 1)
        );
      }
      this.doc.line(startx, starty, startx + this.l4[this.fname], starty);
      this.doc.line(startx, starty, startx, starty + this.h3[this.fname]);
      this.doc.line(
        startx,
        starty + this.h3[this.fname],
        startx + this.l7[this.fname],
        starty + this.h3[this.fname]
      );
      this.doc.line(
        startx + this.l7[this.fname],
        starty + this.h3[this.fname],
        startx + this.l7[this.fname],
        starty
      );
    }

    if (this.frm.get("rPrintRecep")) {
      let textIdent = this.frm.get("rPrintTips") ? this.rtipswidth : 0;

      this.doc.setFont(this.Rff);
      this.doc.setFontSize(this.pnt(this.recep.name.fsize));

      for (let i = 0; i < this.recep.name.count; i++) {
        this.doc.text(
          this.recep.name.lines[i],
          startx + (i == 0 ? textIdent : 0),
          starty - this.frm.get("rRowHeight") * (rowcount - i - 1) - 0.7
        );
      }

      this.doc.setFontSize(this.pnt(this.recep.addr.fsize));
      for (let i = 0; i < this.recep.addr.count; i++) {
        this.doc.text(
          this.recep.addr.lines[i],
          startx + (i == 0 ? textIdent : 0),
          starty -
            this.frm.get("rRowHeight") * (this.recep.addr.count - i - 1) -
            0.7
        );
      }
      this.doc.setFont(this.MZff);
      this.doc.setFontSize(this.pnt(this.frm.get("MZfs")));
      this.doc.text(
        startx + 12 + this.frm.get("rZipMoveX"),
        4.5 + starty + this.frm.get("rZipMoveY"),
        this.recep.zip == "0" ? "" : this.recep.zip + ""
      );
    }

    if (this.frm.get("rPrintTips")) {
      this.doc.setFont(this.Tff);
      this.doc.setFontSize(this.pnt(this.frm.get("Tfs")));
      if (this.frm.get("rPrintTipsWhite")) {
        this.doc.setTextColor(255, 255, 255);
      }
      this.doc.text(
        startx,
        starty - this.frm.get("rRowHeight") * (rowcount - 1),
        this.totext
      );
      this.doc.text(
        startx,
        starty - this.frm.get("rRowHeight") * (this.recep.addr.count - 1),
        this.towheretext
      );
      this.doc.setTextColor(0, 0, 0);
    }

    if (this.frm.get("printZip")) {
      let z = "";

      if (
        (Number(this.recep.zip) && this.recep.zip.length == 6) ||
        this.recep.zip == "0" ||
        this.recep.zip == ""
      ) {
        this.doc.setFontSize(this.pnt(this.frm.get("ZIPfontsize")));
        z = "-";
        if (this.recep.zip == "" || this.recep.zip == "0") {
          z += "######";
        } else {
          z += this.recep.zip;
        }
        if (this.frm.get("zipClearFont")) {
          this.doc.setFont("ZIPclear");
        } else {
          this.doc.setFont("ZIP");
        }
      } else {
        this.doc.setFont("Play");
        this.doc.setFontSize(this.pnt(this.frm.get("ZIPfontsize") - 6));
        z = this.recep.zip + "";
      }
      for (var i = 0; i < z.length; i++) {
        this.doc.text(
          startx -
            10 -
            this.l3[this.fname] +
            this.frm.get("zMoveX") +
            10 +
            i *
              (this.frm.get("ZIPfontsize") * 0.4 +
                this.frm.get("ZIPletterspace")),
          starty -
            this.H[this.fname] +
            25 +
            this.frm.get("zMoveY") +
            this.H[this.fname] -
            25 +
            this.frm.get("ZIPfontsize") -
            4,
          z[i]
        );
      }
    }
  }

  async add(sender, recep, mass) {
    if (typeof this.frm == "undefined") {
      return;
    }
    if (typeof sender == "undefined") {
      sender = new Sender({});
    }
    if (recep == null) {
      recep = new Address({});
    }
    if (mass) {
      this.doc.addPage();
    } else {
      this.doc.deletePage(1);
      this.doc.addPage();
    }
    let msender = null;
    let mrecep = null;
    if (recep.receptosender) {
      mrecep = new Sender({
        name: sender.name,
        fullname: sender.fullname,
        addr: sender.addr,
        zip: sender.zip,
        comment: sender.comment
      });
      msender = new Address({
        name: recep.name,
        fullname: recep.fullname,
        addr: recep.addr,
        zip: recep.zip,
        comment: recep.comment
      });
    } else {
      msender = sender;
      mrecep = recep;
    }
    this.prepareSender(recep.receptosender ? msender : sender);
    this.prepareRecep(recep.receptosender ? mrecep : recep);

    let mesh = {};
    if (
      this.frm.get("printLogo") == 1 &&
      sender.logo_uid !== null &&
      sender.logo_uid !== ""
    ) {
      if (this.frm.get("logoFirst") == 0) {
        mesh.x_sender =
          this.xmove + 10 - this.l2[this.fname] + this.frm.get("sMoveX");
        mesh.y_sender =
          this.ymove +
          this.H[this.fname] -
          this.h2[this.fname] -
          this.h5[this.fname] -
          this.h4[this.fname] -
          25 +
          this.frm.get("sMoveY");
        let sh =
          this.frm.get("sRowHeight") *
          (this.sender.name.count + this.sender.addr.count);
        mesh.x_logo =
          this.xmove + 10 - this.l2[this.fname] + this.frm.get("logoMoveX");
        mesh.y_logo = mesh.y_sender + sh + 12 + this.frm.get("logoMoveY");
      } else {
        mesh.x_logo =
          this.xmove + 10 - this.l2[this.fname] + this.frm.get("logoMoveX");
        mesh.y_logo =
          this.ymove +
          this.H[this.fname] -
          this.h2[this.fname] -
          this.h5[this.fname] -
          this.h4[this.fname] -
          25 +
          this.frm.get("sMoveY");
        mesh.x_sender =
          this.xmove + 10 - this.l2[this.fname] + this.frm.get("sMoveX");
        mesh.y_sender =
          mesh.y_logo +
          (sender.logo
            ? (this.pxtopnt(sender.logo.height) * this.frm.get("logoSize")) /
              100
            : 0);
      }
    } else {
      mesh.x_sender =
        this.xmove + 10 - this.l2[this.fname] + this.frm.get("sMoveX");
      mesh.y_sender =
        this.ymove +
        this.H[this.fname] -
        this.h2[this.fname] -
        this.h5[this.fname] -
        this.h4[this.fname] -
        25 +
        this.frm.get("sMoveY");
    }
    mesh.x_recep =
      this.xmove + 10 + this.l3[this.fname] + this.frm.get("rMoveX");
    mesh.y_recep =
      this.ymove + this.H[this.fname] - 25 + this.frm.get("rMoveY");

    mesh.bar_x = this.xmove + 10 + this.frm.get("l5") + 30;
    mesh.bar_y =
      this.ymove +
      this.H[this.fname] -
      this.h2[this.fname] -
      this.h5[this.fname] -
      this.h4[this.fname] -
      25;

    this.addSender(mesh.x_sender, mesh.y_sender);
    this.addRecep(mesh.x_recep, mesh.y_recep);

    if (
      this.frm.get("printLogo") &&
      sender.logo_uid !== null &&
      sender.logo_uid !== "" &&
      sender.logo64 !== null
    ) {
      this.doc.addImage(
        sender.logo64,
        "JPEG",
        mesh.x_logo,
        mesh.y_logo,
        (this.pxtopnt(sender.logo.width) * this.frm.get("logoSize")) / 100,
        (this.pxtopnt(sender.logo.height) * this.frm.get("logoSize")) / 100
      );
    }

    if (recep.tip != "") {
      this.doc.setFont(this.Rff);
      this.doc.setFontSize(this.tipFontSize[this.fname]);
      let tipText = this.doc.splitTextToSize(
        recep.tip,
        this.tipWidth[this.fname]
      );
      this.doc.text(
        tipText,
        this.xmove + this.l1[this.fname] + this.frm.get("zakazMoveX"),
        this.ymove +
          this.H[this.fname] -
          25 -
          this.h1[this.fname] +
          this.frm.get("zakazMoveY") -
          0.5 -
          (tipText.length - 1) * this.lineheight[this.fname],
        { align: "right" }
      );
    }

    if (recep.barcode !== null && recep.barcode.length === 14) {
      let bar = new Interleaved(40);
      bar.setNumberBarcode(recep.barcode);
      bar.drawBar(
        this.doc,
        mesh.bar_x + this.frm.get("barMoveX"),
        mesh.bar_y + this.frm.get("barMoveY"),
        this.frm.get("Mzfs"),
        this.MZff
      );
    }

    //УголaddNdsToPrice
    if (this.frm.get("rPrintLines")) {
      this.doc.line(
        this.xmove + this.l1[this.fname] - 15,
        this.ymove + this.H[this.fname] - 25 - this.h1[this.fname],
        this.xmove + this.l1[this.fname],
        this.ymove + this.H[this.fname] - 25 - this.h1[this.fname]
      );
      this.doc.line(
        this.xmove + this.l1[this.fname],
        this.ymove + this.H[this.fname] - 25 - this.h1[this.fname],
        this.xmove + this.l1[this.fname],
        this.ymove + this.H[this.fname] - 25 - this.h1[this.fname] + 15
      );
    }
    if (this.addPrice) {
      this.doc.setFont(this.Rff);
      this.doc.setFontSize(14);
      this.doc.text(
        recep.GeneratePriceWeight(
          this.addNdsToPrice,
          this.addNdsToPriceServices
        ),
        this.xmove + this.l1[this.fname] - 2,
        this.ymove + this.H[this.fname] - 25 - this.h1[this.fname] + 5,
        { align: "right" }
      );
    }
  }
}
