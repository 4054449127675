<template>
  <div :class="minimal ? 'minimal holsts' : 'holsts q-pa-sm'">
    <div v-if="!minimal" style="padding: 0 8px">
      Выберите, как необходимо формировать макет конверта для печати
    </div>
    <div v-if="minimal" style="padding: 0px 8px 2px 6px">По размеру макета</div>
    <div class="hosttitle q-mt-lg" v-if="!minimal">
      Данный способ создает макет конверта размером {{ envelopesize }}. Подойдет
      для всех МФУ принтеров, имеющих датчик размера бумаги.
    </div>
    <div
      :class="useholst == 0 && posnum == 4 ? 'fullhost activef' : 'fullhost'"
      @click="changeOutputFormat(0, 4)"
    >
      <div class="fullenvelope">
        <div class="fullenvelopesize">{{ envelopesize }}</div>
      </div>
    </div>
    <div style="padding: 16px 0 4px 8px" v-if="minimal">
      Разместить на листе {{ holstname }}
    </div>
    <div class="hosttitle q-mt-lg" v-if="!minimal">
      Для простых принтеров, не имеющих датчика размера бумаги можете выбрать
      один из следующих макетов. В этом случает конверт размером
      {{ envelopesize }} будет сформирован на листе размером {{ holstname }}
    </div>
    <div
      v-for="(item, index) in variants"
      :class="
        item.useholst == useholst && item.posnum == posnum
          ? 'withholst activef'
          : 'withholst'
      "
      :key="index"
      @click="changeOutputFormat(item.useholst, item.posnum)"
    >
      <div :class="'holstname ' + item.hostnameclass">{{ holstname }}</div>
      <div :class="'henvelope ' + item.envclass">
        <div class="envelopesize">{{ envelopesize }}</div>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  props: {
    useholst: { type: Number, default: null },
    posnum: { type: Number, default: null },
    minimal: { type: Boolean, default: false }
  },
  data() {
    return {
      variants: [
        {
          useholst: 1,
          posnum: 1,
          hostnameclass: "hright",
          envclass: "etop eleft"
        },
        {
          useholst: 1,
          posnum: 2,
          hostnameclass: "hleft",
          envclass: "etop eright"
        },
        {
          useholst: 1,
          posnum: 3,
          hostnameclass: "hright",
          envclass: "emiddle eleft"
        },
        {
          useholst: 1,
          posnum: 4,
          hostnameclass: "hleft",
          envclass: "emiddle eright"
        },
        {
          useholst: 1,
          posnum: 5,
          hostnameclass: "hright",
          envclass: "ebottom eleft"
        },
        {
          useholst: 1,
          posnum: 6,
          hostnameclass: "hleft",
          envclass: "ebottom eright"
        }
      ]
    };
  },

  computed: {
    f() {
      return this.$store.getters.CURFORMAT_OBJECT;
    },
    holstname() {
      let fname = this.f.list.fname;
      if (fname == "c5" || fname == "dl" || fname == "c6") {
        return "A4";
      } else {
        return "A3";
      }
    },
    envelopesize() {
      return this.f.list.fname;
    },
    activeUser() {
      return this.$store.getters.ACTIVEUSER;
    }
  },

  methods: {
    changeToggle(val) {
      this.$store.commit("SET", {
        name: this.name,
        value: val
      });
    },
    changeOutputFormat(useholst, posnum) {
      this.$store.commit("SET", {
        name: "useholst",
        value: useholst
      });
      this.$store.commit("SET", {
        name: "posnum",
        value: posnum
      });
      if (this.activeUser) {
        this.$store.dispatch("SAVE_SETTINGS", {
          id: this.f.getId(),
          settings: JSON.stringify(this.f.getSettingsObject()),
          action: "savesettings",
          common: 0
        });
      }
      this.$store.commit("UPDATE_CANVAS");
    }
  }
};
</script>

<style lang="sass">
.hosttitle
  font-size: 12px
  padding: 0 8px
.holsts
  overflow: hidden
.withholst
  width: 146px
  float: left
  height: 120px
  position: relative
  margin: 6px
  background: #fffdec
  border: 1px solid #a9a9a9
  cursor: pointer
.holstname
  position: absolute
  font-size: 22px
  font-weight: 700
  color: #bfbfbf
  line-height: 100%
  padding: 4px
.henvelope
  position: absolute
.hright
  top: 0
  right: 0
.hleft
  top: 0
  left: 0
.henvelope
  background: url('/images/stips/smallenvelope.png')
  width: 110px
  height: 73px
  border: 1px dashed #989898
  background-size: contain
.envelopesize
  position: absolute
  left: 72px
  font-size: 22px
  color: #4a4a4a
  font-weight: 700
  text-transform: uppercase
  line-height: 100%
  top: 16px
.ebottom
  bottom: 1px
.etop
  top: 1px
.emiddle
  top: 22px
.fheightdrop .emiddle
  top: 12px
.eleft
  left: 1px
.eright
  right: 1px
.fullhost
  width: 146px
  height: 100px
  background: #fffdec
  margin: 6px
  cursor: pointer
.fullenvelope
  background: url('/images/stips/middlenvelope.png')
  width: 146px
  height: 100px
  background-size: contain
  position: relative
.fullenvelopesize
  position: absolute
  left: 108px
  font-size: 22px
  color: #4a4a4a
  font-weight: 700
  text-transform: uppercase
  line-height: 100%
  top: 29px
.activef, .activef:hover
  outline: 5px solid #10a8ab!important
.fullhost:hover, .withholst:hover
  outline: 5px solid #a9a9a9
</style>