<template>
  <div :class="'hcontrolinput ' + contentclass">
    <div class="btitle float-left">{{ title }}</div>
    <div class="ttip float-left">
      <q-icon size="xs" name="help_outline" @click="$emit('tip', name)" />
    </div>
    <div class="siouter float-left q-ml-md">
      <q-input
        v-if="num"
        color="secondary"
        bg-color="white"
        filled
        square
        unelevated
        v-model="text"
        mask="#############"
        dense
        class="settingsinp"
        @input="updateInputSettings"
        debounce="800"
      />
      <q-input
        v-else
        color="secondary"
        bg-color="white"
        filled
        square
        unelevated
        v-model="text"
        dense
        class="settingsinp"
        @input="updateInputSettings"
        debounce="800"
      />
    </div>
  </div>
</template>


<script>
export default {
  props: {
    val: { default: null },
    name: { type: String, default: null },
    title: { tybe: String, default: "" },
    contentclass: { type: String, default: "" },
    settingtype: { type: String, default: "format" },
    num: { type: Boolean, default: false },
  },
  data() {
    return {
      text: this.val,
    };
  },
  watch: {
    val: {
      immediate: true,
      deep: true,
      handler(newValue) {
        this.text = newValue;
      },
    },
  },
  methods: {
    updateInputSettings(nv) {
      if (this.settingtype == "common") {
        this.$store.commit("SETCOMMON", {
          name: this.name,
          value: this.num ? parseInt(nv) : nv,
        });
      } else {
        this.$store.commit("SET", {
          name: this.name,
          value: nv,
        });
      }
    },
  },
};
</script>

<style lang="sass">
.hcontrolinput
  padding: 6px 8px
  overflow: hidden
  border-bottom: 1px dashed #b3b0b0
.hcontrolinput.floatleftinp
  border-bottom: none
.q-expansion-item__content>.hcontrolinput:nth-last-child(1)
  border-bottom: none
  padding-bottom: 24px
.q-expansion-item__content>.hcontrolinput:nth-child(1)
  border-top: 1px dashed #ccc
  padding-top: 24px
.floatleftinp
  float: left
  padding-top: 13px
.floatrightinp .siouter
  float: right!important
.floatrightinp .settingsinp
  width: 70px
.settingsinp
  font-size: 12px!important
  width: 188px
  &.q-field--dense .q-field__control
    height: 22px
    padding: 0 6px
    &.q-field--dense .q-field__marginal
      height: 22px
      padding: 0 6px
</style>