import { render, staticRenderFns } from "./Chat.vue?vue&type=template&id=3d315e04"
import script from "./Chat.vue?vue&type=script&lang=js"
export * from "./Chat.vue?vue&type=script&lang=js"
import style0 from "./Chat.vue?vue&type=style&index=0&id=3d315e04&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QBadge,QIcon});
