<template>
  <q-slider
    v-model="value"
    :min="0"
    :max="100"
    color="grey-7"
    @change="update"
    label
    :label-value="'Размер логотипа ' + value + '%'"
    label-always
  />
</template>


<script>
export default {
  props: {
    val: { type: Number, default: null }
  },
  data() {
    return {
      tid: null,
      value: 0
    };
  },
  computed: {
    f() {
      return this.$store.getters.CURFORMAT_OBJECT;
    }
  },
  watch: {
    val: function() {
      this.value = this.val;
    }
  },
  mounted() {
    this.value = this.val;
  },
  methods: {
    update(nv) {
      this.$store.commit("SET", { name: "logoSize", value: nv });
      this.$store.commit("UPDATE_CANVAS");
      this.$store.dispatch("SAVE_SETTINGS", {
        id: this.f.getId(),
        settings: JSON.stringify(this.f.getSettingsObject()),
        action: "savesettings",
        common: 0
      });
    }
  }
};
</script>

<style lang="sass">
.hsliderouter
    width: 30%
    float: right
    padding-right: 10px
.hsin .btitle
    margin-top: 10px
.hsin .ttip
    margin-top: 8px
.hsqb
    float: right
    margin-left: 10px
    min-width: 42px
    margin-top: 11px
    text-align: center
</style>