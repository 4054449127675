<template>
  <q-dialog
    v-model="show"
    persistent
    transition-show="scale"
    transition-hide="scale"
  >
    <q-card class="mypdial text-white printtipdial">
      <q-card-section class="dialhead">
        <div class="text-h6">Добавить пометку на конверт</div>
      </q-card-section>
      <q-card-section class="q-pb-none">
        <div class="row">
          <div class="col">
            <q-radio
              v-model="mode"
              dark
              color="white"
              val="auto"
              label="Сформировать автоматически"
            />
            <q-radio
              class="q-ml-md"
              color="white"
              v-model="mode"
              dark
              val="hand"
              label="Ввести внучную"
            />
            <q-radio
              class=""
              color="white"
              v-model="mode"
              dark
              val="recep"
              label="Взять из получателя"
            />
          </div>
        </div>
        <div class="row q-mt-md" v-show="mode == 'auto'">
          <div class="col text-yellow-3 q-px-sm">
            <p>
              Формируется автоматически, если выбран тип отправления "Конверт"
            </p>
            <p>
              Автоматически добавится информация "Заказное" или "Ценное" с
              описью вложения. Если заполнена опись - в пометку добавится сумма
              описи.
            </p>
          </div>
        </div>
        <div class="row q-mt-md" v-show="mode == 'recep'">
          <div class="col text-yellow-3 q-px-sm">
            <p>
              У каждого получателя есть дополнительное поле "Пометка".
              Информация из этого поля будет напечатана на конверте.
            </p>
          </div>
        </div>
      </q-card-section>
      <q-card-section v-show="mode == 'hand'">
        <div class="row">
          <div class="col">
            <q-input
              dense
              bg-color="white"
              color="accent"
              clearable
              filled
              class="q-my-xs"
              v-model="inputTip"
              placeholder="Введите текст пометки"
            />
          </div>
        </div>
      </q-card-section>

      <q-card-actions
        align="right"
        class="q-px-md q-pt-md q-pb-md text-white dialbtns"
      >
        <q-btn
          unelevated
          label="Ок"
          no-caps
          @click="done(true)"
          color="accent"
        />
        <q-btn
          unelevated
          label="Отмена"
          v-close-popup
          no-caps
          @click="done(false)"
          color="accent"
          class="q-ml-md"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    recepientsIds: {
      type: Array,
      default: null
    },
    recepientsList: {
      type: Array,
      default: null
    },
    openedRecep: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      inputTip: null,
      mode: "hand"
    };
  },
  computed: {
    mass() {
      return this.$router.currentRoute.name == "mass";
    },
    f() {
      return this.$store.getters.CURFORMAT_OBJECT;
    },
    s() {
      return this.$store.getters.SETTINGS_OBJECT;
    },
    tipP() {
      return this.openedRecep !== null ? this.openedRecep.tip : "";
    },
    defaulTipP() {
      return this.s !== undefined ? this.f.get("zakazText") : "";
    }
  },

  watch: {
    show(val) {
      if (val == true) {
        this.inputTip = this.tipP !== "" ? this.tipP : this.defaulTipP;
        if (this.mass && this.openedRecep === null) {
          this.mode = "auto";
        } else {
          this.mode = "hand";
        }
      }
    },
    mode(val) {
      if (val == "auto" && this.openedRecep !== null) {
        this.inputTip = this.openedRecep.GenerateTip();
      }
    }
  },
  methods: {
    async generateMassTip(clear = false) {
      let tips = [];
      for (let i = 0, len = this.recepientsIds.length; i < len; i++) {
        let tip = "";
        if (!clear) {
          if (this.mode == "auto") {
            tip = this.recepientsList[i].GenerateTip();
          } else if (this.mode == "hand") {
            tip = this.recepientsList[i].GenerateTipSimple(this.inputTip);
          } else if (this.mode == "recep") {
            tip = this.recepientsList[i].comment2;
          }
        }
        tips[this.recepientsIds[i]] = tip;
      }
      this.$store.dispatch("CHANGE_AND_REPLACE_RECEPIENTS_IN_LIST", {
        selected: this.recepientsIds,
        newData: {
          keyName: "tip",
          values: tips
        },
        progressText: clear
          ? "Добавление пометок для печати на конвертах"
          : "Удаление пометок для печати на конвертах"
      });
    },
    generateSingleTip() {
      this.$store.commit("SET_TIP_TO_RECEP", {
        recep: this.openedRecep,
        tip: this.inputTip
      });
    },
    updateTipInCommonSettings() {
      if (this.inputTip != "" && this.inputTip != this.defaulTip) {
        this.$store.commit("SET", {
          name: "zakazText",
          value: this.inputTip
        });
        this.$store.dispatch("SAVE_SETTINGS", {
          id: this.f.getId(),
          settings: JSON.stringify(this.f.getSettingsObject()),
          action: "savesettings",
          common: 0
        });
      }
    },
    done(payload) {
      if (payload == true) {
        if (this.mass && this.openedRecep === null) {
          this.generateMassTip();
        } else {
          this.generateSingleTip();
          this.updateTipInCommonSettings();
          this.$store.commit("UPDATE_CANVAS");
        }
      }
      this.$emit("update:show", false);
      if (this.mass) {
        this.$emit("update:openedRecep", null);
      }
    },
    clearTip(payload) {
      this.$store.commit("SET_TIP_TO_RECEP", {
        recep: payload.recep,
        tip: ""
      });
    },
    clearTipMass() {
      this.generateMassTip(true);
    }
  },
  mounted() {
    this.$eventBus.$on("clear-tip", this.clearTip);
    this.$eventBus.$on("clear-tip-mass", this.clearTipMass);
  },
  beforeDestroy() {
    this.$eventBus.$off("clear-tip");
    this.$eventBus.$off("clear-tip-mass");
  }
};
</script>

<style lang="sass">
.printtipdial
  width: 520px
</style>
