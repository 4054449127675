<template>
  <div class="overflow-hidden">
    <div class="q-mb-md row panelrow">
      <q-btn
        dense
        color="primary-light"
        unelevated
        icon="person_add"
        size="md"
        class="q-mr-sm"
        @click="openEditor('create')"
      >
        <q-tooltip
          transition-show="flip-right"
          transition-hide="flip-left"
          content-class="bg-accent fs14px"
          >Создать отправителя
        </q-tooltip>
      </q-btn>

      <q-input
        v-model="search"
        input-class="text-left"
        class="recepsearchinp"
        bg-color="primary-light"
        placeholder="Поиск.."
        color="yellow"
        debounce="700"
        dark
      >
        <template v-slot:prepend>
          <q-icon v-if="search === null" name="search" color="grey-3" />
          <q-icon
            v-else
            name="clear"
            class="cursor-pointer"
            color="accent"
            @click="clearSearch"
          />
        </template>
      </q-input>
    </div>

    <q-scroll-area
      ref="chatScrollArea"
      class="tgroupsarea bg-primary-light q-pa-md"
      :thumb-style="trthumbStyle"
    >
      <q-list>
        <q-item
          v-for="sender in senders"
          :key="sender.id"
          class="q-px-none sitem"
          v-show="
            filteredSenders.length == 0 || ~filteredSenders.indexOf(sender.id)
          "
        >
          <q-item-section top avatar>
            <q-avatar
              :class="
                sender.logo_uid ? 'bg-white logotype' : 'bg-primary logotype'
              "
              @click="openLogogomaker(sender.id)"
            >
              <q-img
                v-if="sender.logo_uid"
                :src="logopath + '/i/logo/' + sender.logo_uid"
              />
              <span class="logoplaceholder" v-else>Лого</span>
            </q-avatar>
          </q-item-section>
          <q-item-section
            class="cursor-pointer"
            @click="openEditor('edit', sender.id)"
          >
            <q-item-label class="text-left">{{ sender.name }}</q-item-label>

            <q-item-label class="text-grey-5" caption lines="2">{{
              sender.addr
            }}</q-item-label>
          </q-item-section>

          <q-item-section side>
            <div class="row">
              <q-toggle
                size="xs"
                color="secondary"
                :value="sender.id == activesender"
                @input="setActiveSender(sender.id)"
              />
              <q-icon
                name="close"
                color="accent"
                class="cursor-pointer q-pt-sm"
                @click="deleteSender(sender.id)"
              />
            </div>
          </q-item-section>
        </q-item>
      </q-list>
    </q-scroll-area>
    <q-dialog persistent position="left" v-model="editor" color="text-grey-7">
      <Editor
        :issender="true"
        :editor.sync="editor"
        :record="contragent"
        :editmode="editmode"
      />
    </q-dialog>

    <q-dialog
      v-model="deletedialog"
      transition-show="scale"
      class="transall mwnone"
      transition-hide="scale"
    >
      <q-card class="bg-primary text-white transall mwnone">
        <q-card-section class="q-pa-md">
          <div class="text-h6 text-left">Удалить отправителя ?</div>
        </q-card-section>
        <q-card-actions class="q-px-md q-pb-md">
          <q-btn outline v-focus label="Да" @click="deleteSenderFromDialog" />
          <q-space />
          <q-btn outline label="Нет" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>
    <q-dialog v-model="logomaker" color="text-grey-7" class="lmdialog">
      <Logomaker :rec="contragent" />
    </q-dialog>
  </div>
</template>

<script>
import Sender from "src/service/classes/sender.js";
import Editor from "src/service/components/common/Editor.vue";
import Logomaker from "src/service/components/common/Logomaker.vue";
import { sconfig } from "src/boot/axios";

export default {
  components: {
    Editor,
    Logomaker
  },
  data() {
    return {
      search: null,
      trthumbStyle: {
        right: "px",
        borderRadius: "5px",
        backgroundColor: "#11a8ab",
        width: "7px",
        opacity: 0.75
      },
      //editor variables
      editor: false,
      editmode: false,
      contragent: new Sender({
        name: "",
        fullname: "",
        zip: "",
        addr: "",
        comment: "",
        comment2: "",
        gids: [],
        issender: 1,
        cursender: 0,
        logo_uid: null,
        autoformat: 1,
        digitzip: 1
      }),
      currentsender: null,
      senderfordelete: null,
      //List variables

      deletedialog: false,
      logomaker: false,
      filteredSenders: []
      //groups
    };
  },
  computed: {
    logopath() {
      return sconfig.logopath;
    },
    senders() {
      return this.$store.getters.GET_SENDERS;
    },
    activesender() {
      let active = this.senders.find(item => item.cursender == 1);
      if (active) {
        return active.id;
      } else {
        return null;
      }
    }
  },
  watch: {
    search(nv) {
      if (nv !== null && nv.length > 1) {
        this.filteredSenders = this.senders.map(item => {
          let tpl = (
            item.fullname +
            "##" +
            item.comment +
            "##" +
            item.comment2
          ).toLowerCase();
          if (~tpl.indexOf(nv)) {
            return item.id;
          } else {
            return 0;
          }
        });
      } else if (nv === null || nv.length <= 1) {
        this.filteredSenders = [];
      }
      if (nv === "") {
        this.search = null;
      }
    }
  },
  methods: {
    clearSearch() {
      this.search = null;
      this.filteredSenders = [];
    },
    setActiveSender(id) {
      this.$store.dispatch("CHANGE_ACTIVE_SENDER", {
        rec: { id: id },
        action: "setactivesender"
      });
    },
    deleteSender(id) {
      this.deletedialog = true;
      this.senderfordelete = id;
    },
    deleteSenderFromDialog() {
      this.contragent = this.senders.find(
        item => item.id == this.senderfordelete
      );
      this.$store.dispatch("DELETE_SENDER", {
        action: "deletesender",
        rec: this.contragent.Export()
      });
      this.deletedialog = false;
      this.senderfordelete = null;
    },
    //Editor block
    openEditor(mode, sid = null) {
      this.contragent = new Sender({
        name: "",
        fullname: "",
        zip: "",
        addr: "",
        comment: "",
        comment2: "",
        gids: [],
        issender: 1,
        cursender: 0,
        logo_uid: null,
        autoformat: 1,
        digitzip: 1
      });
      let record = "";
      if (mode == "create") {
        this.editmode = false;
      } else if (mode == "edit") {
        this.editmode = true;
        record = this.senders.find(item => item.id == sid);
      }

      this.editor = true;
      this.contragent.fullname = this.editmode ? record.fullname : "";
      this.contragent.name = this.editmode ? record.name : "";
      this.contragent.zip = this.editmode ? record.zip : "";
      this.contragent.addr = this.editmode ? record.addr : "";
      this.contragent.comment = this.editmode ? record.comment : "";
      this.contragent.comment2 = this.editmode ? record.comment2 : "";
      this.contragent.gids = this.editmode ? record.gids : new Array();
      this.contragent.id = this.editmode ? record.id : undefined;
      this.contragent.cursender = this.editmode ? record.cursender : 0;
      this.contragent.logo_uid = this.editmode ? record.logo_uid : null;
      this.contragent.autoformat = this.editmode ? record.autoformat : 1;
      this.contragent.digitzip = this.editmode ? record.digitzip : 1;
    },
    openLogogomaker(sid) {
      //this.contragent = new Sender();
      let record = this.senders.find(item => item.id == sid);
      this.contragent = record;

      this.logomaker = !this.logomaker;
    }
  }
};
</script>

<style lang="sass">
.logoplaceholder
  font-size: 12px
.logotype
  cursor: pointer
.logotype:hover .q-avatar__content
  border: 2px solid #e64c65
.logotype .q-avatar__content
  border: 2px solid transparent
.sitem
  border-bottom: 1px solid rgba(255,255,255,0.12)
.ellipsised
  text-overflow: ellipsis
  overflow: hidden
  white-space: nowrap
.croppedname
  width: 80%
.recepsearchinp
  height: 32px
  width: 255px
  .q-field__marginal
    height: 34px
    font-size: 22px
  input
    padding: 0
  .q-field__control
    height: 34px
    border-radius: 4px
    padding: 0 6px
.recepsearchinp.q-field--dark .q-field__control:before
  border-color: #394264
.recepsearchinp.q-field--dark .q-field__control:hover:before
  border-color: #394264
</style>
