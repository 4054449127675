import { render, staticRenderFns } from "./VirtualArea.vue?vue&type=template&id=423e91f8"
import script from "./VirtualArea.vue?vue&type=script&lang=js"
export * from "./VirtualArea.vue?vue&type=script&lang=js"
import style0 from "./VirtualArea.vue?vue&type=style&index=0&id=423e91f8&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QVirtualScroll from 'quasar/src/components/virtual-scroll/QVirtualScroll.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QScrollArea,QVirtualScroll});
