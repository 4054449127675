<template>
  <q-btn-dropdown
    dense
    unelevated
    color="secondary"
    label="Заполнить"
    icon="done_all"
    no-caps
  >
    <q-list dense class="pagradient q-px-sm q-py-md">
      <q-item clickable v-close-popup @click="fill('PostType')">
        <q-item-section>
          <q-item-label>Тип и категория отправления</q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-btn
            class="q-ml-xl bg-ddbtn text-white"
            size="sm"
            unelevated
            dense
            icon="close"
            v-close-popup
            @click.stop="clearPostType"
          >
            <q-tooltip
              anchor="center right"
              content-class="bg-accent fs14px"
              self="center left"
              :offset="[10, 10]"
            >
              Очистить
            </q-tooltip>
          </q-btn>
        </q-item-section>
      </q-item>

      <q-item clickable v-close-popup @click="fill('Weight')">
        <q-item-section>
          <q-item-label>Вес отправления</q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-btn
            class="q-ml-xl bg-ddbtn text-white"
            size="sm"
            unelevated
            dense
            icon="close"
            v-close-popup
            @click.stop="clearWeight"
          >
            <q-tooltip
              anchor="center right"
              content-class="bg-accent fs14px"
              self="center left"
              :offset="[10, 10]"
            >
              Очистить
            </q-tooltip>
          </q-btn>
        </q-item-section>
      </q-item>

      <q-item clickable v-close-popup @click="fill('Inventory')">
        <q-item-section>
          <q-item-label>Ценность (сумма или опись Ф-119)</q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-btn
            class="q-ml-xl bg-ddbtn text-white"
            size="sm"
            unelevated
            dense
            icon="close"
            v-close-popup
            @click.stop="clearInventorys"
          >
            <q-tooltip
              anchor="center right"
              content-class="bg-accent fs14px"
              self="center left"
              :offset="[10, 10]"
            >
              Очистить
            </q-tooltip>
          </q-btn>
        </q-item-section>
      </q-item>

      <q-item clickable v-close-popup @click="fill('Note')">
        <q-item-section>
          <q-item-label>Примечание</q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-btn
            class="q-ml-xl bg-ddbtn text-white"
            size="sm"
            unelevated
            dense
            icon="close"
            v-close-popup
            @click.stop="clearNote"
          >
            <q-tooltip
              anchor="center right"
              content-class="bg-accent fs14px"
              self="center left"
              :offset="[10, 10]"
            >
              Очистить
            </q-tooltip>
          </q-btn>
        </q-item-section>
      </q-item>

      <q-item clickable v-close-popup @click="fill('Bar')">
        <q-item-section>
          <q-item-label>ШПИ (номер отправления)</q-item-label>
        </q-item-section>
        <q-item-section side>
          <div class="text-grey-8 q-gutter-xs">
            <q-btn
              class="q-ml-md bg-ddbtn text-white"
              size="sm"
              unelevated
              dense
              icon="settings"
              v-close-popup
              @click.stop="openBarSettings"
            >
              <q-tooltip
                anchor="center right"
                content-class="bg-accent fs14px"
                self="center left"
                :offset="[10, 10]"
              >
                Настроить
              </q-tooltip>
            </q-btn>
            <q-btn
              class="q-ml-md bg-ddbtn text-white"
              size="sm"
              unelevated
              dense
              icon="close"
              v-close-popup
              @click.stop="clearBarcods"
            >
              <q-tooltip
                anchor="center right"
                content-class="bg-accent fs14px"
                self="center left"
                :offset="[10, 10]"
              >
                Очистить
              </q-tooltip>
            </q-btn>
          </div>
        </q-item-section>
      </q-item>
      <q-item clickable v-close-popup @click="fill('Tip')">
        <q-item-section>
          <q-item-label>Текст пометки на конверте</q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-btn
            class="q-ml-xl bg-ddbtn text-white"
            size="sm"
            unelevated
            dense
            icon="close"
            v-close-popup
            @click.stop="clearTips"
          >
            <q-tooltip
              anchor="center right"
              content-class="bg-accent fs14px"
              self="center left"
              :offset="[10, 10]"
            >
              Убрать пометку
            </q-tooltip>
          </q-btn>
        </q-item-section>
      </q-item>
      <q-item>
        <div class="full-width">
          <q-separator class="fullwidth q-mt-md fmsep1" />
          <q-separator class="fullwidth q-mb-md fmsep2" />
        </div>
      </q-item>
      <q-item clickable v-close-popup @click="fill('RecepToSender')">
        <q-item-section avatar class="q-pr-sm faava" color="grey-3">
          <q-icon name="autorenew" />
        </q-item-section>
        <q-item-section>
          <q-item-label>Поменять отправителя с получателем</q-item-label>
        </q-item-section>
        <q-item-section side>
          <q-btn
            class="q-ml-xl bg-ddbtn text-white"
            size="sm"
            unelevated
            dense
            icon="close"
            v-close-popup
            @click.stop="clearRecepToSender"
          >
            <q-tooltip
              anchor="center right"
              content-class="bg-accent fs14px"
              self="center left"
              :offset="[10, 10]"
            >
              Отменить замену
            </q-tooltip>
          </q-btn>
        </q-item-section>
      </q-item>
    </q-list>
  </q-btn-dropdown>
</template>

<script>
export default {
  methods: {
    clearInvoices() {
      this.$eventBus.$emit("clear-invoice-mass");
    },
    clearTips() {
      this.$eventBus.$emit("clear-tip-mass");
    },
    clearInventorys() {
      this.$eventBus.$emit("clear-inventory-mass");
    },
    clearBarcods() {
      this.$eventBus.$emit("clear-barcode-mass");
    },
    clearRecepToSender() {
      this.$eventBus.$emit("clear-receptosender-mass");
    },
    clearCod() {
      this.$eventBus.$emit("clear-single-param-mass", "cod");
    },
    clearWeight() {
      this.$eventBus.$emit("clear-single-param-mass", "weight");
    },
    clearNote() {
      this.$eventBus.$emit("clear-single-param-mass", "note");
    },
    clearPostType() {
      this.$eventBus.$emit("clear-posttype-mass");
    },
    fill(doctype) {
      this.$emit("fill", doctype);
    },
    openBarSettings() {
      this.$eventBus.$emit("open-tab-settings", {
        tabName: "Bar"
      });
    }
  }
};
</script>

<style lang="sass">
.bg-ddbtn
  background: rgba(0,0,0, 0.15)
.fmsep1
  background: #133642
.fmsep2
  background: #22617b
.faava
  min-width: 0
.pagradient
  background-image: linear-gradient(to bottom, #0a8b8e 0%, #252c44 100%)!important
</style>
