import { api } from "src/boot/axios";
import Sender from "src/service/classes/sender.js";
import Vue from "vue";
export default {
  state: {
    senders: [],
    activesender: null
  },

  getters: {
    GET_SENDERS: state => {
      return state.senders;
    },
    GET_ACTIVESENDER: () => {},
    GET_CURSENDER: state => {
      let cs = state.senders.filter(obj => obj.cursender == 1);
      if (typeof cs[0] !== "undefined") {
        return cs[0];
      } else {
        return null;
      }
    }
  },
  mutations: {
    SET_SENDERS(state, payload) {
      state.senders = [];
      for (let record of payload) {
        state.senders.push(new Sender(record));
      }
      this.commit("SET_SENDERS_LOADED");
    },
    SET_ACTIVE_SENDER(state, payload) {
      for (let sender of state.senders) {
        if (sender.id == payload.rec.id) {
          sender.cursender = 1;
        } else {
          sender.cursender = 0;
        }
      }
    },
    ADD_NEW_SENDER(state, payload) {
      let newrec = new Sender(payload);
      state.senders.push(newrec);
    },
    UPDATE_SENDER(state, payload) {
      let newrec = new Sender(payload);
      let index = state.senders.findIndex(item => item.id == payload.id);
      Vue.set(state.senders, index, newrec);
    },
    UPDATE_SENDER_LOGO(state, payload) {
      let sender = state.senders.find(item => item.id == payload.id);
      sender.logo_uid = payload.logo_uid;
      sender.logo64 = payload.logo64;
    },
    UPDATE_LOGO_FINISHED() {}
  },
  actions: {
    LOAD_SENDERS(context) {
      api
        .post("/api/sender/", {
          action: "loadsenders"
        })
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
            //context.commit("SET_ACCOUNT", response.data);
          } else {
            context.commit("SET_SENDERS", response.data.body);
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при загрузке отправителей. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "LOAD_SENDERS action"
          });
        });
    },
    CHANGE_ACTIVE_SENDER(context, payload) {
      context.commit("SET_ACTIVE_SENDER", payload);
      api
        .post("/api/sender/", payload)
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при установке отправителя по-умолчанию. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "CHANGE_ACTIVE_SENDER action"
          });
        });
    },
    CREATE_SENDER(context, payload) {
      api
        .post("/api/sender/", payload)
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            context.commit("ADD_NEW_SENDER", response.data.body.record);
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при создании отправителя. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "CREATE_SENDER action"
          });
        });
    },
    UPDATE_SENDER(context, payload) {
      api
        .post("/api/sender/", payload)
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            context.commit("UPDATE_SENDER", response.data.body.record);
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при редактировании отправителя. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "CREATE_SENDER action"
          });
        });
    },
    DELETE_SENDER(context, payload) {
      api
        .post("/api/sender/", payload)
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            context.dispatch("LOAD_SENDERS");
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при удалении отправителя. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "DELETE_SENDER action"
          });
        });
    },
    SAVE_LOGO(context, payload) {
      api
        .post("/api/sender/", payload)
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            context.commit("UPDATE_SENDER_LOGO", response.data.body.record);
            context.commit("UPDATE_LOGO_FINISHED");
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при сохранении логотипа. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "UPDATE_SENDER action"
          });
          context.commit("UPDATE_LOGO_FINISHED");
        });
    },
    DELETE_LOGO(context, payload) {
      api
        .post("/api/sender/", payload)
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            context.commit("UPDATE_SENDER_LOGO", response.data.body.record);
            context.commit("UPDATE_LOGO_FINISHED");
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при удалении логотипа. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "DELETE_SENDER action"
          });
        });
    }
  },
  modules: {}
};
