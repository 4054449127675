import { api } from "src/boot/axios";
import Group from "src/service/classes/group.js";

export default {
  state: {
    groups: null
  },

  getters: {
    GET_GROUPS: state => {
      return state.groups;
    }
  },
  mutations: {
    SET_GROUPS(state, payload) {
      state.groups = [];
      if (payload != null) {
        for (let record of payload) {
          state.groups.push(new Group(record));
        }
      }
      this.commit("SET_GROUPS_LOADED");
    }
  },
  actions: {
    LOAD_GROUPS(context) {
      api
        .post("/api/group/", {
          action: "loadgroups"
        })
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            context.commit("SET_GROUPS", response.data.body);
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при загрузке групп получателей. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "LOAD_GROUPS action"
          });
        });
    },
    CREATE_GROUP(context, payload) {
      api
        .post("/api/group/", payload)
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            context.dispatch("LOAD_GROUPS", response.data.body);
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при создании группы получателей. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "CREATE_GROUP action"
          });
        });
    },
    UPDATE_GROUP(context, payload) {
      api
        .post("/api/group/", payload)
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            context.dispatch("LOAD_GROUPS", response.data.body);
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при обновлении имени группы. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "UPDATE_GROUP action"
          });
        });
    },
    DELETE_GROUP(context, payload) {
      api
        .post("/api/group/", payload)
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            context.dispatch("LOAD_GROUPS", response.data.body);
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при удалении группы. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "UPDATE_GROUP action"
          });
        });
    }
  },
  modules: {}
};
