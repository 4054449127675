import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { Cookies } from "quasar";
import { api } from "src/boot/axios";
import { Platform } from "quasar";

export default {
  state: {
    fid: null,
    oldfid: null
  },

  getters: {
    FID: state => {
      return state.fid;
    },
    OLD_FID: state => {
      return state.oldfid;
    }
  },

  mutations: {
    SET_FID: (state, payload) => {
      state.fid = payload;
    },
    SET_OLD_FID: (state, payload) => {
      state.oldfid = payload;
    }
  },

  actions: {
    GEN_MARK: async context => {
      try {
        if (!Cookies.has("fid")) {
          const fp = await FingerprintJS.load();
          const result = await fp.get();
          const visitorId = result.visitorId;

          Cookies.set("fid", visitorId, { expires: "24h", path: "/" });
          if (!Cookies.has("oldfid")) {
            Cookies.set("oldfid", visitorId, { expires: "365d", path: "/" });
          }
          context.commit("SET_FID", visitorId);
        } else {
          context.commit("SET_FID", Cookies.get("fid"));
        }

        if (
          Cookies.has("oldfid") &&
          Cookies.has("fid") &&
          Cookies.get("oldfid") !== Cookies.get("fid")
        ) {
          context.commit("SET_OLD_FID", Cookies.get("oldfid"));
        }
      } catch (err) {
        context.commit("SET_ERROR", {
          error: true,
          errortext:
            "Ошибка маркировки. Информация передана разработчикам. Попробуйте позже"
        });
        context.dispatch("LOG_ERROR", {
          error: err,
          desc: "Update mark function"
        });
      }
    },
    SEND_MARK_SERVER: async context => {
      setTimeout(() => {
        api
          .post("/api/marker/", {
            hash: context.getters.FID,
            oldhash: context.getters.OLD_FID,
            action: "updatemark",
            browser: Platform.is.name
          })
          .catch(error => {
            context.dispatch("LOG_ERROR", {
              error: error,
              desc: "SEND_MARK_SERVER action"
            });
          });
        if (
          Cookies.has("oldfid") &&
          Cookies.has("fid") &&
          Cookies.get("oldfid") !== Cookies.get("fid")
        ) {
          Cookies.set("oldfid", null, { expires: "-1d", path: "/" });
          context.commit("SET_OLD_FID", null);
        }
      }, 5000);
    }
  }
};
