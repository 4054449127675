import { render, staticRenderFns } from "./ChatWindow.vue?vue&type=template&id=b41df0fe"
import script from "./ChatWindow.vue?vue&type=script&lang=js"
export * from "./ChatWindow.vue?vue&type=script&lang=js"
import style0 from "./ChatWindow.vue?vue&type=style&index=0&id=b41df0fe&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QChatMessage from 'quasar/src/components/chat/QChatMessage.js';
import QResizeObserver from 'quasar/src/components/resize-observer/QResizeObserver.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QToolbar,QToolbarTitle,QBtn,QSeparator,QCard,QCardSection,QScrollArea,QChatMessage,QResizeObserver,QInput,QSpace,QField});
