<template>
  <div class="q-pa-xl">
    <h1 class="fs32 lhnormal q-mt-none q-mb-md text-weight-regular">
      Восстановление доступа с помощью Email-а
    </h1>
    <q-stepper
      v-model="step"
      ref="stepper"
      color="primary"
      animated
      class="bg-primary-light wd800 pqstepper"
      active-color="yellow"
      done-color="light-green-12"
      dark
    >
      <q-step
        :name="1"
        prefix="1"
        title="Введите email,"
        caption="привязанный к вашему аккауту"
        icon="email"
        :done="step > 1"
      >
        <p>На указанный email придет письмо с проверочным кодом.</p>
        <q-input
          outlined
          bg-color="white"
          ref="recoveryaccount"
          v-model="recoveryaccount"
          placeholder="Email или логин"
          class="wd375"
          dense
          required
          lazy-rules
          :rules="[
            val => (val && val.length > 0) || 'Заполните это поле пожалуйста',
            val =>
              !/[а-яА-ЯёЁ]/g.test(val) ||
              'Пожалуйста, не используйте русские буквы'
          ]"
        >
          <template v-slot:prepend>
            <q-icon name="email" />
          </template>
        </q-input>
      </q-step>

      <q-step
        :name="2"
        prefix="2"
        title="Введите код,"
        caption="полученный в письме"
        icon="vpn_key"
        :done="step > 2"
      >
        <p>Проверочный код из полученного письма</p>
        <q-input
          color="accent"
          outlined
          bg-color="white"
          ref="code"
          v-model="code"
          placeholder="Проверочный код"
          dense
          class="wd270"
          required
          lazy-rules
          :rules="[
            val => (val && val.length > 0) || 'Заполните это поле пожалуйста',
            val =>
              !/[а-яА-ЯёЁ]/g.test(val) ||
              'Пожалуйста, не используйте русские буквы'
          ]"
        >
          <template v-slot:prepend>
            <q-icon name="vpn_key" />
          </template>
        </q-input>
      </q-step>

      <q-step
        :name="3"
        prefix="3"
        title="Введите новый пароль"
        caption="чтобы продолжить работу"
        icon="assignment"
      >
        <p>Введите новый пароль дважды</p>
        <q-input
          class="wd270 q-mb-sm float-left q-mr-lg"
          outlined
          bg-color="white"
          v-model="password"
          ref="password"
          :type="isPwd ? 'password' : 'text'"
          placeholder="Пароль"
          dense
          required
          lazy-rules
          :rules="[
            val => (val && val.length > 0) || 'Пароль не указан',
            val =>
              !/[а-яА-ЯёЁ]/g.test(val) ||
              'Пожалуйста, не используйте русские буквы'
          ]"
        >
          <template v-slot:prepend>
            <q-icon name="lock" />
          </template>
          <template v-slot:append>
            <q-icon
              :name="isPwd ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="isPwd = !isPwd"
            />
          </template>
        </q-input>

        <q-input
          class="wd270"
          outlined
          bg-color="white"
          v-model="passwordconfirm"
          ref="passwordconfirm"
          :type="isPwd ? 'password' : 'text'"
          placeholder="Пароль (еще раз)"
          dense
          required
          lazy-rules
          :rules="[
            val => (val && val.length > 0) || 'Пароль не указан',
            val => (val && val == this.password) || 'Пароли не совпадают',
            val =>
              !/[а-яА-ЯёЁ]/g.test(val) ||
              'Пожалуйста, не используйте русские буквы'
          ]"
        >
          <template v-slot:prepend>
            <q-icon name="lock" />
          </template>
          <template v-slot:append>
            <q-icon
              :name="isPwd ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="isPwd = !isPwd"
            />
          </template>
        </q-input>
      </q-step>

      <template v-slot:navigation>
        <q-stepper-navigation>
          <div
            v-if="step == 3"
            class="q-mt-lg col"
            @click="accepting = !accepting"
            style="max-width: 200px; line-height: 125%; float: left"
          >
            <a
              class="col text-white"
              href="https://777.xn--l1adcnfw.xn--p1ai/api/authcookie/"
              target="_blank"
              style="text-decoration: none"
              ><q-icon
                class="col-auto q-mr-sm q-mb-lg float-left"
                :name="
                  !(accepting || $q.cookies.has('cookieaccepted'))
                    ? 'check_box_outline_blank'
                    : 'check_box'
                "
                size="md"
              />Согласие с политикой конфиденциальности</a
            >
          </div>
          <q-btn
            @click="nextStep()"
            :disable="
              step == 3 && !(accepting || $q.cookies.has('cookieaccepted'))
            "
            class="q-mt-lg"
            unelevated
            no-caps
            :loading="progress"
            color="accent"
            :label="
              step === 1
                ? 'Получить проверочный код'
                : step === 2
                ? 'Ввести новый пароль'
                : 'Изменить пароль и войти'
            "
          >
            <template v-slot:loading>
              <q-spinner-hourglass class="on-left" />Обработка запроса
            </template>
          </q-btn>
          <q-btn
            v-if="step > 1"
            class="q-mt-lg q-ml-md"
            flat
            no-caps
            color="grey-5"
            @click="$refs.stepper.previous()"
            label="Назад"
          />
        </q-stepper-navigation>
      </template>
    </q-stepper>
    <div class="q-mt-lg">
      <h1 class="fs32 lhnormal q-mt-xl q-mb-md text-weight-regular">
        Восстановление доступа по списку получателей
      </h1>
      <div class="wd800 rounded-borders secondmethod q-pa-lg bg-primary-light">
        <p>
          Данный метод восстановления доступа вы можете использовать, если к
          вашему аккаунту не привязан адрес электронной почты.
        </p>
        <p>
          Требуется участие сотрудника техподдержки, поэтому время ответа может
          составлять до нескольких часов.
        </p>
        <p>
          Укажите наименование и адрес отправителя, а также наименование 3-х
          получателей.
        </p>
        <q-form @submit="recoveryByNames" autocomplete="off">
          <q-input
            outlined
            bg-color="white"
            ref="name1"
            v-model="name1"
            placeholder="Наименование и адрес отправителя"
            dense
            class="wd492 q-mt-lg q-mb-md float-left q-mr-md"
            lazy-rules
            :rules="[
              val => (val && val.length > 0) || 'Заполните это поле пожалуйста'
            ]"
          />
          <q-input
            type="email"
            outlined
            bg-color="white"
            ref="emailfornames"
            v-model="emailfornames"
            placeholder="Ваш Email"
            class="wd238 q-mt-lg q-mb-md float-left"
            dense
            lazy-rules
            :rules="[
              val => (val && val.length > 0) || 'Заполните это поле пожалуйста',
              val =>
                !/[а-яА-ЯёЁ]/g.test(val) ||
                'Пожалуйста, не используйте русские буквы'
            ]"
          >
            <template v-slot:prepend>
              <q-icon name="email" />
            </template>
          </q-input>

          <q-input
            outlined
            bg-color="white"
            ref="name2"
            v-model="name2"
            placeholder="Наименование получателя №1"
            dense
            class="wd238 float-left q-mr-md"
            lazy-rules
            :rules="[
              val => (val && val.length > 0) || 'Заполните это поле пожалуйста'
            ]"
          />
          <q-input
            outlined
            bg-color="white"
            ref="name3"
            v-model="name3"
            placeholder="Наименование получателя №2"
            dense
            class="wd238 float-left q-mr-md"
            lazy-rules
            :rules="[
              val => (val && val.length > 0) || 'Заполните это поле пожалуйста'
            ]"
          />
          <q-input
            outlined
            bg-color="white"
            ref="name4"
            v-model="name4"
            placeholder="Наименование получателя №3"
            dense
            class="wd238 float-left"
            lazy-rules
            :rules="[
              val => (val && val.length > 0) || 'Заполните это поле пожалуйста'
            ]"
          />
          <q-btn
            type="submit"
            ref="btnfornames"
            class="q-mt-md wd238"
            unelevated
            no-caps
            :disabled="btndisabled"
            color="accent"
            :label="btntext"
          />
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  meta: {
    // sets document title
    title: "Восстановление доступа к аккаунту",
    // meta tags
    meta: {
      description: {
        name: "description",
        content:
          "Восстановление доступа к аккаунту поможет вам, если вы не можете войти в программу."
      },
      keywords: {
        name: "keywords",
        content: "восстановить, логин, пароль"
      },
      ogTitle: {
        name: "Восстановление доступа к аккаунту"
      }
    }
  },
  data() {
    return {
      step: 1,
      recoveryaccount: "",
      code: "",
      password: "",
      passwordconfirm: "",
      isPwd: true,
      name1: "",
      name2: "",
      name3: "",
      name4: "",
      emailfornames: "",
      progress: false,
      btndisabled: false,
      btntext: "Отправить запрос",
      accepting: false
    };
  },
  methods: {
    async recoveryByNames() {
      try {
        this.btntext = "Обработка запроса";
        await new Promise(resolve => setTimeout(resolve, 1500));
        let stepResult = await this.$store.dispatch("RESET_PASSWORD", {
          step: 5,
          name1: this.name1,
          name2: this.name2,
          name3: this.name3,
          name4: this.name4,
          fid: this.$store.getters.FID,
          email: this.emailfornames
        });
        if (!stepResult.error) {
          this.btndisabled = true;
          this.btntext = "Запрос принят";
        } else {
          this.btntext = "Отправить запрос";
        }
        this.progress = false;
      } catch (err) {
        this.btndisabled = false;
        this.btntext = "Отправить запрос";
        this.$store.commit("SET_ERROR", {
          error: true,
          errortext:
            "Ошибка при попытке восстановить пароль. Информация передана разработчикам. Попробуйте позже"
        });
        this.$store.dispatch("LOG_ERROR", {
          error: err,
          desc: "recoveryByNames function"
        });
      }
    },
    async nextStep() {
      try {
        this.progress = true;
        await new Promise(resolve => setTimeout(resolve, 1500));
        if (this.step == 1) {
          this.$refs.recoveryaccount.validate();
          if (!this.$refs.recoveryaccount.hasError) {
            let stepResult = await this.$store.dispatch("RESET_PASSWORD", {
              step: this.step,
              accoremail: this.recoveryaccount,
              code: this.code,
              password: this.password
            });
            if (!stepResult.error) {
              this.$refs.stepper.next();
              this.$store.commit("RECOVERY_EMAIL_REQUEST");
            }
          }
          this.progress = false;
          return;
        }
        if (this.step == 2) {
          this.$refs.code.validate();
          if (!this.$refs.code.hasError) {
            let stepResult = await this.$store.dispatch("RESET_PASSWORD", {
              step: this.step,
              accoremail: this.recoveryaccount,
              code: this.code,
              password: this.password
            });
            if (!stepResult.error) {
              this.$refs.stepper.next();
            }
          }
          this.progress = false;
          return;
        }
        if (this.step == 3) {
          this.$refs.password.validate();
          this.$refs.passwordconfirm.validate();
          if (
            !this.$refs.password.hasError &&
            !this.$refs.passwordconfirm.hasError
          ) {
            let stepResult = await this.$store.dispatch("RESET_PASSWORD", {
              step: this.step,
              accoremail: this.recoveryaccount,
              code: this.code,
              password: this.password
            });
            this.progress = false;
            if (!stepResult.error) {
              /* this.$store.commit("SET_COOKIE", {
                token: stepResult.body.token,
                series: stepResult.body.series
              }); */
              this.$store.commit("RECOVERY_EMAIL_SUCCESS");
              let l = "/";
              window.location = l;
            }
          }
        }
      } catch (err) {
        this.progress = false;
        this.$store.commit("SET_ERROR", {
          error: true,
          errortext:
            "Ошибка при попытке восстановить пароль. Информация передана разработчикам. Попробуйте позже"
        });
        this.$store.dispatch("LOG_ERROR", {
          error: err,
          desc: "recovery nextStep function"
        });
      }
    }
  }
};
</script>

<style lang="sass">
.pqstepper
.q-stepper__nav
	background: #4a5271
	border-top: 1px solid #5a6280
.q-stepper__header
	background-color: #4a5271
	border-bottom: 1px solid #434a61!important
.q-stepper__content
	border-top: 1px solid #667198
	border-bottom: 1px solid #384058
.secondmethod
	border: 1px solid rgba(255,255,255,0.28)
	overflow: hidden
</style>
