import jsPDF from "jspdf";
import PostData from "src/service/classes/posttypes.js";
import PostParser from "src/service/classes/postparser.js";
import F103Html from "src/service/classes/f103html.js";
import CommonTemplate from "src/service/classes/printCommon.js";
import ExcelJS from "exceljs";
import Parser from "src/service/classes/parsertable.js";
import { rubles } from "rubles";
import { date } from "quasar";
import { saveAs } from "file-saver";
export default class ReestrF103Template extends CommonTemplate {
  constructor(f103Params) {
    super();
    this.doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a4"
    });

    this.settings = f103Params;
    this.doc.setFont("Play");
    this.cols = [7, 59, 23, 21, 22, 19, 18, 18, 19, 18, 16, 18, 19];
    this.colsStart = [];
    this.lineHeight = 4;
    this.padding = 4;
    this.marg = 10;
    this.listTitleHeight = 13;
    this.headerHeight = 58;
    this.headerTotalHeight = 5;
    this.tableHeaderHeight = 27.5;

    this.pData = new PostData();
    this.pPars = new PostParser();
    this.RpoCount = 0;

    this.footerCategories = {
      valNds: "Сумма платы за пересылку с НДС",
      val: "Сумма платы за пересылку без НДС",
      nds: "В т.ч. НДС"
    };
    this.footerTotal = {
      gValNds: "Общая сумма платы за пересылку с НДС",
      gVal: "Общая сумма платы за пересылку без НДС",
      gNds: "В т.ч. НДС",
      sumOc: "Общая сумма объявленной ценности всех РПО",
      cValNds: "Общая сумма платы за объявленную ценность с НДС",
      cNds: "В т.ч. НДС",
      gcValNds: "Итого за пересылку, с НДС",
      gcVal: "Итого за пересылку без НДС",
      gcNds: "В т.ч. НДС"
    };

    this.footerService = {
      s0valnds:
        "Общая сумма платы за услугу «SMS-уведомление» на номер мобильного телефона отправителя с НДС",
      s0val:
        "Общая сумма платы за услугу «SMS-уведомление» на номер мобильного телефона отправителя без НДС",
      s3valnds:
        "Общая сумма платы за услугу «Пакет SMS» на номер мобильного телефона отправителя с НДС",
      s3val:
        "Общая сумма платы за услугу «Пакет  SMS» на номер мобильного телефона отправителя без НДС",
      k1valnds: "Общая сумма платы за услугу «Курьерский сбор» с НДС",
      k1val: "Общая сумма платы за услугу «Курьерский сбор» без НДС",
      undef3:
        "Общая сумма платы за услугу «SMS-уведомление» на номер мобильного телефона адресата с НДС",
      undef4:
        "Общая сумма платы за услугу «SMS-уведомление» на номер мобильного телефона адресата без НДС",
      i1valnds:
        "Общая сумма платы за услугу «Уведомление о вручении простое» с НДС",
      i1val:
        "Общая сумма платы за услугу «Уведомление о вручении простое» без НДС",
      i2valnds:
        "Общая сумма платы за услугу «Уведомление о вручении заказное» с НДС",
      i2val:
        "Общая сумма платы за услугу «Уведомление о вручении заказное» без НДС",
      i3valnds:
        "Общая сумма платы за услугу «Электронное уведомление о вручении» с НДС",
      p1valnds:
        "Общая сумма платы за услугу «Проверка соответствия вложения описи Ф 107» с НДС",
      p1val:
        "Общая сумма платы за услугу «Проверка соответствия вложения описи Ф 107» без НДС",
      unef1: "Общая сумма платы за услугу «Доставка по звонку» с НДС",
      undef2: "Общая сумма платы за услугу «Доставка по звонку» без НДС",
      p2valnds: "Общая сумма платы за услугу «Проверка комплектности» с НДС",
      p2val: "Общая сумма платы за услугу «Проверка комплектности» без НДС",
      s4valnds:
        "Общая сумма платы за услугу «Пакет SMS» на номер мобильного телефона адресата с НДС",
      s4val:
        "Общая сумма платы за услугу «Пакет SMS» на номер мобильного телефона адресата без НДС",
      stvalnds:
        "Общая сумма платы за дополнительные услуги, вошедшие в список, с НДС",
      stval:
        "Общая сумма платы за дополнительные услуги, вошедшие в список, без НДС",
      stnds: "В т.ч. НДС"
    };
  }

  InCol(num) {
    num = num - 1;
    if (this.colsStart[num] !== undefined) {
      return this.colsStart[num];
    } else {
      let x = 0;
      for (let i = 0; i < num; i++) {
        x += this.cols[i];
      }
      x = x + this.cols[num] / 2;
      this.colsStart.push(x);
      return x;
    }
  }

  DrawTabHeader(y) {
    let ac = {
      align: "center"
    };
    let padding = 4;
    let hh = 24;
    let startx = 0;
    for (let col of this.cols) {
      this.doc.rect(this.marg + startx, y, col, hh);
      startx += col;
    }
    startx = 0;
    for (let col of this.cols) {
      this.doc.rect(this.marg + startx, y + hh, col, 3.5);
      startx += col;
    }

    let nc = 1;
    this.doc.setFontSize(8);
    for (let col of this.cols) {
      this.doc.text(nc.toString(), this.marg + this.InCol(nc), y + hh + 2.7);
      nc++;
    }
    this.doc.rect(this.marg + this.cols[0], y + 3.5, this.cols[1], 3.5);
    this.doc.rect(this.marg + this.cols[0], y + 7, this.cols[1], 10);
    this.doc.rect(this.marg + this.cols[0], y + 3.5, this.cols[1], 3.5);

    this.doc.setFontSize("7.5");
    this.doc.text("№№", this.marg + this.InCol(1), y + 11, ac);
    this.doc.text("п/п", this.marg + this.InCol(1), y + 14.5, ac);

    this.doc.text("Адресат (Ф.И.О.)", this.marg + this.InCol(2), y + 2.5, ac);
    this.doc.text("Почтовый адрес", this.marg + this.InCol(2), y + 6.2, ac);
    this.doc.text(
      "Номер почтомата, наименование",
      this.marg + this.InCol(2),
      y + 10,
      { align: "center" }
    );
    this.doc.text(
      "населенного пункта, индекс",
      this.marg + this.InCol(2),
      y + 12.5,
      { align: "center" }
    );
    this.doc.text(
      "ОПС, обслуживающий почтомат",
      this.marg + this.InCol(2),
      y + 15,
      { align: "center" }
    );
    this.doc.text(
      "Номер мобильного телефона адресата",
      this.marg + this.InCol(2),
      y + 21,
      { align: "center" }
    );

    this.doc.text("ШПИ", this.marg + this.InCol(3), y + 8, ac);
    this.doc.text("(штриховой", this.marg + this.InCol(3), y + 11, ac);
    this.doc.text("почтовый", this.marg + this.InCol(3), y + 15, ac);
    this.doc.text("идентификатор)", this.marg + this.InCol(3), y + 18, ac);

    this.doc.text("Категория", this.marg + this.InCol(4), y + 12, ac);
    this.doc.text("РПО", this.marg + this.InCol(4), y + 15, ac);

    this.doc.rect(
      this.marg + this.cols[0] + this.cols[1] + this.cols[2] + this.cols[3],
      y + 6,
      this.cols[4],
      5.3
    );
    this.doc.line(
      this.marg + this.cols[0] + this.cols[1] + this.cols[2] + this.cols[3],
      y + 19.3,
      this.marg +
        this.cols[0] +
        this.cols[1] +
        this.cols[2] +
        this.cols[3] +
        this.cols[4],
      y + 19.3
    );
    this.doc.rect(
      this.marg +
        this.cols[0] +
        this.cols[1] +
        this.cols[2] +
        this.cols[3] +
        this.cols[4],
      y + 2,
      this.cols[5],
      4
    );
    this.doc.rect(
      this.marg +
        this.cols[0] +
        this.cols[1] +
        this.cols[2] +
        this.cols[3] +
        this.cols[4],
      y + 14,
      this.cols[5],
      4
    );
    this.doc.line(
      this.marg +
        this.cols[0] +
        this.cols[1] +
        this.cols[2] +
        this.cols[3] +
        this.cols[4],
      y + 22,
      this.marg +
        this.cols[0] +
        this.cols[1] +
        this.cols[2] +
        this.cols[3] +
        this.cols[4] +
        this.cols[5],
      y + 22
    );

    this.doc.text("Фактический", this.marg + this.InCol(5), y + 2.8, ac);
    this.doc.text("вес, (кг.)", this.marg + this.InCol(5), y + 5.2, ac);
    this.doc.text("Объемный", this.marg + this.InCol(5), y + 8.1, ac);
    this.doc.text("вес, (кг.)", this.marg + this.InCol(5), y + 10.5, ac);
    this.doc.text("Линейные", this.marg + this.InCol(5), y + 13.4, ac);
    this.doc.text("размеры", this.marg + this.InCol(5), y + 15.8, ac);
    this.doc.text("(ДхШхВ), (см.)", this.marg + this.InCol(5), y + 18.5, ac);
    this.doc.text("Типоразмер", this.marg + this.InCol(5), y + 21.2, ac);
    this.doc.text("(S/M/L)", this.marg + this.InCol(5), y + 23.3, ac);
    this.doc.setFontSize("6.5");
    this.doc.text("Дополн. услуги", this.marg + this.InCol(6), y + 1.7, ac);
    this.doc.text("Тип", this.marg + this.InCol(6), y + 3.7, ac);
    this.doc.text("уведомления", this.marg + this.InCol(6), y + 5.3, ac);

    this.doc.text("Проверка", this.marg + this.InCol(6), y + 7.7, ac);
    this.doc.text("соответствия", this.marg + this.InCol(6), y + 9.7, ac);
    this.doc.text("вложения", this.marg + this.InCol(6), y + 11.7, ac);
    this.doc.text("описи Ф 107", this.marg + this.InCol(6), y + 13.7, ac);
    this.doc.text("Доставка", this.marg + this.InCol(6), y + 16, ac);
    this.doc.text("по звонку", this.marg + this.InCol(6), y + 17.6, ac);
    this.doc.text("Проверка", this.marg + this.InCol(6), y + 20, ac);
    this.doc.text("комплектности", this.marg + this.InCol(6), y + 21.6, ac);
    this.doc.text("Пакет SMS", this.marg + this.InCol(6), y + 23.7, ac);

    this.doc.setFontSize("7.5");
    this.doc.text("Сумма", this.marg + this.InCol(7), y + 8, ac);
    this.doc.text("объявленной", this.marg + this.InCol(7), y + 11, ac);
    this.doc.text("ценности", this.marg + this.InCol(7), y + 14, ac);
    this.doc.text("(руб.)", this.marg + this.InCol(7), y + 17, ac);

    this.doc.text("Сумма", this.marg + this.InCol(8), y + 8, ac);
    this.doc.text("наложенного", this.marg + this.InCol(8), y + 11, ac);
    this.doc.text("платежа", this.marg + this.InCol(8), y + 14, ac);
    this.doc.text("(руб.)", this.marg + this.InCol(8), y + 17, ac);

    this.doc.text("Сумма платы", this.marg + this.InCol(9), y + 5, ac);
    this.doc.text("за пересылку", this.marg + this.InCol(9), y + 8, ac);
    this.doc.text("(без доп.", this.marg + this.InCol(9), y + 11, ac);
    this.doc.text("услуг), без", this.marg + this.InCol(9), y + 14, ac);
    this.doc.text("НДС (руб.)", this.marg + this.InCol(9), y + 17, ac);

    this.doc.text("Сумма", this.marg + this.InCol(10), y + 3, ac);
    this.doc.text("платы за", this.marg + this.InCol(10), y + 6, ac);
    this.doc.text("пересылку", this.marg + this.InCol(10), y + 9, ac);
    this.doc.text("(без доп.", this.marg + this.InCol(10), y + 12, ac);
    this.doc.text("услуг), с", this.marg + this.InCol(10), y + 15, ac);
    this.doc.text("НДС (руб.)", this.marg + this.InCol(10), y + 18, ac);

    this.doc.text("Сумма", this.marg + this.InCol(11), y + 5, ac);
    this.doc.text("платы", this.marg + this.InCol(11), y + 8, ac);
    this.doc.text("за доп.", this.marg + this.InCol(11), y + 11, ac);
    this.doc.text("услуги, без", this.marg + this.InCol(11), y + 14, ac);
    this.doc.text("НДС (руб.)", this.marg + this.InCol(11), y + 17, ac);

    this.doc.text("Сумма", this.marg + this.InCol(12), y + 8, ac);
    this.doc.text("платы за", this.marg + this.InCol(12), y + 11, ac);
    this.doc.text("доп. услуги", this.marg + this.InCol(12), y + 14, ac);
    this.doc.text("с НДС,(руб.)", this.marg + this.InCol(12), y + 17, ac);

    this.doc.text("Примечание", this.marg + this.InCol(13), y + 13, ac);
    this.doc.setFontSize("7.5");
  }

  DrawListTitle(y) {
    let ofs = this.doc.getFontSize();
    this.doc.setFontSize(10);
    this.doc.text("СПИСОК №", 125, y);
    this.doc.line(145, y + 0.5, 155, y + 0.5);
    this.doc.text(this.settings.listNumber.toString(), 146, y);
    this.doc.text("внутренних почтовых отправлений", 110, y + 3.5);
    this.doc.text(
      date.formatDate(this.settings.listDate, "DD.MM.YYYY"),
      130,
      y + 7
    );
    this.doc.line(130, y + 7.5, 148, y + 7.5);
    this.doc.setFontSize(6);
    this.doc.text("(дата)", 135, y + 9);
    this.doc.setFontSize(ofs);
  }

  DrawListHeader(y, sender, settings, vidRPO) {
    this.doc.text("Вид РПО:", this.marg + 5, y);
    this.doc.text(vidRPO, this.marg + 45, y);
    this.doc.line(this.marg + 43, y + 1, this.marg + 43 + 136, y + 1);

    this.doc.text("Способ оплаты:", this.marg + 5, y + 6);
    this.doc.text("-основной услуги", this.marg + 44, y + 6);
    this.doc.rect(this.marg + 95, y + 3.5, 3, 3);
    if (this.settings.basicPaymentType !== "beznal") {
      this.doc.text("x", this.marg + 95.4, y + 6);
    }
    this.doc.text("марки", this.marg + 100, y + 6);
    this.doc.text("или", this.marg + 118, y + 6);
    this.doc.rect(this.marg + 133, y + 3.5, 3, 3);
    if (this.settings.basicPaymentType == "beznal") {
      this.doc.text("x", this.marg + 133.4, y + 6);
    }
    this.doc.text("безналичный расчет", this.marg + 138, y + 6);

    this.doc.text("-уведомлений о вручении", this.marg + 44, y + 11);
    this.doc.rect(this.marg + 95, y + 8.5, 3, 3);
    if (
      this.settings.servicePaymentType !== null &&
      this.settings.servicePaymentType !== "beznal"
    ) {
      this.doc.text("x", this.marg + 95.4, y + 11);
    }
    this.doc.text("марки", this.marg + 100, y + 11);
    this.doc.text("или", this.marg + 118, y + 11);
    this.doc.rect(this.marg + 133, y + 8.5, 3, 3);
    if (
      this.settings.servicePaymentType !== null &&
      this.settings.servicePaymentType == "beznal"
    ) {
      this.doc.text("x", this.marg + 133.4, y + 11);
    }
    this.doc.text("безналичный расчет", this.marg + 138, y + 11);

    this.doc.text("Способ пересылки:", this.marg + 5, y + 17);
    this.doc.text("Наземный", this.marg + 45, y + 17);
    this.doc.line(this.marg + 43, y + 18, this.marg + 43 + 136, y + 18);

    this.doc.text("Отправитель:", this.marg + 5, y + 23);
    let ofs = this.doc.getFontSize();
    let compressedSender = this.CompressString(sender.fullname, 254, 1);
    this.doc.setFontSize(compressedSender.fontSize);
    this.doc.text(compressedSender.split[0], this.marg + 45, y + 23);
    this.doc.setFontSize(ofs);
    this.doc.line(this.marg + 43, y + 24, 297 - this.marg, y + 24);

    this.doc.text("email:", this.marg + 43 + 136 + 6, y + 23);
    this.doc.text(
      this.settings.senderEmail ? this.settings.senderEmail.toString() : "",
      this.marg + 43 + 136 + 18,
      y + 23
    );
    this.doc.line(this.marg + 43 + 136 + 18, y + 24, 297 - this.marg, y + 24);

    this.doc.rect(this.marg, y + 26, 297 - 2 * this.marg, 14);
    this.doc.text(
      "С услугой «SMS-уведомление» на номер мобильного телефона отправителя РПО",
      this.marg + 2,
      y + 30.5
    );
    if (this.settings.senderPhone) {
      this.settings.senderPhone = this.settings.senderPhone.toString();
      for (let i = 0; i < 10; i++) {
        this.doc.rect(this.marg + 150 + i * 3.5, y + 27.5, 3.5, 3.5);
        this.doc.text(
          this.settings.senderPhone[i],
          this.marg + 150.8 + i * 3.5,
          y + 30.3,
          {
            align: "left"
          }
        );
      }
    }
    this.doc.rect(this.marg + 10, y + 34, 3.5, 3.5);
    if (this.settings.smsDeliveryToRecep) {
      this.doc.text("x", this.marg + 10.7, y + 36.7);
    }
    this.doc.text("«SMS-уведомление о вручении РПО»", this.marg + 15, y + 37);
    this.doc.rect(this.marg + 110, y + 34, 3.5, 3.5);
    if (this.settings.smsDeliveryToPost) {
      this.doc.text("x", this.marg + 110.7, y + 36.7);
    }
    this.doc.text(
      "«SMS-уведомление о поступлении РПО в адресное ОПС»",
      this.marg + 115,
      y + 37
    );

    this.doc.rect(this.marg + 220, y + 34, 3.5, 3.5);
    if (this.settings.smsPacket) {
      this.doc.text("x", this.marg + 220.7, y + 36.7);
    }
    this.doc.text("«SMS-пакет»", this.marg + 225, y + 37);
    this.doc.rect(this.marg, y + 40, 297 - 2 * this.marg, 8);
    this.doc.rect(this.marg + 10, y + 42.5, 3.5, 3.5);
    if (this.settings.courierTax) {
      this.doc.text("x", this.marg + 10.7, y + 45.2);
    }
    this.doc.text("«Курьерский сбор»", this.marg + 15, y + 45.5);
    this.doc.text("Наименование и индекс места приема:", this.marg + 5, y + 54);
    this.doc.text(String(settings.list.postID), this.marg + 75, y + 54);
    this.doc.line(this.marg + 75, y + 54.5, 297 - this.marg, y + 54.5);
  }
  drawHeaderTotal(y) {
    let ofs = this.doc.getFontSize();
    this.doc.setFontSize(10);
    this.doc.text("Всего отправлений:", this.marg + 5, y);
    this.doc.text(this.RpoCount.toString(), this.marg + 44, y);
    this.doc.line(this.marg + 41, y + 0.5, this.marg + 56, y + 0.5);

    this.doc.text("Всего листов:", this.marg + 62, y);
    //this.doc.text("1", this.marg + 90, y);
    this.doc.line(this.marg + 87, y + 0.5, this.marg + 105, y + 0.5);

    this.doc.text("лист №:", this.marg + 110, y);
    this.doc.text(
      (this.doc.internal.getNumberOfPages() - 1).toString(),
      this.marg + 128,
      y
    );
    this.doc.line(this.marg + 125, y + 0.5, this.marg + 145, y + 0.5);
    this.doc.setFontSize(ofs);
  }
  setTotalCountToHeaders() {
    let ofs = this.doc.getFontSize();
    this.doc.setFontSize(10);
    let count = (this.doc.internal.getNumberOfPages() - 1).toString();
    this.doc.setPage(2);
    let y = this.marg + this.listTitleHeight + this.headerHeight;
    this.doc.text(count, this.marg + 90, y);
    this.doc.setFontSize(ofs);
  }

  drawFooter(y) {
    let i = 0;
    let dh = 5;
    for (let key in this.footerDelivery) {
      this.doc.text(key, this.marg, y + i * dh);
      let sw = this.doc.getStringUnitWidth(key);
      this.doc.line(
        4 * sw + 2 + 30 / sw,
        y + i * dh,
        297 - this.marg,
        y + i * dh
      );
      i++;
    }
  }

  DrawServiceInRow(recepient, serviceName, strNum, cols, y, rowHeight) {
    let cm = { baseline: "middle", align: "center" };
    let dy = (0.5 + strNum - 1) * rowHeight;
    if (~recepient.postservices.indexOf(serviceName)) {
      this.doc.text(
        this.pData.services[serviceName].f107Label,
        cols[0],
        y + dy / 5,
        cm
      );
      let sId = this.pData.services[serviceName].serviceId;
      let sVal = 0;
      let sValNds = 0;

      if (
        this.settings.servicePaymentType !== null &&
        this.settings.servicePaymentType == "beznal"
      ) {
        sVal = this.pPars.getServicePrice(sId, "val");
        sValNds = this.pPars.getServicePrice(sId, "valnds");
      } else {
        sVal = this.pPars.getServicePrice(sId, "val");
        sValNds = "-";
      }

      this.doc.text(sVal ? sVal : "-", cols[1], y + dy / 5, cm);
      this.doc.text(sValNds ? sValNds : "-", cols[2], y + dy / 5, cm);
    } else {
      this.doc.text("-", cols[0], y + dy / 5, cm);
      this.doc.text("-", cols[1], y + dy / 5, cm);
      this.doc.text("-", cols[2], y + dy / 5, cm);
    }
  }

  DrawSMSServiceInRow(recepient, serviceName, strNum, cols, y, rowHeight) {
    let cm = { baseline: "middle", align: "center" };
    let dy = (0.5 + strNum - 1) * rowHeight;
    let sValS3 = 0;
    let sValNdsS3 = 0;
    let sValS4 = 0;
    let sValNdsS4 = 0;
    let text = [];
    if (~recepient.postservices.indexOf("s3")) {
      text.push(this.pData.services.s3.f107Label);
      let sId = this.pData.services.s3.serviceId;
      sValS3 = this.pPars.getServicePrice(sId, "val");
      sValNdsS3 = this.pPars.getServicePrice(sId, "valnds");
      sValS3 = sValS3 !== null ? parseFloat(sValS3) : 0;
      sValNdsS3 = sValNdsS3 !== null ? parseFloat(sValNdsS3) : 0;
    }
    if (~recepient.postservices.indexOf("s4")) {
      text.push(this.pData.services.s4.f107Label);
      let sId = this.pData.services.s4.serviceId;
      sValS4 = this.pPars.getServicePrice(sId, "val");
      sValNdsS4 = this.pPars.getServicePrice(sId, "valnds");
      sValS4 = sValS3 !== null ? parseFloat(sValS3) : 0;
      sValNdsS4 = sValNdsS3 !== null ? parseFloat(sValNdsS3) : 0;
    }

    let sVal = sValS3 + sValS4;
    let sValNds = sValNdsS3 + sValNdsS4;

    if (sVal) {
      this.doc.text(text.join("/"), cols[0], y + dy / 5, cm);
      this.doc.text(sVal.toFixed(2), cols[1], y + dy / 5, cm);
      if (
        this.settings.servicePaymentType !== null &&
        this.settings.servicePaymentType == "beznal"
      ) {
        this.doc.text(sValNds.toFixed(2), cols[2], y + dy / 5, cm);
      } else {
        this.doc.text("-", cols[2], y + dy / 5, cm);
      }
    } else {
      this.doc.text("-", cols[0], y + dy / 5, cm);
      this.doc.text("-", cols[1], y + dy / 5, cm);
      this.doc.text("-", cols[2], y + dy / 5, cm);
    }
  }

  DrawRow(y, rowHeight, lineNumber, splitName, splitAddr, recepient) {
    let startx = 0;
    let bltop = { baseline: "top" };
    let cm = { baseline: "middle", align: "center" };
    let oldFS = this.doc.getFontSize();
    for (let col of this.cols) {
      this.doc.rect(this.marg + startx, y, col, rowHeight);
      startx += col;
    }

    this.doc.rect(
      this.marg + this.cols[0],
      y + splitName.split.length * this.lineHeight,
      this.cols[1],
      splitAddr.split.length * this.lineHeight
    );
    this.doc.setFontSize(splitName.fontSize);
    this.doc.text(
      splitName.split,
      this.marg + this.padding / 4 + this.cols[0],
      y + this.padding / 6,
      bltop
    );
    this.doc.setFontSize(splitAddr.fontSize);
    this.doc.text(
      splitAddr.split,
      this.marg + this.padding / 4 + this.cols[0],
      y + splitName.split.length * this.lineHeight + this.padding / 6,
      bltop
    );
    this.doc.line(
      this.marg + this.cols[0],
      y + rowHeight - this.lineHeight,
      this.marg + this.cols[0] + this.cols[1],
      y + rowHeight - this.lineHeight
    );
    if (recepient.barcode) {
      this.doc.text(
        recepient.barcode.toString(),
        this.marg + this.InCol(3),
        y + rowHeight / 2,
        cm
      );
    }
    this.pPars.setResponseObject(
      recepient.hasOwnProperty("response") ? recepient.response : {}
    );
    if (recepient.posttype) {
      this.doc.text(
        this.pData.getMailCatName(recepient.posttype),
        this.marg + this.InCol(4),
        y + rowHeight / 2,
        cm
      );
    }
    //Вес 5 колонка
    for (let i = 0; i < 3; i++) {
      let dy = y + (rowHeight / 4) * (i + 1);
      this.doc.line(
        this.marg + this.cols[0] + this.cols[1] + this.cols[2] + this.cols[3],
        dy,
        this.marg +
          this.cols[0] +
          this.cols[1] +
          this.cols[2] +
          this.cols[3] +
          this.cols[4],
        dy
      );
    }

    let xService =
      this.marg +
      this.cols[0] +
      this.cols[1] +
      this.cols[2] +
      this.cols[3] +
      this.cols[4];
    let xServicePay =
      xService +
      this.cols[5] +
      this.cols[6] +
      this.cols[7] +
      this.cols[8] +
      this.cols[9];
    let xServicePayNDS = xServicePay + this.cols[10];
    for (let i = 0; i < 4; i++) {
      let dy = y + (rowHeight / 5) * (i + 1);
      this.doc.line(xService, dy, xService + this.cols[5], dy);
      this.doc.line(xServicePay, dy, xServicePay + this.cols[10], dy);
      this.doc.line(xServicePayNDS, dy, xServicePayNDS + this.cols[11], dy);
    }

    let c6 = this.marg + this.InCol(6);
    let c11 = this.marg + this.InCol(11);
    let c12 = this.marg + this.InCol(12);
    let ofss = this.doc.getFontSize();
    this.doc.setFontSize(6);
    if (~recepient.postservices.indexOf("i1")) {
      this.DrawServiceInRow(recepient, "i1", 1, [c6, c11, c12], y, rowHeight);
    } else {
      this.DrawServiceInRow(recepient, "i2", 1, [c6, c11, c12], y, rowHeight);
    }
    this.DrawServiceInRow(recepient, "p1", 2, [c6, c11, c12], y, rowHeight);
    this.DrawSMSServiceInRow(
      recepient,
      "s3,s4",
      5,
      [c6, c11, c12],
      y,
      rowHeight
    );
    this.doc.setFontSize(ofss);
    this.doc.text("-", c6, y + (2.5 * rowHeight) / 5, cm);
    this.doc.text("-", c6, y + (3.5 * rowHeight) / 5, cm);

    this.doc.text("-", c11, y + (2.5 * rowHeight) / 5, cm);
    this.doc.text("-", c11, y + (3.5 * rowHeight) / 5, cm);

    this.doc.text("-", c12, y + (2.5 * rowHeight) / 5, cm);
    this.doc.text("-", c12, y + (3.5 * rowHeight) / 5, cm);

    this.doc.text(
      recepient.weight ? (recepient.weight / 1000).toFixed(3) : "-",
      this.marg + this.InCol(5),
      y + (0.5 * rowHeight) / 4,
      cm
    );
    this.doc.text(
      "-",
      this.marg + this.InCol(5),
      y + (1.5 * rowHeight) / 4,
      cm
    );
    this.doc.text(
      "-",
      this.marg + this.InCol(5),
      y + (2.5 * rowHeight) / 4,
      cm
    );

    if (recepient.posttype !== null && recepient.posttype[0] == "p") {
      this.doc.text(
        this.pData.boxTypes[recepient.postpack].short,
        this.marg + this.InCol(5),
        y + (3.5 * rowHeight) / 4,
        cm
      );
    } else {
      this.doc.text(
        "-",
        this.marg + this.InCol(5),
        y + (3.5 * rowHeight) / 4,
        cm
      );
    }
    if (recepient.note !== "") {
      this.doc.text(
        recepient.note !== "" ? recepient.note : "-",
        this.marg + this.InCol(13) - this.cols[12] / 2 + 1,
        y + 2.5,
        { align: "left", maxWidth: this.cols[12] - 2 }
      );
    } else {
      this.doc.text("-", this.marg + this.InCol(13), y + rowHeight / 2, cm);
    }

    let groundCoverVal = this.pPars.getGroundCover("val");
    let groundCoverValNds = this.pPars.getGroundCover("valnds");
    if (
      this.settings.basicPaymentType !== null &&
      this.settings.basicPaymentType == "nal"
    ) {
      groundCoverValNds = 0;
    }
    this.doc.text(
      groundCoverVal ? groundCoverVal : "-",
      this.marg + this.InCol(9),
      y + (2.5 * rowHeight) / 5,
      cm
    );
    this.doc.text(
      groundCoverValNds ? groundCoverValNds : "-",
      this.marg + this.InCol(10),
      y + (2.5 * rowHeight) / 5,
      cm
    );
    let sumOc = this.pPars.getSumOc();
    this.doc.text(
      sumOc ? sumOc : "-",
      this.marg + this.InCol(7),
      y + (2.5 * rowHeight) / 5,
      cm
    );
    this.doc.text(
      sumOc && this.pData.getMailCat(recepient.posttype) == 4 ? sumOc : "-",
      this.marg + this.InCol(8),
      y + (2.5 * rowHeight) / 5,
      cm
    );
  }
  DrawEmptyRow(y, lineNumber) {
    return;
  }

  getTotals(recepients) {
    let rowCount = recepients.length;
    let res = {};

    res.byCategories = {};
    res.total = {};
    res.services = {};
    res.total.gVal = 0;
    res.total.gValNds = 0;
    res.total.gNds = 0;
    res.total.cVal = 0;
    res.total.cValNds = 0;
    res.total.cNds = 0;
    res.total.sumOc = 0;
    res.total.gcVal = 0;
    res.total.gcValNds = 0;
    res.total.gcNds = 0;
    res.total.sval = 0;
    res.total.svalnds = 0;
    res.total.snds = 0;
    res.total.nalogenplat = 0;
    for (let i = 0; i < rowCount; i++) {
      let rec = recepients[i];
      if (rec.posttype === null) {
        return;
      }
      let mailCat = this.pData.getMailCat(rec.posttype);

      this.pPars.setResponseObject(rec.response);
      if (!res.byCategories.hasOwnProperty(mailCat)) {
        res.byCategories[mailCat] = {};
        res.byCategories[mailCat].val = 0;
        res.byCategories[mailCat].valNds = 0;
        res.byCategories[mailCat].nds = 0;
        res.byCategories[mailCat].count = 0;
      }

      let gcVal = this.pPars.getGroundCoverNum("val");
      let gcValNds = 0;
      let gVal = this.pPars.getGroundNum("val");
      let gValNds = 0;
      let cVal = this.pPars.getCoverNum("val");
      let cValNds = 0;
      gcVal = gcVal !== null ? gcVal : 0;
      gVal = gVal !== null ? gVal : 0;
      cVal = cVal !== null ? cVal : 0;
      res.byCategories[mailCat].val += gcVal;

      if (this.settings.basicPaymentType == "beznal") {
        gcValNds = this.pPars.getGroundCoverNum("valnds");
        gValNds = this.pPars.getGroundNum("valnds");
        cValNds = this.pPars.getCoverNum("valnds");
        gcValNds = gcValNds !== null ? gcValNds : 0;
        gValNds = gValNds !== null ? gValNds : 0;
        cValNds = cValNds !== null ? cValNds : 0;
      }

      res.byCategories[mailCat].valNds += gcValNds;
      res.byCategories[mailCat].nds += gcValNds - gcVal;
      res.byCategories[mailCat].count++;

      res.total.gVal += gVal;
      res.total.cVal += cVal;
      res.total.sumOc += this.pPars.getSumOcNum();
      res.total.gcVal += gVal + cVal;
      if (this.settings.basicPaymentType == "beznal") {
        res.total.gValNds += gValNds;
        res.total.gNds += gValNds - gVal;
        res.total.cValNds += cValNds;
        res.total.cNds += cValNds - cVal;
        res.total.gcValNds += gValNds + cValNds;
        res.total.gcNds += gValNds + cValNds - gVal - cVal;
      }

      //let ndsKey = (this.settings.basicPaymentType = "valnds");
      //let ndsKey = this.settings.basicPaymentType;
      //let gcVal = this.pPars.getGroundCoverNum("val");
      //let gcValNds = this.pPars.getGroundCoverNum(ndsKey);
      //let gVal = this.pPars.getGroundNum("val");
      //let gValNds = this.pPars.getGroundNum(ndsKey);
      //let cVal = this.pPars.getCoverNum("val");
      //let cValNds = this.pPars.getCoverNum(ndsKey);
      //gcVal = gcVal !== null ? gcVal : 0;
      //gcValNds = gcValNds !== null ? gcValNds : 0;
      //gVal = gVal !== null ? gVal : 0;
      //gValNds = gValNds !== null ? gValNds : 0;
      //cVal = cVal !== null ? cVal : 0;
      //cValNds = cValNds !== null ? cValNds : 0;

      //res.byCategories[mailCat].val += gcVal;
      //res.byCategories[mailCat].valNds += gcValNds;
      //res.byCategories[mailCat].nds += gcValNds - gcVal;
      //res.byCategories[mailCat].count++;

      //res.total.gVal += gVal;
      //res.total.gValNds += gValNds;
      //res.total.gNds += gValNds - gVal;
      //res.total.cVal += cVal;
      //res.total.cValNds += cValNds;
      //res.total.cNds += cValNds - cVal;
      //res.total.sumOc += this.pPars.getSumOcNum();
      //res.total.gcVal += gVal + cVal;
      //res.total.gcValNds += gValNds + cValNds;
      //res.total.gcNds += gValNds + cValNds - gVal - cVal;
      if (
        rec.posttype == "e4" ||
        rec.posttype == "e7" ||
        rec.posttype == "b4" ||
        rec.posttype == "b7" ||
        rec.posttype == "p3" ||
        rec.posttype == "p6" ||
        rec.posttype == "p9"
      ) {
        res.total.nalogenplat += this.pPars.getSumOcNum();
      }
      let sa = this.pPars.getServicesArray();
      for (let key in sa) {
        if (res.services.hasOwnProperty(key)) {
          res.services[key].val += sa[key].val;
          if (
            this.settings.servicePaymentType !== null &&
            this.settings.servicePaymentType == "beznal"
          ) {
            res.services[key].valnds += sa[key].valnds;
            res.services[key].nds += sa[key].nds;
          }
        } else {
          res.services[key] = {};
          res.services[key].val = sa[key].val;
          res.services[key].valnds = 0;
          res.services[key].nds = 0;
          if (
            this.settings.servicePaymentType !== null &&
            this.settings.servicePaymentType == "beznal"
          ) {
            res.services[key].valnds = sa[key].valnds;
            res.services[key].nds = sa[key].nds;
          }
        }
        res.total.sval += sa[key].val;
        if (
          this.settings.servicePaymentType !== null &&
          this.settings.servicePaymentType == "beznal"
        ) {
          res.total.svalnds += sa[key].valnds;
          res.total.snds += sa[key].valnds - sa[key].val;
        }
      }
    }

    return res;
  }

  CompressString(str, width, maxlines) {
    let split = this.doc.splitTextToSize(str, width);
    let oldFs = this.doc.getFontSize();
    let curFs = this.doc.getFontSize();
    while (split.length > maxlines) {
      curFs = curFs - 0.3;
      this.doc.setFontSize(curFs);
      split = this.doc.splitTextToSize(str, width);
    }
    this.doc.setFontSize(oldFs);
    return { split: split, fontSize: curFs };
  }

  AddPageWithHeader() {
    this.doc.addPage();
    let y = this.marg;
    this.doc.text("СПИСОК № ", this.marg, y);
    this.doc.text(this.settings.listNumber.toString(), this.marg + 20, y, {
      align: "center"
    });
    this.doc.line(this.marg + 15, y + 0.5, this.marg + 25, y + 0.5);
    this.doc.text("Дата", this.marg + 27, y);
    this.doc.text(
      date.formatDate(this.settings.listDate, "DD.MM.YYYY"),
      this.marg + 42,
      y,
      { align: "center" }
    );
    this.doc.line(this.marg + 34, y + 0.5, this.marg + 50, y + 0.5);
    this.doc.text("Лист №", this.marg + 51, y);
    let count = (this.doc.internal.getNumberOfPages() - 1).toString();
    this.doc.text(count, this.marg + 66, y, {
      align: "center"
    });
    this.doc.line(this.marg + 61, y + 0.5, this.marg + 71, y + 0.5);
    y += this.lineHeight;
    this.doc.text("Отправитель: ", this.marg, y);
    this.doc.line(this.marg + 20, y + 0.5, this.marg + 200, y + 0.5);
    let ofs = this.doc.getFontSize();
    let compressedSender = this.CompressString(this.sender.fullname, 180, 1);
    this.doc.setFontSize(compressedSender.fontSize);
    this.doc.text(compressedSender.split[0], this.marg + 20, y);
    y += 2 * this.lineHeight;
    this.doc.setFontSize(ofs);
    return y;
  }

  AddMiniTableHeader(y) {
    let startx = 0;
    for (let col of this.cols) {
      this.doc.rect(this.marg + startx, y, col, this.lineHeight);
      startx += col;
    }

    let nc = 1;
    for (let col of this.cols) {
      this.doc.text(nc.toString(), this.marg + this.InCol(nc), y + 2.7);
      nc++;
    }
    y += this.lineHeight;
    return y;
  }
  AddTableTotal(y, total) {
    let startx = this.InCol(6) + this.cols[5] / 2;
    for (let i = 6; i < 12; i++) {
      this.doc.rect(this.marg + startx, y, this.cols[i], 1.5 * this.lineHeight);
      startx += this.cols[i];
    }
    let ac = { align: "center" };
    this.doc.text(
      (total.sumOc / 100).toFixed(2),
      this.marg + this.InCol(7),
      y + 4,
      ac
    );

    this.doc.text(
      (total.nalogenplat / 100).toFixed(2),
      this.marg + this.InCol(8),
      y + 4,
      ac
    );

    this.doc.text(
      (total.gcVal / 100).toFixed(2),
      this.marg + this.InCol(9),
      y + 4,
      ac
    );
    this.doc.text(
      (total.gcValNds / 100).toFixed(2),
      this.marg + this.InCol(10),
      y + 4,
      ac
    );
    this.doc.text(
      (total.sval / 100).toFixed(2),
      this.marg + this.InCol(11),
      y + 4,
      ac
    );
    this.doc.text(
      (total.svalnds / 100).toFixed(2),
      this.marg + this.InCol(12),
      y + 4,
      ac
    );
    return y + 1.5 * this.lineHeight;
  }

  DrawSings(y) {
    let ofs = this.doc.getFontSize();
    this.doc.setFontSize(ofs);
    this.doc.text("Сдал", this.marg, y);
    let texts = ["(Должность)", "(Подпись)", "(ФИО)"];
    this.doc.setFontSize(5.5);
    for (let i = 0; i < 3; i++) {
      this.doc.line(
        this.marg + 10,
        y + i * 2 * this.lineHeight + 0.5,
        this.marg + 50,
        y + i * 2 * this.lineHeight + 0.5
      );

      this.doc.text(
        texts[i],
        this.marg + 35,
        y + i * 2 * this.lineHeight + 2.3,
        {
          align: "center"
        }
      );
    }
    this.doc.rect(this.marg + 56, y + 2.5 * this.lineHeight, 20, 20);

    this.doc.setFontSize(ofs);
    let dx = 100;
    this.doc.text("Принял", this.marg + dx, y);
    this.doc.setFontSize(5.5);
    for (let i = 0; i < 3; i++) {
      this.doc.line(
        this.marg + 10 + dx,
        y + i * 2 * this.lineHeight + 0.5,
        this.marg + 50 + dx,
        y + i * 2 * this.lineHeight + 0.5
      );

      this.doc.text(
        texts[i],
        this.marg + 35 + dx,
        y + i * 2 * this.lineHeight + 2.3,
        {
          align: "center"
        }
      );
    }
    this.doc.rect(this.marg + 56 + dx, y + 2.5 * this.lineHeight, 20, 20);

    this.doc.setFontSize(ofs);
    dx = 210;
    this.doc.text("Получил счетом", this.marg + dx, y);
    this.doc.line(this.marg + dx + 23, y + 0.5, 297 - this.marg - 7, y + 0.5);
    this.doc.text("штук", 297 - this.marg - 7, y);
    y += this.lineHeight;
    this.doc.text("в исправной упаковке:", this.marg + dx, y);
    y += this.lineHeight * 1.5;
    this.doc.setFontSize(5.5);
    for (let i = 0; i < 3; i++) {
      this.doc.line(
        this.marg + dx,
        y + i * 2 * this.lineHeight + 0.5,
        this.marg + 40 + dx,
        y + i * 2 * this.lineHeight + 0.5
      );

      this.doc.text(
        texts[i],
        this.marg + 15 + dx,
        y + i * 2 * this.lineHeight + 2.3,
        {
          align: "center"
        }
      );
    }
    y -= 2.5 * this.lineHeight;
    this.doc.rect(297 - this.marg - 20, y + 2.5 * this.lineHeight, 20, 20);
    this.doc.setFontSize(ofs);
  }

  CreateReestrF103(sender, recepients, settings) {
    this.sender = sender;
    this.doc.addPage();
    let padding = 4;
    let ac = {
      align: "center"
    };

    this.doc.setFontSize("10");
    let y = this.marg;
    this.DrawListTitle(y);
    let rowCount = recepients.length;
    this.RpoCount = recepients.length;

    y += this.listTitleHeight;
    this.DrawListHeader(
      y,
      sender,
      settings,
      this.pData.getMailTypeName(recepients[0].posttype)
    );
    y += this.headerHeight;
    this.drawHeaderTotal(y);
    y += this.headerTotalHeight;
    this.DrawTabHeader(y);
    y += this.tableHeaderHeight;

    let i = 0;
    for (i = 0; i < rowCount; i++) {
      let rname = recepients[i].NameWithComment();
      let raddr = recepients[i].FullAddress();
      let splitName = this.CompressString(
        rname,
        this.cols[1] - this.padding,
        2
      );
      let splitAddr = this.CompressString(
        raddr,
        this.cols[1] - this.padding,
        2
      );
      let currentRowHeight =
        (2 + splitName.split.length + splitAddr.split.length) * this.lineHeight;
      if (y + currentRowHeight > 210 - this.marg) {
        y = this.AddPageWithHeader();
        y = this.AddMiniTableHeader(y);
        this.DrawRow(
          y,
          currentRowHeight,
          i + 1,
          splitName,
          splitAddr,
          recepients[i]
        );
      } else {
        this.DrawRow(
          y,
          currentRowHeight,
          i + 1,
          splitName,
          splitAddr,
          recepients[i]
        );
      }
      y += currentRowHeight;
    }

    let totals = this.getTotals(recepients);
    y = this.AddTableTotal(y, totals.total);
    y += 7;
    let dh = 5;
    this.doc.text(
      "Из них общее количество отправлений по категориям, а также сумма их платы за пересылку с учетом платы за объявленную ценность:",
      this.marg,
      y
    );
    y += dh;

    for (let [key, value] of Object.entries(totals.byCategories)) {
      if (y + dh > 210 - this.marg) {
        y = this.AddPageWithHeader();
      }
      this.doc.line(this.marg, y, 297 - this.marg, y);
      this.doc.text(this.pData.mailCategories[key], 25, y - 0.5, ac);
      let cStr = rubles(value.count);
      cStr = cStr.slice(0, cStr.indexOf("руб") - 1);
      this.doc.text(
        value.count.toFixed(0) + "  (" + cStr + ")",
        130,
        y - 0.5,
        ac
      );
      let ofs = this.doc.getFontSize();
      this.doc.setFontSize(5);
      this.doc.text("(категория РПО)", 25, y + 1.5, ac);
      this.doc.text("(сумма цифрами и прописью)", 130, y + 1.5, ac);
      this.doc.setFontSize(ofs);
      y += dh;
      for (let key in this.footerCategories) {
        if (y + dh > 210 - this.marg) {
          y = this.AddPageWithHeader();
        }
        this.doc.text(this.footerCategories[key], this.marg, y);
        let sw = this.doc.getStringUnitWidth(this.footerCategories[key]);
        let lStart = 70;
        this.doc.line(lStart, y, 297 - this.marg, y);

        let sum = (value[key] / 100).toFixed(2);
        if (
          (key == "valnds" || key == "nds") &&
          this.settings.basicPaymentType == "nal"
        ) {
          sum = (0).toFixed(2);
        }
        let rubStr = rubles(sum);
        if (!rubStr) {
          rubStr = "ноль рублей 00 копеек";
        }
        this.doc.text(
          sum + "  (" + rubStr.charAt(0).toUpperCase() + rubStr.slice(1) + ")",
          lStart + (297 - this.marg * 2 - lStart) / 2,
          y - 1,
          {
            align: "center"
          }
        );
        let ofs = this.doc.getFontSize();
        this.doc.setFontSize(5);
        this.doc.text(
          "(Сумма цифрами и прописью)",
          lStart + (297 - this.marg * 2 - lStart) / 2,
          y + 1.5,
          { align: "center" }
        );
        this.doc.setFontSize(ofs);
        y += dh;
      }
    }
    this.doc.text("Плата за пересылку", this.marg, y);
    this.doc.text("Плата за пересылку", this.marg + 0.1, y + 0.1);
    y += dh;

    for (let key in this.footerTotal) {
      if (y + dh > 210 - this.marg) {
        y = this.AddPageWithHeader();
      }
      this.doc.text(this.footerTotal[key], this.marg, y);
      let sw = this.doc.getTextWidth(this.footerTotal[key]);
      let lStart = sw + this.marg + 3;
      this.doc.line(lStart, y, 297 - this.marg, y);
      let sum = (totals.total[key] / 100).toFixed(2);
      let rubStr = rubles(sum);
      if (!rubStr) {
        rubStr = "ноль рублей 00 копеек";
      }
      this.doc.text(
        sum + "  (" + rubStr.charAt(0).toUpperCase() + rubStr.slice(1) + ")",
        lStart + (297 - this.marg * 2 - lStart) / 2,
        y - 1,
        {
          align: "center"
        }
      );
      let ofs = this.doc.getFontSize();
      this.doc.setFontSize(5);
      this.doc.text(
        "(Сумма цифрами и прописью)",
        lStart + (297 - this.marg * 2 - lStart) / 2,
        y + 1.5,
        { align: "center" }
      );
      this.doc.setFontSize(ofs);
      y += dh;
    }
    this.doc.text("Дополнительные услуги", this.marg, y);
    this.doc.text("Дополнительные услуги", this.marg + 0.1, y + 0.1);
    y += dh;
    for (let key in this.footerService) {
      if (y + dh > 210 - this.marg) {
        y = this.AddPageWithHeader();
      }
      this.doc.text(this.footerService[key], this.marg, y);
      let sw = this.doc.getTextWidth(this.footerService[key]);
      let lStart = sw + this.marg + 3;
      this.doc.line(lStart, y, 297 - this.marg, y);
      let sum = 0;
      let serviceName = key[0] + key[1];
      let serviceTariff = key.slice(2, key.length);
      if (serviceName == "s0") {
        if (totals.services.hasOwnProperty("20")) {
          sum += totals.services["20"][serviceTariff];
        }
        if (totals.services.hasOwnProperty("21")) {
          sum += totals.services["21"][serviceTariff];
        }
      } else if (serviceName == "st") {
        sum = totals.total["s" + serviceTariff];
      } else {
        let serviceId = this.pData.services.hasOwnProperty(serviceName)
          ? this.pData.services[serviceName].serviceId
          : null;
        if (serviceId !== null && totals.services.hasOwnProperty(serviceId)) {
          sum = totals.services[serviceId][serviceTariff];
        }
      }

      sum = (sum / 100).toFixed(2);

      let rubStr = rubles(sum);
      let sumStr = "";
      if (!rubStr) {
        sumStr = "---";
      } else {
        sumStr =
          sum + "  (" + rubStr.charAt(0).toUpperCase() + rubStr.slice(1) + ")";
      }

      this.doc.text(
        sumStr,
        lStart + (297 - this.marg * 2 - lStart) / 2,
        y - 1,
        {
          align: "center"
        }
      );
      let ofs = this.doc.getFontSize();
      this.doc.setFontSize(5);
      this.doc.text(
        "(Сумма цифрами и прописью)",
        lStart + (297 - this.marg * 2 - lStart) / 2,
        y + 1.5,
        { align: "center" }
      );
      this.doc.setFontSize(ofs);
      y += dh;
    }
    this.doc.text("Всего к оплате", this.marg, y);
    this.doc.text("Всего к оплате", this.marg + 0.1, y + 0.1);
    if (y + dh > 210 - this.marg) {
      y = this.AddPageWithHeader();
    } else {
      y += dh;
    }
    this.doc.text(
      "Сумма платы за пересылку (с учетом дополнительных услуг) с НДС",
      this.marg,
      y
    );

    this.doc.line(this.marg + 89, y + 0.5, 297 - this.marg, y + 0.5);

    let gc = 0;
    let s = 0;
    let hasNds = 0;
    if (
      this.settings.basicPaymentType !== null &&
      this.settings.basicPaymentType == "beznal"
    ) {
      gc = totals.total.gcValNds;
      hasNds = 1;
    } else {
      gc = totals.total.gcVal;
    }

    if (
      this.settings.servicePaymentType !== null &&
      this.settings.servicePaymentType == "beznal"
    ) {
      s = totals.total.svalnds;
      hasNds = 1;
    } else {
      s = totals.total.sval;
    }
    let itog = ((gc + s) / 100).toFixed(2);

    if (!hasNds) {
      itog = (0).toFixed(2);
    }
    let rubStr = rubles(itog);
    if (!rubStr) {
      rubStr = "---";
      itog = "";
    }

    this.doc.text(
      itog + "  (" + rubStr.charAt(0).toUpperCase() + rubStr.slice(1) + ")",
      200,
      y,
      {
        align: "center"
      }
    );
    if (y + dh > 210 - this.marg) {
      y = this.AddPageWithHeader();
    } else {
      y += dh;
    }

    this.doc.text(
      "Сумма платы за пересылку (с учетом дополнительных услуг) без НДС",
      this.marg,
      y
    );
    this.doc.line(this.marg + 92, y + 0.5, 297 - this.marg, y + 0.5);
    itog = ((totals.total.gcVal + totals.total.sval) / 100).toFixed(2);
    rubStr = rubles(itog);
    if (!rubStr) {
      rubStr = "---";
      itog = "";
    }
    this.doc.text(
      itog + "  (" + rubStr.charAt(0).toUpperCase() + rubStr.slice(1) + ")",
      200,
      y,
      { align: "center" }
    );
    if (y + dh > 210 - this.marg) {
      y = this.AddPageWithHeader();
    } else {
      y += dh;
    }

    this.doc.text("В т.ч. НДС", this.marg, y);
    this.doc.line(this.marg + 15, y + 0.5, 297 - this.marg, y + 0.5);

    gc = 0;
    s = 0;
    hasNds = 0;
    if (
      settings.basicPaymentType !== null &&
      settings.basicPaymentType == "beznal"
    ) {
      gc = totals.total.gcNds;
      hasNds = 1;
    } else {
      gc = 0;
    }

    if (
      settings.servicePaymentType !== null &&
      settings.servicePaymentType == "beznal"
    ) {
      s = totals.total.snds;
      hasNds = 1;
    } else {
      s = 0;
    }

    itog = ((gc + s) / 100).toFixed(2);
    if (!hasNds) {
      itog = (0).toFixed(2);
    }
    rubStr = rubles(itog);
    if (!rubStr) {
      rubStr = "---";
      itog = "";
    }
    this.doc.text(
      itog + "  (" + rubStr.charAt(0).toUpperCase() + rubStr.slice(1) + ")",
      200,
      y,
      { align: "center" }
    );
    y += 2 * this.lineHeight;
    if (y + 2 * this.lineHeight + 20 > 210 - this.marg) {
      y = this.AddPageWithHeader();
    }
    this.DrawSings(y);
    this.setTotalCountToHeaders();
    /* while (y < 210 - this.marg) {
      this.DrawEmptyRow(y, ++i);
      y += this.lineHeight + this.padding;
    }
    return;
    y = 15;
    this.doc.addPage();
    this.doc.setFontSize("12");
    this.DrawListHeader(y, sender);
    y += 20;
    this.DrawTabHeader(y);
    y += 20;

    this.doc.rect(15, y, this.InCol(5), 6);
    this.doc.rect(15 + this.InCol(5), y, this.cols[4], 6);
    this.doc.rect(15 + this.InCol(6), y, this.cols[5], 6);
    this.doc.rect(15 + this.InCol(7), y, this.cols[6], 6);
    this.doc.rect(15 + this.InCol(8), y, this.cols[7], 6);
    this.doc.rect(15 + this.InCol(9), y, this.cols[8], 6);

    this.doc.text("Итого:", 15 + this.InCol(4) + 1, y + 4.2);

    this.doc.setFontSize("10");
    this.doc.text("Общее количество", 15, 70);
    this.doc.line(48, 70.5, 282, 70.5);
    this.doc.text("Сумма объявленной ценности", 15, 78);
    this.doc.line(68, 78.5, 282, 78.5);
    this.doc.text("Общая сумма платы за пересылку", 15, 86);
    this.doc.line(75, 86.5, 282, 86.5);
    this.doc.text("НДС (в том числе / сверху)*", 15, 94);
    this.doc.line(63, 94.5, 282, 94.5);
    this.doc.text("Общая сумма платы за объявленную ценность с НДС", 15, 102);
    this.doc.line(107, 102.5, 282, 102.5);
    this.doc.text("в т.ч. НДС", 15, 110);
    this.doc.line(32, 110.5, 282, 110.5);
    this.doc.text("Итого за пересылку, с НДС", 15, 118);
    this.doc.line(62, 118.5, 282, 118.5);
    this.doc.text("Дополнительные услуги", 15, 126);
    this.doc.text(
      "Общая сумма платы за пересылку простых/заказных* уведомлений о вручении с НДС",
      15,
      131
    );
    this.doc.line(164, 131.5, 282, 131.5);
    this.doc.text("В т.ч. НДС", 15, 139);
    this.doc.line(32, 139.5, 282, 139.5);
    this.doc.text("Всего к оплате:", 15, 147);
    this.doc.line(42, 147.5, 282, 147.5);
    this.doc.text("В т.ч. НДС", 15, 155);
    this.doc.line(32, 155.5, 282, 155.5);

    this.doc.text("Сдал:", 20, 165);
    this.doc.line(30, 165.5, 69, 165.5);
    this.doc.line(30, 175.5, 69, 175.5);
    this.doc.line(30, 185.5, 69, 185.5);
    this.doc.rect(84, 165.5, 30, 20);

    this.doc.text("Принял:", 154, 165);
    this.doc.line(169, 165.5, 210, 165.5);
    this.doc.line(169, 175.5, 210, 175.5);
    this.doc.line(169, 185.5, 210, 185.5);
    this.doc.rect(225, 165.5, 30, 20);

    this.doc.setFontSize("8");
    this.doc.text("(Сумма цифрами и прописью)", 130, 81);
    this.doc.text("(Сумма цифрами и прописью)", 130, 89);
    this.doc.text("(Сумма цифрами и прописью)", 130, 97);
    this.doc.text("(Сумма цифрами и прописью)", 130, 105);
    this.doc.text("(Сумма цифрами и прописью)", 130, 113);
    this.doc.text("(Сумма цифрами и прописью)", 130, 121);
    this.doc.text("(Сумма цифрами и прописью)", 202, 134);
    this.doc.text("(Сумма цифрами и прописью)", 130, 142);
    this.doc.text("(Сумма цифрами и прописью)", 130, 150);
    this.doc.text("(Сумма цифрами и прописью)", 130, 158);

    this.doc.text("(должность)", 41, 169);
    this.doc.text("(подпись)", 42.5, 179);
    this.doc.text("(Ф.И.О.)", 44, 189);

    this.doc.text("(должность)", 180, 169);
    this.doc.text("(подпись)", 181.5, 179);
    this.doc.text("(Ф.И.О.)", 183, 189);

    this.doc.setFontSize("10");
    this.doc.text("М.П.", 115.5, 176);
    this.doc.setFontSize("8");
    this.doc.text("(оттиск КПШ ОПС", 228, 189);
    this.doc.text("мест приема)", 231, 192); */
  }
  GenerateHTMLReestr(sender, recepients, settings) {
    let tpl = new F103Html(sender, recepients, settings, this.settings);
    let t = "";
    let cw = new Array(66);
    cw.fill(2);
    cw[0] = 3;
    cw[15] = 3;

    t = '<table id="tfsimple" data-cols-width="' + cw.join(",") + '"> <tbody>';
    t += tpl.title;
    t += tpl.tabHead;
    //t += tpl.tabRow;
    let rowCount = recepients.length;
    for (let i = 0; i < rowCount; i++) {
      this.pPars.setResponseObject(
        recepients[i].hasOwnProperty("response") ? recepients[i].response : {}
      );

      //СМС сервис отдельно
      let sValS3 = 0;
      let sValNdsS3 = 0;
      let sValS4 = 0;
      let sValNdsS4 = 0;
      let text = [];

      if (~recepients[i].postservices.indexOf("s3")) {
        text.push(this.pData.services["s3"].f107Label);
        let sId = this.pData.services["s3"].serviceId;
        sValS3 = this.pPars.getServicePrice(sId, "val");
        if (
          this.settings.servicePaymentType !== null &&
          this.settings.servicePaymentType == "beznal"
        ) {
          sValNdsS3 = this.pPars.getServicePrice(sId, "valnds");
        }
        sValS3 = sValS3 !== null ? parseFloat(sValS3) : 0;
        sValNdsS3 = sValNdsS3 !== null ? parseFloat(sValNdsS3) : 0;
      }
      if (~recepients[i].postservices.indexOf("s4")) {
        text.push(this.pData.services["s4"].f107Label);
        let sId = this.pData.services["s4"].serviceId;
        sValS4 = this.pPars.getServicePrice(sId, "val");
        if (
          this.settings.servicePaymentType !== null &&
          this.settings.servicePaymentType == "beznal"
        ) {
          sValNdsS4 = this.pPars.getServicePrice(sId, "valnds");
        }
        sValS4 = sValS3 !== null ? parseFloat(sValS3) : 0;
        sValNdsS4 = sValNdsS3 !== null ? parseFloat(sValNdsS3) : 0;
      }

      let sVal = sValS3 + sValS4;
      let sValNds = sValNdsS3 + sValNdsS4;

      let groundCoverVal = this.pPars.getGroundCover("val");
      let groundCoverValNds =
        this.settings.basicPaymentType == "beznal"
          ? this.pPars.getGroundCover("valnds")
          : 0;
      let sumOc = this.pPars.getSumOc();

      let servicei1i2 = "-";
      let servicei1i2val = "-";
      let servicei1i2valnds = "-";
      if (~recepients[i].postservices.indexOf("i1")) {
        servicei1i2 = this.pData.services["i1"].f107Label;
        servicei1i2val = this.pPars.getServicePrice(
          this.pData.services["i1"].serviceId,
          "val"
        )
          ? this.pPars.getServicePrice(
              this.pData.services["i1"].serviceId,
              "val"
            )
          : "";
        if (
          this.settings.servicePaymentType !== null &&
          this.settings.servicePaymentType == "beznal"
        ) {
          servicei1i2valnds = this.pPars.getServicePrice(
            this.pData.services["i1"].serviceId,
            "valnds"
          )
            ? this.pPars.getServicePrice(
                this.pData.services["i1"].serviceId,
                "valnds"
              )
            : "";
        }
      } else if (~recepients[i].postservices.indexOf("i2")) {
        servicei1i2 = this.pData.services["i2"].f107Label;
        servicei1i2val = this.pPars.getServicePrice(
          this.pData.services["i2"].serviceId,
          "val"
        )
          ? this.pPars.getServicePrice(
              this.pData.services["i2"].serviceId,
              "val"
            )
          : "";
        if (
          this.settings.servicePaymentType !== null &&
          this.settings.servicePaymentType == "beznal"
        ) {
          servicei1i2valnds = this.pPars.getServicePrice(
            this.pData.services["i2"].serviceId,
            "valnds"
          )
            ? this.pPars.getServicePrice(
                this.pData.services["i2"].serviceId,
                "valnds"
              )
            : "";
        }
      }
      //
      let rowReplacements = {
        rowname: recepients[i].NameWithComment(),
        rowaddr: recepients[i].FullAddress(),
        rowbarcode: recepients[i].barcode ? recepients[i].barcode : "",
        posttype: recepients[i].posttype
          ? this.pData.getMailCatName(recepients[i].posttype)
          : "",
        postweight: recepients[i].weight
          ? (recepients[i].weight / 1000).toFixed(3)
          : "-",
        servicei1i2label: servicei1i2,
        servicei1i2val: servicei1i2val,
        servicei1i2valnds: servicei1i2valnds,

        servicep1label: ~recepients[i].postservices.indexOf("p1")
          ? this.pData.services["p1"].f107Label
          : "-",
        servicep1val: this.pPars.getServicePrice(
          this.pData.services["p1"].serviceId,
          "val"
        )
          ? this.pPars.getServicePrice(
              this.pData.services["p1"].serviceId,
              "val"
            )
          : "-",
        servicep1valnds: this.pPars.getServicePrice(
          this.pData.services["p1"].serviceId,
          "valnds"
        )
          ? this.pPars.getServicePrice(
              this.pData.services["p1"].serviceId,
              "valnds"
            )
          : "-",
        services3s4label: sVal ? text.join("/") : "-",
        services3s4val: sVal ? sVal.toFixed(2) : "-",
        services3s4valnds: sValNds ? sValNds.toFixed(2) : "-",
        boxtype:
          recepients[i].posttype !== null && recepients[i].posttype[0] == "p"
            ? this.pData.boxTypes[recepients[i].postpack].short
            : "-",
        note: recepients[i].note !== "" ? recepients[i].note : "-",
        groundcoverval: groundCoverVal ? groundCoverVal : "-",
        groundcovervalnds: groundCoverValNds ? groundCoverValNds : "-",
        sumoc: sumOc ? sumOc : "-",
        nalogeniplat:
          sumOc && this.pData.getMailCat(recepients[i].posttype) == 4
            ? sumOc
            : "-"
      };
      if (
        this.settings.servicePaymentType !== null &&
        this.settings.servicePaymentType !== "beznal"
      ) {
        rowReplacements.servicei1i2valnds = "-";
        rowReplacements.servicep1valnds = "-";
        rowReplacements.services3s4valnds = "-";
        rowReplacements.servicei1i2valnds = "-";
      }
      if (
        this.settings.basicPaymentType !== null &&
        this.settings.basicPaymentType !== "beznal"
      ) {
        rowReplacements.groundcovervalnds = "-";
      }
      t += tpl.InsertRow(rowReplacements);
    }
    let totals = this.getTotals(recepients);
    t += tpl.InsertTotalRow({
      nalogenplat:
        totals.total.nalogenplat > 0
          ? (totals.total.nalogenplat / 100).toFixed(2)
          : "0",
      totalsumoc:
        totals.total.sumOc > 0 ? (totals.total.sumOc / 100).toFixed(2) : "0",
      totalcval:
        totals.total.gcVal > 0 ? (totals.total.gcVal / 100).toFixed(2) : "0",
      totalgcvalnds:
        totals.total.gcValNds > 0
          ? (totals.total.gcValNds / 100).toFixed(2)
          : "0",
      totalsval:
        totals.total.sval > 0 ? (totals.total.sval / 100).toFixed(2) : "0",
      totalsvalnds:
        totals.total.svalnds > 0 ? (totals.total.svalnds / 100).toFixed(2) : "0"
    });
    ////////////
    for (let [key, value] of Object.entries(totals.byCategories)) {
      let params = {};
      params.catname = this.pData.mailCategories[key];
      let cStr = rubles(value.count);
      cStr = cStr.slice(0, cStr.indexOf("руб") - 1);
      params.catcount = value.count.toFixed(0) + "  (" + cStr + ")";
      for (let key in this.footerCategories) {
        let sum = (value[key] / 100).toFixed(2);
        if (
          (key == "nds" || key == "valnds") &&
          this.settings.basicPaymentType == "nal"
        ) {
          sum = (0).toFixed(2);
        }
        let rubStr = rubles(sum);
        if (!rubStr) {
          rubStr = "ноль рублей 00 копеек";
        }
        params[key] =
          sum + "  (" + rubStr.charAt(0).toUpperCase() + rubStr.slice(1) + ")";
      }
      t += tpl.InsertFooterBlockByCategories(params);
    }
    t += tpl.footerDelveryTitle;
    t += tpl.InesertBlockFooterDelivery(this.footerTotal, totals);
    t += tpl.footerServiceTitle;
    t += tpl.InesertBlockFooterService(this.footerService, totals);
    t += tpl.footerItogTitle;
    t += tpl.InsertCommonTotals(totals, this.settings);
    t += tpl.footerSigns;
    t += "</tbody></table>";
    return t;
  }

  SaveReestrToXLS() {
    let d = new Date();
    let day = d.getDate();
    if (day < 10) day = "0" + day.toString();
    let month = d.getMonth() + 1;
    if (month < 10) month = "0" + month.toString();
    let year = d.getFullYear();

    let table = document.querySelector("#tfsimple");

    let wb = new ExcelJS.Workbook();
    let ws = wb.addWorksheet("REESTR");
    ws = Parser.parseDomToTable(ws, table, {
      autoStyle: false
    });
    ws.pageSetup.orientation = "landscape";
    ws.pageSetup.paperSize = 9;
    ws.pageSetup.margins = {
      left: 0.4,
      right: 0.4,
      top: 0.7,
      bottom: 0.4,
      header: 0.1,
      footer: 0.1
    };
    ws.pageSetup.fitToWidth = 1;
    ws.headerFooter = {
      oddFooter:
        "Список №: " +
        this.settings.listNumber +
        " дата: " +
        date.formatDate(this.settings.listDate, "DD.MM.YYYY") +
        " Лист №: &P из &N"
    };

    wb.xlsx.writeBuffer().then(function(buffer) {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        "Реестр Ф103 от " + day + "-" + month + "-" + year + ".xlsx"
      );
    });
  }
}
