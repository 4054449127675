<template>
  <div class="dispflex self-stretch">
    <q-btn stretch flat label="Вход" @click="showSigninDialog"></q-btn>
    <q-dialog v-model="showsignin" content-style="background:rgba(0,0,0,0.5)">
      <q-card class="formsignin q-pa-md">
        <q-card-section class="row q-py-xs">
          <div class="text-h6 q-mx-auto h6formheader">Вход</div>
        </q-card-section>

        <q-card-section class="q-mt-sm">
          <q-form @submit="onSubmit" autocomplete="off">
            <q-input
              color="purple-12"
              outlined
              bg-color="white"
              v-model="login"
              placeholder="Логин"
              dense
              required
              lazy-rules
              :rules="[
                val => (val && val.length > 0) || 'Логин не указан',
                val =>
                  !/[а-яА-ЯёЁ]/g.test(val) ||
                  'Пожалуйста, не используйте русские буквы'
              ]"
            >
              <template v-slot:prepend>
                <q-icon name="account_circle" />
              </template>
            </q-input>
            <q-input
              class="q-mb-lg"
              outlined
              bg-color="white"
              v-model="password"
              :type="isPwd ? 'password' : 'text'"
              placeholder="Пароль"
              dense
              required
              lazy-rules
              :rules="[
                val => (val && val.length > 0) || 'Пароль не указан',
                val =>
                  !/[а-яА-ЯёЁ]/g.test(val) ||
                  'Пожалуйста, не используйте русские буквы'
              ]"
            >
              <template v-slot:prepend>
                <q-icon name="lock" />
              </template>
              <template v-slot:append>
                <q-icon
                  :name="isPwd ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  @click="isPwd = !isPwd"
                />
              </template>
            </q-input>
            <div
              class="q-mt-md"
              @click="acceptCookie"
              style="line-height: 125%"
              v-show="!(accepting || $q.cookies.has('cookieaccepted'))"
            >
              <a
                class="col text-white"
                href="https://777.xn--l1adcnfw.xn--p1ai/api/authcookie/"
                target="_blank"
                style="text-decoration: none"
                ><q-icon
                  class="col-auto q-mr-sm q-mb-lg float-left"
                  :name="
                    !(accepting || $q.cookies.has('cookieaccepted'))
                      ? 'check_box_outline_blank'
                      : 'check_box'
                  "
                  size="md"
                />
              </a>
              Согласие с политикой конфиденциальности
            </div>
            <q-btn
              :disable="!(accepting || $q.cookies.has('cookieaccepted'))"
              :loading="loginprogress"
              type="submit"
              unelevated
              class="signinbtn full-width"
              >Войти</q-btn
            >
            <q-separator class="sep1 q-mt-lg" />
            <q-separator class="sep2" />
            <q-btn
              router-link
              to="/recovery/"
              class="q-mt-sm full-width text-center fs12"
              label="Забыли пароль? Нажмите сюда"
              no-caps
              flat
            />
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      login: "",
      password: "",
      isPwd: true,
      accept: false,
      loginprogress: false,
      accepting: false
    };
  },
  methods: {
    showSigninDialog() {
      this.showsignin = true;
    },
    async onSubmit() {
      this.loginprogress = true;
      await this.$store.dispatch("SIGNIN", {
        login: this.login,
        password: this.password
      });
      await new Promise(resolve => setTimeout(resolve, 1500));
      this.loginprogress = false;
    },
    acceptCookie() {
      this.accepting = !this.accepting;
      this.$q.cookies.set("cookieaccepted", 1, { expires: "365d" });
    }
  },
  computed: {
    showsignin: {
      get() {
        return this.$store.getters.SIGNINWND;
      },
      set(value) {
        this.$store.commit("SET_SIGNINWND", value);
      }
    }
  }
};
</script>

<style lang="sass">
$pochtumdialog: #8c2749

.q-card.formsignin
  width: 320px
  border-radius: 6px
  background: #127d65

.formsignin .q-field--error .q-field__bottom
  color: #FFF

.signinbtn.q-btn
  background: rgba(255, 255, 255, 0.15)
.singcreateaccbtn.q-btn
  border: 1px solid #751b3a
.sep1.q-separator
  background: #0b614d
.sep2.q-separator
  background: #16a786

.fs12
  font-size: 12px
.h6formheader.text-h6
  font-weight: 100
  font-size: 16px
</style>
