import jsPDF from "jspdf";
import "jspdf/dist/polyfills.es.js";
//import Interleaved from "src/service/classes/interleaved.js";
import Address from "src/service/classes/address.js";
import Sender from "src/service/classes/sender.js";
import CommonTemplate from "src/service/classes/printCommon.js";

export default class InventoryTemplate extends CommonTemplate {
  constructor(settingsObject) {
    super();
    this.doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a4"
    });
    this.doc.setFont("Play");
    this.settings = settingsObject;

    this.f107 = [];
    this.totalcount = 0;
    this.totalcost = 0;

    this.pad = 4;
    this.padding = 1;
    this.col1 = 10;
    this.col2 = 64.5;
    this.col3 = 16;
    this.col4 = 26;
    this.tabw = this.col1 + this.col2 + this.col3 + this.col4;
    this.curfs = 7;

    this.x1 = 12;
    this.y1 = 14;
    this.x2 = 160.5;
    this.y2 = 14;
    this.lineheight = 4;
  }

  prepareInventory(recep) {
    this.f107 = [];
    this.totalcost = 0;
    this.totalcount = 0;
    for (let elem of recep.inventory) {
      this.doc.setFontSize(this.curfs);
      let splitedName = this.doc.splitTextToSize(elem.name, this.col2);
      let fs = this.curfs;
      /*if (splitedName.length > 2) {
        fs = fs - 4;
        splitedName = this.doc.splitTextToSize(elem.name, this.col2);
      }
      while (splitedName.length > 2 && fs > 3) {
        fs = fs - 0.3;
        this.doc.setFontSize(fs);
        splitedName = this.doc.splitTextToSize(elem.name, this.col2);
      } */
      this.f107.push({
        lines: splitedName,
        len: splitedName.length,
        fsize: fs,
        cost: elem.cost,
        count: elem.count
      });

      this.totalcount += Number(elem.count);
      this.totalcost += Number(elem.cost);
    }
    /* let ost = this.f107.length % 13; */

    if (this.f107.length == 0) {
      for (let i = 0; i < 13; i++) {
        this.f107.push({
          lines: [""],
          len: 0,
          fsize: 10,
          cost: 0,
          count: 0
        });
      }
    }
  }

  addHeader(x, y, recep) {
    this.doc.addImage("images/common/logopost.jpg", "JPEG", x + 4, y + 4);
    this.doc.setFontSize("13");
    this.doc.text("ОПИСЬ", x + 55, y + 11);
    this.doc.setFontSize("8");
    this.doc.text("ф.107", x + 113, y + 6);
    this.doc.text("Исправления не допускаются", x + 80, y + 8.8);
    this.doc.setFontSize("6");
    this.doc.text(x + 4, y + 20, "Номер почтового идентификатора");
    this.doc.setFontSize("12");
    for (var i = 0; i < 14; i++) {
      this.doc.rect(x + 4 + 5 * i, y + 21, 4.5, 4.5);
      if (recep.barcode !== null) {
        this.doc.text(
          String(recep.barcode)[i],
          x + 4 + 5 * i + 2.25,
          y + 21 + 3.5,
          null,
          null,
          "center"
        );
      }
    }

    this.doc.setFontSize("8");
    this.doc.text("Вложение в", x + 4, y + 29.5);
    let postcategory = "";
    let pt = recep.posttype;

    if (pt == "e3" || pt == "e4") {
      postcategory = "Письмо";
    } else if (pt == "b3" || pt == "b4") {
      postcategory = "Бандероль";
    } else if (pt == "p2" || pt == "p3" || pt == "p5" || pt == "p6") {
      postcategory = "Посылку";
    } else if (
      pt == "e6" ||
      pt == "e7" ||
      pt == "b6" ||
      pt == "b7" ||
      pt == "p8" ||
      pt == "p9"
    ) {
      postcategory = "Отправление 1-го класса";
    }

    this.doc.text(postcategory, x + 22, y + 29.5);
    this.doc.line(x + 22, y + 30, x + this.tabw + this.pad, y + 30);
    this.doc.text("Кому", x + 4, y + 33.5);
    this.doc.line(x + 12, y + 34, x + this.tabw + this.pad, y + 34);
    this.doc.text("Куда", x + 4, y + 37.5);
    this.doc.line(x + 12, y + 38, x + this.tabw + this.pad, y + 38);

    this.doc.setFontSize("7");
    let splitedName = this.doc.splitTextToSize(recep.fullname, this.tabw - 8);
    let fs = 7;
    while (splitedName.length > 1) {
      fs = fs - 0.3;
      this.doc.setFontSize(fs);
      splitedName = this.doc.splitTextToSize(recep.fullname, this.tabw - 8);
    }
    this.doc.text(x + 12, y + 33.5, splitedName[0]);

    this.doc.setFontSize("7");
    let splitedAddr = this.doc.splitTextToSize(
      (recep.zip != "" ? recep.zip + ", " : "") + recep.addr,
      this.tabw - 8
    );
    fs = 7;
    while (splitedAddr.length > 1) {
      fs = fs - 0.3;
      this.doc.setFontSize(fs);
      splitedAddr = this.doc.splitTextToSize(
        (recep.zip != "" ? recep.zip + ", " : "") + recep.addr,
        this.tabw - 8
      );
    }
    this.doc.text(x + 12, y + 37.5, splitedAddr[0]);

    this.doc.setLineWidth(0.6);
    this.doc.rect(x + this.pad, y + 40, this.tabw, 7);
    this.doc.setLineWidth(0.2);
    this.doc.setFontSize("8");
    this.doc.text("№№", x + this.pad + 2, y + 43);
    this.doc.text("п/п", x + this.pad + 3, y + 46);
    this.doc.line(
      x + this.pad + this.col1,
      y + 40,
      x + this.pad + this.col1,
      y + 47
    );
    this.doc.text(
      "Наименование предметов",
      x + this.pad + this.col1 + 15,
      y + 44.5
    );
    this.doc.line(
      x + this.pad + this.col1 + this.col2,
      y + 40,
      x + this.pad + this.col1 + this.col2,
      y + 47
    );
    this.doc.text("Кол-во", x + this.pad + this.col1 + this.col2 + 3, y + 43);
    this.doc.text(
      "предметов",
      x + this.pad + this.col1 + this.col2 + this.padding / 2,
      y + 46
    );
    this.doc.line(
      x + this.pad + this.col1 + this.col2 + this.col3,
      y + 40,
      x + this.pad + this.col1 + this.col2 + this.col3,
      y + 47
    );
    this.doc.text(
      "Объявленная",
      x + this.pad + this.col1 + this.col2 + this.col3 + 3,
      y + 43
    );
    this.doc.text(
      "ценность, руб.",
      x + this.pad + this.col1 + this.col2 + this.col3 + 3,
      y + 46
    );
  }

  addRow(x, y, num, rowobject) {
    this.doc.setLineWidth(0.6);
    let cellH =
      rowobject.len > 0 ? this.lineheight * rowobject.len : this.lineheight;
    this.doc.line(x + this.pad, y, x + this.pad, y + cellH);
    this.doc.line(
      x + this.pad + this.tabw,
      y,
      x + this.pad + this.tabw,
      y + cellH
    );
    this.doc.setLineWidth(0.2);
    this.doc.cell(x + this.pad, y, this.col1, cellH, " ");
    this.doc.text(
      rowobject.count > 0 ? String(num + 1) : "",
      x + this.pad + this.col1 / 2,
      y + cellH / 2 + this.padding,
      null,
      null,
      "center"
    );

    this.doc.cell(x + this.pad + this.col1, y, this.col2, cellH, " ");
    this.doc.text(
      rowobject.lines,
      x + this.pad + this.col1 + this.padding,
      y + this.lineheight - 0.4,
      { baseline: "bottom" }
    );
    /* if (rowobject.len > 1) {
      for (var k = 0; k < rowobject.len; k++) {
        let oldfs = this.doc.getFontSize();
        this.doc.setFontSize(rowobject.fsize);
        this.doc.text(
          rowobject.lines[k],
          x + this.pad + this.col1 + this.padding,
          y + this.lineheight / 2 + 0.05 + k * (this.lineheight / 2 - 0.4)
        );
        this.doc.setFontSize(oldfs);
      }
    } else {
      this.doc.text(
        rowobject.lines[0],
        x + this.pad + this.col1 + this.padding,
        y + this.lineheight / 2 + this.padding
      );
    } */

    this.doc.cell(
      x + this.pad + this.col1 + this.col2,
      y,
      this.col3,
      cellH,
      " "
    );
    this.doc.text(
      rowobject.count > 0 ? String(rowobject.count) : "",
      x + this.pad + this.col1 + this.col2 + this.col3 / 2,
      y + cellH / 2 + this.padding,
      null,
      null,
      "center"
    );

    this.doc.cell(
      x + this.pad + this.col1 + this.col2 + this.col3,
      y,
      this.col4,
      cellH,
      " "
    );
    this.doc.text(
      String(rowobject.cost > 0 ? rowobject.cost : " "),
      x +
        this.pad +
        this.col1 +
        this.col2 +
        this.col3 +
        this.col4 -
        this.padding,
      y + cellH / 2 + this.padding,
      null,
      null,
      "right"
    );
  }

  addFooter(x, y, sender, withtotals) {
    let h = this.lineheight + 2 * this.padding;

    this.doc.setFontSize("8");
    this.doc.text(
      "Общий итог предметов и объявленной ценности",
      x + this.pad,
      y + 5
    );
    this.doc.setLineWidth(0.6);

    this.doc.line(x + this.pad, y, x + this.pad + this.tabw, y);
    this.doc.rect(x + this.pad + this.col1 + this.col2, y, this.col3, h);
    this.doc.rect(
      x + this.pad + this.col1 + this.col2 + this.col3,
      y,
      this.col4,
      h
    );

    this.doc.setLineWidth(0.2);
    if (withtotals) {
      this.doc.setFontSize("10");
      this.doc.text(
        this.totalcount > 0 ? String(this.totalcount) : "",
        x + this.pad + this.col1 + this.col2 + this.col3 / 2,
        y + 5,
        null,
        null,
        "center"
      );
      this.doc.setFontSize("10");
      this.doc.text(
        this.totalcost > 0 ? String(this.totalcost) : "",
        x +
          this.pad +
          this.col1 +
          this.col2 +
          this.col3 +
          this.col4 -
          this.padding,
        y + 5,
        "right"
      );
    }
    this.doc.setFontSize("6");
    this.doc.text(
      "(цифрами)",
      x + this.pad + this.col1 + this.col2 + this.col3 + 3,
      y + 10
    );

    this.doc.setFontSize("8");
    this.doc.text("Отправитель", x + this.pad, y + h + 5);
    this.doc.setFontSize("6");
    this.doc.text(
      "ФИО, наименование юридического лица",
      x + this.pad,
      y + h + 7.5
    );

    this.doc.line(
      x + this.pad,
      y + h + 13,
      x + this.pad + this.col1 + this.col2 + this.col3 / 2,
      y + h + 13
    );
    this.doc.line(
      x + this.pad,
      y + h + 17,
      x + this.pad + this.col1 + this.col2 + this.col3 / 2,
      y + h + 17
    );

    this.doc.setFontSize("10");
    let splitedName = this.doc.splitTextToSize(
      sender.fullname,
      this.col1 + this.col2 + this.col3 / 2
    );
    let fs = 10;
    while (splitedName.length > 2) {
      fs = fs - 0.5;
      this.doc.setFontSize(fs);
      splitedName = this.doc.splitTextToSize(
        sender.fullname,
        this.col1 + this.col2 + this.col3 / 2
      );
    }

    for (var k = 0; k < splitedName.length; k++) {
      this.doc.text(
        splitedName[k],
        x + this.pad + this.padding / 2,
        y + h + 12.5 + k * (this.lineheight - this.padding)
      );
    }

    this.doc.setFontSize("8");
    this.doc.text("Проверил", x + this.pad, y + h + 26.7);
    this.doc.setFontSize("6");
    this.doc.text("ФИО почтового работника", x + this.pad, y + h + 29);
    this.doc.text("должность почтового работника", x + this.pad, y + h + 37);

    this.doc.line(
      x + this.pad + 15,
      y + h + 27,
      x + this.pad + this.col1 + this.col2 + this.col3 / 2,
      y + h + 27
    );
    this.doc.line(
      x + this.pad,
      y + h + 31,
      x + this.pad + this.col1 + this.col2 + this.col3 / 2,
      y + h + 31
    );
    this.doc.line(
      x + this.pad,
      y + h + 35,
      x + this.pad + this.col1 + this.col2 + this.col3 / 2,
      y + h + 35
    );
    this.doc.line(
      x + this.pad,
      y + h + 39,
      x + this.pad + this.col1 + this.col2 + this.col3 / 2,
      y + h + 39
    );
    this.doc.line(
      x + this.pad,
      y + h + 43,
      x + this.pad + this.col1 + this.col2 + this.col3 / 2,
      y + h + 43
    );
    this.doc.setLineWidth(0.6);
    this.doc.line(
      x + this.pad + this.col1 + this.col2 + this.col3 / 2 - 33.5,
      y + h + 52,
      x + this.pad + this.col1 + this.col2 + this.col3 / 2,
      y + h + 52
    );
    this.doc.setLineWidth(0.2);
    this.doc.text(
      "(подпись почтового работника)",
      x + this.pad + this.col1 + this.col2 + this.col3 / 2 - 33,
      y + h + 54
    );
    this.doc.rect(
      x + this.pad + this.col1 + this.col2 + this.col3 / 2 + 3,
      y + h + 9,
      this.col3 / 2 + this.col4 - this.pad + this.padding,
      10
    );
    this.doc.text(
      "(подпись)",
      x + this.pad + this.col1 + this.col2 + this.col3 / 2 + 13,
      y + h + 21
    );
    this.doc.rect(
      x + this.pad + this.col1 + this.col2 + this.col3 / 2 + 3,
      y + h + 24,
      this.col3 / 2 + this.col4 - this.pad + this.padding,
      31
    );
    this.doc.text(
      "Оттиск КПШ",
      x + this.pad + this.col1 + this.col2 + this.col3 / 2 + 12,
      y + h + 52
    );
    this.doc.text(
      "ОПС места приема",
      x + this.pad + this.col1 + this.col2 + this.col3 / 2 + 9,
      y + h + 54
    );
  }

  addTable(x, y, start, end) {
    for (let i = start; i < end; i++) {
      this.addRow(x, y + (i - start) * this.lineheight, i, this.f107[i]);
    }
  }

  addLines() {
    this.doc.setLineWidth(0.1);
    this.doc.rect(12, 14, 124.5, 181);
    this.doc.setLineDash([4]);
    this.doc.line(148.5, 0, 148.5, 210);
    this.doc.setLineDash([]);
    this.doc.rect(160.5, 14, 124.5, 181);
    this.doc.setLineWidth(0.2);
  }

  putInventory(sender, recep) {
    this.prepareInventory(recep);
    this.addLines();
    this.addHeader(this.x1, this.y1, recep);
    this.addHeader(this.x2, this.y2, recep);
    let yStartTabFirst = this.y1 + 47;
    let yStartTabNext = this.y1 + 4;
    let yStartFooter = this.y1 + 47 + 14 * 5;
    let curH = yStartTabFirst;
    this.doc.setFontSize(this.curfs);
    for (let i = 0; i < this.f107.length; i++) {
      if (curH + this.f107[i].len * this.lineheight < 192) {
        this.addRow(this.x1, curH, i, this.f107[i]);
        this.addRow(this.x2, curH, i, this.f107[i]);
        curH += this.f107[i].len * this.lineheight;
      } else if (curH + this.f107[i].len * this.lineheight > yStartFooter) {
        this.doc.addPage();
        this.addLines();
        curH = yStartTabNext;
        this.addRow(this.x1, curH, i, this.f107[i]);
        this.addRow(this.x2, curH, i, this.f107[i]);
        curH += this.f107[i].len * this.lineheight;
      }
    }

    let end = curH + this.lineheight > yStartFooter ? 192 : yStartFooter;
    while (curH + this.lineheight < end) {
      let emptyRow = {
        lines: [""],
        len: 0,
        fsize: 10,
        cost: 0,
        count: 0
      };
      this.addRow(this.x1, curH, "", emptyRow);
      this.addRow(this.x2, curH, "", emptyRow);
      curH += this.lineheight;
    }
    if (end == 192) {
      this.doc.addPage();
      this.addLines();
      curH = yStartTabNext;
      while (curH + this.lineheight < yStartFooter) {
        let emptyRow = {
          lines: [""],
          len: 0,
          fsize: 10,
          cost: 0,
          count: 0
        };
        this.addRow(this.x1, curH, "", emptyRow);
        this.addRow(this.x2, curH, "", emptyRow);
        curH += this.lineheight;
      }
      this.addFooter(this.x1, yStartFooter - 3, sender, 1);
      this.addFooter(this.x2, yStartFooter - 4, sender, 1);
    } else {
      this.addFooter(this.x1, curH, sender, 1);
      this.addFooter(this.x2, curH, sender, 1);
    }
    /* let pagecount = Math.floor(this.f107.length / 13);
    for (let i = 0; i < pagecount; i++) {
      if (i > 0) {
        this.doc.addPage();
      }
      this.addLines();
      this.addHeader(this.x1, this.y1, recep);
      this.addHeader(this.x2, this.y2, recep);
      this.addTable(this.x1, this.y1 + 47, i * 13, (i + 1) * 13);
      this.addTable(this.x2, this.y2 + 47, i * 13, (i + 1) * 13);
      this.addFooter(
        this.x1,
        this.y1 + 47 + 13 * this.lineheight,
        sender,
        i == pagecount - 1 ? 1 : 0
      );
      this.addFooter(
        this.x2,
        this.y2 + 47 + 13 * this.lineheight,
        sender,
        i == pagecount - 1 ? 1 : 0
      );
    } */
  }

  add(sender, recep, mass) {
    if (typeof sender == "undefined") {
      sender = new Sender({});
    }
    if (recep == null) {
      recep = new Address({});
    }
    if (mass) {
      this.doc.addPage();
      this.putInventory(sender, recep);
    } else {
      //this.doc.deletePage(1);
      this.putInventory(sender, recep);
      //this.PutInvoice2(1, sender, recep);
    }
  }
}
