<template>
  <div>
    <div class="row q-mb-lg">
      <div class="col-5 text-h4">Счета и акты</div>
      <div class="col-7">
        <q-btn
          no-caps
          label="Удалить счёт"
          v-bind:disable="!billsnotpayed"
          color="white"
          flat
          @click.prevent="deleteBill()"
          class="float-right q-ml-md"
        ></q-btn>
        <q-btn
          :loading="billcreateprogress"
          color="secondary"
          class="float-right createbillbtn"
          unelevated
          no-caps
          @click.prevent="
            billcreateprogress = true;
            createBill();
          "
        >
          Создать счёт
          <template v-slot:loading>
            <q-spinner-hourglass class="on-left" />Счёт формируется..
          </template>
        </q-btn>
      </div>
    </div>
    <q-table
      dark
      class="no-border"
      table-class="bg-primary-light billtable"
      table-header-class="boldheader"
      :pagination="{
        rowsPerPage: 10
      }"
      flat
      dense
      hide-bottom
      bordered
      :data="bills"
      :columns="billcolumns"
      row-key="name"
      separator="cell"
      square
      v-if="bills != ''"
    >
      <template v-slot:body="props">
        <q-tr :props="props">
          <q-td key="billnumber" :props="props" class="q-pa-sm">
            {{ props.row.billnumber + " от " + props.row.billdate }}
            <q-btn
              size="md"
              flat
              round
              color="accent"
              icon="print"
              @click="
                printDoc({
                  doctype: 'bill',
                  number: props.row.billnumber,
                  date: props.row.billdate
                })
              "
              target="_blank"
            />
          </q-td>
          <q-td key="billstatus" :props="props">{{
            props.row.billpayed > 0 ? "Оплачен" : "Не оплачен"
          }}</q-td>
          <q-td key="billpayedpp" :props="props">{{
            props.row.billpayedpp > 0
              ? props.row.billpayedpp + " от " + props.row.billpayeddate
              : "---"
          }}</q-td>
          <q-td key="sfstatus" :props="props">
            {{
              props.row.sfstatus > 0
                ? props.row.sfnumber + " от " + props.row.sfdate
                : "---"
            }}
            <q-btn
              size="md"
              flat
              round
              color="accent"
              icon="print"
              v-if="props.row.sfstatus > 0"
              @click="
                printDoc({
                  doctype: 'act',
                  number: props.row.sfnumber,
                  date: props.row.sfdate
                })
              "
            />
          </q-td>
        </q-tr>
      </template>
    </q-table>
    <p class="q-mt-lg">
      * Отчетные документы формируются в последний календарный день квартала
    </p>
  </div>
</template>

<script>
import BillTemplate from "src/service/classes/printbill.js";
import ActTemplate from "src/service/classes/printact.js";
export default {
  data: function() {
    return {
      billcolumns: [
        { name: "billnumber", align: "left", label: "Счет" },
        { name: "billstatus", align: "center", label: "Статус" },
        { name: "billpayedpp", align: "center", label: "Платежное поручение" },
        { name: "sfstatus", align: "center", label: "Акт" }
      ],
      billcreateprogress: false
    };
  },
  computed: {
    bills() {
      return this.$store.getters.BILLS;
    },
    billsnotpayed() {
      let res = false;
      for (let i = 0; i < this.bills.length; i++) {
        if (this.bills[i].billpayed == 0) res = true;
      }
      return res;
    },
    browser() {
      return {
        name: this.$q.platform.is.name,
        version: this.$q.platform.is.versionNumber
      };
    }
  },
  methods: {
    async createBill() {
      try {
        if (this.$store.getters.ACCOUNT.req.companyname != "") {
          this.billcreateprogress = true;
          this.$store.dispatch("CREATE_BILL");
          await new Promise(resolve => setTimeout(resolve, 1500));
          this.billcreateprogress = false;
        } else {
          this.$store.commit("SET_ERROR", {
            error: true,
            errortext: "Сначала необходимо заполнить реквизиты"
          });
        }
      } catch (err) {
        this.billcreateprogress = false;
        this.$store.commit("SET_ERROR", {
          error: true,
          errortext:
            "Ошибка при создании счета. Информация передана разработчикам. Попробуйте позже"
        });
        this.$store.dispatch("LOG_ERROR", {
          error: err,
          desc: "createBill function"
        });
      }
    },
    deleteBill() {
      this.$store.dispatch("DELETE_BILL");
    },
    async printDoc(payload) {
      try {
        let data = await this.$store.dispatch("PRINT_DOC", payload);
        if (payload.doctype == "bill" && !data.error) {
          let bill = new BillTemplate();
          bill.CreateBill(data.body.req, data.body.qr);
          bill.print(this.browser);
        } else if (payload.doctype == "act" && !data.error) {
          let act = new ActTemplate();
          let addStamp = true;
          act.CreateAct(data.body.req, addStamp);
          act.print(this.browser);
        }

        /* if (!data.error && data.body !== null) {
          var loc = "newbill";
          let w = window.open(loc, "_blank");
          w.document.write(data.body.replace(/\\/g, ""));
          setTimeout(() => w.print(), 1000);
        } */
      } catch (err) {
        this.$store.commit("SET_ERROR", {
          error: true,
          errortext:
            "Ошибка при создании печатной формы документа. Информация передана разработчикам. Попробуйте позже"
        });
        this.$store.dispatch("LOG_ERROR", {
          error: err,
          desc: "deleteBill function"
        });
      }
    },
    printLastBill() {
      this.printDoc({
        doctype: "bill",
        number: this.bills[this.bills.length - 1].billnumber,
        date: this.bills[this.bills.length - 1].billdate
      });
    }
  },
  mounted() {
    this.$eventBus.$on("print-last-bill", a => this.printLastBill(a));
  },
  beforeDestroy() {
    this.$eventBus.$off("print-last-bill");
  }
};
</script>

<style lang="sass">
.boldheader th
  font-weight: 700
  background: rgba(0,0,0,0.1)
.billtable>table
  border: 1px solid rgba(0, 0, 0, 0.3)
.billtable>table th, .billtable>table td
  border-color: rgba(0,0,0,0.3)
.createbillbtn
  min-width: 180px
</style>
