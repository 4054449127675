<template>
  <div class="q-mx-sm">
    <q-scroll-area
      :thumb-style="thumbStyle"
      :bar-style="barStyle"
      :style="'height:' + areaheight + 'px'"
      class="varea"
      :id="vscrollarea"
    >
      <q-virtual-scroll
        :scroll-target="scrolltarget"
        :items="list"
        :virtual-scroll-item-size="itemheight"
        separator
      >
        <template v-slot="{ item, index }">
          <slot name="list" v-bind:item="item" v-bind:index="index"></slot>
        </template>
      </q-virtual-scroll>
    </q-scroll-area>
  </div>
</template>

<script>
export default {
  props: {
    list: { type: Array, default: null },
    areaheight: { type: Number, default: 200 },
    itemheight: { type: Number, default: 32 },
    vscrollarea: { type: String, default: "vscrollarea" }
  },
  data() {
    return {
      thumbStyle: {
        right: "-4px",
        borderRadius: "5px",
        backgroundColor: "#11a8ab",
        width: "8px",
        opacity: 0.75
      },
      barStyle: {
        right: "-6px",
        borderRadius: "14px",
        backgroundColor: "#000000",
        width: "12px",
        opacity: 0.1,
        marginTop: "-3px",
        marginBottom: "-3px",
        paddingTop: "3px",
        paddingBottom: "3px"
      }
    };
  },

  computed: {
    scrolltarget() {
      return "#" + this.vscrollarea + " > .scroll";
    }
  },
  watch: {},
  methods: {}
};
</script>
<style lang="sass">
.varea
  padding-right: 6px
</style>