<template>
  <div>
    <q-card
      class="formsignup q-pa-md q-mx-auto q-mt-xl"
      v-if="this.$store.getters.ACTIVEUSER"
    >
      <q-card-section class="row q-py-xs">
        <div class="fs16 q-mx-auto h6formheader text-center">
          Вы зарегистрированы. Вход выполнен.
        </div>
        <q-btn
          router-link
          to="/"
          label="Продолжить работу"
          unelevated
          class="signinbtn full-width q-mt-lg"
          color="accent"
        />
      </q-card-section>
    </q-card>
    <q-card
      class="formsignup q-pa-lg q-mx-auto q-mt-xl bg-primary-light"
      v-if="this.$store.getters.ALLOWREGISTRATION"
    >
      <q-card-section class="row q-py-xs">
        <div class="no-caps text-h6 q-mx-auto h6formheader">Регистрация</div>
      </q-card-section>

      <q-card-section class="q-mt-none">
        <q-form @submit="onSubmit" autocomplete="off">
          <q-input
            filled
            color="grey-2"
            dark
            type="email"
            v-model="email"
            placeholder="Email"
            dense
            class="q-mb-md"
            required
          >
            <template v-slot:prepend>
              <q-icon name="email" />
            </template>
          </q-input>
          <q-input
            filled
            color="grey-2"
            dark
            v-model="login"
            placeholder="Логин"
            dense
            class="q-mb-md"
            required
            lazy-rules
            :rules="[
              val => (val && val.length > 0) || 'Логин не указан',
              val =>
                !/[а-яА-ЯёЁ]/g.test(val) ||
                'Пожалуйста, не используйте русские буквы'
            ]"
          >
            <template v-slot:prepend>
              <q-icon name="account_circle" />
            </template>
          </q-input>
          <q-input
            filled
            color="grey-3"
            dark
            v-model="password"
            :type="isPwd ? 'password' : 'text'"
            placeholder="Пароль"
            dense
            required
            lazy-rules
            :rules="[
              val => (val && val.length > 0) || 'Пароль не указан',
              val =>
                !/[а-яА-ЯёЁ]/g.test(val) ||
                'Пожалуйста, не используйте русские буквы'
            ]"
          >
            <template v-slot:prepend>
              <q-icon name="lock" />
            </template>
            <template v-slot:append>
              <q-icon
                :name="isPwd ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwd = !isPwd"
              />
            </template>
          </q-input>

          <q-input
            class="q-mb-md"
            filled
            color="grey-3"
            dark
            v-model="passwordconfirm"
            :type="isPwd ? 'password' : 'text'"
            placeholder="Пароль (еще раз)"
            dense
            required
            lazy-rules
            :rules="[
              val => (val && val.length > 0) || 'Пароль не указан',
              val => (val && val == this.password) || 'Пароли не совпадают',
              val =>
                !/[а-яА-ЯёЁ]/g.test(val) ||
                'Пожалуйста, не используйте русские буквы'
            ]"
          >
            <template v-slot:prepend>
              <q-icon name="lock" />
            </template>
            <template v-slot:append>
              <q-icon
                :name="isPwd ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwd = !isPwd"
              />
            </template>
          </q-input>

          <q-btn
            :loading="signupinprogress"
            type="submit"
            unelevated
            no-caps
            class="signinbtn full-width"
            :disable="!(accepting || $q.cookies.has('cookieaccepted'))"
          >
            Создать аккаунт
            <template v-slot:loading>
              <q-spinner-hourglass class="on-left" />Аккаунт создается..
            </template>
          </q-btn>
          <q-separator class="sep1 q-mt-md" />
          <q-separator class="sep2" />
          <div
            class="q-mt-md"
            style="line-height: 125%"
            @click="accepting = !accepting"
          >
            <a
              class="col text-white"
              href="https://777.xn--l1adcnfw.xn--p1ai/api/authcookie/"
              target="_blank"
              style="text-decoration: none"
              ><q-icon
                class="col-auto q-mr-sm q-mb-lg float-left"
                :name="
                  !(accepting || $q.cookies.has('cookieaccepted'))
                    ? 'check_box_outline_blank'
                    : 'check_box'
                "
                size="md"
              />Согласие с политикой конфиденциальности</a
            >
          </div>
        </q-form>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
export default {
  meta: {
    // sets document title
    title: "Регистрация в сервисе ПОЧТУМ.РФ",
    // meta tags
    meta: {
      description: {
        name: "description",
        content: "Регистрация в сервисе ПОЧТУМ."
      },
      keywords: {
        name: "keywords",
        content: "регистрация"
      },
      ogTitle: {
        name: "Регистрация в сервисе ПОЧТУМ."
      }
    }
  },
  data() {
    return {
      email: "",
      login: "",
      password: "",
      passwordconfirm: "",
      isPwd: true,
      accept: false,
      signupinprogress: false,
      accepting: false
    };
  },
  methods: {
    async onSubmit() {
      try {
        this.signupinprogress = true;
        await this.$store.dispatch("SIGNUPLIVE", {
          mode: "live",
          email: this.email,
          login: this.login,
          password: this.password,
          browser: this.$q.platform.is.name,
          fid: this.$store.getters.FID
        });
        this.signupinprogress = false;
      } catch (err) {
        this.signupinprogress = false;
        this.$store.commit("SET_ERROR", {
          error: true,
          errortext:
            "Ошибка при регистрации. Информация передана разработчикам. Попробуйте позже"
        });
        this.$store.dispatch("LOG_ERROR", {
          error: err,
          desc: "signuponSubmit function"
        });
      }
    }
  }
};
</script>

<style lang="sass">
$pochtumdialog: #8c2749

.q-card.formsignup
  width: 400px

.formsignup .q-field--error .q-field__bottom
  color: #FFF

.signinbtn.q-btn
  background: rgba(255, 255, 255, 0.15)
.singcreateaccbtn.q-btn
  border: 1px solid #751b3a
.formsignup .sep1.q-separator
  background: #363c56
.formsignup .sep2.q-separator
  background: #6672a0
.fs16
  font-size: 16px
.fs12
  font-size: 12px
.h6formheader.text-h6
  font-weight: 100
  font-size: 24px
</style>
