import Vue from "vue";
import Vuex from "vuex";

//platform store
import UserModule from "src/platform/store/user";
import GeneralModule from "src/platform/store/general";
import InterfaceModule from "src/platform/store/interface";
import ChatModule from "src/platform/store/chat";
import FprtModule from "src/platform/store/fprt";
import Metrics from "src/platform/store/metrics";

//service store
import Recepients from "src/service/store/recepients";
import Groups from "src/service/store/groups";
import Senders from "src/service/store/senders";
import RecepientsMass from "src/service/store/recepients-mass";
import Settings from "src/service/store/settings";
import Progress from "src/service/store/progress";
import Lists from "src/service/store/lists";
import ReachGoals from "src/service/store/reachgoals";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    GeneralModule,
    UserModule,
    InterfaceModule,
    ChatModule,
    FprtModule,
    Metrics,

    Recepients,
    Groups,
    Senders,
    RecepientsMass,
    Settings,
    Progress,
    Lists,
    ReachGoals
  },
  strict: process.env.NODE_ENV !== "production",
  namespaced: true
});
