import jsPDF from "jspdf";

import Interleaved from "src/service/classes/interleaved.js";
import Address from "src/service/classes/address.js";
import Sender from "src/service/classes/sender.js";
import CommonTemplate from "src/service/classes/printCommon.js";

export default class InvoiceTemplate extends CommonTemplate {
  constructor(settingsObject) {
    super();
    this.doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a4"
    });
    this.doc.setFont("Play");
    this.settings = settingsObject;
  }

  PutInvoice1(pos, sender, recep) {
    let dx = 0;
    let dy = 0;
    if (pos == 1) {
      dx = 148.5 - 128.5 - 10;
      dy = 105 - 85 - 10;
    }
    if (pos == 2) {
      dx = 148.5 + 10;
      dy = 105 - 85 - 10;
    }
    if (pos == 3) {
      dx = 148.5 - 128.5 - 10;
      dy = 105 + 10;
    }
    if (pos == 4) {
      dx = 148.5 + 10;
      dy = 105 + 10;
    }
    this.doc.setLineDash([0.4]);

    this.doc.line(dx + 0, dy + 0, dx + 0, dy + 85);
    this.doc.line(dx + 128.5, dy + 0, dx + 128.5, dy + 85);
    //dottedsquare(dx+1, dy+0, dx+50, dy+15, doc);

    this.doc.setFontSize("6");
    this.doc.setLineDash([0.4]);
    this.doc.text("Для дополнительных отметок отправителя", dx + 29, dy + 69);
    this.doc.rect(dx + 25, dy + 70, 53, 15);
    if (recep.getInvoiceTipFromRecep) {
      let curfstip = 10;
      this.doc.setFontSize(curfstip);
      //if (this.Sname != "NONAME"){
      let sSplitsInvoiceTip = this.doc.splitTextToSize(recep.comment2, 51);
      while (
        sSplitsInvoiceTip.length * this.doc.getTextDimensions("Text").h >
        14
      ) {
        sSplitsInvoiceTip = this.doc.splitTextToSize(recep.comment2, 51);
        curfstip = curfstip - 0.5;
        this.doc.setFontSize(curfstip);
      }
      let lh = this.doc.getTextDimensions("Text").h;
      for (let i = 0; i < sSplitsInvoiceTip.length; i++) {
        this.doc.text(sSplitsInvoiceTip[i], dx + 26, dy + 73 + i * lh);
      }
    } else if (recep.invoicetip !== null) {
      let curfstip = 10;
      this.doc.setFontSize(curfstip);
      //if (this.Sname != "NONAME"){
      let sSplitsInvoiceTip = this.doc.splitTextToSize(recep.invoicetip, 51);
      while (
        sSplitsInvoiceTip.length * this.doc.getTextDimensions("Text").h >
        14
      ) {
        sSplitsInvoiceTip = this.doc.splitTextToSize(recep.invoicetip, 51);
        curfstip = curfstip - 0.5;
        this.doc.setFontSize(curfstip);
      }
      let lh = this.doc.getTextDimensions("Text").h;
      for (let i = 0; i < sSplitsInvoiceTip.length; i++) {
        this.doc.text(sSplitsInvoiceTip[i], dx + 26, dy + 73 + i * lh);
      }
    }
    this.doc.setFontSize("6");
    this.doc.text("(оттиск КПШ ОПС места", dx + 12, dy + 55);
    this.doc.text("приема почтового отправления)", dx + 9, dy + 57);
    this.doc.setLineDash([]);
    this.doc.rect(dx + 13, dy + 26, 24, 26);

    this.doc.setLineWidth(0.5);
    this.doc.line(dx + 49.5, dy + 16, dx + 128.1, dy + 16);
    this.doc.line(dx + 49.5, dy + 15.8, dx + 49.5, dy + 67.2);
    this.doc.line(dx + 49.5, dy + 67, dx + 77.5, dy + 67);
    this.doc.line(dx + 77.5, dy + 67.2, dx + 77.5, dy + 61.8);
    this.doc.line(dx + 77.5, dy + 62, dx + 128.1, dy + 62);

    this.doc.setLineWidth(0.2);
    this.doc.line(dx + 54, dy + 67, dx + 54, dy + 61);
    this.doc.line(dx + 58.7, dy + 67, dx + 58.7, dy + 61);
    this.doc.line(dx + 63.4, dy + 67, dx + 63.4, dy + 61);
    this.doc.line(dx + 68.1, dy + 67, dx + 68.1, dy + 61);
    this.doc.line(dx + 72.8, dy + 67, dx + 72.8, dy + 61);
    this.doc.line(dx + 49.5, dy + 61, dx + 127, dy + 61);
    this.doc.text("Индекс места назначения", dx + 50, dy + 60.5);
    this.doc.setFontSize("15");
    //this.Szip = this.Szip==0?"":this.Szip.toString();

    //if (this.Sname != "NONAME"){
    let zip = sender.zip.toString();
    for (var k = 0; k < zip.length && k < 7; k++) {
      this.doc.text(zip[k], dx + 50.5 + k * 4.5, dy + 65.7);
    }
    //}
    for (let k = 0; k < sender.zip.length; k++) {
      this.doc.text(zip[k], dx + 50.5 + k * 4.5, dy + 65.7);
    }

    this.doc.setFontSize("10");
    this.doc.text("Адрес", dx + 50.5, dy + 51);
    this.doc.text("Кому", dx + 50.5, dy + 41);

    let options = { textIndent: 10 };
    let curfs = 10;
    this.doc.setFontSize(curfs);
    //if (this.Sname != "NONAME"){
    let sSplitsName = this.doc.splitTextToSize(sender.fullname, 76, options);
    while (sSplitsName.length > 2 && curfs > 2) {
      sSplitsName = this.doc.splitTextToSize(sender.fullname, 76, options);
      curfs = curfs - 1;
      this.doc.setFontSize(curfs);
    }
    for (let i = 0; i < sSplitsName.length; i++)
      this.doc.text(
        sSplitsName[i],
        dx + 50.5 + (i == 0 ? 10 : 0),
        dy + 40.5 + 5 * i
      );
    //}
    options = { textIndent: 11.5 };
    curfs = 10;
    this.doc.setFontSize(curfs);
    //if (this.Sname != "NONAME"){
    let sSplitsAddr = this.doc.splitTextToSize(sender.addr, 76, options);
    while (sSplitsAddr.length > 2 && curfs > 2) {
      sSplitsAddr = this.doc.splitTextToSize(sender.addr, 76, options);
      curfs = curfs - 1;
      this.doc.setFontSize(curfs);
    }
    for (let i = 0; i < sSplitsAddr.length; i++)
      this.doc.text(
        sSplitsAddr[i],
        dx + 50.5 + (i == 0 ? 11.5 : 0),
        dy + 50.5 + 5 * i
      );
    //}
    this.doc.line(dx + 50.5, dy + 56, dx + 127, dy + 56);
    this.doc.line(dx + 62, dy + 51, dx + 127, dy + 51);
    this.doc.line(dx + 50.5, dy + 46, dx + 127, dy + 46);
    this.doc.line(dx + 60.5, dy + 41, dx + 127, dy + 41);

    this.doc.line(dx + 58, dy + 36, dx + 120, dy + 36);
    this.doc.setFontSize("8");
    this.doc.text(
      "Уведомление подлежит возврату по адресу:",
      dx + 58.5,
      dy + 34.9
    );

    this.doc.setFontSize("7");
    this.doc.rect(dx + 50.5, dy + 19, 3, 3);
    this.doc.text("Письма", dx + 54.3, dy + 21.3);

    this.doc.rect(dx + 64, dy + 19, 3, 3);
    this.doc.text("Бандероли", dx + 67.7, dy + 21.3);

    this.doc.rect(dx + 82.2, dy + 19, 3, 3);
    this.doc.text("Посылки", dx + 85.7, dy + 21.3);

    this.doc.rect(dx + 97.4, dy + 19, 3, 3);
    this.doc.text("Отправление 1 класса", dx + 101.1, dy + 21.3);

    this.doc.setFontSize("6");
    this.doc.rect(dx + 50.5, dy + 25, 3, 3);
    this.doc.text("Заказного(ой)", dx + 54.3, dy + 27.3);

    this.doc.rect(dx + 70.5, dy + 25, 3, 3);
    this.doc.text("C объявленной ценностью", dx + 74.3, dy + 27.3);

    this.doc.rect(dx + 106.5, dy + 25, 3, 3);
    this.doc.text("Обыкновенной", dx + 110.8, dy + 27.3);

    this.doc.setFontSize("7.5");
    this.doc.text(
      "Обведенное жирной чертой заполняется отправителем",
      dx + 53,
      dy + 15
    );

    this.doc.setFontSize("16");
    this.doc.text("УВЕДОМЛЕНИЕ", dx + 52, dy + 7);

    this.doc.setFontSize("13");
    this.doc.text("о вручении:", dx + 60.5, dy + 11.5);

    this.doc.setFontSize("11");
    this.doc.text("Простое", dx + 97, dy + 5);
    this.doc.text("Заказное", dx + 97, dy + 10);
    this.doc.line(dx + 97, dy + 6.5, dx + 118.5, dy + 6.5);
    this.doc.rect(dx + 115.5, dy + 2.8, 3, 3);

    this.doc.setFontSize("8");
    this.doc.text("ф. 119", dx + 119.5, dy + 3.5);

    this.doc.setFontSize("7");

    if (~recep.postservices.indexOf("i1"))
      this.doc.text("Х", dx + 116.2, dy + 5.2);
    this.doc.rect(dx + 115.5, dy + 7.2, 3, 3);
    if (~recep.postservices.indexOf("i2"))
      this.doc.text("Х", dx + 116.2, dy + 9.3);

    let pt = recep.posttype;
    if (pt == "e1" || pt == "e2" || pt == "e3" || pt == "e4")
      this.doc.text("Х", dx + 51.2, dy + 21.4);
    if (pt == "b1" || pt == "b2" || pt == "b3" || pt == "b4")
      this.doc.text("Х", dx + 64.7, dy + 21.4);
    if (
      pt == "p1" ||
      pt == "p2" ||
      pt == "p3" ||
      pt == "p4" ||
      pt == "p5" ||
      pt == "p6"
    )
      this.doc.text("Х", dx + 82.9, dy + 21.4);
    if (
      pt == "e5" ||
      pt == "e6" ||
      pt == "e7" ||
      pt == "b5" ||
      pt == "b6" ||
      pt == "b7" ||
      pt == "p7" ||
      pt == "p8" ||
      pt == "p9"
    )
      this.doc.text("Х", dx + 98.1, dy + 21.4);
    if (pt == "e2" || pt == "e5" || pt == "b2" || pt == "b5")
      this.doc.text("Х", dx + 51.2, dy + 27.1);
    if (
      pt == "e3" ||
      pt == "e4" ||
      pt == "e6" ||
      pt == "e7" ||
      pt == "b3" ||
      pt == "b4" ||
      pt == "b6" ||
      pt == "b7" ||
      pt == "p2" ||
      pt == "p3" ||
      pt == "p5" ||
      pt == "p6" ||
      pt == "p8" ||
      pt == "p9"
    )
      this.doc.text("Х", dx + 71.2, dy + 27.1);
    if (pt == "p1" || pt == "p4" || pt == "p7")
      this.doc.text("Х", dx + 107.2, dy + 27.1);
    if (recep.barcode !== null && recep.barcode.length === 14) {
      let bar = new Interleaved(34);
      bar.setNumberBarcode(recep.barcode);
      bar.drawBar(this.doc, dx + 1, dy + 0, 3, "Play");
    } else {
      this.doc.setLineDash([0.4]);
      this.doc.rect(dx + 1, dy + 0, 49, 15);
      this.doc.setLineDash();
    }

    this.doc.addImage("images/common/logopost.jpg", "JPEG", dx + 90, dy + 71);
  }

  PutInvoice2(pos, sender, recep) {
    let dx = 0;
    let dy = 0;
    if (pos == 2) {
      dx = 148.5 - 128.5 - 10;
      dy = 105 - 85 - 10;
    }
    if (pos == 1) {
      dx = 148.5 + 10;
      dy = 105 - 85 - 10;
    }
    if (pos == 4) {
      dx = 148.5 - 128.5 - 10;
      dy = 105 + 10;
    }
    if (pos == 3) {
      dx = 148.5 + 10;
      dy = 105 + 10;
    }
    this.doc.setLineDash([0.4]);
    this.doc.line(dx + 0, dy + 0, dx + 0, dy + 85);
    this.doc.line(dx + 128.5, dy + 0, dx + 128.5, dy + 85);

    this.doc.setLineDash();
    this.doc.line(dx + 2, dy + 6, dx + 126.5, dy + 6);
    this.doc.line(dx + 56.5, dy + 0, dx + 126.5, dy + 0);
    this.doc.setFontSize("13");
    for (var i = 0; i < 15; i++) {
      this.doc.line(dx + 126.5 - i * 5, dy + 0, dx + 126.5 - i * 5, dy + 6);
      if (recep.barcode !== null && i < 14) {
        let strBar = String(recep.barcode);
        while (strBar.length < 14) {
          strBar += " ";
        }
        this.doc.text(
          String(strBar)[13 - i],
          dx + 126.5 - i * 5 - 2.5,
          dy + 4.5,
          null,
          null,
          "center"
        );
      }
    }
    this.doc.setFontSize("11");
    this.doc.text("почтовое отправление №:", dx + 4, dy + 4.8);

    this.doc.setFontSize("8");
    this.doc.text(
      "Обведенное жирной чертой заполняется отправителем",
      dx + 25,
      dy + 10
    );

    this.doc.setLineWidth(0.5);
    this.doc.rect(dx + 2, dy + 11, 124.5, 26);

    this.doc.setLineWidth(0.2);
    this.doc.setFontSize("7");

    this.doc.rect(dx + 3.5, dy + 12.5, 3, 3);
    this.doc.text("Письма", dx + 3.5 + 3.7, dy + 12.5 + 2.4);

    this.doc.rect(dx + 29, dy + 12.5, 3, 3);
    this.doc.text("Бандероли", dx + 29 + 3.7, dy + 12.5 + 2.4);

    this.doc.rect(dx + 56, dy + 12.5, 3, 3);
    this.doc.text("Посылки", dx + 56 + 3.7, dy + 12.5 + 2.4);

    this.doc.rect(dx + 82, dy + 12.5, 3, 3);
    this.doc.text("Отправление 1 класса", dx + 82 + 3.7, dy + 12.5 + 2.4);

    this.doc.rect(dx + 3.5, dy + 17.5, 3, 3);
    this.doc.text("Заказного(ой)", dx + 3.5 + 3.7, dy + 17.5 + 2.4);

    this.doc.rect(dx + 36, dy + 17.5, 3, 3);
    this.doc.text("C объявленной ценностью", dx + 36 + 3.7, dy + 17.5 + 2.4);

    this.doc.rect(dx + 80, dy + 17.5, 3, 3);
    this.doc.text("Обыкновенной", dx + 80 + 3.7, dy + 17.5 + 2.4);

    this.doc.setLineWidth(0.35);
    this.doc.setFontSize("11");
    this.doc.text("адрес", dx + 3, dy + 25.5);
    this.doc.line(dx + 15, dy + 26, dx + 125.5, dy + 26);
    this.doc.line(dx + 3, dy + 31, dx + 125.5, dy + 31);
    this.doc.line(dx + 3, dy + 36, dx + 125.5, dy + 36);
    for (let i = 0; i < 7; i++) {
      this.doc.line(dx + 125.5 - i * 5, dy + 31, dx + 125.5 - i * 5, dy + 36);
    }

    this.doc.text("Вручено", dx + 2, dy + 41.5);
    this.doc.text("«", dx + 21, dy + 41.5);
    this.doc.line(dx + 23, dy + 42, dx + 29, dy + 42);
    this.doc.text("»", dx + 29, dy + 41.5);
    this.doc.line(dx + 31, dy + 42, dx + 52, dy + 42);
    this.doc.text("20", dx + 52, dy + 41.5);
    this.doc.line(dx + 56, dy + 42, dx + 61.5, dy + 42);
    this.doc.text("г.", dx + 62, dy + 41.5);
    this.doc.line(dx + 65, dy + 42, dx + 95.5, dy + 42);
    this.doc.line(dx + 97.5, dy + 42, dx + 126.5, dy + 42);

    this.doc.setFontSize("9");
    this.doc.text("лично", dx + 75, dy + 40.5);
    this.doc.text("по доверенности", dx + 65, dy + 45);
    this.doc.text("(кому)", dx + 106.5, dy + 44.5);

    this.doc.setLineWidth(0.2);
    this.doc.rect(dx + 85.9, dy + 38, 3, 3);
    this.doc.rect(dx + 92, dy + 43, 3, 3);

    this.doc.setFontSize("11");
    this.doc.text("Получил", dx + 2, dy + 51.5);
    this.doc.line(dx + 20, dy + 52, dx + 55, dy + 52);
    this.doc.line(dx + 57, dy + 52, dx + 126.5, dy + 52);
    this.doc.setFontSize("9");
    this.doc.text("(подпись)", dx + 30, dy + 54.5);
    this.doc.text("(Ф.И.О.)", dx + 85, dy + 54.5);

    this.doc.setFontSize("6");
    this.doc.rect(dx + 10, dy + 57.5, 25, 25);
    this.doc.rect(dx + 96.5, dy + 57.5, 25, 25);
    this.doc.text("(оттиск КПШ ОПС места", dx + 10, dy + 85);
    this.doc.text("вручения уведомления)", dx + 10, dy + 87);
    this.doc.text("(оттиск КПШ ОПС места", dx + 97, dy + 85);
    this.doc.text("оформления уведомления)", dx + 95.5, dy + 87);

    this.doc.setFontSize("11");
    this.doc.text("Руководитель ОПС:", dx + 47, dy + 70);
    this.doc.line(dx + 45, dy + 79, dx + 86, dy + 79);

    this.doc.setFontSize("7");
    this.doc.text("(подпись)", dx + 59, dy + 81);

    this.doc.setFontSize("8");
    /* if (recep.posttype == "0")
      this.doc.text("x", dx + 0.7 + 3.4, dy + 2.2 + 12.6);
    if (recep.posttype == "1")
      this.doc.text("x", dx + 0.7 + 29, dy + 2.2 + 12.5);
    if (recep.posttype == "2")
      this.doc.text("x", dx + 0.7 + 56, dy + 2.2 + 12.5);
    if (recep.posttype == "3")
      this.doc.text("x", dx + 0.7 + 82, dy + 2.2 + 12.5);
    if (recep.postcategory == "0")
      this.doc.text("x", dx + 0.7 + 3.5, dy + 2.2 + 17.5);
    if (recep.postcategory == "1")
      this.doc.text("x", dx + 0.7 + 36, dy + 2.2 + 17.5);
    if (recep.postcategory == "2")
      this.doc.text("x", dx + 0.7 + 80, dy + 2.4 + 17.5); */

    let pt = recep.posttype;
    if (pt == "e1" || pt == "e2" || pt == "e3" || pt == "e4")
      this.doc.text("Х", dx + 0.7 + 3.4, dy + 2.2 + 12.6);
    if (pt == "b1" || pt == "b2" || pt == "b3" || pt == "b4")
      this.doc.text("Х", dx + 0.7 + 29, dy + 2.2 + 12.5);
    if (
      pt == "p1" ||
      pt == "p2" ||
      pt == "p3" ||
      pt == "p4" ||
      pt == "p5" ||
      pt == "p6"
    )
      this.doc.text("Х", dx + 0.7 + 56, dy + 2.2 + 12.5);
    if (
      pt == "e5" ||
      pt == "e6" ||
      pt == "e7" ||
      pt == "b5" ||
      pt == "b6" ||
      pt == "b7" ||
      pt == "p7" ||
      pt == "p8" ||
      pt == "p9"
    )
      this.doc.text("Х", dx + 0.7 + 82, dy + 2.2 + 12.5);
    if (pt == "e2" || pt == "e5" || pt == "b2" || pt == "b5")
      this.doc.text("Х", dx + 0.7 + 3.5, dy + 2.2 + 17.5);
    if (
      pt == "e3" ||
      pt == "e4" ||
      pt == "e6" ||
      pt == "e7" ||
      pt == "b3" ||
      pt == "b4" ||
      pt == "b6" ||
      pt == "b7" ||
      pt == "p2" ||
      pt == "p3" ||
      pt == "p5" ||
      pt == "p6" ||
      pt == "p8" ||
      pt == "p9"
    )
      this.doc.text("Х", dx + 0.7 + 36, dy + 2.2 + 17.5);
    if (pt == "p1" || pt == "p4" || pt == "p7")
      this.doc.text("Х", dx + 0.7 + 80, dy + 2.4 + 17.5);

    let options = { textIndent: 13 };
    let curfs = 10;
    this.doc.setFontSize(curfs);
    let sSplitsRecep = this.doc.splitTextToSize(
      recep.fullname + ", " + recep.addr,
      92,
      options
    );
    while (sSplitsRecep.length > 3 && curfs > 3) {
      sSplitsRecep = this.doc.splitTextToSize(
        recep.fullname + ", " + recep.addr,
        92,
        options
      );
      curfs = curfs - 1;
      this.doc.setFontSize(curfs);
    }
    for (let j = 0; j < sSplitsRecep.length; j++)
      this.doc.text(
        sSplitsRecep[j],
        dx + 3 + (j == 0 ? 13 : 0),
        dy + 25.5 + 5 * j
      );
    this.doc.setFontSize("10");
    let sz = recep.zip == 0 || recep.zip == "" ? "" : recep.zip.toString();
    let dxforbigzip = 0;
    if (sz.length > 6) {
      dxforbigzip = sz.length - 6;
    }
    for (let k = 0; k < sz.length; k++) {
      this.doc.text(sz[k], dx + 97 + 5 * k - 5 * dxforbigzip, dy + 35);
    }

    if (recep.printbar && recep.barcode) {
      let b = recep.barcode.toString();
      for (let i = 0; i < 14; i++) {
        this.doc.text(b[13 - i], dx + 126.5 - i * 5 - 3.3, dy + 4);
      }
    }
  }

  add(sender, recep, mass, pages = "all", num = 0) {
    if (typeof sender == "undefined") {
      sender = new Sender({});
    }
    if (recep == null) {
      recep = new Address({});
    }
    if (mass) {
      let textpos = 1 + (num % 4);
      let dx = 0;
      let dy = 0;
      if (textpos == 1) {
        dx = 148.5 - 128.5 - 10;
        dy = 105 - 85 - 10;
      }
      if (textpos == 2) {
        dx = 148.5 + 10;
        dy = 105 - 85 - 10;
      }
      if (textpos == 3) {
        dx = 148.5 - 128.5 - 10;
        dy = 105 + 10;
      }
      if (textpos == 4) {
        dx = 148.5 + 10;
        dy = 105 + 10;
      }
      if (pages == "front" || pages == "back") {
        if (num % 4 == 0) {
          this.doc.addPage();
          this.doc.line(148.5, 0, 148.5, 210);
          this.doc.line(0, 105, 297, 105);
        }
        if (pages == "front") {
          this.PutInvoice1(textpos, sender, recep);
        } else if (pages == "back") {
          this.PutInvoice2(textpos, sender, recep);
        }
      } else if (pages == "all") {
        if (num % 4 == 0) {
          this.doc.addPage();
          this.doc.line(148.5, 0, 148.5, 210);
          this.doc.line(0, 105, 297, 105);
          this.doc.addPage();
          this.doc.line(148.5, 0, 148.5, 210);
          this.doc.line(0, 105, 297, 105);
        }
        this.doc.setPage(this.doc.internal.getNumberOfPages() - 1);
        this.PutInvoice1(textpos, sender, recep);
        this.doc.setPage(this.doc.internal.getNumberOfPages());
        this.PutInvoice2(textpos, sender, recep);
      }
      //}
    } else {
      //this.doc.deletePage(1);
      this.doc.line(148.5, 0, 148.5, 210);
      this.doc.line(0, 105, 297, 105);
      if (pages == "front") {
        this.PutInvoice1(1, sender, recep);
      } else if (pages == "back") {
        this.PutInvoice2(1, sender, recep);
      } else if (pages == "all") {
        this.PutInvoice1(1, sender, recep);
        this.doc.addPage();
        this.doc.line(148.5, 0, 148.5, 210);
        this.doc.line(0, 105, 297, 105);
        this.PutInvoice2(1, sender, recep);
      }
    }
  }
}
