export default class Settings {
  constructor(key, obj, def) {
    this.id = key;
    this.default = def;
    this.list = obj;
    this.modyfied = false;
  }

  get(vname) {
    return this.list[vname];
  }

  getName() {
    return this.list.name;
  }

  getFormatName() {
    return this.list.fname;
  }

  getId() {
    return this.id;
  }

  isDefault() {
    return this.default;
  }

  getList() {
    return this.list;
  }

  set(name, value) {
    this.list[name] = value;
    this.modyfied = true;
  }

  isModyfied() {
    return this.modyfied;
  }
  unlock() {
    this.modyfied = false;
  }

  getSettingsObject() {
    return this.list;
  }
  getCurrentFormat() {
    if (this.list != null) {
      return this.list.f;
    } else {
      return null;
    }
  }
  setCurrentFormat(newf) {
    this.list.f = newf;
  }
  isCommon() {
    return typeof this.list.f !== "undefined";
  }
}
