<template>
  <div>
    <div class="row">
      <div class="col-4 text-h4 q-mb-md">Реквизиты</div>
      <div class="col-8">
        <q-btn-toggle
          class="no-shadow float-right"
          no-caps
          content-class="q-px-md"
          v-model="req.companytype"
          color="secondary-transparent"
          toggle-color="secondary"
          :options="[
            { label: 'Юридическое лицо', value: 'company' },
            { label: 'Физическое лицо', value: 'people' },
          ]"
        />
      </div>
    </div>
    <q-form @submit="saveReq()" autocomplete="off">
      <div v-if="req.companytype != 'company'">
        <q-input
          v-model="req.companyname"
          v-bind:label="
            req.companytype == 'company' ? 'Наименование организации' : 'ФИО'
          "
          dense
          dark
          color="yellow-5"
          lazy-rules
          :rules="[(val) => String(val).length > 0 || 'Значение не заполнено']"
        />
      </div>
      <div v-else>
        <div class="row q-col-gutter-x-md justify-between">
          <div class="col-2">
            <q-input
              v-model="req.inn"
              label="ИНН"
              dense
              dark
              color="yellow-5"
              lazy-rules
              :rules="[(val) => validateInn(val)]"
            />
          </div>
          <div class="col-2">
            <q-input
              v-model="req.kpp"
              label="КПП"
              dense
              dark
              color="yellow-5"
              lazy-rules
              :rules="[(val) => validateKpp(val)]"
            />
          </div>
          <div class="col-8">
            <q-input
              v-model="req.companyname"
              v-bind:label="
                req.companytype == 'company'
                  ? 'Наименование организации'
                  : 'ФИО'
              "
              dense
              dark
              color="yellow-5"
              lazy-rules
              :rules="[
                (val) => String(val).length > 0 || 'Значение не заполнено',
              ]"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <q-input
              v-model="req.address"
              label="Юридический адрес"
              dense
              dark
              color="yellow-5"
              lazy-rules
              :rules="[(val) => validateAddr(val)]"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <q-input
              v-model="req.postaddress"
              label="Почтовый адрес"
              dense
              dark
              color="yellow-5"
              lazy-rules
              :rules="[(val) => validatePostAddr(val)]"
            />
          </div>
        </div>
        <!-- <div class="row justify-between">
          <div class="col-5">
            <q-input
              v-model="req.bic"
              label="Бик"
              dense
              dark
              color="yellow-5"
              lazy-rules
              :rules="[(val) => validateBik(val)]"
            />
          </div>
          <div class="col-5">
            <q-input
              v-model="req.rs"
              label="Расчетный счет"
              dense
              dark
              color="yellow-5"
              lazy-rules
              :rules="[(val) => validateRs(val, req.bic)]"
            />
          </div>
        </div> -->
        <!-- <div class="row justify-between">
          <div class="col-5">
            <q-input
              v-model="req.ks"
              label="Корреспондентский счет"
              dense
              dark
              color="yellow-5"
              lazy-rules
              :rules="[(val) => validateKs(val, req.bic)]"
            />
          </div>
          <div class="col-5">
            <q-input
              v-model="req.bankname"
              label="Наименование банка"
              dense
              dark
              color="yellow-5"
              lazy-rules
              :rules="[(val) => validateBankName(val)]"
            />
          </div>
        </div> -->
      </div>

      <q-btn
        :loading="changereqprogress"
        unelevated
        no-caps
        type="submit"
        class="q-mt-md wd265"
        color="secondary"
      >
        Сохранить
        <template v-slot:loading>
          <q-spinner-hourglass class="on-left" />Сохранение..
        </template>
      </q-btn>
    </q-form>
    <div v-show="success" class="q-mt-lg q-pa-md rounded-borders tip-success">
      Изменения сохранены
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      showaccount: false,
      tab: "profile",
      req: JSON.parse(JSON.stringify(this.$store.getters.ACCOUNT.req)),
      changereqprogress: false,
      success: false
    };
  },
  computed: {
    reqs() {
      return JSON.stringify(this.$store.getters.REQ);
    }
  },
  watch: {
    reqs() {
      setTimeout(() => (this.success = true), 500);
      setTimeout(() => (this.success = false), 2000);
    }
  },
  methods: {
    async saveReq() {
      try {
        this.success = false;
        if (this.req.companytype == "people") {
          this.req.inn = "";
          this.req.kpp = "";
          this.req.address = "";
          this.req.postaddress = "";
          this.req.rs = "";
          this.req.ks = "";
          this.req.bic = "";
          this.req.bankname = "";
        }
        this.changereqprogress = true;
        await this.$store.dispatch(
          "CHANGE_REQ",
          JSON.parse(JSON.stringify(this.req))
        );
        await new Promise(resolve => setTimeout(resolve, 500));
        this.changereqprogress = false;
      } catch (err) {
        this.$store.commit("SET_ERROR", {
          error: true,
          errortext:
            "Ошибка при попытке сохранить реквизиты. Информация передана разработчикам. Попробуйте позже"
        });
        this.$store.dispatch("LOG_ERROR", {
          error: err,
          desc: "saveReq function"
        });
      }
    },
    validateAddr(addr) {
      if (this.req.companytype == "people") return true;
      if (String(addr).length <= 0) {
        return "Укажите юридический адрес";
      } else {
        return true;
      }
    },
    validatePostAddr(postaddr) {
      if (this.req.companytype == "people") return true;
      if (String(postaddr).length <= 0) {
        return "Укажите почтовый адрес для отправки документов";
      } else {
        return true;
      }
    },
    validateBankName(bankname) {
      if (this.req.companytype == "people") return true;
      if (String(bankname).length <= 0) {
        return "У банка должно быть какое-то название";
      } else {
        return true;
      }
    },
    validateBik(bik) {
      if (this.req.companytype == "people") return true;
      if (typeof bik === "number") {
        bik = String(bik);
      } else if (typeof bik !== "string") {
        bik = "";
      }
      if (!bik.length) {
        return "БИК не указан";
      } else if (/[^0-9]/.test(bik)) {
        return "БИК может состоять только из цифр";
      } else if (bik.length !== 9) {
        return "БИК может состоять только из 9 цифр";
      } else {
        return true;
      }
    },
    validateInn(inn) {
      if (this.req.companytype == "people") return true;
      if (typeof inn === "number") {
        inn = String(inn);
      } else if (typeof inn !== "string") {
        inn = "";
      }
      if (!inn.length) {
        return "ИНН не указан";
      } else if (/[^0-9]/.test(inn)) {
        return "ИНН может состоять только из цифр";
      } else if ([10, 12].indexOf(inn.length) === -1) {
        return "ИНН может состоять только из 10 или 12 цифр";
      } else {
        var checkDigit = function(inn, coefficients) {
          var n = 0;
          for (var i in coefficients) {
            n += coefficients[i] * inn[i];
          }
          return parseInt((n % 11) % 10);
        };
        switch (inn.length) {
          case 10:
            var n10 = checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]);
            if (n10 === parseInt(inn[9])) {
              return true;
            }
            break;
          case 12:
            var n11 = checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
            var n12 = checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]);
            if (n11 === parseInt(inn[10]) && n12 === parseInt(inn[11])) {
              return true;
            }
            break;
        }
        return "Такой ИНН не существует. Неправильное контрольное число";
      }
    },

    validateKpp(kpp) {
      if (this.req.companytype == "people") return true;
      if (typeof kpp === "number") {
        kpp = String(kpp);
      } else if (typeof kpp !== "string") {
        kpp = "";
      }
      if (!kpp.length) {
        return true;
      } else if (kpp.length !== 9) {
        return "КПП может состоять только из 9 знаков";
      } else if (!/^[0-9]{4}[0-9A-Z]{2}[0-9]{3}$/.test(kpp)) {
        return "Неправильный формат КПП";
      } else {
        return true;
      }
    },

    validateKs(ks, bik) {
      if (this.req.companytype == "people") return true;
      if (this.validateBik(bik)) {
        if (typeof ks === "number") {
          ks = String(ks);
        } else if (typeof ks !== "string") {
          ks = "";
        }
        if (!ks.length) {
          //error.code = 1;
          //error.message += '<div class="reqerror">К/С не указан</div>';
          return true;
        } else if (/[^0-9]/.test(ks)) {
          return "К/С может состоять только из цифр";
        } else if (ks.length !== 20) {
          return "К/С может состоять только из 20 цифр";
        } else {
          var bikKs = "0" + String(bik).slice(4, 6) + ks;
          var checksum = 0;
          var coefficients = [
            7,
            1,
            3,
            7,
            1,
            3,
            7,
            1,
            3,
            7,
            1,
            3,
            7,
            1,
            3,
            7,
            1,
            3,
            7,
            1,
            3,
            7,
            1
          ];
          for (var i in coefficients) {
            checksum += coefficients[i] * (bikKs[i] % 10);
          }
          if (checksum % 10 === 0) {
            return true;
          } else {
            return "Ошибка К/С. Неправильное контрольное число";
          }
        }
      }
    },
    validateRs(rs, bik) {
      if (this.req.companytype == "people") return true;
      if (this.validateBik(bik)) {
        if (typeof rs === "number") {
          rs = String(rs);
        } else if (typeof rs !== "string") {
          rs = "";
        }
        if (!rs.length) {
          return "Р/С не указан";
        } else if (/[^0-9]/.test(rs)) {
          return "Р/С может состоять только из цифр";
        } else if (rs.length !== 20) {
          return "Р/С может состоять только из 20 цифр";
        } else {
          var bikRs = String(bik).slice(-3) + rs;
          var checksum = 0;
          var coefficients = [
            7,
            1,
            3,
            7,
            1,
            3,
            7,
            1,
            3,
            7,
            1,
            3,
            7,
            1,
            3,
            7,
            1,
            3,
            7,
            1,
            3,
            7,
            1
          ];
          for (var i in coefficients) {
            checksum += coefficients[i] * (bikRs[i] % 10);
          }
          if (checksum % 10 === 0) {
            return true;
          } else {
            return "Ошибка Р/С. Неправильное контрольное число";
          }
        }
      }
    },
    changeType(ctype) {
      this.req.companytype = ctype;
    }
  },
  mounted() {
    this.$eventBus.$on("change-user-type", ctype => this.changeType(ctype));
  },
  beforeDestroy() {
    this.$eventBus.$off("change-user-type");
  }
};
</script>

<style scoped>
</style>