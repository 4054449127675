import jsPDF from "jspdf";
import CommonTemplate from "src/service/classes/printCommon.js";
import Parser from "src/service/classes/parsertable.js";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
export default class ReestrTemplate extends CommonTemplate {
  constructor() {
    super();
    this.doc = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: "a4"
    });
    this.doc.setFont("Play");
  }

  CreateReestr(sender, recepients) {
    this.doc.addPage();
    let adrperpage = 24;

    this.doc.setFontSize("12");

    let padding = 1;
    let col1 = 13;
    let col2 = 100;
    let col3 = 135;
    let lineheight = 3;
    let d = new Date();
    let day = d.getDate();
    if (day < 10) day = "0" + day.toString();
    let month = d.getMonth() + 1;
    if (month < 10) month = "0" + month.toString();
    let year = d.getFullYear();
    this.doc.text(
      "Реестр заказной (простой) почтовой корреспонденции от " +
        day +
        "." +
        month +
        "." +
        year,
      10,
      10
    );

    let sname = this.doc.splitTextToSize(sender.fullname, 165);
    this.doc.text("Отправитель: ", 10, 20);
    for (var i = 0, l = sname.length; i < l; i++) {
      this.doc.text(sname[i], 40, 20 + 6 * i);
    }

    let headers = [
      {
        id: "1",
        name: "number",
        prompt: "№",
        width: col1,
        align: "left",
        padding: padding
      },
      {
        id: "2",
        name: "fullname",
        prompt: "Наименование",
        width: col2,
        align: "left",
        padding: padding
      },
      {
        id: "3",
        name: "addr",
        prompt: "Адрес получателя",
        width: col3,
        align: "left",
        padding: padding
      }
    ];
    this.doc.table(10, 20 + i * 6, recepients, headers, {
      autoSize: false,
      padding: 1,
      margins: { top: 12, bottom: 8 },
      fontSize: 10
    });
  }

  s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  GenerateHTMLReestr(sender, recepients) {
    let coeff = 0.9;
    let col1 = 5 * coeff;
    let col2 = 40 * coeff;
    let col3 = 55 * coeff;
    let d = new Date();
    let day = d.getDate();
    if (day < 10) day = "0" + day.toString();
    let month = d.getMonth() + 1;
    if (month < 10) month = "0" + month.toString();
    let year = d.getFullYear();

    let hfs = 12;
    let thfs = 9;

    let t =
      '<table id="tfsimple" data-cols-width="' +
      col1 +
      "," +
      col2 +
      ", " +
      col3 +
      '"> <tbody>';

    t +=
      '<tr data-height="18"> <td class="header" colspan="3" data-f-name="Arial" data-f-sz="' +
      hfs +
      '" data-f-bold="true" data-f-color="00000000" data-a-h="left" data-a-v="middle" data-f-underline="false" >Реестр заказной (простой) почтовой корреспонденции от ' +
      day +
      "." +
      month +
      "." +
      year +
      "</td> </tr>";
    t +=
      '<tr data-height="18"> <td class="header" colspan="3" data-f-name="Arial" data-f-sz="' +
      hfs +
      '" data-f-bold="true" data-f-color="00000000" data-a-h="left" data-a-v="middle" data-f-underline="false" >Отправитель: ' +
      sender.fullname +
      "</td> </tr>";
    t +=
      '<tr data-height="15"> <td class="header" colspan="3" data-f-name="Arial" data-f-sz="' +
      hfs +
      '" data-f-bold="true" data-f-color="00000000" data-a-h="left" data-a-v="middle" data-f-underline="false" ></td> </tr>';

    let l = recepients.length;

    t +=
      '<tr data-height="30"> <td data-a-wrap="true" class="header" data-b-a-s="medium" data-f-name="Arial" data-f-sz="' +
      thfs +
      '" data-f-bold="true" data-f-color="00000000" data-a-h="center" data-a-v="middle" data-f-underline="false" >№ п/п</td>';
    t +=
      '<td class="header" data-b-a-s="medium" data-f-name="Arial" data-f-sz="' +
      (thfs + 2) +
      '" data-f-bold="true" data-f-color="00000000" data-a-h="left" data-a-v="middle" data-f-underline="false" >Наименование получателя</td>';
    t +=
      '<td class="header" data-b-a-s="medium" data-f-name="Arial" data-f-sz="' +
      (thfs + 2) +
      '" data-f-bold="true" data-f-color="00000000" data-a-h="left" data-a-v="middle" data-f-underline="false" >Адрес получателя</td></tr>';

    for (let i = 0; i < l; i++) {
      let cname = recepients[i].fullname;
      let caddr = recepients[i].addr;
      let maxh = Math.max(
        12 * Math.ceil(cname / 40),
        12 * Math.ceil(caddr / 50)
      );
      t +=
        '<tr data-height="' +
        maxh +
        '"> <td class="header" style="border:1px solid #000000;border-collapse: collapse" data-b-a-s="thin" data-f-name="Arial" data-f-sz="' +
        thfs +
        '" data-f-bold="true" data-f-color="00000000" data-a-h="right" data-a-v="middle" data-f-underline="false" >' +
        (i + 1).toString() +
        "</td>";
      t +=
        '<td data-a-wrap="true"  class="header" data-b-a-s="thin" data-f-name="Arial" data-f-sz="' +
        thfs +
        '" data-f-bold="true" data-f-color="00000000" data-a-h="left" data-a-v="middle" data-f-underline="false" >' +
        cname +
        "</td>";
      t +=
        '<td data-a-wrap="true" class="header" data-b-a-s="thin" data-f-name="Arial" data-f-sz="' +
        thfs +
        '" data-f-bold="true" data-f-color="00000000" data-a-h="left" data-a-v="middle" data-f-underline="false" >' +
        caddr +
        "</td></tr>";
    }

    t += "</tbody> </table>";
    return t;
  }

  SaveReestrToXLS() {
    let d = new Date();
    let day = d.getDate();
    if (day < 10) day = "0" + day.toString();
    let month = d.getMonth() + 1;
    if (month < 10) month = "0" + month.toString();
    let year = d.getFullYear();

    let table = document.querySelector("#tfsimple");
    let wb = new ExcelJS.Workbook();
    let ws = wb.addWorksheet("REESTR");
    ws = Parser.parseDomToTable(ws, table, {
      autoStyle: false
    });
    ws.pageSetup.orientation = "portrait";
    ws.pageSetup.paperSize = 9;
    ws.pageSetup.margins = {
      left: 0.4,
      right: 0.4,
      top: 0.7,
      bottom: 0.4,
      header: 0.1,
      footer: 0.1
    };
    wb.xlsx.writeBuffer().then(function(buffer) {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        "Реестр отправлений от " + day + "-" + month + "-" + year + ".xlsx"
      );
    });
  }
}
