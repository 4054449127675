<template>
  <div id="q-app">
    <q-layout view="hHh lpr fFf" class="bg-dark mhlayout overflow-hidden">
      <AppHeader />
      <q-page-container v-if="!this.$store.getters.FINISHED">
        <router-view />
      </q-page-container>
      <q-page-container v-if="this.$store.getters.FINISHED">
        <Finished />
      </q-page-container>
      <iframe
        id="blobpdf"
        style="visibility: hidden"
        width="1"
        type="application/pdf"
      ></iframe>
    </q-layout>
  </div>
</template>

<script>
import AppHeader from "src/platform/components/AppHeader.vue";
import Finished from "src/platform/components/Finished.vue";

export default {
  name: "App",

  components: {
    AppHeader,
    Finished
  }
};
</script>

<style lang="sass">
</style>
