<template>
  <div>
    <div class="text-h6" v-if="!isCommercial">
      Внимание! В тестовой версии вы можете импортировать не более 10
      получателей за 1 раз.
    </div>
    <div class="text-h6" v-else>
      Внимание! Максимальное количество адресов в файле 3000. Большие файлы
      необходимо разбить на несколько.
    </div>
    <div>
      <a class="tpl-ref" download href="/ADDRESS_LIST_INDEX.xls"
        >Скачать шаблон файла (индексы в отдельной колонке)</a
      >
    </div>
    <div>
      <a class="tpl-ref" download href="/ADDRESS_LIST.xls"
        >Скачать шаблон файла (индексы в адресе)</a
      >
    </div>
    <div class="row items-start q-mt-sm q-mb-lg overflow-hidden">
      <div class="col-6" v-if="done == 0">
        <q-btn
          dense
          no-caps
          unelevated
          label="Новый импорт"
          color="secondary"
          class="q-pl-sm q-px-sm q-py-none"
          icon="add_circle_outline"
          @click="newImport"
        />
      </div>
      <div class="col-10" v-else>
        <div
          v-if="validated && !errors && !statechange && newreceps.length > 0"
        >
          <q-select
            v-model="newgids"
            :options="groups"
            label="Выбрать группу"
            option-value="id"
            option-label="gname"
            multiple
            emit-value
            map-options
            class="impgrsel q-mr-md q-py-none"
            use-chips
            dark
            color="white"
            dense
            options-dense
            filled
            content-class="text-white"
          >
            <template v-slot:prepend>
              <q-icon name="folder_shared" />
            </template>
            <template v-slot:option="scope">
              <q-item dense v-bind="scope.itemProps" v-on="scope.itemEvents">
                <q-item-section>
                  <q-item-label v-html="scope.opt.gname"></q-item-label>
                </q-item-section>
                <q-item-section side>
                  <q-toggle
                    color="secondary"
                    v-model="newgids"
                    :val="scope.opt.id"
                  />
                </q-item-section>
              </q-item>
            </template>
            <q-tooltip content-class="bg-accent fs14px" :offset="[10, -120]">
              Выберите группу, в которую будут занесены получатели.
              <br />Если группа не создана - можете создать ее на вкладке
              "Группы" <br />Либо оставьте значение пустым - получатели
              добавятся в общую группу
            </q-tooltip>
          </q-select>
          <q-btn
            unelevated
            dense
            class="q-px-sm q-py-none"
            color="secondary"
            icon="cloud_done"
            left
            label="Сохранить"
            no-caps
            @click="importList"
            :disable="!validated"
          />
        </div>
        <div v-else>
          <q-btn
            unelevated
            color="accent"
            class="q-mr-md q-px-sm q-py-none"
            icon="playlist_add_check"
            left
            no-caps
            dense
            label="Перепроверить"
            @click="control"
          />
          <div class="impimfo q-ml-xl" v-if="errors || warnings">
            <span v-if="errors" class="text-accent q-mr-md"
              >Ошибок: {{ errors }}</span
            >
            <span v-if="warnings" class="text-yellow-7"
              >Предупреждений: {{ warnings }}</span
            >
            <q-btn
              unelevated
              :color="!showerrors ? 'accent' : 'secondary'"
              class="q-ml-md q-px-sm q-py-none"
              icon="visibility"
              left
              no-caps
              dense
              :label="!showerrors ? 'Показать ошибки' : 'Показать все строки'"
              @click="showerrors = !showerrors"
            />
            <q-btn
              unelevated
              color="accent"
              class="q-ml-md q-px-sm q-py-none"
              icon="clear"
              left
              no-caps
              dense
              label="Удалить строки с ошибками"
              @click="deleteRowsWithErrors"
            />
          </div>
        </div>
      </div>
      <div class="col-2">
        <q-btn
          unelevated
          color="secondary"
          class="q-ml-md float-right q-px-sm q-py-none"
          label="Очистить"
          no-caps
          dense
          @click="clearTable"
        />
      </div>
    </div>
    <div class="row fit">
      <div class="col">
        <q-table
          ref="importtable"
          :class="
            statechange ||
            errors ||
            !errors ||
            warnings ||
            !warnings ||
            showerrors ||
            !showerrors
              ? 'imptable'
              : 'imptable'
          "
          :data="showerrors ? errorreceps : newreceps"
          :columns="columns"
          row-key="index"
          dense
          virtual-scroll
          :virtual-scroll-sticky-size-start="48"
          :pagination="pagination"
          :rows-per-page-options="[0]"
          wrap-cells
          flat
          separator="cell"
          :loading="loading"
          hide-bottom
        >
          <template v-slot:body="props">
            <q-tr
              :class="
                statechange ||
                errors ||
                !errors ||
                warnings ||
                !warnings ||
                showerrors ||
                !showerrors
                  ? 'imptable'
                  : 'imptable'
              "
              :props="props"
            >
              <q-td class="ittdn" key="number" :props="props">{{
                props.row.number + 1
              }}</q-td>
              <q-td
                tabindex="-1"
                disable
                key="name"
                :props="props"
                @paste="onPaste($event, props.row.number, 0)"
                :class="props.row.name == '' && props.row.error ? 'tdfix' : ''"
              >
                {{ props.row.name }}
                <q-popup-edit
                  content-class="bg-yellow-3 text-primary"
                  v-model="props.row.name"
                  auto-save
                  @save="setStateChange"
                  :disable="!alloweditor || props.row.number > maxlength - 1"
                >
                  <q-input
                    v-model="props.row.name"
                    debounce="1000"
                    dense
                    autofocus
                  />
                </q-popup-edit>
              </q-td>
              <q-td
                tabindex="-1"
                key="fullname"
                :props="props"
                :class="
                  props.row.fullname == '' && props.row.error ? 'tdfix' : ''
                "
                @paste="onPaste($event, props.row.number, 1)"
              >
                {{ props.row.fullname }}
                <q-popup-edit
                  content-class="bg-yellow-3 text-primary"
                  v-model="props.row.fullname"
                  auto-save
                  :disable="!alloweditor || props.row.number > maxlength - 1"
                  @save="val => setName(val, props.row.number)"
                >
                  <q-input
                    v-model="props.row.fullname"
                    debounce="1000"
                    dense
                    autofocus
                  />
                </q-popup-edit>
              </q-td>
              <q-td
                tabindex="-1"
                key="zip"
                :props="props"
                :class="
                  props.row.error && props.row.zip == ''
                    ? 'tdfix'
                    : props.row.warningzip && props.row.zip.length != 6
                    ? 'tdwarn'
                    : ''
                "
                @paste="onPaste($event, props.row.number, 2)"
              >
                {{ props.row.zip }}
                <q-popup-edit
                  content-class="bg-yellow-3 text-primary"
                  v-model="props.row.zip"
                  auto-save
                  :disable="!alloweditor || props.row.number > maxlength - 1"
                  @save="setStateChange"
                >
                  <q-input
                    type="text"
                    v-model="props.row.zip"
                    debounce="1000"
                    dense
                    autofocus
                  />
                </q-popup-edit>
              </q-td>
              <q-td
                tabindex="-1"
                key="addr"
                :props="props"
                :class="props.row.addr == '' && props.row.error ? 'tdfix' : ''"
                @paste="onPaste($event, props.row.number, 3)"
              >
                {{ props.row.addr }}
                <q-popup-edit
                  content-class="bg-yellow-3 text-primary"
                  v-model="props.row.addr"
                  auto-save
                  @save="setStateChange"
                  :disable="!alloweditor || props.row.number > maxlength - 1"
                >
                  <q-input
                    type="text"
                    v-model="props.row.addr"
                    debounce="1000"
                    dense
                    autofocus
                  />
                </q-popup-edit>
              </q-td>
              <q-td
                tabindex="-1"
                key="comment"
                :props="props"
                @paste="onPaste($event, props.row.number, 4)"
              >
                {{ props.row.comment }}
                <q-popup-edit
                  content-class="bg-yellow-3 text-primary"
                  v-model="props.row.comment"
                  auto-save
                  @save="setStateChange"
                  :disable="!alloweditor || props.row.number > maxlength - 1"
                >
                  <q-input
                    type="text"
                    v-model="props.row.comment"
                    debounce="1000"
                    dense
                    autofocus
                  />
                </q-popup-edit>
              </q-td>
              <q-td
                tabindex="-1"
                key="comment2"
                :props="props"
                @paste="onPaste($event, props.row.number, 4)"
              >
                {{ props.row.comment2 }}
                <q-popup-edit
                  content-class="bg-yellow-3 text-primary"
                  v-model="props.row.comment2"
                  auto-save
                  @save="setStateChange"
                  :disable="!alloweditor || props.row.number > maxlength - 1"
                >
                  <q-input
                    type="text"
                    v-model="props.row.comment2"
                    debounce="1000"
                    dense
                    autofocus
                  />
                </q-popup-edit>
              </q-td>
            </q-tr>
          </template>
        </q-table>
      </div>
    </div>

    <q-dialog
      v-model="importdialog"
      persistent
      transition-show="scale"
      class="transall mwnone"
      transition-hide="scale"
    >
      <q-card class="bg-primary text-white transall mwnone">
        <q-card-section>
          <div class="text-h6 text-center">
            {{
              showfinishactions
                ? "Импорт адресов выполнен"
                : "Импорт адресов: " +
                  (this.step * this.partsize > this.recepsforsending.length
                    ? this.recepsforsending.length
                    : this.step * this.partsize) +
                  " из " +
                  this.recepsforsending.length
            }}
          </div>
        </q-card-section>
        <q-card-actions
          align="left"
          class="q-px-md q-pt-none q-pb-lg"
          v-if="showfinishactions"
        >
          <q-btn
            flat
            label="Загрузить еще адреса"
            no-caps
            class="bg-primary-light"
            @click="
              showfinishactions = false;
              importdialog = false;
            "
            v-close-popup
          />
          <q-btn
            flat
            label="Перейти к печати"
            no-caps
            class="bg-primary-light"
            @click="finishImport"
          />
        </q-card-actions>
        <q-linear-progress
          v-if="!showfinishactions"
          indeterminate
          size="md"
          color="accent"
        />
      </q-card>
    </q-dialog>

    <q-dialog
      v-model="istepper"
      persistent
      transition-show="scale"
      transition-hide="scale"
      content-style="background:rgba(0,0,0,0.5)"
    >
      <q-card class="bg-primary-light text-white" style="max-width: 700px">
        <q-card-section class="dialhead row items-center">
          <div class="text-h6">Импорт получателей из таблицы EXCEL или ODF</div>
          <q-space />
          <q-btn
            class="q-ml-xl"
            color="accent"
            icon="close"
            unelevated
            @click="closeStepper"
            round
            dense
          />
        </q-card-section>
        <q-card-section>
          <q-file
            filled
            dark
            label-color="white"
            bg-color="secondary"
            class="excelselector q-pa-none"
            v-model="objfile"
            :label="!objfile ? 'Выбрать файл' : 'Обработка файла'"
            max-file-size="18388608"
            dense
            bottom-slots
            accept=".xlsx, .xls, .csv, .ods"
            @rejected="onRejected"
          >
            <template v-if="objfile" v-slot:append>
              <q-icon
                name="cancel"
                @click.stop.prevent="objfile = null"
                class="cursor-pointer"
              />
            </template>
            <template v-slot:prepend>
              <q-icon name="cloud_upload" color="white" @click.stop />
            </template>
          </q-file>
        </q-card-section>
        <q-card-section v-if="this.readfileresult" class="dialhead">
          <div class="text-h6">Результат обработки файла</div>
        </q-card-section>
        <q-card-section v-if="this.readfileresult" v-html="this.readfileresult">
        </q-card-section>
        <q-card-section v-if="this.goodsheet" class="dialhead">
          <q-btn
            class="q-px-sm g-mr-lg"
            label="Получить адреса"
            unelevated
            dense
            no-caps
            @click="readFile"
            color="secondary"
          ></q-btn>
          <q-toggle
            v-model="autoformat"
            class="q-mr-md"
            color="secondary"
            label="Автоформатирование"
            checked-icon="check"
            unchecked-icon="clear"
          >
            <q-tooltip content-class="bg-accent fs14px" :offset="[10, 10]">
              Автоматическое извлечение рабочего наименования.
              <br />Удаление лишних пробелов. <br />Исправление пунктуации в
              наименовании и адресе
            </q-tooltip>
          </q-toggle>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import Address from "src/service/classes/address.js";
import XLSX from "xlsx";
//import { debounce } from 'quasar'

export default {
  data() {
    return {
      istep: 1,
      istepper: false,
      progress: false,
      objfile: null,
      sheet: null,
      readfileresult: null,
      goodsheet: false,
      letters: [
        "все",
        "*",
        "А",
        "Б",
        "В",
        "Г",
        "Д",
        "Е",
        "Ж",
        "З",
        "И",
        "К",
        "Л",
        "М",
        "Н",
        "О",
        "П",
        "Р",
        "С",
        "Т",
        "У",
        "Ф",
        "Х",
        "Ц",
        "Ч",
        "Ш",
        "Щ",
        "Э",
        "Ю",
        "Я"
      ],

      newreceps: Array(3000)
        .fill()
        .map(() => new Address()),
      errorreceps: [],
      statechange: false,
      pagination: {
        rowsPerPage: 0
      },
      alloweditor: true,
      autoformat: true,
      loading: false,
      validated: false,
      errors: 0,
      warnings: 0,
      showerrors: false,
      done: 0,
      newgids: [],
      partsize: 50,
      step: 0,
      recepsforsending: [],
      importdialog: false,
      showfinishactions: false
    };
  },
  computed: {
    isCommercial() {
      return this.$store.getters.ISCOMMERCIAL;
    },
    isActive() {
      return this.$store.getters.ACTIVEUSER;
    },
    groups() {
      return this.$store.getters.GET_GROUPS;
    },
    maxlength() {
      return this.$store.getters.ISCOMMERCIAL ? 3000 : 10;
    },
    columns() {
      return this.validated
        ? [
            { name: "number", align: "left", label: "№" },
            { name: "name", align: "left", label: "Рабочее наименование" },
            {
              name: "fullname",
              align: "left",
              label: "Наименование для печати"
            },
            { name: "zip", align: "left", label: "Индекс" },
            { name: "addr", align: "left", label: "Адрес" },
            { name: "comment", align: "left", label: "Комментарий" },
            { name: "comment2", align: "left", label: "Пометка" }
          ]
        : [
            { name: "number", align: "left", label: "№" },
            {
              name: "fullname",
              align: "left",
              label: "Наименование для печати"
            },
            { name: "zip", align: "left", label: "Индекс" },
            { name: "addr", align: "left", label: "Адрес" },
            { name: "comment", align: "left", label: "Комментарий" },
            { name: "comment2", align: "left", label: "Пометка" }
          ];
    }
  },
  watch: {
    objfile: function(nv) {
      if (nv) {
        this.readfileresult = null;
        var reader = new FileReader();
        reader.onload = e => {
          // pre-process data
          let binary = "";
          let bytes = new Uint8Array(e.target.result);
          let length = bytes.byteLength;
          for (var i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
          }

          /* read workbook */
          let so = XLSX.read(binary, { type: "binary" });
          this.sheet = XLSX.utils.sheet_to_json(so.Sheets[so.SheetNames[0]], {
            raw: true
          });
          this.readfileresult = "";
          let critical_error = false;
          if (this.sheet.length == 0) {
            this.sheet = [];
            this.sheet[0] = { a: "b" };
          }

          if (this.hasOwnPropertyCI(this.sheet[0], "Наименование")) {
            this.readfileresult +=
              '<div class="row q-mb-md items-center q-col-gutter-x-sm q-mb-md"><div class="col-6"><span class="exfieldname">Наменование</span><span class="exfieldexist">найдено</span></div></div>';
          } else {
            critical_error = true;
            this.readfileresult +=
              '<div class="row  items-center q-col-gutter-x-sm q-mb-md"><div class="col-4"><span class="exfieldname">Наменование</span><span class="exfieldnoexist">не найдено</span></div><div class="col-8"><span class="exfieldinfo">В первой строке таблицы должна быть колонка с названием "Наименование"</span></div></div>';
          }
          if (this.hasOwnPropertyCI(this.sheet[0], "Адрес")) {
            this.readfileresult +=
              '<div class="row q-mb-md items-center q-col-gutter-x-sm q-mb-md"><div class="col-6"><span class="exfieldname">Адрес</span><span class="exfieldexist">найдено</span></div></div>';
          } else {
            critical_error = true;
            this.readfileresult +=
              '<div class="row  items-center q-col-gutter-x-sm q-mb-md"><div class="col-4"><span class="exfieldname">Адрес</span><span class="exfieldnoexist">не найдено</span></div><div class="col-8"><span class="exfieldinfo">В первой строке таблицы должна быть колонка с названием "Адрес"</span></div></div>';
          }
          if (this.hasOwnPropertyCI(this.sheet[0], "Индекс")) {
            this.readfileresult +=
              '<div class="row q-mb-md items-center  q-col-gutter-x-sm q-mb-md"><div class="col-6"><span class="exfieldname">Индекс</span><span class="exfieldexist">найдено</span></div></div>';
          } else {
            this.readfileresult +=
              '<div class="row  items-center  q-col-gutter-x-sm q-mb-md"><div class="col-4"><span class="exfieldname">Индекс</span><span class="exfieldnoexist">не найдено</span></div><div class="col-8"><span class="exfieldinfo">Отсутствует колонка "Индекс". Мы извлечем индексы из колонки "Адрес", если они там есть.</span></div></div>';
          }
          if (this.hasOwnPropertyCI(this.sheet[0], "Комментарий")) {
            this.readfileresult +=
              '<div class="row q-mb-md items-center  q-col-gutter-x-sm q-mb-md"><div class="col-6"><span class="exfieldname">Комментарий</span><span class="exfieldexist">найдено</span></div></div>';
          } else {
            this.readfileresult +=
              '<div class="row  items-center  q-col-gutter-x-sm q-mb-md"><div class="col-4"><span class="exfieldname">Комментарий</span><span class="exfieldnoexist">не найдено</span></div><div class="col-8"><span class="exfieldinfo">Необязательное поле. Комментарий печатается в поле "Кому" после наименования</span></div></div>';
          }
          if (this.hasOwnPropertyCI(this.sheet[0], "Пометка")) {
            this.readfileresult +=
              '<div class="row q-mb-md items-center  q-col-gutter-x-sm "><div class="col-6"><span class="exfieldname">Пометка</span><span class="exfieldexist">найдено</span></div></div>';
          } else {
            this.readfileresult +=
              '<div class="row  items-center  q-col-gutter-x-sm "><div class="col-4"><span class="exfieldname">Пометка</span><span class="exfieldnoexist">не найдено</span></div><div class="col-8"><span class="exfieldinfo">Необязательное поле. Где оно используется?</span></div></div>';
          }

          this.goodsheet = critical_error ? false : true;

          //let template = this.sheet.Strings[0][0]
        };
        reader.readAsArrayBuffer(nv);
      } else {
        this.readfileresult = null;
        this.goodsheet = false;
      }
    }
  },
  methods: {
    closeStepper() {
      this.istepper = false;
      this.objfile = null;
      this.readfileresult = null;
      this.goodsheet = false;
    },
    hasOwnPropertyCI(obj, property) {
      let props = [];
      for (var i in obj) if (obj.hasOwnProperty(i)) props.push(i);
      let prop;
      while ((prop = props.pop()))
        if (prop.toLowerCase() === property.toLowerCase()) return prop;
      return false;
    },
    newImport() {
      if (!this.isActive) {
        this.$store.commit("SET_ERROR", {
          error: true,
          errortext:
            "Для начала <br/>необходимо добавить отправителя или получателя<br/><br/> либо войти в личный кабинет, если вы пользовались сервисом ранее."
        });
        return false;
      }
      this.istepper = true;
    },
    readFile() {
      let len = this.sheet.length;
      len = len > this.maxlength ? this.maxlength : len;
      for (let i = 0; i < len; i++) {
        let propName = this.hasOwnPropertyCI(this.sheet[i], "Наименование");
        this.newreceps[i]["fullname"] = propName ? this.sheet[i][propName] : "";

        propName = this.hasOwnPropertyCI(this.sheet[i], "Комментарий");
        this.newreceps[i]["comment"] = propName ? this.sheet[i][propName] : "";

        propName = this.hasOwnPropertyCI(this.sheet[i], "Пометка");
        this.newreceps[i]["comment2"] = propName ? this.sheet[i][propName] : "";

        propName = this.hasOwnPropertyCI(this.sheet[i], "Индекс");
        if (propName) {
          this.newreceps[i]["zip"] = propName ? this.sheet[i][propName] : "";
          propName = this.hasOwnPropertyCI(this.sheet[i], "Адрес");
          this.newreceps[i]["addr"] = propName ? this.sheet[i][propName] : "";
        } else {
          propName = this.hasOwnPropertyCI(this.sheet[i], "Адрес");
          this.newreceps[i]["addr"] = propName ? this.sheet[i][propName] : "";
          this.newreceps[i].ExtractZipFromAddress();
        }
      }
      this.control();
      this.istepper = false;
    },
    onRejected() {
      this.$store.commit("SET_ERROR", {
        error: true,
        errortext: "Размер файла слишком большой."
      });
    },
    nextStep() {
      this.istep++;
    },
    mtimeout(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    finishImport() {
      let l = "/";
      window.location = l;
    },
    extractZip() {
      for (let recep of this.newreceps) {
        if (recep.addr) {
          recep.ExtractZipFromAddress();
        }
      }
    },
    clearTable() {
      this.newreceps = Array(3000)
        .fill()
        .map(() => new Address());
      for (let i = 0, len = this.newreceps.length; i < len; i++) {
        this.newreceps[i].number = i;
        this.newreceps[i].error = false;
        this.newreceps[i].warningzip = false;
      }
      this.step = 0;
      this.recepsforsending = [];
      this.errorreceps = [];
      this.validated = false;
      this.errors = 0;
      this.warnings = 0;
      this.showerrors = false;
      this.done = 0;
      this.newgids = [];
      this.objfile = null;
      this.sheet = null;
      this.readfileresult = null;
      this.goodsheet = false;
    },

    setStateChange() {
      this.statechange = true;
    },
    importList() {
      if (this.errors) {
        this.$store.commit("SET_ERROR", {
          error: true,
          errortext:
            "Обнаружены ошибки. Их необходиомо испраить перед сохранением"
        });
      } else {
        for (let recep of this.newreceps) {
          let state = recep.State();
          if (state == "done" || state == "badzip") {
            this.recepsforsending.push(recep.ExportWithoutId());
          }
        }
        if (this.recepsforsending.length > 0) {
          if (this.step > 0) {
            this.step--;
          }
          this.showfinishactions = false;
          this.importdialog = true;
          this.sendPart();
        }
      }
    },
    deleteRowsWithErrors() {
      let buff = this.newreceps;
      this.clearTable();
      let k = 0;
      for (let i = 0, len = buff.length; i < len; i++) {
        if (!buff[i].error) {
          this.newreceps[k] = buff[i];
          this.newreceps[k].number = k++;
        }
      }
      for (let i = 0, len = this.newreceps.length; i < len; i++) {
        this.newreceps[i]["number"] = i;
      }
      this.control();
    },
    sendPart() {
      let start = this.step * this.partsize;
      let end =
        (this.step + 1) * this.partsize < this.recepsforsending.length
          ? (this.step + 1) * this.partsize
          : this.recepsforsending.length;
      let request = {
        action: "import",
        recepients: this.recepsforsending.slice(start, end),
        gids: this.newgids
      };
      this.step++;
      this.$store.dispatch("IMPORT_PART", request);
    },
    setName(v, index) {
      if (this.autoformat) {
        this.newreceps[index].fullname = this.newreceps[index].ClearFullName(v);
        this.newreceps[index].name = this.newreceps[index].ExtractName(
          this.newreceps[index].fullname
        );
      } else {
        this.newreceps[index].name = v;
      }
      this.statechange = true;
    },
    control() {
      this.errors = 0;
      this.errorreceps = [];
      this.warnings = 0;
      this.done = 0;
      for (let recep of this.newreceps) {
        if (this.autoformat) {
          recep.fullname = recep.ClearFullName(recep.fullname);
          recep.name = recep.ExtractName(recep.fullname);
        } else {
          //
        }
        recep.datadigit = recep.ExtractDigit(recep.name);
        if (this.autoformat) {
          recep.comment = recep.ClearAddress(recep.comment);
          recep.addr = recep.ClearAddress(recep.addr);
        }
        recep.zip = recep.zip.trim();

        let state = recep.State();

        if (state == "halfempty") {
          recep.error = true;
          recep.warningzip = false;
          this.errors++;
          this.done++;
          this.errorreceps.push(recep);
        } else if (state == "empty") {
          recep.warningzip = false;
          recep.error = false;
        } else if (state == "badzip") {
          this.warnings++;
          recep.warningzip = true;
          this.done++;
          recep.error = false;
          this.errorreceps.push(recep);
        } else if (state == "done") {
          recep.warningzip = false;
          recep.error = false;
          this.done++;
        }
      }
      this.statechange = false;
      if (!this.errors && !this.warnings) {
        this.showerrors = false;
      }
      if (this.done > 0) {
        this.validated = true;
      }
    },
    onPaste(event, row = 1, col = 1) {
      let clipboarddata = "";
      if (event.clipboardData != null / false / undefined) {
        //ignore the incorrectness of the truncation
        clipboarddata = event.clipboardData;
      } else if (window.clipboardData != null / false / undefined) {
        clipboarddata = window.clipboardData;
      } else {
        //default to the last option even if it is null/false/undefined
        clipboarddata = event.originalEvent.clipboardData;
      }
      let rows = clipboarddata.getData("text/plain").split("\x0D");
      for (let i = 0; i < rows.length - 1; i++) {
        let splitedrow = rows[i].replace(/(\r\n|\n|\r)/gm, " ").split("\x09");
        for (let i = 0, len = splitedrow.length; i < len; i++) {
          splitedrow[i] = splitedrow[i].trim();
          if (splitedrow[i][0] == '"') {
            splitedrow[i] = splitedrow[i].substring(
              1,
              splitedrow[i].length - 1
            );
          }
          splitedrow[i] = splitedrow[i].replace(/\x22\x22/g, "\x22");
        }
        if (row + i > this.maxlength - 1) return;
        let r = this.newreceps[row + i];
        if (col == 0) {
          splitedrow.splice(5, 3000);
          [
            r.name = r.name,
            r.fullname = r.fullname,
            r.zip = r.zip,
            r.addr = r.addr,
            r.comment = r.comment
          ] = splitedrow;
        } else if (col == 1) {
          splitedrow.splice(4, 3000);
          [
            r.fullname,
            r.zip = r.zip,
            r.addr = r.addr,
            r.comment = r.comment,
            r.name = r.name
          ] = splitedrow;
        } else if (col == 2) {
          splitedrow.splice(3, 3000);
          [
            r.zip,
            r.addr = r.addr,
            r.comment = r.comment,
            r.name = r.name,
            r.fullname = r.fullname
          ] = splitedrow;
        } else if (col == 3) {
          splitedrow.splice(2, 3000);
          [
            r.addr,
            r.comment = r.comment,
            r.name = r.name,
            r.fullname = r.fullname,
            r.zip = r.zip
          ] = splitedrow;
        } else if (col == 4) {
          splitedrow.splice(1, 3000);
          [
            r.comment,
            r.name = r.name,
            r.fullname = r.fullname,
            r.zip = r.zip,
            r.addr = r.addr
          ] = splitedrow;
        }
      }
    }
  },
  created() {
    this.unsubscribe = this.$store.subscribe(async mutation => {
      if (mutation.type === "IMPORT_PART_SUCCESS") {
        await this.mtimeout(8000);
        if (
          this.step * this.partsize < this.recepsforsending.length &&
          this.recepsforsending.length > 0
        ) {
          this.sendPart();
        } else {
          this.clearTable();
          this.showfinishactions = true;
        }
      } else if (mutation.type === "IMPORT_PART_FAILURE") {
        this.importdialog = false;
      }
    });

    for (let i = 0, len = this.newreceps.length; i < len; i++) {
      this.newreceps[i].number = i;
      this.newreceps[i].error = false;
      this.newreceps[i].warningzip = false;
    }
  }
};
</script>

<style lang="sass">
.ittdn
  width: 50px
.impft
  margin-left: -10px
.imptable
  height: 340px
.imptable .q-table__middle
  height: 100%
.imptable thead tr th
  position: sticky
  z-index: 1
.imptable thead tr:first-child th
  top: 0
  background-color: #d6d6d6

.pimporter
  bottom: 0
  position: fixed!important
  top: 50px
  right: 0
  left: 0
.impimfo
  display: inline
  font-weight: 700
.q-table td.tdfix
  background: red
.q-table td.tdwarn
  background: yellow
.impgrsel
  min-width: 290px
  max-width: 400px
  float: left
.impgrsel .q-field__control
  background: #50597b!important
.impgrsel  .q-field__label, .impgrsel .q-field__marginal

.impgrsel .q-field__native
  padding-top: 4px!important
.impgrsel  .q-chip.row
  margin-left: 0!important
  margin-right: 8px!important
.impgrsel .q-chip
  background: #11a8ab

.excelselector
  width: 230px
.excelselector.q-field--auto-height.q-field--labeled .q-field__native
  padding-top: 2px
  margin-right: 10px
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
.exfieldname
  background: #313544
  padding: 2px 5px
  border-radius: 3px
  margin-right: 8px
.exfieldexist
  background: #313544
  padding: 2px 5px
  border-radius: 3px
.exfieldnoexist
  background: #e64c65
  padding: 2px 5px
  border-radius: 3px
.exfieldinfo
  color: #fbfb75
  line-height: 140%
.tpl-ref
  color: #FFF
</style>
