<template>
  <div class="hcontrol hsin">
    <div class="btitle">{{title}}</div>
    <div class="ttip">
      <q-icon size="xs" class="cursor-pointer" name="help_outline" @click="$emit('tip', name)" />
    </div>
    <q-badge class="hsqb" color="secondary">{{value}}%</q-badge>
    <div class="hsliderouter">
      <q-slider v-model="value" :min="min" :max="max" color="secondary" @change="update" />
    </div>
  </div>
</template>


<script>
export default {
  props: {
    val: { type: Number, default: null },
    name: { type: String, default: null },
    title: { tybe: String, default: "" },
    min: { type: Number, default: 0 },
    max: { type: Number, default: 100 }
  },
  data() {
    return {
      tid: null,
      value: 0
    };
  },
  computed: {
    f() {
      return this.$store.getters.CURFORMAT;
    }
  },
  watch: {
    val: function(){
      this.value = this.val;
    }
  },
  mounted() {
    this.value = this.val;
  },
  methods: {
    update(nv) {
      this.$store.commit("SET", { name: this.name, value: nv });
    }
  }
};
</script>

<style lang="sass">
.hsliderouter
    width: 30%
    float: right
    padding-right: 10px
.hsin .btitle 
    margin-top: 10px
.hsin .ttip 
    margin-top: 8px
.hsqb
    float: right
    margin-left: 10px
    min-width: 42px
    margin-top: 11px
    text-align: center
</style>