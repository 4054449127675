import { render, staticRenderFns } from "./Account.vue?vue&type=template&id=09705779"
import script from "./Account.vue?vue&type=script&lang=js"
export * from "./Account.vue?vue&type=script&lang=js"
import style0 from "./Account.vue?vue&type=style&index=0&id=09705779&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QBtn,QBadge,QDialog,QCard,QAvatar,QTabs,QTab,QScrollArea,QTabPanels,QTabPanel});qInstall(component, 'directives', {ClosePopup});
