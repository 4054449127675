<template>
  <q-dialog v-model="error" style="z-index: 1000000">
    <q-card class="q-pa-lg text-center bg-info text-white">
      <q-card-section>
        <q-avatar
          size="52px"
          font-size="52px"
          text-color="yellow-4"
          icon="error_outline"
        />
      </q-card-section>

      <q-card-section>
        <div style="font-size: 18px" class="q-my-sm" v-html="errortext"></div>
      </q-card-section>

      <q-card-actions align="center">
        <q-btn
          outline
          no-caps
          label="Всё понятно"
          color="white"
          v-close-popup
          style="width: 150px"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  computed: {
    error: {
      get() {
        return this.$store.getters.GET_ERROR;
      },
      set() {
        this.$store.commit("CLEAN_ERROR");
      }
    },
    errortext: {
      get() {
        return this.$store.getters.GET_ERRORTEXT;
      },
      set() {
        this.$store.commit("CLEAN_ERROR");
      }
    }
  }
};
</script>

<style scoped>
</style>