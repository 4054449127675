<template>
  <div>
    <div
      class="
        row
        q-mt-md q-mr-md q-mb-lg q-pa-sm
        listouter
        bg-primary-light
        justify-start
        items-center
      "
    >
      <div class="col-auto collistname">
        <span class="wlist">Список :</span>
        <span class="text-yellow-4 wlistname">{{ workList.name }}</span>
        <q-icon name="edit" color="yellow-4 q-mb-xs" class="cursor-pointer"
          ><q-tooltip content-class="bg-accent fs14px" :offset="[10, 10]">
            Изменить название списка
          </q-tooltip></q-icon
        >
        <q-popup-edit
          auto-save
          v-model="listName"
          content-class="bg-accent text-white"
        >
          <q-input dark color="white" v-model="listName" dense autofocus>
            <template v-slot:append>
              <q-icon name="edit" />
            </template>
          </q-input>
        </q-popup-edit>
      </div>
      <div class="col-auto">
        <q-btn
          :label="workList.id !== null ? 'Изменить список' : 'Сохранить список'"
          class="q-ml-md q-px-xs tbbgr"
          dense
          no-caps
          unelevated
          @click="saveList"
        />
        <q-btn
          label="Загрузить список"
          class="q-ml-sm q-px-xs tbbgr"
          dense
          no-caps
          unelevated
          @click="openListDialog"
        />
        <q-btn
          label="Очистить"
          class="q-ml-sm q-px-xs"
          dense
          color="accent"
          no-caps
          unelevated
          @click="clearWorkspace"
        />
      </div>
      <div class="col text-right q-pr-md"></div>
    </div>
    <div class="row q-mt-md justify-start items-center">
      <div class="col-auto">Действия с выбранными :</div>
      <div class="col-auto q-ml-lg">
        <MassPrintActions v-on:print="printMass" />
      </div>
      <div class="col-auto q-ml-sm">
        <MassFillinActions v-on:fill="fillMass" />
      </div>
      <div class="col-auto q-ml-sm">
        <MassAdditionalActions />
      </div>
      <div class="col-auto q-ml-lg">
        <q-btn
          icon="monetization_on"
          unelevated
          dense
          no-caps
          class="q-px-sm"
          color="secondary"
          label="Рассчитать стоимость пересылки"
          @click="calcDelivery"
        />
      </div>
      <div class="col-auto q-ml-sm">
        <q-toggle color="secondary" size="xs" v-model="withNDS" label="с НДС" />
      </div>
      <div class="col-auto q-ml-sm">
        <q-toggle
          color="secondary"
          size="xs"
          v-model="withMark"
          label="Марками"
        />
      </div>
    </div>
    <div class="row">
      <div class="col"></div>
      <div class="col"></div>
      <div class="col"></div>
      <div class="col"></div>
      <div class="col"></div>
    </div>
    <div class="masslistouter q-my-md q-mr-md bg-primary-light">
      <div class="row mlhead bg-primary items-stretch content-center">
        <div class="col-auto">
          <q-checkbox
            size="xs"
            @input="selectUnselectAll"
            :value="allselected"
            color="accent"
            dark
          />
        </div>
        <div class="col-3">
          <div class="row">
            <div class="col-auto q-pr-sm">Наименование</div>
            <div class="col">
              <q-btn
                size="xs"
                rounded
                flat
                icon="sort"
                padding="xs"
                class="cursor-pointer"
                :class="sortOrder > 0 ? 'rotate-180' : ''"
                @click="sortSelected"
              />
            </div>
          </div>
        </div>
        <div class="col">Вид отправления, категория, вес</div>
        <div class="col-1 text-center">Ценность</div>
        <div class="col-2 text-center">ШПИ</div>
        <div class="col-2 nopad">
          <div class="row payheight">
            <div class="col-12 text-center">Плата за</div>
          </div>
          <div class="row btcost content-center payheight">
            <div
              class="col-4 text-center brcost payheight q-pt-xs"
              style="font-size: 11px"
            >
              пересылку
            </div>
            <div
              class="col-4 text-center brcost payheight q-pt-xs"
              style="font-size: 11px"
            >
              доп. услуги
            </div>
            <div
              class="col-4 text-center payheight q-pt-xs"
              style="font-size: 11px"
            >
              ценность
            </div>
          </div>
        </div>

        <div class="col-1 text-center">Примечание</div>
      </div>
      <VirtualArea
        v-if="massListUpdateFinished"
        :list="rlist"
        :areaheight="687"
        :itemheight="24"
        vscrollarea="vscrollmasslist"
        class="wlistarea"
      >
        <template v-slot:list="{ item, index }">
          <div class="row mlrow bg-primary-light cursor-pointer fstrpx">
            <div class="col-auto">
              <q-checkbox
                color="accent"
                size="xs"
                v-model="selection"
                :val="index"
                dark
              />
            </div>
            <div class="col-3" @click="remove(item)">
              <div class="row fschetpx">
                <div class="col">
                  <span
                    @mouseover.self="showInfo($event, item.id)"
                    @mouseleave="hideInfo()"
                  >
                    {{ item.name }}
                  </span>
                  <span
                    class="tipspan"
                    v-show="item.tip != ''"
                    @click.self="openTipDialog(item)"
                    >{{ item.tip }}</span
                  >
                </div>
              </div>
            </div>
            <div class="col">
              <div class="row items-center">
                <div class="col-9 q-pr-xs" @click="openPostTypeDialog(item)">
                  <div class="addparambtn" v-show="item.posttype === null">
                    Тип и категория
                  </div>
                  <div
                    v-show="item.posttype != null"
                    class="clitype"
                    v-html="getPostInfo(item)"
                  ></div>
                </div>
                <div
                  class="col-3 q-pl-xs bldashed text-center text-yellow-5"
                  @click="
                    openSingleParameterDialog(item, {
                      name: 'weight',
                      type: 'number',
                      text: 'Вес отправления в граммах'
                    })
                  "
                >
                  <div class="addparambtn" v-show="item.weight == 0">Вес</div>
                  {{ item.weight != 0 ? parseWeight(item.weight) : "" }}
                </div>
              </div>
            </div>
            <div class="col-1 text-right">
              <div @click="openInventoryDialog(item)">
                <div
                  class="addparambtn"
                  v-show="
                    item.inventorytotalcount == null &&
                      item.inventorytotalcost == null &&
                      item.cost == 0 &&
                      (item.posttype == 'e3' ||
                        item.posttype == 'e4' ||
                        item.posttype == 'e6' ||
                        item.posttype == 'e7' ||
                        item.posttype == 'b3' ||
                        item.posttype == 'b4' ||
                        item.posttype == 'b6' ||
                        item.posttype == 'b7' ||
                        item.posttype == 'p2' ||
                        item.posttype == 'p3' ||
                        item.posttype == 'p5' ||
                        item.posttype == 'p6' ||
                        item.posttype == 'p8' ||
                        item.posttype == 'p9')
                  "
                >
                  Ценность
                </div>
                <div v-if="item.cost > 0">
                  {{ item.cost }} р.
                  <q-icon
                    name="close"
                    v-show="item.cost > 0"
                    class="text-accent cliclose"
                    @click.stop="clearInventory(item)"
                  />
                </div>

                <div v-else-if="item.cost == 0 && item.inventorytotalcount > 0">
                  <span class="clinvtext">Опись</span>
                  <span class="clinvsum">
                    {{ " " + item.inventorytotalcost }} р.</span
                  >
                  <q-icon
                    name="close"
                    v-show="item.inventorytotalcount > 0"
                    class="text-accent cliclose q-ml-xs"
                    @click.stop="clearInventory(item)"
                  />
                </div>
              </div>
            </div>
            <div class="col-2 text-center" @click="openBarcodeDialog(item)">
              <div>
                <div class="addparambtn" v-show="item.barcode == null">
                  Номер отправления (шпи)
                </div>
                <span v-html="barColorized(item.barcode)"></span>
                <q-icon
                  name="close"
                  v-show="item.barcode != null"
                  class="text-accent cliclose q-ml-xs"
                  @click.stop="clearBarcode(item)"
                />
              </div>
            </div>
            <!--  <div class="col-2" @click="openInvoiceDialog(item)">
              <div>
                <span v-show="item.posttype != null" class="clptype">{{
                  item.PostTypeStr()
                }}</span>
                <span v-show="item.postcategory != null" class="clpcat">{{
                  item.PostCategoryStr()
                }}</span>
                <q-icon
                  name="close"
                  v-show="item.posttype != null || item.postcategory != null"
                  class="text-accent cliclose q-ml-xs"
                  @click.stop="clearInvoice(item, 'post')"
                />
              </div>
            </div> -->
            <div class="col-2 nopad">
              <div class="row">
                <div class="col-4 text-center">
                  {{
                    item.price.g != 0
                      ? (withMark
                          ? item.price.g
                          : withNDS
                          ? item.price.gnds
                          : item.price.g) + " p."
                      : ""
                  }}
                </div>
                <div class="col-4 text-center bldashed">
                  {{
                    item.price.s != 0
                      ? (withNDS ? item.price.snds : item.price.s) + " p."
                      : ""
                  }}
                </div>
                <div class="col-4 text-center bldashed">
                  {{
                    item.price.c != 0
                      ? (withNDS ? item.price.cnds : item.price.c) + " p."
                      : ""
                  }}
                </div>
              </div>
            </div>

            <div
              class="col-1"
              @click="
                openSingleParameterDialog(item, {
                  name: 'note',
                  type: 'text',
                  text: 'Примечание к отправлению'
                })
              "
            >
              <div class="addparambtn prim" v-show="item.note == ''">
                Примечание
              </div>
              <span class="ellipsised">{{ item.note }}</span>
            </div>
          </div>
        </template>
      </VirtualArea>
      <InventoryDialog
        :show.sync="inventoryDialog"
        :recepientsIds="selection"
        :openedRecep.sync="openedRecep"
      />
      <InvoiceDialog
        :show.sync="invoiceDialog"
        :recepientsIds="selection"
        :openedRecep.sync="openedRecep"
      />
      <TipDialog
        :show.sync="tipDialog"
        :recepientsIds="selection"
        :recepientsList="rlist"
        :openedRecep.sync="openedRecep"
      />
      <BarCodeDialog
        :show.sync="barcodeDialog"
        :recepientsIds="selection"
        :openedRecep.sync="openedRecep"
      />
      <RecepToSenderDialog
        :recepToSenderP.sync="recepToSender"
        :recepientsIds="selection"
        :openedRecep.sync="openedRecep"
      />
      <PostTypeDialog
        :show.sync="postTypeDialog"
        :recepientsIds="selection"
        :openedRecep.sync="openedRecep"
      />
      <F103Dialog
        :show.sync="f103Dialog"
        :recepientsIds="selection"
        v-on:printF103="printF103"
      />
      <SimplePostParameterDialog
        :show.sync="singleParameterDialog"
        :recepientsIds="selection"
        :openedRecep.sync="openedRecep"
        :parameterText="parameterText"
        :parameterValueName="parameterValueName"
        :parameterValueType="parameterValueType"
      />
      <ListDialog :show.sync="listDialog" />
      <div
        class="addrtip"
        v-bind:style="tipposition"
        v-html="hoveredrecep"
        v-show="showtip"
      ></div>

      <q-dialog
        v-model="selectInvoicePrintTypeDialog"
        transition-show="scale"
        transition-hide="scale"
      >
        <q-card class="bg-secondary text-white imdial">
          <q-card-section class="dialhead">
            <div class="text-h6">
              {{ saveInvoices ? "Соханение" : "Печать" }} уведомлений о вручении
            </div>
          </q-card-section>
          <q-card-section>
            Уведомления печатаются по 4 штуки на листе А4. Печать с двух
            сторон.<br />Для двусторонней печати у вашего принтера должна быть
            поддержка данной функции.<br />Если принтер не поддерживает
            двусторонню печать - печатайте сначала лицевые, потом оборотные
            стороны.
          </q-card-section>
          <q-card-actions
            align="right"
            class="q-px-md q-pt-md q-pb-md text-white dialbtns"
          >
            <div class="row full-width">
              <div class="col-auto">
                <q-btn
                  unelevated
                  color="accent"
                  dense
                  no-caps
                  :label="
                    saveInvoices ? 'Сохранить лицевые' : 'Напечатать лицевые'
                  "
                  class="q-mr-sm"
                  @click="printInvoice({ pages: 'front', save: saveInvoices })"
                />
              </div>
              <div class="col-auto">
                <q-btn
                  unelevated
                  color="accent"
                  dense
                  no-caps
                  label="Оборотные"
                  class="q-mr-xl"
                  @click="printInvoice({ pages: 'back', save: saveInvoices })"
                />
              </div>
              <div class="col">
                <q-btn
                  unelevated
                  color="accent"
                  dense
                  no-caps
                  label="Двусторонняя печать"
                  class="float-right"
                  @click="printInvoice({ pages: 'all', save: saveInvoices })"
                />
              </div>
            </div>
          </q-card-actions>
        </q-card>
      </q-dialog>
    </div>
    <div class="hiddentable" v-html="htmlTable"></div>
  </div>
</template>

<script>
import Axios from "axios";
Axios.defaults.withCredentials = false;
import VirtualArea from "src/service/components/common/VirtualArea.vue";
//import InventoryTemplate from "src/service/classes/printInventory.js";
import Address from "src/service/classes/address.js";
import Sender from "src/service/classes/sender.js";
import Interleaved from "src/service/classes/interleaved.js";
import InventoryDialog from "src/service/components/dialogs/InventoryDialog.vue";
import PostData from "src/service/classes/posttypes.js";

import MassPrintActions from "src/service/components/mass-page/MassPrintActions.vue";
import MassFillinActions from "src/service/components/mass-page/MassFillinActions.vue";
import MassAdditionalActions from "src/service/components/mass-page/MassAdditionalActions.vue";

//Классы для печати
import CommonTemplate from "src/service/classes/printCommon.js";
import EnvelopeTemplate from "src/service/classes/printEnvelope.js";
import InvoiceTemplate from "src/service/classes/printInvoice.js";
import InventoryTemplate from "src/service/classes/printInventory.js";
import ReestrTemplate from "src/service/classes/printReestr.js";
import ReestrF103Template from "src/service/classes/printF103.js";
//Диалоги
import ListDialog from "src/service/components/dialogs/ListDialog";
import InvoiceDialog from "src/service/components/dialogs/InvoiceDialog";
import BarCodeDialog from "src/service/components/dialogs/BarCodeDialog";
import TipDialog from "src/service/components/dialogs/TipDialog";
import RecepToSenderDialog from "src/service/components/dialogs/RecepToSenderDialog";
import SimplePostParameterDialog from "src/service/components/dialogs/SimplePostParameterDialog";
import PostTypeDialog from "src/service/components/dialogs/PostTypeDialog";
import F103Dialog from "src/service/components/dialogs/F103Dialog";
import jsPDF from "jspdf";

export default {
  components: {
    VirtualArea,
    InventoryDialog,
    MassPrintActions,
    MassFillinActions,
    MassAdditionalActions,
    InvoiceDialog,
    BarCodeDialog,
    TipDialog,
    RecepToSenderDialog,
    SimplePostParameterDialog,
    PostTypeDialog,
    F103Dialog,
    ListDialog
  },
  data() {
    return {
      showtip: false,
      tipposition: {
        left: "210px",
        top: 0
      },
      hoveredrecep: "",
      allselected: true,
      selection: [],
      inventoryDialog: false,
      invoiceDialog: false,
      barcodeDialog: false,
      tipDialog: false,
      postTypeDialog: false,
      f103Dialog: false,
      listDialog: false,

      singleParameterDialog: false,
      parameterValueType: "",
      parameterValueName: "",
      parameterText: "",

      forAll: false,
      openedRecep: null,
      sortOrder: 1,
      recepToSender: false,
      selectInvoicePrintTypeDialog: false,
      saveInvoices: false,
      /* workList: {
        listName: "№1 от "
      }, */
      htmlTable: "",
      postData: new PostData(),
      map: null,
      withNDS: false,
      withMark: false
    };
  },

  computed: {
    psdialog: {
      get: function() {
        return this.$store.getters.PSDIALOG;
      },
      set: function() {
        this.$store.commit("CLOSE_SETTINGS");
      }
    },
    workList() {
      return this.$store.getters.GET_WORK_LIST;
    },
    listName: {
      get() {
        return this.$store.getters.GET_WORK_LIST.name;
      },
      set(nv) {
        this.$store.commit("SET_WORK_LIST", {
          id: this.workList.id,
          name: nv
        });
      }
    },
    massListUpdateFinished() {
      return this.$store.getters.GET_MASS_LIST_UPDATE_FINISHED;
    },
    rlist() {
      return this.$store.getters.GET_RECEPIENTS_LIST;
    },
    recepients() {
      return this.$store.getters.GET_RECEPIENTS;
    },
    curformat: {
      get: function() {
        return this.formatlist.filter(pair => pair.value == this.curfid)[0];
      },
      set: function(newval) {
        this.$store.commit("SET_FORMAT", newval);
      }
    },
    f() {
      return this.$store.getters.CURFORMAT_OBJECT;
    },
    s() {
      return this.$store.getters.SETTINGS_OBJECT;
    },
    cursender() {
      return this.$store.getters.GET_CURSENDER || new Sender({});
    },
    currecep() {
      if (this.needregister) {
        return new Address();
      } else {
        return this.$store.getters.GET_RECEPIENTS.filter(
          item => item.id == this.$store.getters.GET_ACTIVERECEPS[0]
        )[0];
      }
    },
    browser() {
      return {
        name: this.$q.platform.is.name,
        version: this.$q.platform.is.versionNumber
      };
    }
  },

  methods: {
    openSettings() {
      this.$store.commit("OPEN_SETTINGS");
    },
    async saveList() {
      this.selection = Object.keys(this.rlist).map(item => Number(item));
      this.allselected = true;
      let datas = [];
      let ids = [];
      for (let i = 0, l = this.selection.length; i < l; i++) {
        datas.push(this.rlist[this.selection[i]].GetSerializedObject());
        ids.push(this.rlist[this.selection[i]].id);
      }
      this.$store.commit("SET_WORK_LIST", {
        id: this.workList.id,
        name: this.workList.name.replace("Новый ", "")
      });
      await this.$store.dispatch("SAVE_WORKSPACE", {
        ids: "#" + ids.join("#") + "#",
        datas: JSON.stringify(datas),
        listname: this.workList.name,
        id: this.workList.id
      });
      this.notify("Список сохранен");
    },
    openListDialog() {
      this.listDialog = true;
    },
    getPostInfo(item) {
      return this.postData.getShortName(item);
    },
    parseWeight(weight) {
      if (weight < 1000) {
        return weight + " г";
      } else {
        return (weight / 1000).toFixed(2) + " кг";
      }
    },
    formatDate(date) {
      var dd = date.getDate();
      if (dd < 10) dd = "0" + dd;

      var mm = date.getMonth() + 1;
      if (mm < 10) mm = "0" + mm;

      var yy = date.getFullYear() % 100;
      if (yy < 10) yy = "0" + yy;

      return dd + "." + mm + "." + yy;
    },
    remove(item) {
      this.$store.commit("REMOVE_RECEPIENT_FROM_LIST", item);
      this.selectUnselectAll();
      this.selectUnselectAll();
    },
    selectUnselectAll() {
      if (this.selection.length > 0) {
        this.selection = [];
        this.allselected = false;
      } else {
        this.selection = Object.keys(this.rlist).map(item => Number(item));
        this.allselected = true;
      }
    },
    setActive(id) {
      this.$store.commit("SET_ACTIVE_RECEP", [id]);
    },

    openInventoryDialog(item) {
      this.openedRecep = item;
      this.inventoryDialog = true;
    },
    openInvoiceDialog(item) {
      this.openedRecep = item;
      this.invoiceDialog = true;
    },
    openBarcodeDialog(item) {
      this.openedRecep = item;
      this.barcodeDialog = true;
    },
    openTipDialog(item) {
      this.openedRecep = item;
      this.tipDialog = true;
    },
    openSingleParameterDialog(item, payload) {
      this.openedRecep = item;
      this.parameterValueType = payload.type;
      this.parameterValueName = payload.name;
      this.parameterText = payload.text;
      this.singleParameterDialog = true;
    },
    openPostTypeDialog(item) {
      this.openedRecep = item;
      this.postTypeDialog = true;
    },
    showInfo(event, id) {
      event.stopPropagation();
      let addr = "",
        comment = "",
        comment2 = "";
      let recep = this.recepients.find(item => item.id == id);
      if (recep) {
        (addr = recep.addr),
          (comment = recep.comment),
          (comment2 = recep.comment2);
      }

      this.tipposition.top = event.clientY - event.offsetY - 50 + "px";
      this.hoveredrecep =
        '<span class="addr">' +
        addr +
        "</span>" +
        (comment != "" ? '<span class="com">' + comment : "") +
        "</span>" +
        (comment2 != "" ? '<span class="tiplbl">' + comment2 + "</span>" : "");
      this.showtip = true;
    },
    hideInfo() {
      this.tipposition.top = 0;
      this.hoveredrecep = "";
      this.showtip = false;
    },
    sortSelected() {
      this.$store.commit("SORT_LIST", {
        sortOrder: this.sortOrder
      });
      this.sortOrder = -1 * this.sortOrder;
    },
    openBarSettings() {
      this.$eventBus.$emit("open-tab-settings", {
        tabName: "Bar"
      });
    },
    checkForActions() {
      if (this.rlist.length == 0) {
        this.$store.commit("SET_ERROR", {
          error: true,
          errortext:
            "Список рассылки пуст. <br/>Выбирите получателей из левой колонки."
        });
        return false;
      }
      let len = this.selection.length;
      if (len == 0) {
        this.$store.commit("SET_ERROR", {
          error: true,
          errortext: "Чтобы выполнить действие - отметьте нужных получателей. "
        });
        return false;
      }
      return true;
    },
    startProgress(len, title) {
      this.$store.commit("START_PROGRESS", {
        count: len,
        title: title
      });
    },
    stopProgress() {
      this.$store.commit("STOP_PROGRESS");
    },
    async stepProgress(len, iterator, progress) {
      if (iterator % progress.chunk == 0) {
        await new Promise(resolve => setTimeout(resolve, progress.ptime));
        this.$store.commit("STEP_PROGRESS", progress.chunk);
      }
    },
    async printMass(payload) {
      if (!this.checkForActions()) {
        return;
      }
      let obj = new CommonTemplate();
      let progress = this.$chunk.GetChunk(this.selection.length);
      if (payload.doctype == "Envelope") {
        obj = new EnvelopeTemplate(
          this.f,
          this.s,
          payload.addprice,
          payload.addndstoprice,
          payload.addndstopriceservices
        );
        this.startProgress(this.selection.length, "Подготовка конвертов");
        for (let i = 0, l = this.selection.length; i < l; i++) {
          obj.add(this.cursender, this.rlist[this.selection[i]], 1);
          await this.stepProgress(l, i, progress);
        }
        if (payload.save) {
          obj.save(this.workList.name + " - Конверты.pdf", 1);
        } else {
          obj.print(this.browser, 1);
        }
        this.$store.commit("MASSPRINT_ENVELOPE");
      } else if (payload.doctype == "Invoice") {
        if (this.invoicesAccepted()) {
          this.selectInvoicePrintTypeDialog = true;
          this.saveInvoices = payload.save;
          this.$store.commit("MASSPRINT_INVOICE");
        }
      } else if (payload.doctype == "Inventory") {
        let invInfo = this.getInventoryInfo();
        if (!invInfo.error) {
          obj = new InventoryTemplate(this.s);
          this.startProgress(this.selection.length, "Подготовка описей");
          for (let i = 0, l = this.selection.length; i < l; i++) {
            if (this.rlist[this.selection[i]].inventorytotalcost > 0) {
              obj.add(this.cursender, this.rlist[this.selection[i]], 1);
            }
            await this.stepProgress(l, i, progress);
          }
          if (payload.save) {
            obj.save(this.workList.name + " - Описи.pdf", 1);
          } else {
            obj.print(this.browser, 1);
          }
          this.$store.commit("MASSPRINT_INVENTORY");
        } else {
          this.$store.commit("SET_ERROR", {
            error: true,
            errortext: invInfo.text
          });
        }
      } else if (payload.doctype == "ReestrSimple") {
        obj = new ReestrTemplate();
        let recepients = [];
        this.startProgress(this.selection.length, "Подготовка реестра");
        for (let i = 0, l = this.selection.length; i < l; i++) {
          let zip = this.rlist[this.selection[i]].zip;
          let addr = this.rlist[this.selection[i]].addr;
          let fullname =
            this.rlist[this.selection[i]].fullname != ""
              ? this.rlist[this.selection[i]].fullname
              : " ";
          let comment = this.rlist[this.selection[i]].comment;
          recepients.push({
            number: String(i + 1),
            fullname: comment != "" ? fullname + ", " + comment : fullname,
            addr: zip != "" ? zip + ", " + addr : addr
          });
          await this.stepProgress(l, i, progress);
        }
        if (payload.save) {
          this.htmlTable = obj.GenerateHTMLReestr(this.cursender, recepients);
          setTimeout(() => {
            obj.SaveReestrToXLS();
            this.htmlTable = "";
          }, 2000);
        } else {
          obj.CreateReestr(this.cursender, recepients);
          obj.print(this.browser, 1);
        }
      } else if (payload.doctype == "ReestrF103") {
        for (let i = 0, l = this.selection.length; i < l; i++) {
          if (this.rlist[i].posttype === null) {
            this.$store.commit("SET_ERROR", {
              error: true,
              errortext:
                "Для печати реестра необходимо заполнить тип и категорию у всех отправлений. А также, рассчитать стоимость пересылки"
            });
            return;
          }
        }

        this.f103Dialog = true;
      }
    },
    getInventoryInfo() {
      let costed = 0;
      let hasInventory = 0;
      let res = {
        error: false,
        text: ""
      };
      for (let id of this.selection) {
        if (this.rlist[id].isCosted()) {
          costed++;
          if (this.rlist[id].inventorytotalcost > 0) {
            hasInventory++;
          }
        }
      }
      if (costed == 0) {
        res.text =
          'В списке не найдено ценных отправлений. <br/>Нажмите кнопку "Заполнить" и <br/>выберите "Тип и категория отпрвлений"';
        res.error = true;
      } else if (costed > 0 && hasInventory == 0) {
        res.error = true;
        res.text =
          "В вашем списке " +
          costed +
          ' ценных отправлений. <br/>У них не заполнена опись вложений.<br/><br/>Нажмите кнопку "Заполнить" и <br/>выберите "Опись вложений"';
      }
      return res;
    },
    async printF103(f103Params) {
      let progress = this.$chunk.GetChunk(this.selection.length);
      let obj = new ReestrF103Template(f103Params);
      this.startProgress(this.selection.length, "Подготовка реестра");
      let recepients = [];
      for (let i = 0, l = this.selection.length; i < l; i++) {
        recepients[i] = this.rlist[this.selection[i]];
        await this.stepProgress(l, i, progress);
      }

      obj.CreateReestrF103(this.cursender, recepients, this.s);
      if (f103Params.pdf === true) {
        obj.save(this.workList.name + " - Реестр Ф103.pdf", 1);
      } else if (f103Params.xls === true) {
        this.htmlTable = obj.GenerateHTMLReestr(
          this.cursender,
          recepients,
          this.s
        );
        setTimeout(() => {
          obj.SaveReestrToXLS();
          this.htmlTable = "";
        }, 2000);
      } else {
        obj.print(this.browser, 1);
      }
      this.f103Dialog = false;
      this.$store.commit("MASSPRINT_REESTR");
    },
    async printInvoice(payload) {
      let obj = new InvoiceTemplate(this.s);

      this.$store.commit("START_PROGRESS", {
        count: this.selection.length,
        title: "Подготовка уведомлений"
      });
      for (let i = 0, l = this.selection.length; i < l; i++) {
        obj.add(
          this.cursender,
          this.rlist[this.selection[i]],
          1,
          payload.pages,
          i
        );
        await new Promise(resolve => setTimeout(resolve, 1));
        this.$store.commit("STEP_PROGRESS", 30);
      }
      if (payload.save) {
        obj.save(this.workList.name + " - Уведомления.pdf", 1);
      } else {
        obj.print(this.browser, 1);
      }
    },
    fillMass(doctype) {
      if (!this.checkForActions()) {
        return;
      }

      if (doctype == "PostType") {
        this.postTypeDialog = true;
      }
      if (doctype == "Inventory") {
        this.inventoryDialog = true;
      }
      if (doctype == "Bar") {
        this.barcodeDialog = true;
      }
      if (doctype == "Tip") {
        this.tipDialog = true;
      }
      if (doctype == "RecepToSender") {
        this.recepToSender = true;
      }
      if (doctype == "Cod") {
        this.parameterValueType = "number";
        this.parameterValueName = "cod";
        this.parameterText = "Наложенный платеж";
        this.singleParameterDialog = true;
      }
      if (doctype == "Note") {
        this.parameterValueType = "text";
        this.parameterValueName = "note";
        this.parameterText = "Примечание к отправлениям";
        this.singleParameterDialog = true;
      }
      if (doctype == "Weight") {
        this.parameterValueType = "number";
        this.parameterValueName = "weight";
        this.parameterText = "Вес отправлений в граммах";
        this.singleParameterDialog = true;
      }
    },

    invoicesAccepted() {
      let bad = 0;
      for (let id of this.selection) {
        let itxt = this.rlist[id].invoiceCorrectText();
        if (itxt !== "") {
          this.$store.commit("SET_ERROR", {
            error: true,
            errortext: itxt
          });
          return false;
        }
      }
      return true;
    },

    clearBarcode(item) {
      this.$eventBus.$emit("clear-barcode", { recep: item });
    },
    clearInvoice(item, field) {
      this.$eventBus.$emit("clear-invoice", { recep: item, field: field });
    },
    clearInventory(item) {
      this.$eventBus.$emit("clear-inventory", { recep: item });
    },

    async removeAllFromMassList() {
      this.$store.dispatch("UNSELECT_SELECTED");
    },
    barColorized(value) {
      if (!value) return "";
      value = value.toString();
      if (value.length > 6)
        return (
          '<span class="bcclass">' +
          value[0] +
          value[1] +
          value[2] +
          value[3] +
          value[4] +
          value[5] +
          " " +
          value[6] +
          value[7] +
          ' <span class="colorbar">' +
          value[8] +
          value[9] +
          value[10] +
          value[11] +
          value[12] +
          "</span> " +
          value[13] +
          "</span>"
        );
      else {
        return (
          '<span class="bcclass">' +
          value[0] +
          value[1] +
          value[2] +
          value[3] +
          value[4] +
          value[5]
        );
      }
    },
    addRecepientToSelectedModel() {
      if (this.allselected) {
        this.selection.push(this.selection.length);
      }
    },
    async checkDelivery() {
      let progress = this.$chunk.GetChunk(this.selection.length);
      this.startProgress(
        this.selection.length,
        "Проверка правильности заполнения сведений об отправлениях"
      );
      for (let i = 0, l = this.selection.length; i < l; i++) {
        let rec = this.rlist[this.selection[i]];
        let notif = "";
        if (!rec.posttype) {
          notif =
            "Не указан вид и категория почтового отправления. Нажмите кнопку «Заполнить»";
          this.stopProgress();
          this.notify(notif);
          return false;
        }
        let postType = this.postData.types[rec.posttype];
        if (rec.weight == 0) {
          notif += "Не указан вес отправления.<br/>";
        }
        if (postType.maxWeight < rec.weight) {
          notif +=
            "Максимальный вес для отправления «" +
            postType.short +
            "» равен " +
            postType.maxWeight +
            " грамм.<br/>";
        }
        let cennost =
          rec.cost > 0
            ? rec.cost
            : rec.inventorytotalcost !== null
            ? rec.inventorytotalcost
            : null;
        if (cennost === null && postType.maxPrice !== undefined) {
          notif +=
            "Для отправления вида «" +
            postType.short +
            "» должна быть указана ценность <br/>";
        } else if (
          postType.maxPrice !== undefined &&
          postType.maxPrice < cennost
        ) {
          notif +=
            "Максимальная ценность для отправления «" +
            postType.short +
            "» равна " +
            postType.maxPrice +
            " рублей.<br/>";
        }

        if (notif != "") {
          notif =
            ' <span style="font-size: 20px;">Получатель: ' +
            rec.fullname +
            "</span><br/>" +
            notif;
          this.stopProgress();
          this.notify(notif);
          return false;
        } else {
          await this.stepProgress(l, i, progress);
        }
      }
      return true;
    },

    async calcDelivery() {
      this.$store.commit("MASSPRINT_POSTCALC");
      let good = await this.checkDelivery();
      if (!good) {
        return;
      }
      this.map = {};
      let progress = this.$chunk.GetChunk(this.selection.length);
      this.startProgress(
        this.selection.length,
        "Подготовка запросов стоимости пересылки в Почте России"
      );
      for (let i = 0, l = this.selection.length; i < l; i++) {
        let rec = this.rlist[this.selection[i]];
        let postType = this.postData.types[rec.posttype];
        let objectKey = {};
        objectKey.object = postType.object;
        objectKey.weight = rec.weight;
        objectKey.services = [];
        for (let serviceKey of rec.postservices) {
          objectKey.services.push(this.postData.services[serviceKey].serviceId);
        }

        if (rec.postpack !== null) {
          objectKey.pack = this.postData.boxTypes[rec.postpack].boxId;
        } else {
          objectKey.pack = rec.postpack;
        }
        let cost =
          rec.cost > 0
            ? rec.cost
            : rec.inventorytotalcost !== null
            ? rec.inventorytotalcost
            : null;
        objectKey.cost = cost;
        if (rec.posttype[0] == "e" || rec.posttype[0] == "b") {
          objectKey.from = this.cursender.zip;
          objectKey.to = this.cursender.zip;
        } else {
          objectKey.from = this.cursender.zip;
          objectKey.to = this.rlist[this.selection[i]].zip;
        }

        if (this.map[JSON.stringify(objectKey)] !== undefined) {
          this.map[JSON.stringify(objectKey)].recepients.push(i);
        } else {
          this.map[JSON.stringify(objectKey)] = {
            g: 0,
            gnds: 0,
            s: 0,
            snds: 0,
            c: 0,
            cnds: 0,
            gc: 0,
            gcnds: 0,
            serviceTarrifs: {},
            recepients: [i]
          };
        }
        await this.stepProgress(l, i, progress);
      }
      this.askPost();
    },
    async askPost() {
      let l = Object.keys(this.map).length;
      let progress = this.$chunk.GetChunk(l);
      this.startProgress(l, "Отправка запросов и обработка результатов");
      let i = 0;
      for (let key in this.map) {
        let obj = JSON.parse(key);
        let query = "https://tariff.pochta.ru/v2/calculate/tariff?json";
        query += "&object=" + obj.object;
        query += "&weight=" + obj.weight;
        query += "&from=" + obj.from;
        query += "&to=" + obj.to;
        if (obj.services.length > 0) {
          query += "&service=";
          for (let ser of obj.services) {
            query += ser + ",";
          }
          query.slice(0, -1);
        }
        if (obj.pack !== null) {
          query += "&pack=" + obj.pack;
        }
        if (obj.cost !== null) {
          query += "&sumoc=" + obj.cost * 100;
        }
        await this.stepProgress(l, i, progress);
        i++;

        await Axios.get(query)
          .then(response => {
            if (response.status == 200) {
              let resp = response.data;
              this.map[key].g = resp.ground.val;
              this.map[key].gnds = resp.ground.valnds;

              this.map[key].s =
                resp.service !== undefined ? resp.service.val : 0;
              this.map[key].snds =
                resp.service !== undefined ? resp.service.valnds : 0;

              this.map[key].c = resp.cover !== undefined ? resp.cover.val : 0;
              this.map[key].cnds =
                resp.cover !== undefined ? resp.cover.valnds : 0;
              this.map[key].response = resp;
              this.map[key].gc = this.map[key].g + this.map[key].c;
              this.map[key].gcnds = this.map[key].gnds + this.map[key].cnds;
              /* if (resp.isservice.length > 0) {
                for (let serviceId of resp.isservice) {
                  let service = resp.items.find(
                    item => item.serviceon[0] == serviceId
                  );
                  this.map[key].serviceTarrifs[serviceId] = service.tariff;
                }
              } */
            } else {
              this.notify(
                "Что-то пошло не так при расчете стоимости пересылки. Сообщение передано в техподдержку."
              );
            }
          })
          .catch(error => {
            if (
              error.response !== undefined &&
              error.response.data !== undefined &&
              error.response.data.errors != "undefined" &&
              error.response.data.errors.length > 0
            ) {
              this.notify(error.response.data.errors[0].msg);
            }
          });
      }
      this.$store.dispatch("UPDATE_PRICES", {
        map: this.map,
        recepientIds: this.selection
      });
    },
    notify(msg) {
      msg = '<div class="ntf">' + msg + "</div>";
      this.$q.notify({
        message: msg,
        color: "red-10",
        multiLine: true,
        icon: "announcement",
        progress: true,
        html: true,
        timeout: 9000
      });
    },
    setListName(mode) {
      let listCounter = this.$q.cookies.get("list_counter");
      if (!listCounter) {
        listCounter = 1;
      } else {
        if (this.workList.id == null) {
          listCounter = parseInt(listCounter);
        } else {
          listCounter = parseInt(listCounter) + 1;
        }
      }
      if (mode == "save") {
        let d = new Date();
        let h = d.getHours();
        let m = d.getMinutes();
        let s = d.getSeconds();
        let seceods = 24 * 60 * 60 - h * 60 * 60 - m * 60 - s;
        this.$q.cookies.set("list_counter", listCounter, {
          expires: seceods + "s",
          path: "/"
        });
      }
      this.$store.commit("SET_WORK_LIST", {
        id: null,
        name: "Новый №" + listCounter + " от " + this.formatDate(new Date())
      });
    },
    clearWorkspace() {
      this.$store.commit("CLEAR_RECEPIENT_SELECTION_FORALL");
      this.$store.commit("REASSIGN_LIST", []);
      this.setListName("save");
    }
  },
  created() {
    this.selection = Object.keys(this.rlist).map(item => Number(item));
    this.allselected = true;
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === "REASSIGN_LIST") {
        if (this.allselected) {
          this.selection = Object.keys(this.rlist).map(item => Number(item));
        }
      }
    });
    this.setListName();
  },
  mounted() {
    this.$eventBus.$on("remove-selected", this.removeAllFromMassList);
    this.$eventBus.$on(
      "add-recepient-to-selected-model",
      this.addRecepientToSelectedModel
    );
    document.body.classList.add("wided");
  },

  destroyed() {
    document.body.classList.remove("wided");
  },
  beforeDestroy() {
    this.$eventBus.$off("remove-selected");
    this.$eventBus.$off("add-recepient-to-selected-model");
    this.unsubscribe();
  }
};
</script>
<style lang="sass">
.bcclass
  white-space: nowrap
  font-size: 12px
.colorbar
  padding: 0px 4px
  color: #f4ee5f
.mlrow>div
  border-right: 1px dashed rgba(255,255,255,0.25)
  border-bottom: 1px dashed rgba(255,255,255,0.25)
  padding: 2px 4px
  justify-content: center
  display: flex
  flex-direction: column
.mlrow>div:last-child, .mlhead>div:last-child
  border-right: none
.mlrow:nth-child(odd)
  background: #464f73

.mlhead>div
  border-right: 1px solid rgba(255,255,255,0.25)
  border-bottom: 1px solid rgba(255,255,255,0.25)
  padding: 2px 4px
  justify-content: center
  display: flex
  flex-direction: column
.clptype
  background: #e4da92
  font-size: 12px
  padding: 1px 3px
  border-radius: 2px
  color: #1f253e
.clpcat
  background: #bbbbbb
  font-size: 12px
  padding: 1px 3px
  border-radius: 2px
  color: #1f253e
  margin-left: 4px
.clitype
  font-size: 12px
  color: #fbfbfb
  line-height: 120%
.cliclose
  padding: 0
  border-radius: 10px
  font-size: 13px
  padding: 2px
.cliclose:hover
  background: rgba(0,0,0,0.2)
.cliclose:active, .cliclose:focus
  background: rgba(0,0,0,0.4)
.clinvtext
  font-size: 12px
  text-decoration: underline
.clinvsum
  font-size: 12px

.tipspan
  font-size: 12px
.imdial
  max-width: 600px!important
.collistname
  font-size: 20px
.wlistname
  max-width: 220px
  display: block
  float: left
  padding-right: 6px
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
.wlist
  display: block
  float: left
  margin-right: 6px
.tbbgr
  background: rgba(255,255,255,0.1)
.wlistarea
  margin-left: 0!important
  margin-right: 0px!important
.wlistarea .varea
  padding-right: 0 !important
.masslistouter
  border: 1px solid rgba(255,255,255,0.1)
.hiddentable
  display: none
.bldashed
  border-left: 1px dashed #747b96
.addparambtn
  display: none
  background: rgba(255, 255, 255, 0.13)
  padding: 4px 5px 4px 3px
  border-radius: 3px
  font-size: 12px
  line-height: 100%
  width: fit-content
  margin: 0 auto
  float: none
.addparambtn:hover
  background: #11a8ab
.addparambtn:active, .addparambtn:focus
  background: #e64c65
.addparamicon
  font-size: 22px
  margin: 0 auto
  display: none
.addparamicon:hover
  color: #11a8ab
.addparamicon:active, .addparamicon:focus
  color: #e64c65
.mlrow:hover .addparambtn, .mlrow:hover .addparamicon
  display: block
.packspan
  background: #f1e471
  color: #5a5a5a
  padding: 0 3px
  border-radius: 3px
  font-weight: 700
  font-size: 10px
.listouter
  background-color: #373d5a
  border-radius: 4px
.fstrpx
  font-size: 13px
.fschetpx
  font-size: 14px
.peres
  width: 70px
  text-align: center
.dop
  width: 75px
  text-align: center
.prim
  width: 80px
  text-align: center
.ntf
  font-size: 18px
  max-width: 900px
.btcost
  border-top: 1px solid #6b728b
.brcost
  border-right: 1px solid #6b728b
.mlhead > div.nopad, .mlrow > div.nopad
  padding: 0
.mlhead > div.nopad>.row>.col-4
  font-size: 12px
  padding: 4px 2px 0 2px
.mlrow > div.nopad>.row>.col-4
  font-size: 12px
  padding: 0px 2px 0 2px
.payheight
  height: 23px!important
.servspan
  color: #f4ee5f
body.wided
  min-width: 1556px
</style>
