<template>
  <div>
    <q-input
      dense
      dark
      standout
      v-model="newname"
      no-shadow
      bg-color="primary-light-imp"
      class="q-mb-md no-shadow appendgroup"
      label="Введите название группы"
      @keyup.enter="createGroup"
    >
      <template v-slot:append>
        <q-icon
          name="add_box"
          class="cursor-pointer"
          color="secondary"
          @click="createGroup"
        />
      </template>
    </q-input>
    <q-scroll-area
      class="tgroupsarea bg-primary-light q-pa-md"
      :thumb-style="trthumbStyle"
    >
      <div
        class="grouplist bg-primary-light q-px-xs q-pt-sm q-pb-sm"
        v-for="(group, index) in groups"
        :key="index"
      >
        {{
          group.gname.length > 18
            ? group.gname.slice(0, 18) + ".."
            : group.gname
        }}
        <q-badge
          v-show="groupscounter.get(group.id) > 0"
          color="primary"
          transparent
          class="q-ml-sm"
          :label="groupscounter.get(group.id)"
        />
        <q-btn
          round
          color="white"
          icon="remove"
          @click="deleteGroup(group.id)"
          size="7px"
          flat
          unelevated
          class="float-right bg-accent bold-icon"
        />

        <q-btn
          round
          color="white"
          icon="edit"
          @click="selected = group.gname"
          size="7px"
          flat
          unelevated
          class="float-right q-mr-sm bg-secondary"
        >
          <q-popup-edit
            v-model="selected"
            content-class="bg-yellow-3 text-white"
            :validate="val => val.length > 0"
          >
            <template
              v-slot="{ initialValue, value, emitValue, validate, set, cancel }"
            >
              <q-input
                autofocus
                dense
                :value="selected"
                hint="Новое название группы"
                :rules="[val => validate(value) || 'Введите название']"
                @input="emitValue"
              >
                <template v-slot:after>
                  <q-btn
                    flat
                    dense
                    color="accent"
                    icon="cancel"
                    @click.stop="cancel"
                  />
                  <q-btn
                    flat
                    dense
                    color="secondary"
                    icon="check_circle"
                    @click="changeGroup(group.id)"
                    @click.stop="set"
                    :disable="
                      validate(value) === false || initialValue === value
                    "
                  />
                </template>
              </q-input>
            </template>
          </q-popup-edit>
        </q-btn>
      </div>
    </q-scroll-area>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newname: "",
      trthumbStyle: {
        right: "px",
        borderRadius: "5px",
        backgroundColor: "#11a8ab",
        width: "7px",
        opacity: 0.75
      },
      selected: ""
    };
  },
  computed: {
    groups() {
      return this.$store.getters.GET_GROUPS;
    },
    groupscounter() {
      let receps = this.$store.getters.GET_RECEPIENTS;
      let map = new Map();
      for (let recep of receps) {
        if (recep.gids != undefined) {
          for (let gid of recep.gids) {
            if (map.has(gid)) {
              map.set(gid, map.get(gid) + 1);
            } else {
              map.set(gid, 1);
            }
          }
        }
      }
      return map;
    }
  },
  methods: {
    createGroup() {
      if (this.$store.getters.ACTIVEUSER) {
        if (this.newname != "") {
          this.$store.dispatch("CREATE_GROUP", {
            action: "creategroup",
            gname: this.newname
          });
          this.newname = "";
        }
      } else {
        this.$store.commit("SET_ERROR", {
          error: true,
          errortext:
            "Для начала <br/>необходимо добавить отправителя или получателя<br/><br/> либо войти в личный кабинет, если вы пользовались сервисом ранее."
        });
      }
    },
    changeGroup(gid) {
      this.$store.dispatch("UPDATE_GROUP", {
        action: "updategroup",
        gname: this.selected,
        gid: gid
      });
      this.selected = "";
    },
    deleteGroup(gid) {
      let receps = this.$store.getters.GET_RECEPIENTS;
      let set = new Set();
      for (let recep of receps) {
        if (recep.gids != undefined) {
          for (let group of recep.gids) {
            set.add(group);
          }
        }
      }
      let oldsize = set.size;
      set.add(gid);
      if (oldsize == set.size) {
        this.$store.commit("SET_ERROR", {
          error: true,
          errortext:
            'Сначала необходимо удалить получателей из этой группы или перенести их в другую группу. Это можно сделать в режиме пакетного редактирования. <br/><a href="/editor/">Перейти в редактор</a>.'
        });
      } else {
        this.$store.dispatch("DELETE_GROUP", {
          action: "deletegroup",
          gid: gid
        });
      }
    }
  }
};
</script>

<style lang="sass">
.tgroupsarea
  width: 100%
  height: 704px
.tgroupsarea .recep i
  border-radius: 4px
.bold-icon i
  font-weight: 700
.receptabpanel.massive .tgroupsarea
  width: 400px
.receptabpanel.massive .appendgroup
  width: 400px
.grouplist
  border-bottom: 1px solid rgba(255, 255, 255, 0.12)
</style>
