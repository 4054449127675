<template>
  <q-dialog
    v-model="show"
    persistent
    transition-show="scale"
    transition-hide="scale"
  >
    <q-card class="mypdial text-white invdial myfprdial">
      <q-card-section class="row dialhead">
        <div class="text-h6">Первая печать конверта</div>
        <q-space />
        <q-btn
          icon="close"
          unelevated
          padding="xs"
          color="accent"
          round
          dense
          @click="close"
          v-close-popup
        />
      </q-card-section>
      <q-card-section class="q-ma-none q-pa-none">
        <q-stepper
          v-model="step"
          ref="stepper"
          class="fstepper"
          animated
          dense
          active-color="accent"
          done-color="white"
          inactive-color="grey-6"
        >
          <q-step
            :name="1"
            prefix="1"
            title="Выберите тип конверта"
            :done="step > 1"
            ><div class="fs18px q-mb-sm">
              В программе вы можете печатать на линованном конверте или на
              чистом. <br />Выберите какой у вас конверт.
            </div>
            <q-img
              src="/images/common/emptyenv.png"
              spinner-color="white"
              class="q-mr-md envprev"
              :class="etype == 'empty' ? 'eactive' : ''"
              @click="etype = 'empty'"
            >
              <div class="absolute-bottom-right text-subtitle2">Чистый</div>
            </q-img>
            <q-img
              src="/images/common/linedenv.png"
              spinner-color="white"
              class="envprev"
              :class="etype == 'lined' ? 'eactive' : ''"
              @click="etype = 'lined'"
            >
              <div class="absolute-bottom-right text-subtitle2">
                С разлиновкой
              </div>
            </q-img>
            <div class="fs18px q-mt-md q-mb-sm">Выберите размер конверта</div>
            <q-btn-group push unelevated>
              <q-btn
                unelevated
                :color="eformat == item ? 'accent' : 'secondary'"
                class="fpformat"
                v-for="(item, index) in esizes"
                :label="item"
                :key="index"
                @click="setFormat(item)"
              ></q-btn>
            </q-btn-group>
            <a
              href="/support/envelope/envelope-sizes/"
              targe="_blank"
              class="text-yellow-4 q-mt-sm q-ml-lg"
              >Какие бывают размеры конвертов?</a
            >
          </q-step>

          <q-step :name="2" prefix="2" title="Положите конверт" :done="step > 2"
            ><div class="fs18px q-mb-sm">
              Положите конверт в принтер, как показано на рисунке ниже
            </div>
            <q-img
              :src="
                typeof curFormat !== 'undefined' && curFormat.list.useholst == 0
                  ? '/images/common/pref-eformat.png'
                  : '/images/common/pref-eformat2.png'
              "
              class="fpprinter"
              spinner-color="white"
            />
          </q-step>

          <q-step :name="3" prefix="3" title="Установите размер страницы">
            <div class="fs18px q-mb-sm">
              Когда вы нажемете кнопку "Печать" - откроется предварительный
              просмотр.<br />
              В окне предварительного просмотра установите настройки, как
              показано на рисунке ниже
            </div>
            <div v-if="$q.platform.is.chrome">
              <q-img
                src="/images/common/fp-chrome.png"
                style="width: 370px; height: 314px"
                spinner-color="white"
              />
            </div>
            <div v-else-if="$q.platform.is.opera">
              <q-img
                src="/images/common/fp-opera.png"
                style="width: 370px; height: 314px"
                spinner-color="white"
              />
            </div>
            <div v-else-if="$q.platform.is.firefox">
              <q-img
                src="/images/common/fp-firefox-1.png"
                style="width: 246px; height: 314px"
                spinner-color="white"
              />
              <q-img
                src="/images/common/fp-firefox-2.png"
                style="width: 346px; height: 314px"
                spinner-color="white"
                class="q-ml-md"
              />
            </div>
            <div v-else-if="$q.platform.is.ie">
              <q-img
                src="/images/common/fp-ie-1.png"
                style="width: 423px; height: 314px"
                spinner-color="white"
              />
              <q-img
                src="/images/common/fp-ie-2.png"
                style="width: 423px; height: 314px"
                spinner-color="white"
                class="q-ml-md"
              />
            </div>
            <div v-else>
              <q-img
                src="/images/common/fp-chrome.png"
                style="width: 370px; height: 314px"
                spinner-color="white"
              />
            </div>
          </q-step>

          <q-step :name="4" prefix="4" title="Дополнительно">
            <div class="text-h6 q-mb-md">Внимание!!</div>
            <div v-if="etype == 'empty'"></div>
            <div v-else>
              <div class="fs18px q-mb-md">
                Если надписи не попадают в разлиновку на конверте -<br />
                воспользуйтесь
                <a
                  href="/support/envelope/lined-envelope/"
                  target="_blank"
                  class="text-yellow-4"
                  >руководством по настройке печати на линованном конверте</a
                >.
              </div>
            </div>
            <div class="fs18px">
              Если не получается настроить принтер - <br />
              ознакомьтесь с
              <a
                href="/support/printersettings/"
                target="_blank"
                class="text-yellow-4"
                >руководством по настройке принтера</a
              >
            </div>
            <div class="q-mt-md q-mb-sm">Другие руководства:</div>
            <a href="/support/tag/logo/" target="_blank" class="text-yellow-4"
              >Как добавить логотип</a
            ><br />
            <a
              href="/support/facilities/import-from-excel/"
              target="_blank"
              class="text-yellow-4"
              >Как загрузить адреса из EXCEL</a
            >
          </q-step>

          <template v-slot:navigation>
            <q-stepper-navigation>
              <q-btn
                @click="nextStep"
                no-caps
                color="accent"
                unelevated
                :label="
                  step === 4 ? 'Завершить' : step === 3 ? 'Печать' : 'Далее'
                "
                :disable="
                  step == 1 && (etype == null || eformat == null) ? true : false
                "
              />
              <q-btn
                v-if="step > 1"
                flat
                no-caps
                color="accent"
                @click="$refs.stepper.previous()"
                label="Назад"
                class="q-ml-sm"
              />
            </q-stepper-navigation>
          </template>
        </q-stepper>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>


<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    curFormat() {
      return this.$store.getters.CURFORMAT_OBJECT;
    },
    browser() {
      return this.$q.platform.is.name;
    },
    f() {
      return this.$store.getters.CURFORMAT_OBJECT;
    },
    s() {
      return this.$store.getters.SETTINGS_OBJECT;
    }
  },

  data() {
    return {
      step: 1,
      etype: null,
      eformat: null,
      esizes: ["c6", "dl", "c5", "c4", "b4"]
    };
  },

  methods: {
    close() {
      this.$emit("update:show", false);
      this.etype = null;
      this.eformat = null;
    },
    setFormat(fname) {
      this.eformat = fname;
      this.$store.commit("SET_FORMAT_BYNAME", fname);
    },
    nextStep() {
      let linedParams = {
        sPrintLines: 0,
        sPrintSender: 1,
        sPrintTips: 1,
        sPrintTipsWhite: 1,
        sPrintZip: 1,
        autoChangeFSize: 1,
        zipClearFont: 1,
        sview: 1,
        rPrintLines: 0,
        rPrintRecep: 1,
        rPrintTips: 1,
        rPrintTipsWhite: 1,
        zipClearFont: 1,
        autoChangeFSize: 1,
        rview: 1
      };
      let emptyParams = {
        sPrintLines: 1,
        sPrintSender: 1,
        sPrintTips: 1,
        sPrintTipsWhite: 0,
        sPrintZip: 1,
        autoChangeFSize: 0,
        zipClearFont: 0,
        sview: 0,
        rPrintLines: 1,
        rPrintRecep: 1,
        rPrintTips: 1,
        rPrintTipsWhite: 0,
        zipClearFont: 0,
        autoChangeFSize: 0,
        rview: 0
      };
      if (this.step === 3) {
        let params = this.etype == "empty" ? emptyParams : linedParams;
        for (let key in params) {
          this.$store.commit("SET", {
            name: key,
            value: params[key]
          });
        }
        this.$store.dispatch("SAVE_SETTINGS", {
          id: this.f.getId(),
          settings: JSON.stringify(this.f.getSettingsObject()),
          action: "savesettings",
          common: 0
        });
        this.$store.dispatch("SAVE_SETTINGS", {
          id: this.s.getId(),
          settings: JSON.stringify(this.s.getSettingsObject()),
          action: "savesettings",
          common: 1
        });
        this.$emit("print");
      } else if (this.step == 4) {
        this.close();
      }
      this.$refs.stepper.next();
    }
  }
};
</script>

<style lang="sass">
.myfprdial
    min-width: 900px
    max-width: 1024px !important
    width: 930px !important
.fstepper
    background: transparent
    min-height: 500px
.fs18px
    font-size: 18px
.envprev
    width: 220px
    height: 156px
    cursor: pointer
    box-sizing: content-box
    border: 7px solid #114d5a
.eactive
    border-color: #e64c65
.fpprinter
    width: 280px
    height: 257px
    background-color: rgba(0, 0, 0, 0.21)
    border-radius: 8px
.q-stepper__tab--done i
    color: red
</style>