<template>
  <q-page class="editormode">
    <q-card
      class="q-pa-md no-border no-border-radius bg-dark"
      v-if="!this.$store.getters.FINISHED"
    >
      <q-tabs
        v-model="tab"
        dense
        no-caps
        align="left"
        class="text-grey-5"
        active-color="white"
        indicator-color="accent"
        active-bg-color="primary"
        narrow-indicator
      >
        <q-tab name="recepients" label="Получатели" />
        <q-tab name="groups" label="Группы" />
      </q-tabs>

      <q-tab-panels v-model="tab" animated>
        <q-tab-panel
          name="recepients"
          class="receptabpanel q-ma-none q-pa-md bg-primary"
        >
          <Workspace />
        </q-tab-panel>

        <q-tab-panel
          name="groups"
          class="receptabpanel massive q-ma-none q-pa-md bg-primary"
        >
          <Groups />
        </q-tab-panel>
      </q-tab-panels>
    </q-card>
    <ProgressDialog />
  </q-page>
</template>


<script>
import Workspace from "src/service/components/editor-page/Workspace.vue";
import Groups from "src/service/components/common/Groups.vue";
import ProgressDialog from "src/service/components/mass-page/ProgressDialog.vue";
export default {
  meta: {
    // sets document title
    title:
      "Массовое редактирование получателей. Изменение, удаление, занесение в группы",
    // meta tags
    meta: {
      description: {
        name: "description",
        content:
          "Массовое редактирование получателей. Изменение, удаление, занесение в группы"
      },
      keywords: {
        name: "keywords",
        content: "редактирование адресов"
      },
      ogTitle: {
        name:
          "Массовое редактирование получателей. Изменение, удаление, занесение в группы"
      }
    }
  },
  components: {
    Workspace,
    Groups,
    ProgressDialog
  },
  data() {
    return {
      tab: "recepients"
    };
  },
  computed: {}
};
</script>

<style lang="sass">
</style>