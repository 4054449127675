export default {
  mutations: {
    HIT: function(state, payload) {
      if (typeof ym !== undefined) {
        if (process.env.DEV) {
          console.log(payload);
        } else {
          ym(45857166, "hit", payload.url, payload.options);
        }
      }
    },
    REACHGOAL: function(state, payload) {
      if (typeof ym !== undefined) {
        if (process.env.DEV) {
          console.log(payload);
        } else {
          ym(45857166, "reachGoal", payload);
        }
      }
    }
  }
};
