<template>
  <q-btn-dropdown
    dense
    unelevated
    color="secondary"
    label="Дополнительно"
    icon="more_vert"
    no-caps
  >
    <div class="row no-wrap q-pa-md pagradient">
      <div class="column">
        <q-toggle
          v-model="allowDuplicateRecepients"
          color="accent"
          label="Разрешить дублирование получателей"
        />
      </div>
    </div>
  </q-btn-dropdown>
</template>

<script>
export default {
  computed: {
    allowDuplicateRecepients: {
      get: function() {
        return this.$store.getters.GET_ALLOW_DUPLICATE_RECEPIENTS;
      },
      set: function(nv) {
        this.$store.commit("SET_ALLOW_DUPLICATES", nv);
      }
    }
  },
  methods: {}
};
</script>

<style lang="sass">
.bg-ddbtn
  background: rgba(0,0,0, 0.15)
.pagradient
  background-image: linear-gradient(to bottom, #0a8b8e 0%, #252c44 100%)!important
</style>
