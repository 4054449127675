import { api } from "src/boot/axios";
import Settings from "src/service/classes/settings.js";

export default {
  state: {
    formats: [],
    settings: new Settings(null, {}, 1),
    changed: false,
    psdialog: false
  },

  getters: {
    SETTINGS: state => {
      return state.settings;
    },
    FORMATS: state => {
      return state.formats;
    },
    CURFORMAT: state => {
      return state.settings.getCurrentFormat();
    },
    CURFORMAT_OBJECT: state => {
      return state.formats.filter(
        obj => obj.id == state.settings.getCurrentFormat()
      )[0];
    },
    SETTINGS_OBJECT: state => {
      return state.settings;
    },
    FORMAT_LIST: state => {
      let fl = [];
      for (let set of state.formats) {
        fl.push({
          label: set.getName(),
          value: set.getId(),
          default: set.isDefault()
        });
      }
      return fl;
    },
    PSDIALOG: state => {
      return state.psdialog;
    }
  },
  mutations: {
    SET_SETTINGS(state, payload) {
      state.formats = [];
      state.settings = [];
      state.changed = false;
      for (let key in payload.settings) {
        let set = JSON.parse(payload.settings[key]["settings"]);
        if (set.name == "common") {
          state.settings = new Settings(
            key,
            set,
            payload.settings[key]["default"]
          );
        } else {
          state.formats.push(
            new Settings(key, set, payload.settings[key]["default"])
          );
        }
      }
      this.commit("SET_SETTINGS_LOADED");
    },
    UPDATE_CANVAS() {},
    UPDATE_SETTINGS(state, payload) {
      let sid = payload.settings["sid"];
      let new_settings = JSON.parse(payload.settings["settings"]);
      let settings_object = {};

      if (payload.settings["common"] == 1) {
        settings_object = state.settings;
      } else {
        settings_object = state.formats.filter(obj => obj.id == sid)[0];
      }
      for (let key in settings_object["list"]) {
        settings_object["list"][key] = new_settings[key];
      }
      settings_object.unlock();
    },
    SET_DEFAULT_SETTINGS(state, payload) {
      let new_settings = JSON.parse(payload.settings["settings"]);
      let settings_object = state.formats.filter(
        obj => obj.id == state.settings.getCurrentFormat()
      )[0];
      for (let key in settings_object["list"]) {
        settings_object.set(key, new_settings[key]);
      }
    },
    SET_FORMAT(state, payload) {
      state.settings.setCurrentFormat(payload);
    },
    SET_FORMAT_BYNAME(state, payload) {
      let ff = state.formats.filter(item => item.list.fname == payload);
      if (ff) {
        state.settings.setCurrentFormat(ff[0].id);
      }
    },
    ADD_NEW_FORMAT(state, payload) {
      for (let key in payload.settings) {
        let set = JSON.parse(payload.settings[key]["settings"]);
        state.formats.push(
          new Settings(key, set, payload.settings[key]["default"])
        );
        state.settings.setCurrentFormat(key);
      }
    },
    REMOVE_FORMAT(state, payload) {
      for (let key in payload.settings) {
        let index = state.formats.findIndex(item => item.id == key);
        if (index > 0) {
          state.formats.splice(index, 1);
        }
      }
    },
    SET(state, payload) {
      let cf = state.formats.filter(
        obj => obj.id == state.settings.getCurrentFormat()
      )[0];
      cf.set(payload.name, payload.value);
    },
    SETCOMMON(state, payload) {
      state.settings.set(payload.name, payload.value);
    },
    OPEN_SETTINGS(state) {
      state.psdialog = true;
    },
    CLOSE_SETTINGS(state) {
      state.psdialog = false;
    }
  },
  actions: {
    LOAD_SETTINGS(context) {
      api
        .post("/api/eparam/", {
          action: "loadsettings"
        })
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            context.commit("SET_SETTINGS", response.data.body);
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при загрузке параметров печати. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "LOAD_SETTINGS action"
          });
        });
    },
    LOAD_DEFAULT_PRINT_SETTINGS(context) {
      api
        .post("/api/eparam/", {
          action: "getalldefaults"
        })
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            context.commit("SET_SETTINGS", response.data.body);
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при загрузке параметров печати. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "LOAD_SETTINGS action"
          });
        });
    },
    CREATE_NEW_SETTINGS(context, payload) {
      api
        .post("/api/eparam/", payload)
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            context.commit("ADD_NEW_FORMAT", response.data.body);
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при создании шаблона конверта. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "CREATE_NEW_SETTINGS action"
          });
        });
    },
    DELETE_SETTINGS(context, payload) {
      api
        .post("/api/eparam/", payload)
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            context.commit("REMOVE_FORMAT", response.data.body);
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при удалении шаблона конверта. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "DELETE_SETTINGS action"
          });
        });
    },
    SAVE_SETTINGS(context, payload) {
      api
        .post("/api/eparam/", payload)
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            context.commit("UPDATE_SETTINGS", response.data.body);
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при сохранении шаблона конверта. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "SAVE_SETTINGS action"
          });
        });
    },
    LOAD_DEFAULT_SETTINGS(context, payload) {
      api
        .post("/api/eparam/", payload)
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            context.commit("SET_DEFAULT_SETTINGS", response.data.body);
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при сохранении шаблона конверта. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "LOAD_DEFAULT_SETTINGS action"
          });
        });
    }
  },
  modules: {}
};
