<template>
  <ul class="letter">
    <li
      class="bg-primary-light"
      v-for="(letter, index) in letters"
      @click="setActiveLetter(index)"
      :class="activeLetterClass(index)"
      :key="index"
    >{{letter}}</li>
  </ul>
</template>


<script>
export default {
  props: {
    activeletter: { type: Number, default: -1 }
  },
  data() {
    return {
      letters: [
        "все",
        "*",
        "А",
        "Б",
        "В",
        "Г",
        "Д",
        "Е",
        "Ж",
        "З",
        "И",
        "К",
        "Л",
        "М",
        "Н",
        "О",
        "П",
        "Р",
        "С",
        "Т",
        "У",
        "Ф",
        "Х",
        "Ц",
        "Ч",
        "Ш",
        "Щ",
        "Э",
        "Ю",
        "Я"
      ]
    };
  },
  methods: {
    setActiveLetter(index) {
      this.$emit("change-letter");
      this.$emit("update:activeletter", index - 1);
      //this.search = "";
    },
    activeLetterClass(index) {
      return index == this.activeletter + 1 ? "letteractive" : "";
    }
  }
};
</script>

<style lang="sass">


.letter
  list-style-type: none
  margin: 0
  width: 26px
  padding: 0
  float: left
.letter li
  cursor: pointer
  border-radius: 0px 0 0 0px
  border: 1px solid rgba(0, 0, 0, 0.19)
  border-collapse: collapse
  border-bottom-width: 0
  font-size: 13px
  padding: 1px 0 1px 0
  border-right-width: 0
  text-align: center
  background: #343d5f
  -webkit-transition: all 0.25s
  -moz-transition: all 0.25s
  -o-transition: all 0.25s
  transition: all 0.25s
.letter li:last-child
  border-bottom-width: 1px
.letter li:hover
  padding-right: 7px
.letter li.letteractive
  background: #11a8ab
  border-color: #343a50
.letter li:nth-child(1)
  border-radius: 4px 0 0 0
.letter li:nth-child(1):hover
  padding-right: 0
</style>