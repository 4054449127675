import SignUp from "../platform/views/SignUp.vue";
import Recovery from "../platform/views/Recovery.vue";

//Servise import
import Single from "../service/views/Single.vue";
import Mass from "../service/views/Mass.vue";

import Editor from "../service/views/Editor.vue";
import Import from "../service/views/Import.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Single
  },
  {
    path: "/mass/",
    name: "mass",
    component: Mass
  },
  {
    path: "/signup/",
    name: "signup",
    component: SignUp
  },
  {
    path: "/recovery/",
    name: "recovery",
    component: Recovery
  },

  //Serveise routes

  {
    path: "/editor/",
    name: "editor",
    component: Editor
  },
  {
    path: "/fromexcel/",
    name: "import",
    component: Import
  },
  {
    path: "/support/",
    name: "support"
  },
  {
    path: "*",
    component: { render: h => h("div", ["404! Page Not Found!"]) }
  }
];

export default routes;
