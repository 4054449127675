import { api } from "src/boot/axios";
import List from "src/service/classes/list.js";

export default {
  state: {
    lists: null
  },

  getters: {
    GET_LISTS: state => {
      return state.lists;
    }
  },
  mutations: {
    SET_LISTS(state, payload) {
      state.lists = [];
      if (payload != null) {
        for (let record of payload) {
          state.lists.push(new List(record));
        }
      }
    },
    REMOVE_LIST(state, payload) {
      let indx = state.lists.findIndex(item => item.id == payload);
      if (~indx) {
        state.lists.splice(indx, 1);
      }
    },
    INSERT_LIST(state, payload) {
      let indx = state.lists.findIndex(item => item.id == payload.after);
      let list = new List(payload.list);
      state.lists.splice(indx + 1, 0, list);
    }
  },
  actions: {
    LOAD_LISTS(context) {
      api
        .post("/api/list/", {
          action: "loadlists"
        })
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            context.commit("SET_LISTS", response.data.body);
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при загрузке списков. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "LOAD_LISTS action"
          });
        });
    },
    LOAD_LISTS_BY_RECEP_NAME(context, payload) {
      api
        .post("/api/list/", {
          action: "loadlistsbyrecep",
          name: payload.name
        })
        .then(response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            context.commit("SET_LISTS", response.data.body);
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при загрузке списков. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "LOAD_LISTS action"
          });
        });
    },
    async DELETE_SAVED_LIST(context, payload) {
      payload.action = "deletelist";
      api
        .post("/api/list/", payload)
        .then(async response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            context.commit("REMOVE_LIST", response.data.body.id);
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при удалении списка. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "DELETE_SAVED_LIST action"
          });
        });
    },

    async COPY_LIST(context, payload) {
      payload.action = "copylist";
      api
        .post("/api/list/", payload)
        .then(async response => {
          if (response.data.error) {
            context.commit("SET_ERROR", {
              error: response.data.error,
              errortext: response.data.errortext
            });
          } else {
            context.commit("INSERT_LIST", {
              list: response.data.body.list,
              after: payload.id
            });
          }
        })
        .catch(error => {
          context.commit("SET_ERROR", {
            error: true,
            errortext:
              "Ошибка при копировании списка. Попробуйте выполнить действие позже. Информация передана разработчикам"
          });
          context.dispatch("LOG_ERROR", {
            error: error,
            desc: "COPY_LIST action"
          });
        });
    }
  }
};
