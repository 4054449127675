<template>
  <q-menu touch-position context-menu>
    <q-list dense style="min-width: 100px" class="text-dark q-pa-sm">
      <q-item clickable v-close-popup @click="addRecepient">
        <q-item-section avatar>
          <q-icon name="person_add" />
        </q-item-section>
        <q-item-section>Добавить отправителя</q-item-section>
      </q-item>
      <q-item clickable v-close-popup @click="changeRecepient">
        <q-item-section avatar>
          <q-icon name="edit" />
        </q-item-section>
        <q-item-section>Изменить</q-item-section>
      </q-item>
      <q-item clickable v-close-popup @click="removeRecepient">
        <q-item-section avatar>
          <q-icon name="person_remove" />
        </q-item-section>
        <q-item-section>Удалить</q-item-section>
      </q-item>
    </q-list>
  </q-menu>
</template>

<script>
export default {
  props: {},
  data() {
    return {};
  },
  methods: {
    addRecepient() {
      this.$emit("open-editor-from-context", "create");
    },
    changeRecepient() {
      this.$emit("open-editor-from-context", "edit");
    },
    removeRecepient() {
      this.$emit("delete-recep");
    }
  }
};
</script>
<style lang="sass">
</style>