<template>
	<div class="hcontrol hvcn">
		<div class="btitle">{{ title }}</div>
		<div class="ttip">
			<q-icon
				size="xs"
				class="cursor-pointer"
				name="help_outline"
				@click="$emit('tip', vertical.name)"
			/>
		</div>
		<div class="hvcbuttonsouter">
			<div
				class="bg-secondary hvcontrols hvleft"
				@mousedown="goFastHorizontal(-1)"
				@mouseup="stopHorizontal"
				@mouseout="stopInterval"
			>
				<q-icon size="xs" name="arrow_left" class="text-white" />
			</div>
			<div class="hvcenterctr">
				<div
					class="bg-secondary hvcontrols hvtop"
					@mousedown="goFastVertical(-1)"
					@mouseup="stopVertical"
					@mouseout="stopInterval"
				>
					<q-icon size="xs" name="arrow_drop_up" class="text-white" />
				</div>
				<div
					class="bg-secondary hvcontrols hvbottom"
					@mousedown="goFastVertical(1)"
					@mouseup="stopVertical"
					@mouseout="stopInterval"
				>
					<q-icon size="xs" name="arrow_drop_down" class="text-white" />
				</div>
			</div>
			<div
				class="bg-secondary hvcontrols hvright"
				@mousedown="goFastHorizontal(1)"
				@mouseup="stopHorizontal"
				@mouseout="stopInterval"
			>
				<q-icon size="xs" name="arrow_right" class="text-white" />
			</div>
		</div>
		<q-tooltip
			anchor="center right"
			self="center middle"
			content-class="bg-accent"
			content-style="font-size: 16px"
			:offset="[90, 0]"
		>
			По вертикали {{ vvalue.toFixed(1) }} мм
			<br />
			По горизонтали {{ hvalue.toFixed(1) }} мм
		</q-tooltip>
	</div>
</template>


<script>
export default {
	props: {
		horizontal: {
			type: Object,
			default: () => {
				return {
					name: "",
					val: null,
					step: 0,
					min: -999,
					max: 990
				};
			}
		},
		vertical: {
			type: Object,
			default: () => {
				return {
					name: "",
					val: null,
					step: 0,
					min: -999,
					max: 990
				};
			}
		},
		title: { tybe: String, default: "" },
		settingtype: { tybe: String, default: "format" }
	},
	data() {
		return {
			tid: null,
			vvalue: 0,
			hvalue: 0
		};
	},
	mounted() {
		this.hvalue = this.horizontal.val;
		this.vvalue = this.vertical.val;
	},
	computed: {
		f() {
			return this.$store.getters.CURFORMAT;
		}
	},
	watch: {
		horizontal: function() {
			this.hvalue = this.horizontal.val;
		},
		vertical: function() {
			this.vvalue = this.vertical.val;
		},
		f: function() {
			this.hvalue = this.horizontal.val;
			this.vvalue = this.vertical.val;
		}
	},
	methods: {
		goFastHorizontal(direction) {
			this.tid = setInterval(() => {
				if (
					this.hvalue + direction * this.horizontal.step <=
						this.horizontal.max &&
					this.hvalue + direction * this.horizontal.step >= this.horizontal.min
				) {
					this.hvalue += direction * this.horizontal.step;
				}
			}, 50);
		},
		stopHorizontal() {
			clearInterval(this.tid);
			this.$store.commit(this.settingtype == "format" ? "SET" : "SETCOMMON", {
				name: this.horizontal.name,
				value: Math.floor(this.hvalue * 10) / 10
			});
		},

		goFastVertical(direction) {
			this.tid = setInterval(() => {
				if (
					this.vvalue + direction * this.vertical.step <= this.vertical.max &&
					this.vvalue + direction * this.vertical.step >= this.vertical.min
				) {
					this.vvalue += direction * this.vertical.step;
				}
			}, 50);
		},
		stopVertical() {
			clearInterval(this.tid);
			this.$store.commit(this.settingtype == "format" ? "SET" : "SETCOMMON", {
				name: this.vertical.name,
				value: Math.floor(this.vvalue * 10) / 10
			});
		},
		stopInterval() {
			clearInterval(this.tid);
		}
	}
};
</script>

<style lang="sass">
.hvcn .btitle
  margin-top: 10px
.hvcn .ttip
  margin-top: 8px
.hvcontrols
  display: block
  position: relative
  text-align: center
  cursor: pointer
  border: 1px solid #127b7d
  line-height: 100%
.hvcontrols:hover
  background: #119c9e!important
  border-color: #0c6f71
.hvcontrols:active
  background: #e64c65 !important
  border-color: #843945
.hvcbuttonsouter
  overflow: hidden
  float: right
.hvtop
  border-radius: 10px 10px 0 0
.hvbottom
  border-radius: 0 0 10px 10px

.hvright
  border-radius: 0px 10px 10px 0
  margin-top: 3px
  float: left
  margin-top: 9px

.hvleft
  border-radius: 10px 0 0 10px
  margin-top: 3px
  float: left
  margin-top: 9px

.hvcenterctr
  float: left
</style>