import { render, staticRenderFns } from "./Canvas.vue?vue&type=template&id=f3965ac2"
import script from "./Canvas.vue?vue&type=script&lang=js"
export * from "./Canvas.vue?vue&type=script&lang=js"
import style0 from "./Canvas.vue?vue&type=style&index=0&id=f3965ac2&prod&lang=sass"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QToggle from 'quasar/src/components/toggle/QToggle.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QLinearProgress from 'quasar/src/components/linear-progress/QLinearProgress.js';
import QField from 'quasar/src/components/field/QField.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QTabs,QTab,QTabPanels,QTabPanel,QBtnDropdown,QBtn,QTooltip,QList,QItem,QItemSection,QItemLabel,QSeparator,QToggle,QBtnToggle,QLinearProgress,QField,QMenu});qInstall(component, 'directives', {ClosePopup});
