<template>
  <div>
    <div class="text-h4 q-mb-md">Личные данные</div>
    <div style="max-width: 300px">
      <q-form @submit="saveSettings()" autocomplete="off">
        <q-input
          v-model="email"
          label="Email"
          class="q-mb-md"
          dark
          color="yellow-5"
          type="email"
          required
          dense
        />
        <q-input
          dense
          v-model="login"
          label="Логин"
          class="q-mb-md q-pb-none"
          dark
          color="yellow-5"
          lazy-rules
          :rules="[
            (val) => (val && val.length > 0) || 'Логин не указан',
            (val) =>
              !/[а-яА-ЯёЁ]/g.test(val) ||
              'Пожалуйста, не используйте русские буквы',
          ]"
        />
        <q-input
          v-model="password"
          label="Пароль"
          class="q-mb-lg"
          dark
          color="yellow-5"
          :type="isPwd ? 'password' : 'text'"
          lazy-rules
          dense
          :rules="[
            (val) =>
              !/[а-яА-ЯёЁ]/g.test(val) ||
              'Пожалуйста, не используйте русские буквы',
          ]"
        >
          <template v-slot:append>
            <q-icon
              :name="isPwd ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="isPwd = !isPwd"
            />
          </template>
        </q-input>
        <q-btn
          :loading="accountchangeprogress"
          color="secondary"
          unelevated
          no-caps
          type="submit"
          class="full-width q-mt-lg"
        >
          Изменить
          <template v-slot:loading>
            <q-spinner-hourglass class="on-left" />Изменение данных..
          </template>
        </q-btn>
        <div
          v-show="success"
          class="q-mt-lg q-pa-md rounded-borders tip-success"
        >
          Изменения сохранены
        </div>
      </q-form>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      login: this.$store.getters.ACCOUNT.login,
      password: this.$store.getters.ACCOUNT.password,
      email: this.$store.getters.ACCOUNT.email,
      isPwd: true,
      accountchangeprogress: false,
      success: false
    };
  },
  computed: {
    details() {
      return JSON.stringify(this.$store.getters.DETAILS);
    }
  },
  watch: {
    details() {
      this.success = true;
      setTimeout(() => (this.success = false), 3000);
    }
  },
  methods: {
    async saveSettings() {
      this.accountchangeprogress = true;
      this.$store.dispatch("CHANGE_USER_SETTINGS", {
        login: this.login,
        password: this.password,
        email: this.email
      });
      await new Promise(resolve => setTimeout(resolve, 1500));
      this.accountchangeprogress = false;
    }
  }
};
</script>

<style scoped>
</style>